
import React, { useState } from 'react';
import AppContext from '../../../../ContextAPI/AppContext';
import '../LeftSidebarStyle.scss'
import Arrow from "../../../../Assets/Icons/next.png";
import Avatar from '../../../../Components/Avatar/avatar';
import { getDPService } from '../../../../services/DPService';




const SingleContact = (props) => {

    const [showOptions, setShowOption] = useState(false)
    const [showDropDown, setShowDropDown] = useState(false)

    const handleOptions = () => {
        setShowOption(!showOptions)
    }

    const handleChildClick = (e) => {
        e.stopPropagation();
    }


    return (
        <div>
            <AppContext.Consumer>
                {(context) => (

                    <div className='single-block'
                        onMouseEnter={() => {
                            setShowDropDown(true)

                        }}
                        onMouseLeave={() => {
                            setShowDropDown(false)
                            setShowOption(false)

                        }}

                        onClick={props.onClick}
                    >

                        <div className='single-contact-left'>
                            <div className="contact-avatar-wrapper">
                                {props.blocklist ? props?.value?.contact?.email !== undefined ?
                                    <Avatar
                                        size='l'
                                        src={props?.value?.contact?.avatar === null || props?.value?.contact?.avatar === "null" || props?.value?.contact?.avatar === '' ?
                                            "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : props?.value?.contact?.avatar
                                        }
                                    /> :
                                    props.blocklist ? props?.value?._cls === "ChatRoom.PrivateChatRoom" ?
                                        <Avatar
                                            size='l'
                                            src={props?.value?.contact?.avatar === null || props?.value?.contact?.avatar === "null" || props?.value?.contact?.avatar === '' ?
                                                "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : getDPService().getDP(props?.value?._cls, props.value.profile_public_id)
                                            }
                                        />

                                        :

                                        <div className="email-profile-picture-dp">{props?.value?.profile_name.charAt(0)}</div> : "" :
                                    props?.value?.contact?.email !== undefined ?
                                        <Avatar
                                            size='l'
                                            src={props?.value?.contact?.avatar === null || props?.value?.contact?.avatar === "null" || props?.value?.contact?.avatar === '' ?
                                                "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : props?.value?.contact?.avatar
                                            }
                                        /> :
                                        props?.value?._cls === "ChatRoom.EmailChatRoom" ? <div className="email-profile-picture-dp">{props?.value?.profile_name.charAt(0)}</div> :

                                            <Avatar
                                                size='l'
                                                src={getDPService().getDP(props?.value?._cls, props.value.profile_public_id) !== undefined || getDPService().getDP(props?.value?._cls, props.value.profile_public_id) !== '' || getDPService().getDP(props?.value?._cls, props.value.profile_public_id) !== 'null' || getDPService().getDP(props?.value?._cls, props.value.profile_public_id) !== null ? getDPService().getDP(props?.value?._cls, props.value.profile_public_id) :
                                                    getDPService().getDP(props?.value?._cls, props.value.profile_public_id) === undefined || getDPService().getDP(props?.value?._cls, props.value.profile_public_id) === '' || getDPService().getDP(props?.value?._cls, props.value.profile_public_id) === 'null' || getDPService().getDP(props?.value?._cls, props.value.profile_public_id) === null ? "" : 'https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png'}
                                            />}

                            </div>

                            <div className="contact-details">
                                <div className="contact-name">
                                    {props.blocklist ? props?.value?.contact?.email !== undefined ?
                                        props?.value?.contact?.name :
                                        props?.value?._cls === "ChatRoom.PrivateChatRoom" ? props?.value?.chatroom_name :
                                            props?.value?._cls === "ChatRoom.EmailChatRoom" ? props?.value?.profile_name : ""
                                        : props?.value?.contact?.email !== undefined ? props?.value?.contact?.name :
                                            props?.value?._cls === "ChatRoom.PrivateChatRoom" ? props?.value?.chatroom_name : props?.value?._cls === "ChatRoom.EmailChatRoom" ?
                                                props?.value?.profile_name : props?.value?.chatroom_name === undefined ? props?.value?.full_name : ''}

                                </div>


                                {!props.fromSugestion ? props?.isBlocked ? <div>Blocked</div> :
                                    props?.value?.contact?.email !== undefined ?
                                        <div>{props?.value?.role ? props?.value?.role : 'Contact'}</div> : '' : ''
                                }

                                {props.fromSugestion ? <div>{props?.value?.contact?.email !== undefined ? 'Contact' : props?.value?.profile_email ? props?.value?.profile_email : props?.value?.email}</div> : ''}

                            </div>



                        </div>
                        <div className='single-contact-right'>
                            {props.isBlocked && showDropDown ?
                                <div className="dropdown-div">
                                    <img src={Arrow} className='arrow-dropdown'
                                        onClick={(e) => {
                                            handleOptions()
                                            handleChildClick(e)

                                        }}
                                        alt=""
                                    />
                                </div> : ''}


                            {showOptions ?
                                <div
                                    className="pop-up1 box-style1"
                                    style={{
                                        marginTop: '-15px'
                                    }}
                                >

                                    {/* {} */}

                                    <div
                                        className="item1"
                                        onClick={() => {
                                            setShowOption(false)
                                            context.blockContact(props.value._id["$oid"], 'unblock')
                                            context.getBlockList(context.allChatrooms)
                                            context?.setBlockList(context?.blockList?.filter(
                                                (item) => item._id["$oid"] !== props.value._id["$oid"]
                                            ));

                                            context?.setBlockedContacts(context?.blockedContacts?.filter(
                                                (item) => item._id["$oid"] !== props.value._id["$oid"]
                                            ));

                                            context?.setBlockedNonContacts(context?.blockedNonContacts?.filter(
                                                (item) => item._id["$oid"] !== props.value._id["$oid"]
                                            ));
                                        }}
                                    >
                                        Unblock
                                    </div>

                                </div>
                                : ''}
                        </div>


                    </div>
                )
                }
            </AppContext.Consumer>
        </div>
    )



};

export default SingleContact;