import React from "react";
import AgreementContent from "../../../Content/AgreementContent";
import "./AgreementStyles.scss"
const Agreement = () => {
    

    return (
        <div className="_Agreement">
           <AgreementContent/>
        </div>

    )
}
export default Agreement