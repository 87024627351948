import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../../../ContextAPI/AppContext";
import "./PasswordChangeStyles.scss";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
import UsernameInput from "../../../Components/InputComponents/UsernameInput/UsernameInput";
import Carousel from "../../../Components/Carousel/Carousel";
import Button from "../../../Components/Button/Button";
import check from "../../../Assets/Icons/checked.png";
import cross from "../../../Assets/Icons/cross.png";
const PasswordChange = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [userName, setUserName] = useState("");
    const [isUsernameFocus, setIsUsernameFocus] = useState(false);
    const forgotBtn = useRef(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [pwdError, setPwdError] = useState(false);
    const [isNewPasswordFocus, setIsNewPasswordFocus] = useState(false);
    const [isConfirmPasswordFocus, setIsConfirmPasswordFocus] = useState(false);
    const location = useLocation();
    const [pwdLength, setPwdLength] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [lowerCase, setLowerCase] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);
    const [numVal, setNumVal] = useState(false);
    const [passwordInputStatus, setPasswordInputStatus] = useState(0);
    const [confirmPassStatus, setConfirmPassStatus] = useState(0);
    const [userNameError, setUserNameError] = useState(false);
    const newContext = useContext(AppContext)
    const [pwdFormat, setPwdFormat] = useState(false);


    const handleUsername = (e) => {
        let userNameValue = e.target.value.trim();
        if (userNameError) {
            if (userNameValue !== "") {
                setUserNameError(false);
            }
        }
        setUserName(userNameValue);
    };
    useEffect(() => {
        let token = window.location.href.split("?token=")[1]
        if (token !== undefined || token !== "") {
            localStorage.setItem("bixChat_resetToken", token)
        }
    }, [])
    const passwordValidation=()=>{
        let passwordValue = newPassword;
        if (passwordValue.length === 0 || passwordValue === "") {
            setPasswordInputStatus(0);
            setPwdLength(false);
            setLowerCase(false);
            setUpperCase(false);
            setSpecialChar(false);
            setNumVal(false);
        } else {
            if (/[A-Z]/.test(passwordValue)) {
                setUpperCase(true);
            } else {
                setUpperCase(false);
            }
            if (/[a-z]/.test(passwordValue)) {
                setLowerCase(true);
            } else {
                setLowerCase(false);
            }
            if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue)) {
                setSpecialChar(true);
            } else {
                setSpecialChar(false);
            }

            if (/\d/.test(passwordValue)) {
                setNumVal(true);
            } else {
                setNumVal(false);
            }
            if (passwordValue.length < 8) {
                setPwdLength(false);
            } else {
                setPwdLength(true);
            }
            if (
                passwordValue.length >= 8 &&
                numVal &&
                upperCase &&
                lowerCase &&
                specialChar
            ) {
                setPasswordInputStatus(1);
                setPwdFormat(false)
            } else {
                setPasswordInputStatus(0);
                setPwdFormat(true)
            }
        }
    }
    useEffect(() => {
       passwordValidation()
    }, [newPassword]);
    const handleConfirm = (e) => {
        let confirm_password = e.target.value;
        if (confirmPassword.length > 0) {
            if (confirmPassword !== newPassword) {
                setConfirmPassStatus(2);
            } else {
                setConfirmPassStatus(1);
            }
        } else {
            setConfirmPassStatus(0);
        }
        if (confirmPasswordError) {
            if (confirm_password !== "") {
                setConfirmPasswordError(false);
            }
        }
        setConfirmPassword(confirm_password);
    };
    useEffect(() => {
        if (
            newPassword.length >= 8 &&
            numVal &&
            upperCase &&
            lowerCase &&
            specialChar
        ) {
            setPasswordInputStatus(1);
        }

    });
    const handlePassword = (e) => {
        let passwordValue = e.target.value;
        if (pwdError) {
            if (passwordValue !== "") {
                setPwdError(false);
            }
        }
        setNewPassword(passwordValue);
    };
    const confirmPassValidation = () => {
        if (confirmPassword.length > 0) {
            if (confirmPassword !== newPassword) {
                return (
                    <div className="form-fail" style={{marginTop:"5px"}}>
                        <img src={danger} alt="" className="danger-image" />
                        <p>Please make sure passwords match.</p>
                    </div>
                );
            } else if (confirmPassStatus === 1) {
                return "";
            }
        }
    };
    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter") {
                if (newPassword === "" && confirmPassword === "") {
                    setPwdError(true);
                    setConfirmPasswordError(true);
                } else if (newPassword === "" && confirmPassword !== "") {
                    setPwdError(true);
                } else if (newPassword !== "" && confirmPassword === "") {
                    setConfirmPasswordError(true);
                } else {
                    setPwdError(false);
                    setConfirmPasswordError(false);
                    forgotBtn.current.click();
                }
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [newPassword, confirmPassword]);
    const removeFocus = () => {
        setIsUsernameFocus(false)
        setIsNewPasswordFocus(false);
        setIsConfirmPasswordFocus(false);
    };
    useEffect(() => {
        if (newContext.passwordChangeLinkError) {
            setUserName("")
            setNewPassword("")
            setConfirmPassword("")
        }

    }, [newContext.passwordChangeLinkError])

    return (
        <AppContext.Consumer>
            {(context) => (
                <div>
                    <div className="_forgotContainer">
                        <div className="_forgotInnerContainerLeft">
                            <div className="wrapper">
                                <div className="bixHeader">ZapMail</div>
                                <div className="header-bottom-text">A smarter way to connect.</div>
                                <div className="form-wrapper">
                                    <div className="input-wrapper username-margin-top-forgot">
                                        <div className="forgotLabel">Reset Password</div>

                                        <div>
                                            {isUsernameFocus || userName !== "" ? (
                                                <label
                                                    className="input-label"
                                                    style={{ color: isUsernameFocus ? "#0094ff" : "" }}
                                                >
                                                    Email
                                                </label>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div
                                            className="inputdiv"
                                            style={{
                                                borderColor:
                                                    (userName === "" && context?.loggedFailed) ||
                                                        (userName !== "" && context?.loggedFailed) ||
                                                        (userName === "" && userNameError)
                                                        ? "#ff0000"
                                                        : isUsernameFocus
                                                            ? "#0d2a4c"
                                                            : "#9d9d9d",
                                            }}
                                        >
                                            <UsernameInput
                                                type="text"
                                                isUsernameFocus={isUsernameFocus}
                                                placeholder={isUsernameFocus ? "" : "Email"}
                                                value={userName}
                                                onChange={(e) => {
                                                    context?.setPasswordLinkError(false)
                                                    context?.setLoggedFailed(false);
                                                    handleUsername(e);
                                                }}
                                                userNameError={userNameError}
                                                onFocus={() => setIsUsernameFocus(true)}
                                                onBlur={() => setIsUsernameFocus(false)}
                                                name="username"
                                                from={location?.state?.from}
                                            />
                                        </div>
                                        {userNameError ? (
                                            <div className="form-fail" style={{marginTop:"5px"}}>
                                                * Required 
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {context.passwordChangeLinkError ? <div className="form-fail" style={{marginTop:"5px"}}>
                                            <img src={danger} alt="" className="danger-image" />
                                            Invalid Email
                                        </div> : ""}

                                        <div className="margin-top-20">

                                            {isNewPasswordFocus || newPassword !== "" ? (
                                                <label
                                                    className="input-label"
                                                    style={{ color: isNewPasswordFocus ? "#0094ff" : "" }}
                                                >
                                                    New Password
                                                </label>
                                            ) : (
                                                ""
                                            )}
                                        </div>



                                        <div
                                            className="inputdiv"
                                            style={{
                                                borderColor:
                                                    (newPassword === "" && passwordInputStatus === 1) ||
                                                        (newPassword !== "" && passwordInputStatus !== 1) || pwdError  || pwdFormat &&
                                                        newPassword.length !== 0 
                                                        ? "#ff0000"
                                                        : isNewPasswordFocus
                                                            ? "#0d2a4c"
                                                            : "#9d9d9d",
                                            }}
                                        >
                                            <UsernameInput
                                                type={showPassword ? "text" : "password"}
                                                placeholder={isNewPasswordFocus ? "" : "New Password"}
                                                onChange={(e) => {
                                                    handlePassword(e);
                                                }}
                                                isPwdFocus={isNewPasswordFocus}
                                                pwdError={pwdError}
                                                onFocus={() => setIsNewPasswordFocus(true)}
                                                onBlur={() => setIsNewPasswordFocus(false)}
                                                name="password"
                                                value={newPassword}
                                            />
                                        </div>
                                        {pwdError ? (
                                            <div className="form-fail" style={{marginTop:"5px"}}>
                                                * Required
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                         {pwdFormat && !isNewPasswordFocus &&
                                            newPassword.length !== 0 ? (
                                            <div className="form-fail" style={{marginTop:"5px"}}>
                                                <img src={danger} alt="" className="danger-image" />
                                                The Password does not meet requirements
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {isNewPasswordFocus &&
                                            newPassword.length !== 0 &&
                                            passwordInputStatus !== 1 ? (
                                            <div className="condition-strength-2">
                                                <div className={upperCase ? "item" : "item2"}>
                                                    {" "}
                                                    {upperCase ? (
                                                        <img src={check} alt="" className="danger-image2" />
                                                    ) : (
                                                        <img src={cross} alt="" className="danger-image2" />
                                                    )}
                                                    Contains an uppercase letter
                                                </div>
                                                <div className={lowerCase ? "item" : "item2"}>
                                                    {lowerCase ? (
                                                        <img src={check} alt="" className="danger-image2" />
                                                    ) : (
                                                        <img src={cross} alt="" className="danger-image2" />
                                                    )}
                                                    Contains a lowercase letter
                                                </div>
                                                <div className={numVal ? "item" : "item2"}>
                                                    {numVal ? (
                                                        <img src={check} alt="" className="danger-image2" />
                                                    ) : (
                                                        <img src={cross} alt="" className="danger-image2" />
                                                    )}
                                                    Contains a number
                                                </div>
                                                <div className={specialChar ? "item" : "item2"}>
                                                    {specialChar ? (
                                                        <img src={check} alt="" className="danger-image2" />
                                                    ) : (
                                                        <img src={cross} alt="" className="danger-image2" />
                                                    )}
                                                    Contains a special character
                                                </div>
                                                <div className={pwdLength ? "item" : "item2"}>
                                                    {pwdLength ? (
                                                        <img src={check} alt="" className="danger-image2" />
                                                    ) : (
                                                        <img src={cross} alt="" className="danger-image2" />
                                                    )}
                                                    Password strength 8 characters
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="input-wrapper username-margin-top-forgot">
                                        <div className="margin-top-20">
                                            {isConfirmPasswordFocus || confirmPassword !== "" ? (
                                                <label
                                                    className="input-label"
                                                    style={{ color: isConfirmPasswordFocus ? "#0094ff" : "" }}
                                                >
                                                    Confirm Password
                                                </label>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div
                                            className="inputdiv"
                                            style={{
                                                borderColor:
                                                    confirmPassword !== "" && confirmPassword !== newPassword
                                                        ? "#ff0000"
                                                        : isConfirmPasswordFocus
                                                            ? "#0d2a4c"
                                                            : "#9d9d9d",
                                            }}
                                        >
                                            <UsernameInput
                                                type={showPassword ? "text" : "password"}
                                                placeholder={isConfirmPasswordFocus ? "" : "Confirm Password"}
                                                onChange={(e) => {
                                                    handleConfirm(e);
                                                }}
                                                isPwdFocus={isConfirmPasswordFocus}
                                                pwdError={confirmPasswordError}
                                                onFocus={() => setIsConfirmPasswordFocus(true)}
                                                onBlur={() => setIsConfirmPasswordFocus(false)}
                                                name="password"
                                                value={confirmPassword}
                                            />
                                        </div>
                                        {confirmPassValidation()}

                                    </div>

                                    <Button
                                        ref={forgotBtn}
                                        loading={context?.changePasswordIsLoading}
                                        buttonText="Reset Password"
                                        onClick={() => {
                                            removeFocus()
                                            context.changePassword(userName.toLowerCase(), newPassword, confirmPassword)

                                        }}
                                    />


                                </div>
                            </div>
                        </div>
                        <div className="_forgotInnerContainerRight">
                            <div className="wrapper2">
                                <Carousel />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    );
};
export default PasswordChange;
