import React, { useContext, useEffect } from "react";
import AppContext from "../../../ContextAPI/AppContext";
import multiDownload from "multi-download";
import reply from ".././../../Assets/Icons/reply.png";
import upload from ".././../../Assets/Icons/upload.png";
import at from ".././../../Assets/Icons/at-sign.png";
import setAs from ".././../../Assets/Icons/file.png";
import bixSave from "../../../Assets/Icons/vuesax-linear-hierarchy-2.svg";
import Feature from "../../../Feature/Feature";
import report from ".././../../Assets/Icons/report.svg";
import details from ".././../../Assets/Icons/detailsicon.png";




const MorePopup = (props) => {
  const newContext = useContext(AppContext)

  useEffect(() => {
    newContext.selectedReply?.map((message) => {
      newContext.setToList(message.to_list)
      newContext.setCcList(message.cc_list)
      newContext.setCcMessage(message._cls)


    }
    )


  })


  const handleSave = () => {
    if (!props.isSaveToCloud) {
      props.setIsSaveToCloud(true);
    }
  }


  const forwardMessageComponent = () => {
    return (
      <div className="item" onClick={() => {
        newContext?.setSendMessageType("multiUser");
        newContext?.setForwardModal(true)
        newContext.setForwardMessageID(props.message._id["$oid"])

      }}>
        <img alt="" src={upload} className="img-3" />
        Forward Message
      </div>
    )
  }

  const joinOthers = () => {
    return (
      <div>
        <div className="item">
          <img alt="" src={at} className="img-2" />
          Join Others
        </div>
      </div>
    )

  }

  const saveToCloud = () => {
    return (
      <div>
        {props.message.attachments.length > 0 ? props.message.attachments.length === 1 ?
          <div className="item"
            onClick={handleSave}
          >
            <img alt="" src={bixSave} className="img-2" />
            Save to bixCloud
          </div> :
          <div className="item"
            onClick={handleSave}
          >
            <img alt="" src={bixSave} className="img-2" />
            Save to bixCloud
          </div>
          : ""}
      </div>
    )
  }

  return (
    <AppContext.Consumer>
      {(context) =>

        <div
          className="pop"
          style={{
            display:
              props.message?._id === props.selectedMore
                ? props.moreIcon
                : "none",
            flexDirection: "row-reverse",
            // marginRight: props.message?.attachments.length > 0 &&
            //       props.message?.message_body === ""
            //       ? "-150px"
            //       : "-165px",
            right: 10,
            bottom:
              props.i + 1 ===
                context?.selectedChatroomDatas?.threads
                  ?.length
                ? "-1vh"
                : "",
          }}
        >
          {/* {localStorage.getItem(
            "bixChat_loggedUserPublicId"
          ) === props.message?.sender_public_id ? "" :

            <div className="item1">
              <div
                className="it"
                onClick={() => {
                  mixpanel.track("Reaction reply")
                  context?.reactMessage(
                    "👌",
                    props.message?._id["$oid"],
                    context?.selectedChatroomDatas
                      ?.chatroomData._id["$oid"]
                  );
                }}
              >
                👌
              </div>
              <div
                className="it"
                onClick={() => {
                  mixpanel.track("Reaction reply")
                  context?.reactMessage(
                    "👍",
                    props.message?._id["$oid"],
                    context?.selectedChatroomDatas
                      ?.chatroomData._id["$oid"]
                  );
                }}
              >
                👍
              </div>
              <div
                className="it"
                onClick={() => {
                  mixpanel.track("Reaction reply")
                  context?.reactMessage(
                    "✅",
                    props.message?._id["$oid"],
                    context?.selectedChatroomDatas
                      ?.chatroomData._id["$oid"]
                  );
                }}
              >
                ✅
              </div>
              <div
                className="it"
                onClick={() => {
                  mixpanel.track("Reaction reply")
                  context?.reactMessage(
                    "❌",
                    props.message?._id["$oid"],
                    context?.selectedChatroomDatas
                      ?.chatroomData._id["$oid"]
                  );
                }}
              >
                ❌
              </div>
            </div>
          } */}
          <div
            className="item"
            onClick={() => {
              context.setSelectedReply(props.singleTread);
              props.setIsReply("block");
              props.setSelectedTabs("reply");
              context?.setReplySubject(true);
            }}
          >
            <img alt="" src={reply} className="img" />
            Reply Message
          </div>
          {forwardMessageComponent()}
          {localStorage.getItem(
            "bixChat_loggedUserPublicId"
          ) === props.message?.sender_public_id ? "" :

            <div className="item" onClick={() => {
              newContext?.setReportModal(true)
              context.setThreadIdNum(props.message._id.$oid)
              context.setReportEmail(context?.selectedChatroomDatas.chatroomData.profile_email)

            }}>

              <img alt="" src={report} className="img-3" />
              Report
            </div>

          }


          <div className="item" onClick={() => {

            context?.setRightSideBarStatus(true);
            context?.setContentToShow("details");
          }}>

            <img alt="" src={details} className="img-3" />
            Details
          </div>





          {/* <div className="item"
              onClick={handleSave}
            >
              <img alt="" src={bixSave} className="img-2" />
              Save to bixCloud
            </div> */}

          {/* {props.message.attachments.length > 0 ? props.message.attachments.length === 1 ? 
          <div className="item"
            onClick={handleSave}
          >
            <img alt="" src={bixSave} className="img-2" />
            Save to bixCloud
          </div> :
            <div className="item"
              onClick={handleSave}
            >
              <img alt="" src={bixSave} className="img-2" />
              Save to bixCloud
            </div>
            : ""} */}

          <Feature name='save-to-cloud' activeComponent={saveToCloud()} inactivecomponent={null} />


          <Feature name='join-others' activeComponent={joinOthers()} inactivecomponent={null} />
          {props.message.attachments.length > 0 ? props.message.attachments.length === 1 ? <a href={props.attachments[0]}><div className="item">
            <img alt="" src={setAs} className="img-2" />
            Download
          </div> </a> : <div className="item"
            onClick={() => {
              multiDownload(props.attachments);
            }}
          >
            <img alt="" src={setAs} className="img-2" />
            Download All
          </div> : ""}



        </div>


      }
    </AppContext.Consumer>
  )


}
export default MorePopup