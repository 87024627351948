import React, { useState, useRef, useContext, useEffect } from "react"
import AppContext from "../../../ContextAPI/AppContext";
import image from "../../../Assets/Icons/logout-new.png";
import close from "../../../Assets/Icons/cross-square-button.svg";
import tick from "../../../Assets/Icons/tick.svg";
import clear from "../../../Assets/Icons/close-black.svg";
import "./ForwardModalStyles.scss";
import { getDPService } from "../../../services/DPService";


const ForwardModal = (props) => {
    const conText = useContext(AppContext)
    const [members, setMembers] = useState([])
    const [selected, setSelected] = useState([])
    const [recievers, setRecievers] = useState([])
    const [emailReceivers, setEmailReceivers] = useState([])

    localStorage.setItem("bixChat_adminPublicId", selected.public_id)
    const input = useRef(null)
    const searchMember = (e) => {
        let array = conText.allChatrooms.filter((item) => item.profile_name.toLowerCase().includes(e.target.value))
        setMembers(array)
    }



    const showProPic = (e, name) => {
        if (e === "ChatRoom.PrivateChatRoom") {
            return (
                <img
                    className="profile-picture"
                    src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                    alt="test"
                />
            );
        } else if (e === "ChatRoom.EmailChatRoom") {
            return <div className="email-profile-picture">{name.charAt(0)}</div>;
        } else if (e === "ChatRoom.GroupChatRoom") {
            return (
                <img
                    className="profile-picture"
                    src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/group_dp.png"
                    alt="test"
                />
            );
        }
    };

    console.log(emailReceivers, recievers);

    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_forwardModal" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
                    <div id="myModal" className="modal-1">
                        <div className="modal-content-top" style={{ marginTop: context.searchList.length > 0 ? "6%" : "" }}>
                            <div className="modal-content-add-admin">
                                <div className="plus-container-left">
                                    <img alt="" src={image} className="plus" />
                                </div>

                                <div className="add-header">Forward Message</div>
                                <img alt="" src={close} className="close"
                                    onClick={() => { context?.setForwardModal(false) }} />

                            </div>

                            <div className="inner-container">
                                <div className="search-container">
                                    <div className="search-div" style={{ display: selected.length > 0 ? "flex" : "none" }}>
                                        {selected.map((value) => {
                                            return (
                                                <div className="name-container">
                                                    <div className="name-div">{value.profile_name}</div>
                                                    <img alt="" src={clear} className="clear" onClick={() => {
                                                        let arr = selected.filter(
                                                            (item) => item !== value
                                                        );
                                                        setSelected(arr);
                                                        input.current.focus()
                                                    }} />
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <input
                                        type="text"
                                        className="search-input"
                                        id="searchInput"
                                        ref={input}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                context.searchContact(e, [])
                                            }
                                        }}
                                        style={{ marginTop: selected }} />


                                </div>
                                <div className="group-members-container">
                                    {context.searchList.length > 0 ? (
                                        <div>
                                            <div className="header-members">
                                                Contacts from BixChat

                                            </div>
                                            <div className="memebrs-list" id="myList">
                                                {context.searchList.map((value, i) => {
                                                    return (

                                                        <div className="members" key={i}
                                                            id="item"
                                                            onClick={() => {

                                                                if (!selected.filter((s) =>
                                                                    s._id === value._id
                                                                ).length > 0) {
                                                                    if (value._cls) {
                                                                        const obj = {
                                                                            _id: value._id,
                                                                            profile_name: value?._cls === "ChatRoom.GroupChatRoom" ? value.group_title : value.profile_name,
                                                                            _cls: value._cls
                                                                        }
                                                                        setSelected([...selected, obj])
                                                                        setRecievers([...recievers, value._id["$oid"]])
                                                                    } else {
                                                                        const obj = {
                                                                            _id: value._id,
                                                                            profile_name: value.email,
                                                                            _cls: value._cls
                                                                        }
                                                                        setSelected([...selected, obj])
                                                                        console.log(value.email);
                                                                        setEmailReceivers([...emailReceivers, value.email])
                                                                    }

                                                                }
                                                                input.current.value = ""
                                                                input.current.focus()
                                                                setMembers([])

                                                            }}>
                                                            {console.log(value, "value")}
                                                            <div className="circle-add">

                                                                {value?._cls === "ChatRoom.GroupChatRoom" ? (
                                                                    getDPService().getDP(value._cls, value._id["$oid"]) &&
                                                                        getDPService().getDP(value._cls, value._id["$oid"]) !== "None" ? getDPService().getDP(value._cls, value._id["$oid"]) !== "null" ? (
                                                                            <img
                                                                                className="img"
                                                                                src={getDPService().getDP(value._cls, value._id["$oid"])}
                                                                                alt=""
                                                                            />
                                                                        ) : <img
                                                                        className="img"
                                                                        src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/group_dp.png"
                                                                        alt=""
                                                                    /> : (
                                                                        showProPic(value._cls, value?.group_title
                                                                            ? value?.group_title
                                                                            : value?.email_receiver)
                                                                    )
                                                                ) : value?._cls === "ChatRoom.EmailChatRoom" ? (
                                                                    showProPic(value._cls, value?.group_title
                                                                        ? value?.group_title
                                                                        : value?.email_receiver)
                                                                ) : getDPService().getDP(value._cls, value.profile_public_id) ? getDPService().getDP(value._cls, value.profile_public_id) !== "null" ? (
                                                                    <img
                                                                        className="img"
                                                                        src={getDPService().getDP(value._cls, value.profile_public_id)}
                                                                        alt=""
                                                                    />
                                                                ) : <img
                                                                    className="img"
                                                                    src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                                                    alt=""
                                                                /> : (
                                                                    showProPic(value._cls, value?.group_title
                                                                        ? value?.group_title
                                                                        : value?.email_receiver)
                                                                )}

                                                            </div>
                                                            <div className="div-name">
                                                                <div className="name" id="name">
                                                                    {/* {value?.group_title ? value?.group_title : value?.profile_name ? value?.profile_name : value?.email_receiver} */}
                                                                    {value.profile_name ? value.profile_name : value.full_name}

                                                                </div>




                                                            </div>
                                                        </div>


                                                    )
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {context?.allChatrooms.length > 0 ? (
                                        <div>
                                            <div className="header-members">Recent Chat</div>
                                            <div className="memebrs-list" id="myList">
                                                {context?.allChatrooms?.map((value, i) => {
                                                    return (

                                                        <div className="members" key={i}
                                                            id="item"
                                                            onClick={() => {
                                                                if (!selected.filter((s) =>
                                                                    s._id === value._id).length > 0) {
                                                                    const obj = {
                                                                        _id: value._id,
                                                                        profile_name: value?._cls === "ChatRoom.GroupChatRoom" ? value.group_title : value.profile_name,
                                                                        _cls: value._cls
                                                                    }
                                                                    setSelected([...selected, obj])
                                                                    setRecievers([...recievers, value._id["$oid"]])

                                                                }
                                                                input.current.focus()

                                                            }}>
                                                            <div className="circle-add">
                                                                {value?._cls === "ChatRoom.GroupChatRoom" ? (
                                                                    getDPService().getDP(value._cls, value._id["$oid"]) &&
                                                                        getDPService().getDP(value._cls, value._id["$oid"]) !== "None" ? getDPService().getDP(value._cls, value._id["$oid"]) !== "null" ? (
                                                                            <img
                                                                                className="img"
                                                                                src={getDPService().getDP(value._cls, value._id["$oid"])}
                                                                                alt=""
                                                                            />
                                                                        ) : <img
                                                                        className="img"
                                                                        src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/group_dp.png"
                                                                        alt=""
                                                                    /> : (
                                                                        showProPic(value._cls, value?.group_title
                                                                            ? value?.group_title
                                                                            : value?.email_receiver)
                                                                    )
                                                                ) : value?._cls === "ChatRoom.EmailChatRoom" ? (
                                                                    showProPic(value._cls, value?.group_title
                                                                        ? value?.group_title
                                                                        : value?.email_receiver)
                                                                ) : getDPService().getDP(value._cls, value.profile_public_id) ? getDPService().getDP(value._cls, value.profile_public_id) !== "null" ? (
                                                                    <img
                                                                        className="img"
                                                                        src={getDPService().getDP(value._cls, value.profile_public_id)}
                                                                        alt=""
                                                                    />
                                                                ) : <img
                                                                    className="img"
                                                                    src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                                                    alt=""
                                                                /> : (
                                                                    showProPic(value._cls, value?.group_title
                                                                        ? value?.group_title
                                                                        : value?.email_receiver)
                                                                )}

                                                            </div>
                                                            <div className="div-name">
                                                                <div className="name" id="name">
                                                                    {value?.group_title
                                                                        ? value?.group_title
                                                                        : value?.profile_name ? value?.profile_name : value?.email_receiver}

                                                                </div>


                                                            </div>
                                                        </div>


                                                    )

                                                })}
                                            </div></div>
                                    ) : ""}

                                </div>
                            </div>
                            <div className="circle" onClick={() => {
                            }}>{context.forwardLoading ? <div className="loader" /> :
                                <img alt="" src={tick} style={{ height: 30, width: 30 }}
                                    onClick={() => {
                                        context.setForwadLoading(true)
                                        context.handleForwardMessage(recievers, emailReceivers)

                                    }}
                                />}


                            </div>


                        </div>
                    </div>
                </div>
            )}</AppContext.Consumer>
    )
}
export default ForwardModal