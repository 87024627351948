import "./DragDropStyles.scss"
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import AppContext from "../../../../ContextAPI/AppContext";


const DragDrop = (props) => {

    return (props.trigger) ? (

        <AppContext.Consumer>
            {context => (
                <div className="dropzone">
                    <FileUploader
                        handleChange={context?.dropHandleFiles}
                        name="files"
                        multiple={true}
                        disabled={true}
                    />
                </div>
            )}
        </AppContext.Consumer>

    ) : "";


}

export default DragDrop