import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import AppContext from "../../../ContextAPI/AppContext";
import star from ".././../../Assets/Icons/star.png";
import MorePopup from "./MorePopup";
import reactions from "../../../reactions";
import AttachmentPreviewComponent from "./AttachmentPreviewComponent";
import AudioComponent from "../../../Components/AudioComponent/AudioComponent";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import localStorage from "react-secure-storage";
import replyButton from "../../../Assets/Icons/inline-reply.png";
import moreIcon from "../../../Assets/Icons/more-icon.svg";
import ContentLoader from "react-content-loader"

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */



const InlineMessageComponent = (props) => {
	const [copied, setCopied] = useState("")
	const [isHyperlink, setIsHyperlink] = useState(false)
	const newContext = useContext(AppContext)
	const percentage = newContext.uploadPercentage;
	const [loaderWidthSize, setLoaderWidthSize] = useState(0)

	let reactionIcon = "";

	let messageText;
	let url;
	// const [isEmail, setIsEmail] = useEffect()

	// const getDocument = (attachment) => {
	// 	if (attachment.includes(".pdf")) {
	// 		return pdf;
	// 	} else if (attachment.includes(".doc") || attachment.includes(".docx")) {
	// 		return doc;
	// 	} else if (attachment.includes(".ppt")) {
	// 		return ppt;
	// 	} else if (attachment.includes(".csv")) {
	// 		return csv;
	// 	} else if (attachment.includes(".xlsx")) {
	// 		return excel;
	// 	} else if (attachment.includes(".txt")) {
	// 		return txt;
	// 	} else {
	// 		return attachment;
	// 	}
	// };

	const checkURL = (messageBody) => {
		let linkPattern = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/);
		return linkPattern.test(messageBody);
	}

	//checking if string is an email
	const checkEmail = (messageBody) => {
		let emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
		return emailPattern.test(messageBody);
	}

	//checking if email contains a protocol
	const checkProtocol = (messageBody) => {
		if (messageBody.indexOf("http://") === 0 || messageBody.indexOf("https://") === 0) {
			return true;
		}
		else {
			return false;
		}

	}

	const replaceURLs = (message) => {
		if (!message) return;
		let test;
		var Rexp =
			/(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;

		if (message.includes("</a>")) {
			test = message
		} else {
			test = message.replace(Rexp, (url) => {
				var hyperlink = url;
				if (!hyperlink.match('^https?:\/\/')) {
					hyperlink = 'http://' + hyperlink;
				}
				return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
			}
			);
		}
		return test;
	}

	// const replaceEmails = (message) => {
	// 	if (!message) return;

	// 	var emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
	// 	return message.replace(emailRegex, (email) => {
	// 		var mail = email;
	// 		return '<a target="_blank" rel="noopener noreferrer">' + mail + '</a>'
	// 	});
	// }


	const containsEmail = (message) => {
		var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
		return re.test(message);
	}


	// const findUrl = (message) => {
	// 	let array = message.split(" ")
	// 	let array2 = message.split("<br>")
	// 	let isUrl = false
	// 	for (let i = 1; i < array.length; i++) {
	// 		if (checkURL(array[i])) {
	// 			isUrl = false

	// 		} else {
	// 			isUrl = true

	// 			break
	// 		}
	// 	}
	// 	for (let i = 1; i < array2.length; i++) {
	// 		if (checkURL(array[i])) {
	// 			isUrl = false

	// 		} else {
	// 			isUrl = true
	// 			break
	// 		}
	// 	}
	// 	// setNewMessageBody(props.singleThread?.message_body.replace(/<\/?p[^>]*>/g, ""));
	// 	return isUrl


	// }

	// const findEmail = (message) => {


	// 	let array = message.split(" ")
	// 	let array2 = message.split("<br>")
	// 	let email = false


	// 	for (let i = 0; i < array.length; i++) {
	// 		if (checkEmail(array[i])) {
	// 			email = true;


	// 		} else {
	// 			email = false;
	// 			// break

	// 		}
	// 	}

	// 	for (let i = 1; i < array2.length; i++) {
	// 		if (checkEmail(array[i])) {
	// 			email = true
	// 		} else {
	// 			email = false
	// 			// break
	// 		}
	// 	}


	// 	return email;

	// }


	const copyEmail = ((text) => {
		navigator.clipboard.writeText(text.replace(/<[^>]+>/g, ''))
	})
	useEffect(() => {
		setTimeout(() => {
			if (copied !== "") {
				setCopied("")
			}
		}, 1000)

	}, [copied])


	//check if pasted text is wrapped in html tags
	const checkTags = (messageBody) => {
		let htmlString = messageBody;
		let plainText = htmlString.replace(/<[^>]+>/g, '');
		messageText = plainText;

		return messageBody;
	}

	const checkTagsExists = (messageBody) => {
		// let pattern = new RegExp(/<[^>]+>/g);
		// if (pattern.test(messageBody)) {
		// 	checkHyperlinks(messageBody);
		// 	return true;
		// }
	}

	// const checkHyperlinks = (messageBody) => {
	// 	let link = messageBody;

	// 	let temp = document.createElement('div');
	// 	temp.innerHTML = link;
	// 	if (temp.querySelector("a")) {
	// 		url = temp.querySelector("a").getAttribute('href');
	// 		setIsHyperlink(true);
	// 	}
	// 	else {
	// 		setIsHyperlink(false);
	// 	}
	// 	let plainText = link.replace(/<[^>]+>/g, '');
	// 	messageText = plainText.replace(/&nbsp;/, ' ');


	// }

	const isValidEmail = (email) => {
		return /\S+@\S+\.\S+/.test(email);
	}
	// const handleIsDocument = (attachment) => {
	// 	if (attachment) {
	// 		if (attachment.includes(".pdf")) {
	// 			return true;
	// 		} else if (attachment.includes(".doc") || attachment.includes(".docx")) {
	// 			return true;
	// 		} else if (attachment.includes(".ppt")) {
	// 			return true;
	// 		} else if (attachment.includes(".csv")) {
	// 			return true;
	// 		} else if (attachment.includes(".xlsx")) {
	// 			return true;
	// 		} else if (attachment.includes(".txt")) {
	// 			return true;
	// 		} else {
	// 			return false;
	// 		}

	// 	}
	// };
	useEffect(() => {
		let divWidth = document.getElementById("messagesLoader")?.clientWidth

		if (divWidth) {
			if (window.screen.width > 600) {
				setLoaderWidthSize(divWidth + 500)
			} else {
				setLoaderWidthSize(divWidth + 1045)
			}

		}


	}, [newContext.showInlineLoader])
	const getAttachments = () => {
		let attachments = []
		if (props.singleThread?.attachments?.length > 0 && props.selectedMore !== "") {
			props.singleThread?.attachments?.map((attachment) => {
				newContext?.attachmentList?.map((attachmentL, index) => {
					if (JSON.parse(attachment)?.id === attachmentL.id) {
						attachments.push(attachmentL?.file_url)

					}
				})
			})
		}
		return attachments
	}
	return (
		<AppContext.Consumer>
			{(context) =>

				<div
					key={props.i}
					className={"income-message-wrapper"
					}

					onClick={() => {
						context
							.UploadModalHandler("closeOnly")
						props.setTextFocus(false)

					}}
				>
					{copied !== "" ? <div style={{
						display: copied === props?.singleThread?._id["$oid"] ? "block" : "none",
						flexDirection:
							localStorage.getItem(
								"bixChat_loggedUserPublicId"
							) === props.singleThread?.sender_public_id
								? "row"
								: "row-reverse",
						marginRight:
							localStorage.getItem(
								"bixChat_loggedUserPublicId"
							) === props.singleThread?.sender_public_id
								? "10px"
								: props.singleThread?.attachments?.length > 0 &&
									props.singleThread?.message_body === ""
									? "-50px"
									: "-55px",
						marginLeft:
							localStorage.getItem(
								"bixChat_loggedUserPublicId"
							) === props.singleThread?.sender_public_id
								? props.singleThread?.attachments?.length > 0 &&
									props.singleThread?.message_body === ""
									? "-40px"
									: "-60px"
								: "",
						right:
							localStorage.getItem(
								"bixChat_loggedUserPublicId"
							) === props.singleThread?.sender_public_id
								? ""
								: 0,

					}} className="copied">Copied</div> : ""}
					<div id="messagesLoader">
						{context?.showInlineloader ?
							<div
								id="container"
								className={`income-container ${props.singleThread?.message_subject ? "border" : ""}`}
								style={{

									marginBottom: props.thread.length > 1 ? "10px" : "",
									animation: localStorage.getItem(
										"bixChat_loggedUserPublicId"
									) === props.singleThread?.sender_public_id && props.singleThread?.indexdb_status !== "delivered" ? "taadaa 5s" : ""
								}}
							>

								<div>

									<div
										className="reply-message"
										style={{
											flexDirection: "row-reverse",
											marginRight: props.singleThread?.attachments?.length > 0 &&
												props.singleThread?.message_body === ""
												? "-15px"
												: "-20px",
											right: 0,
											top: "40%",
										}}
									>

										{/* <div className="more-image-div" onClick={() => {
		
		context.setSelectedReply(props.thread);
		props.setIsReply("block");
		props.setSelectedTabs("reply");
		context?.setReplySubject(true);
		}}>
		<img
		
		alt=""
		src={reply}
		className="more-image"
		// style={{
		// marginLeft: localStorage.getItem(
		// "bixChat_loggedUserPublicId"
		// ) === props.singleThread?.sender_public_id && props.singleThread?.attachments?.length === 1 &&
		// props.singleThread?.attachments[0]?.attachment_name?.includes(
		// ".mp3") ? "-50px" : ""
		// }}
		
		/>
		
		</div> */}

									</div>
									{context?.showReactionTab && props.singleThread._id === props?.selectedMore ?

										<div className="reaction-div">

											{localStorage.getItem(
												"bixChat_loggedUserPublicId"
											) === props.message?.sender_public_id ? "" :

												<div className="reaction-list">
													<div
														className="reaction-option"
														onClick={() => {
															// mixpanel.track("Reaction reply")
															context?.reactMessage(
																"👌",
																props.singleThread?._id["$oid"],
																context?.selectedChatroomDatas
																	?.chatroomData._id["$oid"]
															);


														}}
													>
														<div className="emoji">👌</div>

													</div>
													<div
														className="reaction-option"
														onClick={() => {
															// mixpanel.track("Reaction reply")
															context?.reactMessage(
																"👍",
																props.singleThread?._id["$oid"],
																context?.selectedChatroomDatas
																	?.chatroomData._id["$oid"]
															);
														}}
													>
														<div className="emoji">👍</div>


													</div>
													<div
														className="reaction-option"
														onClick={() => {
															// mixpanel.track("Reaction reply")
															context?.reactMessage(
																"✅",
																props.singleThread?._id["$oid"],
																context?.selectedChatroomDatas
																	?.chatroomData._id["$oid"]
															);
														}}
													>

														<div className="emoji">✅</div>


													</div>
													<div
														className="reaction-option"
														onClick={() => {
															// mixpanel.track("Reaction reply")
															context?.reactMessage(
																"❌",
																props.singleThread?._id["$oid"],
																context?.selectedChatroomDatas
																	?.chatroomData._id["$oid"]
															);
														}}
													>

														<div className="emoji">❌</div>


													</div>
												</div>
											}

										</div>

										: ""}

									{/* --------------- Pop up ----------- */}
									<MorePopup
										message={props.singleThread}
										setIsReply={props.setIsReply}
										setSelectedTabs={props.setSelectedTabs}
										singleTread={props.thread}
										i={props.i}
										selectedMore={props.selectedMore}
										moreIcon={props.moreIcon}
										isSaveToCloud={props.isSaveToCloud}
										setIsSaveToCloud={props.setIsSaveToCloud}
										attachments={getAttachments()}
									/>

									<div className="header-cont">
										<div className="top-co">

											<div className="subject">
												{props.singleThread?.forwardInfo !== "" ? "Fwd: " : ""}
												{props.singleThread?.message_subject}
											</div>

											<div className="name-date-container">
												<div className={`sender-name-main-${context.fontSizeType}`}>
													<div className="sender-name" style={{ textTransform: !isValidEmail(props.singleThread.sender_name) ? "capitalize" : "" }}>{props.singleThread.sender_name}</div>


													<div className="time-stamp-mob">

														{moment().format("L") ===
															moment(
																props.singleThread?.timestamp["$date"]
															).format("L")
															? `${moment(
																props.singleThread?.timestamp["$date"]
															).format("HH:mm")}`
															: moment(
																props.singleThread?.timestamp["$date"]
															).format("l")}
														{localStorage.getItem(
															"bixChat_loggedUserPublicId"
														) === props.singleThread.sender_public_id ? (
															// <img alt="" src={tick} className="tick-img" />
															<div></div>
														) : (
															""
														)}

													</div>
												</div>


												<div className={`time-${context.fontSizeType}`}	>


													<div className="time-stamp">
														{window.screen.width < 600 ? <div>{moment().format("L") ===
															moment(
																props.singleThread?.timestamp["$date"]
															).format("L")
															? `${moment(
																props.singleThread?.timestamp["$date"]
															).format("HH:mm")}`
															: moment(
																props.singleThread?.timestamp["$date"]
															).format("l")}
															{localStorage.getItem(
																"bixChat_loggedUserPublicId"
															) === props.singleThread.sender_public_id ? (
																// <img alt="" src={tick} className="tick-img" />
																<div></div>
															) : (
																""
															)}</div> :
															<div>
																{moment().format("L") ===
																	moment(
																		props.singleThread?.timestamp["$date"]
																	).format("L")
																	? ` Today ${moment(
																		props.singleThread?.timestamp["$date"]
																	).format("LT")}`
																	: moment(
																		props.singleThread?.timestamp["$date"]
																	).format("lll")}
																{localStorage.getItem(
																	"bixChat_loggedUserPublicId"
																) === props.singleThread.sender_public_id ? (
																	// <img alt="" src={tick} className="tick-img" />
																	<div></div>
																) : (
																	""
																)}</div>}
													</div>

													<div className="reply-svg-div" onClick={() => {

														context.setSelectedReply(props.thread);
														props.setIsReply("block");
														props.setSelectedTabs("reply");
														context?.setReplySubject(true);
														props.setIsReactionsView(false)

													}}>
														<img className="reply-btn" src={replyButton} alt="" />

													</div>

													<div className="more-svg-div" onClick={() => {
														let messageId = props.singleThread?._id["$oid"]
														props.setSelectedMore(props.singleThread?._id);
														props.setMoreIcon("block");
														context.setToList(props.singleThread.to_list)
														context.setCcList(props.singleThread.cc_list)
														context.setMessageInfo(props.singleThread)
														context.setCcMessage(props.singleThread._cls)
														context.setShowReactionTab(true)
														props.setIsReactionsView(false)

														context.getMessageInfo(
															messageId
														).then((
															response
														) => {
															if (response.status === 200) {
																console.log(response.data);
																context.setMessageSeenList(response.data.reads_by)
																context.setForwardedList(response.data.forwardInfo)
															}

														})









													}}>
														<img className="more-icon" src={moreIcon}
															alt=""
														/>

													</div>

												</div>



											</div>
											{props.singleThread._cls === "Message.CCMessage" ?
												<div>
													<div className="cc-name">
														cc -
														<span>{props?.singleThread?.cc_list.map((cc) => {
															return (<div style={{ textTransform: !isValidEmail(cc) ? "capitalize" : "" }}>{cc}</div>)
														})}</span>
													</div>
												</div>
												:
												""}
											{props.singleThread.forwardInfo !== "" ?
												<div>
													<div className="cc-name">
														from -
														<span>{props.singleThread.forwardInfo}</span>
													</div>
												</div>
												:
												""}
										</div>
									</div>
									<div>
										<div className="body">
											{props.singleThread?.message_body !== "" ?
												<div className="body-message-warpper">
													{checkEmail(checkTags(props.singleThread?.message_body)) ?
														<div className="linkMsgBox">
															<div className="emailText" onClick={() => {
																setCopied(props.singleThread?._id["$oid"])
																copyEmail(props.singleThread?.message_body)


															}} >
																{messageText}
															</div>
														</div>
														:
														checkTagsExists(props.singleThread?.message_body) ?
															isHyperlink ?
																<div className="linkMsgBox">
																	<a href={url} target="_blank">{messageText}</a>
																</div>
																:
																<div dangerouslySetInnerHTML={{ __html: props.singleThread?.message_body }} />

															: containsEmail(messageText) ? props.singleThread?.attachments?.length > 0 ?
																<div className="linkMsgBox">
																	<div dangerouslySetInnerHTML={{ __html: messageText }} />
																</div> :
																<div className="linkMsgBox">
																	<div dangerouslySetInnerHTML={{ __html: props.singleThread?.message_body }} />



																</div>
																: checkURL(props.singleThread?.message_body) ?
																	checkProtocol(props.singleThread?.message_body) ?
																		props.singleThread?.attachments?.length > 0 ?
																			<div className="linkMsgBox">
																				<div dangerouslySetInnerHTML={{ __html: replaceURLs(props.singleThread?.message_body) }} />
																				{/* {findUrl(newMessageBody) ? "" : <div className="linkBorder">
																					<div className="borderLine" />
																					<LinkPreview url={newMessageBody} className="linkPreviewComponent" backgroundColor="#ebedfa" borderColor="#ebedfa" width={300} margin={3} ></LinkPreview>
																				</div>} */}

																			</div>
																			:
																			<div className="linkMsgBox">
																				<div dangerouslySetInnerHTML={{ __html: replaceURLs(props.singleThread?.message_body) }} />
																				{/* {!findUrl(newMessageBody) ? "" : <div className="linkBorder">
																					<div className="borderLine" />
																					<LinkPreview url={newMessageBody} className="linkPreviewComponent" backgroundColor="#ebedfa" borderColor="#ebedfa" width={300} margin={3} ></LinkPreview>
																				</div>} */}
																			</div>

																		:
																		<div className="linkMsgBox">
																			<div dangerouslySetInnerHTML={{ __html: replaceURLs(props.singleThread?.message_body) }} />
																			{/* {findUrl(newMessageBody) ? "" : <div className="linkBorder">
																				<div className="borderLine" />
																				<LinkPreview url={"" + newMessageBody} className="linkPreviewComponent" backgroundColor="#ebedfa" borderColor="#ebedfa" width={300} margin={3} ></LinkPreview>
																			</div>} */}

																		</div>



																	:
																	<div className={`pre-${context.fontSizeType}`}>

																		<div dangerouslySetInnerHTML={{ __html: props.singleThread?.message_body }} />

																	</div>}
												</div>
												: ""}
											<div className="attachment-container-wrapper">
												{props.singleThread?.indexdb_status === "delivered" ?
													props.singleThread?.attachments.map((attachment) => {
														if (attachment.attachment_name?.includes(".mp3")) {
															{
																context?.attachmentList && context?.attachmentList?.map((attachmentL, index) => {
																	if (JSON.parse(attachment).id === attachmentL.id) {
																		return (
																			<div className="audio-styles">
																				<AudioComponent audio={props.singleThread
																					?.attachments[0]
																					.attachment_link} /></div>
																		)
																	}
																})
															}
														} else {
															return (

																<div>
																	{context?.attachmentList && context?.attachmentList?.map((attachmentL, index) => {

																		if (JSON.parse(attachment)?.id === attachmentL.id) {
																			return (
																				<div className="attachement-container-1">
																					<AttachmentPreviewComponent message={props.singleThread}
																						link={JSON.parse(attachment)?.thumb_availability === "True" ? attachmentL?.thumb_url : ""}
																						name={attachmentL?.file_name}
																						attachments={props.singleThread?.attachments}
																						download={attachmentL?.file_url}
																						sending={false} />
																				</div>
																			)
																		} else {
																			return (
																				""
																			)
																		}



																	})

																	}

																</div>

															)
														}

													}) : props.singleThread?.attachments.map((attachment) => {
														if (attachment.attachment_name?.includes(".mp3")) {
															return (
																<div className="audio-styles">
																	<AudioComponent audio={props.singleThread
																		?.attachments[0]
																		.attachment_link} /></div>
															)
														} else {
															return (
																<div
																	className="attachement-container-1"
																>
																	<AttachmentPreviewComponent message={props.singleThread}
																		link={attachment?.thumbnail_link}
																		name={attachment.name}
																		attachments={props.singleThread?.attachments}
																		download={attachment.attachment_link}
																		sending={true} />
																</div>
															)
														}
													})}
											</div></div>
										{props.thread.length > 1 ? (
											<div className="reply-message-box-main">

												<div className="reply-message-box">

													<div className="reply-messages-inline">
														{props.thread &&
															props.thread?.slice(1, 3).map((message, i) => {

																return (
																	<div className="reply-inline-message">
																		<div className="reply-head">


																			<div className="sender-name-inline-reply">

																				{message?.sender_name}


																			</div>
																		</div>
																		<div className="message-inline-reply">
																			{message?.message_body ?
																				<div dangerouslySetInnerHTML={{ __html: message?.message_body }} /> : ""}
																			<div className="attachment-container-wrapper">
																				{props.singleThread?.indexdb_status === "delivered" ?
																					message?.attachments.map((attachment) => {
																						if (attachment.attachment_name?.includes(".mp3")) {
																							{
																								context?.attachmentList.map((attachmentL, index) => {
																									if (JSON.parse(attachment).id === attachmentL.id) {

																										return (
																											<div className="audio-styles">
																												<AudioComponent audio={message
																													?.attachments[0]
																													.attachment_link} /></div>
																										)
																									}
																								})
																							}
																						} else {
																							return (
																								<div>
																									{

																										context?.attachmentList.map((attachmentL, index) => {

																											if (JSON.parse(attachment).id === attachmentL.id) {
																												return (
																													<div className="attachement-container-1">

																														<AttachmentPreviewComponent message={message}
																															link={JSON.parse(attachment)?.thumb_availability === "True" ? attachmentL?.thumb_url : ""}
																															name={attachmentL?.file_name}
																															attachments={message.attachments}
																															download={attachmentL?.file_url}
																															sending={false} />

																													</div>
																												)
																											} else {
																												return (
																													""
																												)
																											}



																										})

																									}

																								</div>
																							)
																						}

																					}) : message?.attachments.map((attachment) => {

																						if (attachment.attachment_name?.includes(".mp3")) {
																							return (
																								<div className="audio-styles">
																									<AudioComponent audio={message
																										?.attachments[0]
																										.attachment_link} /></div>
																							)
																						} else {
																							return (
																								<div
																									className="attachement-container-1"
																								>


																									<AttachmentPreviewComponent message={message}
																										link={attachment?.thumbnail_link}
																										name={attachment.name}
																										attachments={message.attachments}
																										download={attachment.attachment_link}
																										sending={true} />



																								</div>
																							)
																						}

																					})}
																			</div>	</div>




																	</div>





																)



															})}





													</div>



												</div>
												{props.thread.length > 3 ? (
													<div
														id="replyCount"
														className="reply-count"
														onClick={() => {
															context.setSelectedReply(props.thread);
															props.setIsReply("block");
															context?.setReplySubject(true);
															props.setIsReactionsView(false)

														}}
													>
														{props.thread.length - 2 === 2
															? "1 More Reply"
															: props.thread.length - 3 + " More Replies"}

													</div>
												) : (
													""
												)}



											</div>

										) : (
											""
										)}

									</div>


								</div>

								{props.singleThread?.reactions.length > 0 ? (
									<div
										className="reaction-count"
										onClick={() => {
											context.setSelectedReply(props.thread);
											props.setIsReply("block");

											props.setSelectedMore(props.singleThread?._id);
											context.setShowReactionTab(true)

											console.log("first")
											props.setIsReactionsView(true);
											props.setSelectedTabs("reactions");
											props.setSelectedReactionTab("All");

										}}
										style={{
											right:
												props.thread.length === 2
													? 57
													: props.thread.length >= 2
														? 67
														: 0,
											backgroundColor:
												localStorage.getItem(
													"bixChat_loggedUserPublicId"
												) === props.singleThread?.sender_public_id
													? "#ebedfa"
													: "#f5f5f5",
										}}
									>
										{props.singleThread.reactions?.length > 1
											? props.singleThread.reactions.map(
												(reaction, index) => {
													if (index === 0) {
														reactionIcon = reaction.emoji;
													}
													return null;
												}
											)
											: ""}
										{/* reaction count */}
										{props.singleThread?.reactions?.length === 1 ? (
											<div>
												{props.singleThread?.reactions[0].emoji}
											</div>
										) : props.singleThread?.reactions?.length > 1 ? ( // reactionCount(reaction.emoji, reaction);


											props.singleThread?.reactions.filter(function (
												el
											) {
												return el.emoji === reactionIcon;
											}).length ===
												props.singleThread?.reactions.length ? (
												reactions.map((r, i) => {
													if (r === reactionIcon) {
														return (<div key={i}>

															{reactionIcon}{" "}
															{props.singleThread?.reactions?.filter(
																el => el.emoji === r
															).length
															}
														</div>)
													}
												})


											) : props.singleThread?.reactions?.length >
												100 ? (
												<>
													<img alt="" src={star} className="img" />
													100 +
												</>
											) : (
												<>
													<img alt="" src={star} className="img" />
													{props.singleThread.reactions?.length}
												</>
											)
										) : (
											""
										)}
									</div>

								) : (
									""
								)}



							</div> :

							<ContentLoader
								width={loaderWidthSize}
								height={470}
								viewBox="0 0 1400 470"
								backgroundColor="#f5f5f5"
								foregroundColor="#dbdbdb"
								{...props}
							>
								<rect x="4" y="8" rx="3" ry="3" width="2" height="282" />
								<rect x="840" y="9" rx="3" ry="3" width="2" height="282" />

								<rect x="6" y="289" rx="3" ry="3" width="835" height="2" />
								<rect x="5" y="8" rx="3" ry="3" width="835" height="2" />

								<rect x="18" y="25" rx="3" ry="3" width="421" height="15" />
								<rect x="665" y="25" rx="3" ry="3" width="110" height="15" />

								<circle cx="819" cy="32" r="12" />
								<circle cx="791" cy="32" r="12" />


								<rect x="18" y="58" rx="3" ry="3" width="193" height="12" />
								<rect x="18" y="78" rx="3" ry="3" width="354" height="6" />
								<rect x="18" y="96" width="758" height="135" />

								<rect x="50" y="240" rx="3" ry="3" width="2" height="40" />
								<rect x="58" y="242" rx="3" ry="3" width="138" height="7" />
								<rect x="58" y="258" rx="3" ry="3" width="354" height="22" />

								{/* _____________________________________________________________________________ */}


								<rect x="18" y="310" rx="3" ry="3" width="421" height="15" />
								<rect x="665" y="310" rx="3" ry="3" width="110" height="15" />

								<circle cx="819" cy="316" r="12" />
								<circle cx="791" cy="316" r="12" />

								<rect x="18" y="343" rx="3" ry="3" width="193" height="12" />
								<rect x="18" y="363" rx="3" ry="3" width="84" height="6" />
								<rect x="18" y="381" width="278" height="35" />

								<rect x="50" y="424" rx="3" ry="3" width="2" height="40" />
								<rect x="58" y="426" rx="3" ry="3" width="138" height="7" />
								<rect x="58" y="442" rx="3" ry="3" width="354" height="22" />

							</ContentLoader>

						}
					</div>



					<div
						className="message-box-footer"
						style={{
							backgroundColor: "white",
						}}
					>
						{props.singleThread?.indexdb_status === "delivered" ? (
							"") : (
							<div>

								<div style={{ fontSize: "10px", float: "right", marginRight: "10px" }}>
									{props.singleThread?.attachments?.length > 0 ? (

										<div className="progress-bar">
											<div>

												Uploading....
											</div>

											<div className="prog-bar">

												<CircularProgressbar
													value={percentage}
													styles={buildStyles({
														// Rotation of path and trail, in number of turns (0-1)
														// rotation: 0.25,
														// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
														strokeLinecap: 'butt',

														// Text size
														textSize: '16px',

														// How long animation takes to go from one percentage to another, in seconds
														pathTransitionDuration: 0.5,

														// Can specify path transition in more detail, or remove it entirely
														// pathTransition: 'none',
														width: '5px',
														// Colors
														pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
														textColor: '#f88',
														trailColor: '#d6d6d6',
														backgroundColor: '#3e98c7',
													})}
												/>

											</div>



										</div>


									) : (
										<div className="status blink">
											<div>
												Sending ...
											</div>
										</div>
									)}


								</div>

							</div>
						)}



					</div>


				</div>
			}
		</AppContext.Consumer>
	)

}
export default InlineMessageComponent