import React, { useState, useRef, useEffect } from "react";
import Popup from "../utils/popup";
import ProImg from "../../../Assets/Icons/addPic.PNG";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "../utils/cropImage";
import Resizer from "react-image-file-resizer";
import close from "../../../Assets/Icons/close.svg";
import upload from "../../../Assets/Icons/vuesax-linear-user-cirlce-add.svg";
import UsernameInput from "../../../Components/InputComponents/UsernameInput/UsernameInput";
import "../SignupWrapper/Signup.scss";
import "../Login.scss";

const SignupSetupMoreDetails = (props) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [designationInputStatus, setDesignationInputStatus] = useState(0); //Zero No Touch, 1 Valid, 2 Not Valid
  const [addressInputStatus, setAddressInputStatus] = useState(0); //Zero No Touch, 1 Valid, 2 Not Valid
  const [isOpen, setIsOpen] = useState(false);
  const [addPhoto, setAddPhoto] = useState(false);
  const [proPicUploaded, setProPicUploaded] = useState(false);
  const inputRef = useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();
  const [croppedArea, setCroppedArea] = useState(null);
  const [newcroppedImage, setNewCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isDesFocus, setIsDesFocus] = useState(false);
  const [isAddressFocus, setIsAddressFocus] = useState(false);
  const finishButton = useRef(null);
  const skipButton = useRef(null);

  const [address, setAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        if (designation !== "" || address !== "") {
          finishButton.current.click();
        } else {
          skipButton.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [designation, address]);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const removeFocus = () => {
    setIsAddressFocus(false);
    setIsDesFocus(false);
  };

  const nextBtn = () => {
    //disable until agree to terms
    if (checked) {
      if (props.signupLoading && !props.signupSkipLoading) {
        return (
          <div
            ref={finishButton}
            className="submit-button button is-loading"
            style={{ cursor: "not-allowed !imporant" }}
          >
            Finish
          </div>
        );
      } else if (
        (props.signupLoading && props.signupSkipLoading) ||
        designationInputStatus === 2
      ) {
        return (
          <div
            ref={finishButton}
            disabled
            className="submit-button button"
            style={{ cursor: "not-allowed !imporant" }}
          >
            Finish
          </div>
        );
      } else {
        if (addressInputStatus !== 2 && designationInputStatus !== 2) {
          return (
            <div
              ref={finishButton}
              className="submit-button button"
              onClick={() => {
                removeFocus();
                submitBtnClick();
              }}
            >
              Finish
            </div>
          );
        } else {
          return (
            <div
              ref={finishButton}
              className="submit-button button"
              style={{ cursor: "not-allowed !imporant" }}
            >
              Finish
            </div>
          );
        }
      }
    } else {
      return (
        <div
          ref={finishButton}
          disabled
          className="submit-button button"
          style={{ cursor: "not-allowed !imporant" }}
        >
          Finish
        </div>
      );
    }
  };
  // const skipBtn = () => {
  //   if (props.signupSkipLoading && props.signupLoading) {
  //     return (
  //       <div
  //         ref={skipButton}
  //         className="skip-button disabledDiv"
  //         style={{ cursor: "not-allowed !imporant" }}
  //       >
  //         <span className="loader" />
  //       </div>
  //     );
  //   } else if (!props.signupSkipLoading && props.signupLoading) {
  //     return (
  //       <div
  //         ref={skipButton}
  //         className="skip-button disabledDiv"
  //         style={{ cursor: "not-allowed !imporant" }}
  //       >
  //         Skip and Finish
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div
  //         ref={skipButton}
  //         className="skip-button"
  //         onClick={() => {
  //           removeFocus();
  //           handleSkipClick();
  //         }}
  //       >
  //         Skip and Finish
  //       </div>
  //     );
  //   }
  // };

  const handleDesignation = (e) => {
    let designationValue = e.target.value;

    if (designationValue === "") {
      setDesignation("");
    } else {
      setDesignation(designationValue);

      if (designationValue.length === 0) {
        setDesignationInputStatus(0);
      } else {
        if (designationValue.length !== 0) {
          let mediumRegex = new RegExp(
            /^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/
          );

          if (mediumRegex.test(designationValue)) {
            setDesignationInputStatus(1);
          } else {
            setDesignationInputStatus(2);
          }
        }
      }
    }
  };

  const designationInputValidation = () => {
    if (designationInputStatus === 0) {
      return "";
    } else if (designationInputStatus === 1) {
      return "";
    } else {
      return <div className="form-fail">Should be Valid Text</div>;
    }
  };

  const handleAddress = (e) => {
    let addressValue = e.target.value;

    if (addressValue === " ") {
      setAddress("");
    } else {
      setAddress(addressValue);

      if (addressValue.length === 0) {
        setAddressInputStatus(0);
      } else {
        if (addressValue.length !== 0) {
          let mediumRegex = new RegExp("[a-zA-Z ]*$");

          if (mediumRegex.test(addressValue)) {
            setAddressInputStatus(1);
          } else {
            setAddressInputStatus(2);
          }
        }
      }
    }
  };

  const addressInputValidation = () => {
    if (addressInputStatus === 0) {
      return "";
    } else if (addressInputStatus === 1) {
      return "";
    } else {
      return <div className="form-fail">Should be Valid Text </div>;
    }
  };

  const submitBtnClick = () => {
    if (addressInputStatus !== 2 && designationInputStatus !== 2) {
      props.sendSigupRequest(newcroppedImage);
      props.setSignupLoading(true);
    }
  };
  // const handleSkipClick = () => {
  //   props.setDesignation("");
  //   props.setAddress("");
  //   props.sendSigupRequest(null);
  //   props.setSignupSkipLoading(true);
  // };

  const handleProPic = (event) => {
    const reader = new FileReader();

    reader.onload = function () {
      setImagePreview(reader.result);

      setAddPhoto(true);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const resizeFile = (newcroppedImage) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        newcroppedImage,

        300,

        300,

        "PNG",

        100,

        0,

        (uri) => {
          resolve(uri);
        },

        "base64"
      );
    });

  const onChange = async (event) => {
    await resizeFile(event.target.files[0]).then((res) => {
    });
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const resizeImage = (base64Str, maxWidth = 300, maxHeight = 300) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imagePreview, croppedArea);
      let newestImage = croppedImage.toDataURL();
      let img = await resizeImage(newestImage);
      setNewCroppedImage(img);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="_SignupContainer">
      <div className="SignupInnercontainerLeft">
        {isOpen && (
          <Popup
            content={
              <>
                style=
                {{
                  background: imagePreview
                    ? `url("${imagePreview}") no-repeat center/cover`
                    : "#FFFFFF",
                }}
              </>
            }
            img={imagePreview}
            handleClose={togglePopup}
          />
        )}

        <div className="wrapper">
          <div className="bixHeader">ZapMail</div>

          <div className="input-wrapper username-margin-top-reg">
            <div className="regLabel">Profile</div>
          </div>

          <div className="input-wrapper username-margin-top-login">
            {isDesFocus || designation !== "" ? (
              <label
                className="input-label"
                style={{ color: isDesFocus ? "#0094ff" : "" }}
              >
                Designation
              </label>
            ) : (
              ""
            )}

            <br />

            <div
              className="inputdiv"
              style={{
                borderColor:
                  ((designation === "" || designation === undefined) &&
                    designationInputStatus === 2) ||
                  (designation !== "" && designationInputStatus === 2)
                    ? "#ff0000"
                    : isDesFocus
                    ? "#0d2a4c"
                    : "#9d9d9d",
              }}
            >
              <UsernameInput
                type="text"
                name="designation"
                placeholder={isDesFocus ? "" : "Designation"}
                value={designation}
                onChange={(e) => {
                  handleDesignation(e);
                }}
                onFocus={() => setIsDesFocus(true)}
                onBlur={() => setIsDesFocus(false)}
              />
            </div>
            {designationInputValidation()}
          </div>

          <div className="input-wrapper username-margin-top-login">
            {isAddressFocus || address !== "" ? (
              <label
                className="input-label"
                style={{ color: isAddressFocus ? "#0094ff" : "" }}
              >
                Address
              </label>
            ) : (
              ""
            )}

            <div
              className="inputdiv"
              style={{
                borderColor:
                  ((address === "" || address === undefined) &&
                    addressInputStatus === 2) ||
                  (address !== "" && addressInputStatus === 2)
                    ? "#ff0000"
                    : isAddressFocus
                    ? "#0d2a4c"
                    : "#9d9d9d",
              }}
            >
              <UsernameInput
                type="text"
                name="address"
                placeholder={isAddressFocus ? "" : "Address"}
                value={address}
                onChange={(e) => {
                  handleAddress(e);
                }}
                onFocus={() => setIsAddressFocus(true)}
                onBlur={() => setIsAddressFocus(false)}
              />
            </div>

            {addressInputValidation()}

            <div className="input-wrapper username-margin-top-login">
              <label className="profile-pic-label">Profile Picture</label>

              {proPicUploaded ? (
                <div style={{ marginBottom: "80px" }}>
                  <div
                    className="add-photo-div"
                    onClick={triggerFileSelectPopup}
                  >
                    <img
                      className="popup-image-after"
                      src={newcroppedImage}
                      alt=""
                      style={{ marginBottom: "-70px" }}
                    />

                    <div className="add-photo-after-div">
                      <img
                        className="add-photo-after"
                        src={ProImg}
                        alt=""
                        onClick={triggerFileSelectPopup}
                        // style={{cursor : "not-allowed"}}
                      />

                      <input
                        onChange={(e) => {
                          handleProPic(e);

                          onChange(e);
                        }}
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        className="hidden"
                        accept="image/*"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "center", marginTop: "40px" }}>
                  <div>
                    <div
                      className="add-photo-div"
                      onClick={triggerFileSelectPopup}
                      // style={{cursor : "not-allowed"}}
                    >
                      <img className="upload-div" src={upload} alt={""} />
                    </div>
                    <div>
                      <label className="lable-style">
                        Drag and drop or browse to choose a file
                      </label>
                    </div>
                  </div>

                  <input
                    onChange={(e) => {
                      handleProPic(e);

                      onChange(e);
                    }}
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    className="hidden"
                    accept="image/*"
                  />
                </div>
              )}
            </div>
            <div className="terms" style={{paddingTop:proPicUploaded?"20px":""}}>
              <input
                type="checkbox"
                className="check"
                id="termsCheck"
                checked={checked}
                onClick={() => {
                  setChecked(!checked);
                }}
              />
              When creating an account you agree to our{" "}
              <a
                className="link"
                href="https://bixmessage.com/bixchat-application-policies/"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className="link"
                href="https://bixmessage.com/bixchat-application-policies/"
              >
                Terms and Conditions.
              </a>
            </div>

            <div className={`modal ${addPhoto ? "is-active" : ""} `}>
              <div
                className="modal-background"
                style={{ backgroundColor: "transparent", borderRadius: "0px" }}
              >
                <div
                  className="modal-content"
                  style={{ backgroundColor: "white", borderRadius: "0px" }}
                >
                  <div className="popup-div1">
                    <div className="popup-div1-h1">
                      <img
                        alt=""
                        src={close}
                        className="close-icon"
                        onClick={() => {
                          setAddPhoto(false);
                        }}
                      />
                      Drag the image to adjust
                    </div>

                    <div className="popup-div1-p">
                      <p onClick={triggerFileSelectPopup}>Reupload</p>
                    </div>
                  </div>
                  <div
                    className="modal-card-body profile-pic-modal"
                    style={{ borderRadius: "0px" }}
                  >
                    {imagePreview ? (
                      <div className="cropper">
                        <Cropper
                          image={imagePreview}
                          crop={crop}
                          zoom={zoom}
                          aspect={1}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                          cropShape="round"
                          showGrid={false}
                          style={{ width: "100%" }}
                        />
                      </div>
                    ) : null}

                    <div
                      style={{
                        marginTop: "-20%",
                        display: "block",
                        marginLeft: "10%",
                      }}
                    >
                      <div>
                        <Slider
                          min={1}
                          max={3}
                          step={0.1}
                          value={zoom}
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>

                      <button
                        className="add-photo-button"
                        onClick={() => {
                          setAddPhoto(false);
                          setProPicUploaded(true);
                          showCroppedImage();
                        }}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {nextBtn()}
            {/* {skipBtn()} */}

            {props?.singupFailed ? (
              <div className="form-fail">Something Went Wrong, Try again</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupSetupMoreDetails;
