import { openDB } from "idb";
import { restHelper } from "../../ContextAPI/Helper/RestHelper"

export const messageServices = () => {

    const api = restHelper()
    const headers = {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
    }

    const handleReportMessage = (response, getAllChatroom) => {
        if (response.status === 200) {
            getAllChatroom()
        }
    }
    const getArray = (response) => {
        let array = []
        let attachmentarr = []
        let stringObj = ""
        return new Promise((resolve) => {
            for (let i = 0; i < response.data.messages.length; i++) {
                console.log(response.data.messages[i].attachments, "response.data.messages");
                if (response.data.messages[i].attachments.length > 0) {
                    for (let j = 0; j < response.data.messages[i].attachments.length; j++) {
                        response.data.messages[i].attachments[j].file_url = response.data.messages[i].attachments[j].attachment_link
                        delete response.data.messages[i].attachments[j].attachment_link
                        response.data.messages[i].attachments[j].thumb_url = response.data.messages[i].attachments[j].thumbnail_link
                        delete response.data.messages[i].attachments[j].thumbnail_link
                        response.data.messages[i].attachments[j].file_name = response.data.messages[i].attachments[j].attachment_name
                        delete response.data.messages[i].attachments[j].attachment_name
                        response.data.messages[i].attachments[j].attachment_id = response.data.messages[i].attachments[j].id
                        delete response.data.messages[i].attachments[j].attachment_id

                        stringObj = JSON.stringify(response.data.messages[i].attachments[j])
                        attachmentarr.push(stringObj)
                    }
                    response.data.messages[i].attachments = attachmentarr

                }
                array.push(response.data.messages[i])
            }
            resolve(array)

        })
    }
    const handleGetThread = (thread_id, setSelectedReply, setCurrentMessage, scollReply, setReplySending) => {
        let url = `thread/${thread_id}`;
        let options = {
            headers: headers
        }
        api.get(url, options).then((response) => {
            console.log(response);
            setReplySending(true)
            getArray(response).then((array) => {
                console.log(array, "array");
                setSelectedReply(array);
                localStorage.setItem(
                    "bixChat_selectedReply",
                    JSON.stringify(response.data.messages)
                );
                setCurrentMessage(response.data.messages[0]);
                scollReply();
            })
            // setSelectedReply(response.data.messages);
            // localStorage.setItem(
            //     "bixChat_selectedReply",
            //     JSON.stringify(response.data.messages)
            // );
            // setCurrentMessage(response.data.messages[0]);
            // scollReply();


        }).catch((error) => {
            console.log(error);
        });
    }
    const handleReactMessages = async (emoji, message_id, chatroom_id, loggedUserData, getSelectedChatFromIDB, chatSelected) => {
        let newReaction = [
            {
                emoji: emoji,
                user: {
                    full_name: loggedUserData.full_name,
                    id: localStorage.getItem("bixChat_loggedUserPublicId"),
                    profile_image:
                        localStorage.getItem("bixChat_profileImage"),
                },
            },
        ];

        const db2 = await openDB("bixChat-SelectedChatrooms", 1);

        if (db2) {
            db2.get("loadedThreads", chatroom_id).then((data) => {
                if (data) {
                    let dataPromise = data?.threads?.map((singleThreads) => {
                        if (singleThreads[0]?._id["$oid"] === message_id) {

                            let oldReactions = singleThreads[0].reactions;
                            let sameReaction = false


                            let oldReactionsPromise = oldReactions.map((oldReact, i) => {
                                if (oldReact.user.id === localStorage.getItem("bixChat_loggedUserPublicId")) {
                                    if (oldReact.emoji === emoji) {
                                        oldReactions.splice(i, 1);

                                        sameReaction = true

                                    }
                                }
                                return "success"
                            })

                            Promise.all(oldReactionsPromise).then(() => {
                                if (!sameReaction) {
                                    let updatedReactions = oldReactions.concat(newReaction)

                                    singleThreads[0].reactions = updatedReactions
                                }

                            })
                        }
                        return ("succes")
                    });

                    Promise.all(dataPromise)?.then(() => {
                        db2.put("loadedThreads", data, chatroom_id)
                            .then(() => {
                                getSelectedChatFromIDB(chatroom_id);
                            })
                            .catch((err) => {
                            });
                        db2.close();
                    })
                }
            });
        }
        let data = {
            emoji: emoji,
            chatroom_id: chatroom_id,
        }
        let url = `message/reaction/${message_id}`
        let options = {
            headers: headers,
            body: data
        }
        api.postCreate(url, options).then((response) => {
            if (response.status === 200) {
                console.log("reactions", response);
                let selectedChat = JSON.parse(
                    localStorage.getItem("bixChat_selectedChatMessage")
                );
                if (selectedChat) {
                    chatSelected(selectedChat);
                }
            }

        })
            .catch((error) => {
            });
    }


    return {
        handleReportMessage,
        handleGetThread,
        handleReactMessages
    }

}