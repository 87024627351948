import React from "react";
import './SaveToCloud.scss'
import CloudAdd from "../../../Assets/Icons/vuesax-linear-cloud-add-white.svg"
import FolderIcon from "../../../Assets/Icons/folder-icon.svg"
import Plus from "../../../Assets/Icons/Iconly-Light-Outline-Plus.svg"
import { useState } from "react";

const SaveToCloud = (props) => {

    const [filesActive, setFilesActive] = useState(true);
    const [chatActive, setChatActive] = useState(false);
    const [cancel, setCancel] = useState(false);

    const filesClick = () => {
        setFilesActive(true);
        setChatActive(false);
    }

    // const chatClick = () => {
    //     setChatActive(true);
    //     setFilesActive(false);
    // }

    const cancelClick = () => {
        setCancel(true);
        props.setIsSaveToCloud(false);

    }



    return (

                <div className={cancel ? " modal save-wrapper" : " modal is-active save-wrapper"}>

                    <div
                        className="modal-content"
                    >
                        <div>
                            <div className="icon-placeholder" style={{ color: 'white', float: 'left' }}
                            >
                                <img src={CloudAdd} alt=""
                                    className="cloud-icon"
                                />
                            </div>

                            <div className="bix-title" >bixCloud</div>

                        </div>

                        <br />

                        <div style={{ marginTop: "30px" }}>
                            <hr style={{ backgroundColor: '#0d2a4c', opacity: '40%' }} />
                        </div>

                        <div style={{ width: '97.5%' }}>

                            <div className='folders-div'>
                                <div
                                    style={{
                                        float: 'left',
                                        fontWeight: 'bold',
                                        fontSize: '17px',
                                        borderBottom: filesActive ? '2px #0d2a4c solid' : 'transparent',
                                        color: 'black',
                                        cursor: 'pointer'
                                    }}
                                    onClick={filesClick}
                                >
                                    My Files
                                </div>
                                {/* <div
                    style={{
                        float: 'left',
                        fontWeight: 'bold',
                        fontSize: '17px',
                        borderBottom: chatActive ? '2px #0d2a4c solid' : 'transparent',
                        color: 'black',
                        cursor: 'pointer',
                        marginLeft:'40px'
                    }}
                    onClick={chatClick}
                >
                    bixChat
                </div> */}

                            </div>
                            <div style={{ float: 'right' }} className='new-folder-btn'>
                                <img src={Plus} className='add' alt=""/>
                                <span className="new-text">New</span>
                            </div>





                        </div>


                        <br /><br />

                        <div className="folder-display-div ">
                            <br />
                            {filesActive ?
                                <div>
                                    <div style={{ marginLeft: '15px' }}>
                                        <div className="float-child">
                                            <div className="single-folder-div">
                                                <div className="folder-icon">
                                                    <img src={FolderIcon} alt=""/>

                                                    <div className="folder-title">
                                                        Images
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="float-child">
                                            <div className="single-folder-div">
                                                <div className="folder-icon">
                                                    <img src={FolderIcon} alt=""/>

                                                    <div className="folder-title">
                                                        Jpeg
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="float-child">
                                            <div className="single-folder-div">
                                                <div className="folder-icon">
                                                    <img src={FolderIcon} alt=""/>

                                                    <div className="folder-title">
                                                        Video
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                :
                                chatActive ?

                                    <div>
                                        <div style={{ marginLeft: '15px' }}>
                                            <div className="float-child">
                                                <div className="single-folder-div">
                                                    <div className="folder-icon">
                                                        <img src={FolderIcon} alt=""/>

                                                        <div className="folder-title">
                                                            Bix Chat UI
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="float-child">
                                                <div className="single-folder-div">
                                                    <div className="folder-icon">
                                                        <img src={FolderIcon} alt=""/>

                                                        <div className="folder-title">
                                                            Martin Scorsese
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    : ""}





                        </div>
                        <br /> <br />

                        <div className="btn-div"
                        >
                            <div style={{ float: 'right' }} className='save-btn'>
                                Save
                            </div>
                            <div className='cancel-btn' onClick={cancelClick}>
                                Cancel
                            </div>
                        </div>


                    </div>

                </div>
           

    )
}

export default SaveToCloud;