import React, { useState, useEffect, useRef, useContext } from 'react';
import { ws } from "../.././App";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditorStyles.scss';
import image from "../../Assets/Icons/Image.svg";
import doc from "../../Assets/Icons/attachment-sign.svg";
import AppContext from "../../ContextAPI/AppContext";
import sendWhite from "../../Assets/Icons/sendicon.svg";
import UploadPopup from "./UploadPopup";
import { nanoid } from "nanoid";
import "../../pages/ChatUIComponents/MessageBox/MessageBoxStyle.scss"
import expand from "../../Assets/Icons/maximize.svg"
import expand2 from "../../Assets/Icons/minimize.svg"
import secureLocalStorage from "react-secure-storage";
import ScrollingMenu from '../ScrollingMenu/ScrollingMenu';
import plus from "../../../src/Assets/Icons/plus-2.png";


function insertHeart() {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, '♥');
    this.quill.setSelection(cursorPosition + 1);
}

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
    'arial',
    'comic-sans',
    'courier-new',
    'georgia',
    'helvetica',
    'lucida',
];
Quill.register(Font, true);


const Editor = (props) => {
    const [tag, settag] = useState("")
    const [fromLocalStorage, setFromLocalStorage] = useState(false);
    const [modal, setModal] = useState("none");
    const [isEmpty, setIsEmpty] = useState(false)
    const [opacity, setOpacity] = useState(0.3)
    const [editorBorder, setEditorBorder] = useState(false)
    const [textFormatButton, setTextFormatButton] = useState(true)
    const [subjectFocus, setSubjectFocus] = useState(false)
    const [subjectpacity, setSubjectOpacity] = useState(0.3)



    const uploadAudio = useRef(null);
    const sendBtn = useRef(null);
    const input = useRef(null);
    const newContext = useContext(AppContext)

    const handleClick = () => {
        if (modal === "none") {
            setModal("block");
        } else {
            setModal("none");
        }
    };




    // const handleUploadFile = (event) => {
    //     for (let file of event.target.files) {
    //         newContext.UploadModalHandler("closeOnly")
    //         if (!files.includes(file)) {
    //             setFiles([...files, ...event.target.files]);
    //         }
    //     }
    // };

    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" && event.altKey) {
                sendBtn.current.click();
                event.preventDefault();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };

    }, [tag]);
    useEffect(() => {
        if (props.changeMargin) {
            props.changeMargin().then((objDiv) => {
                if (objDiv) {
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            })

        }
    }, [newContext.expandSelected])
    useEffect(() => {
        if (newContext.messageTag) {
            settag("Hi");
        }
    }, [newContext.messageTag])
    const handleChange = html => {
        settag(html)
        if (props.changeMargin) {
            props.changeMargin().then((objDiv) => {
                if (objDiv) {
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            })
        }
    };
    const getValue = (value) => {
        let val = value.replace(/(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g, "$1$3")

        let slicer;
        while (val.slice(0, 7) === '<p></p>' || val.slice(0, 11) === '<p><br></p>') {
            if (val.slice(0, 7) === '<p></p>') slicer = 7
            else slicer = 11
            val = val.substring(slicer, val.length)
        }

        while (val.slice(-7) === '<p></p>' || val.slice(-11) === '<p><br></p>') {
            if (val.slice(-7) === '<p></p>') slicer = 7
            else slicer = 11
            val = val.substring(0, val.length - slicer)
        }

        return val;

        // console.log(val , "valueeeee")
        // } else {

        //     for (let i = 1; i < val.length; i++) {
        //         if (val[i] === "<br></p>") {
        //             isempty = true;
        //         } else {
        //             isempty = false;
        //             break;
        //         }
        //     }
        //     if (isempty) {
        //         return value.replace(/<[^>]*>\s*<\/[^>]*>/gs, "");
        //     } else {
        //         return value;
        //     }
        // }
    };



    useEffect(() => {
        const getValueIsEmpty = (tag) => {
            let val = tag.split("<p><br></p>");
            if (tag === "") {
                return true
            } else {
                let isempty = val.filter((v) => v !== "").length > 0
                return !isempty
            }
        }
        setIsEmpty(getValueIsEmpty(tag))

    }, [tag])
    const generateBottomText = (isOnline) => {

        if (!props.isNewMessage && props.windowDimensions.width > 900) {
            if (tag !== "" && tag !== "<p><br></p>") {
                return (
                    <div className="editor-bottom-text">
                        <div className="text">
                            <span className="left-text">
                                {navigator.platform === "MacIntel"
                                    ? "Option + Return"
                                    : "Alt + Enter"}


                            </span>{" "}
                            to send
                        </div>

                        <div className="text" >
                            <span className="left-text">
                                {navigator.platform === "MacIntel" ? "Return" : "Enter"}
                            </span>{" "}
                            <span>to add a new line</span>
                        </div>
                    </div>
                );
            }
        }


    };
    // const onAudioClick = () => {
    //     uploadAudio.current.click();
    // };

    // const removeUploadedFile = (e) => {
    //     const name = e.target.getAttribute("name");
    //     const filteredItems = newContext.files.filter((item) => item.name !== name);
    //     newContext.setFiles(filteredItems);
    // };


    const modules = {
        toolbar: {
            container: '#toolbar',
            handlers: {
                insertHeart: insertHeart,
            },

            theme: 'snow'
        },

        clipboard: {
            matchVisual: false
        }
    };


    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'color',
    ];
    useEffect(() => {
        if (props.changeMargin) {
            props.changeMargin().then((objDiv) => {
                if (objDiv) {
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            })
        }
    }, [newContext.files])
    useEffect(() => {
        newContext.setFiles([])
    }, [props.selectedChat]);
    useEffect(() => {
        if (props.textFocus) {
            input.current.focus();
            if (props.changeMargin) {
                props.changeMargin().then((objDiv) => {
                    if (objDiv) {
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }
                })
            }
        }
    }, [props.textFocus])
    return (
        <AppContext.Consumer>
            {(context) => (
                <div className='text-editor'>

                    <div className={context?.expandSelected ? "expand-selected" : "text-editor-div" && editorBorder ? "text-editor-div-focus" : "text-editor-div"}>
                        {props.subjectInput ?

                            <div className={context?.replySubject ? "reply-subject-input-new" : "subject-input-new"} >



                                <input
                                    type="text"
                                    style={{ opacity: opacity }}
                                    className="subject-textinput"
                                    value={props.subject}
                                    onChange={(e) => {
                                        props.setSubject(e.target.value);
                                    }}
                                    placeholder="Subject"
                                    onClick={() => {
                                        setOpacity(1)
                                        setSubjectOpacity(0)
                                        setEditorBorder(true)
                                    }}
                                    onFocus={() => {
                                        setSubjectFocus(true)
                                        setOpacity(1)
                                        setSubjectOpacity(0)
                                        setEditorBorder(true)
                                    }}
                                    onBlur={() => {
                                        setSubjectFocus(false)
                                        setOpacity(0.3)
                                        setSubjectOpacity(0.3)
                                        setEditorBorder(false)
                                    }}
                                />

                                <div>
                                    {context?.expandSelected ?
                                        <img
                                            alt=""
                                            className="expand-button"
                                            src={expand2}
                                            onClick={() => {
                                                if (props.changeMargin) {
                                                    props.changeMargin().then((objDiv) => {
                                                        if (objDiv) {
                                                            objDiv.scrollTop = objDiv.scrollHeight;
                                                        }
                                                    })
                                                }
                                                context?.setExpandSelected(false);

                                            }}
                                        /> :

                                        <img
                                            alt=""
                                            className="expand-button"
                                            src={expand}
                                            onClick={() => {
                                                if (props.changeMargin) {
                                                    props.changeMargin().then((objDiv) => {
                                                        if (objDiv) {
                                                            objDiv.scrollTop = objDiv.scrollHeight;
                                                        }
                                                    })
                                                }


                                                context?.setExpandSelected(true);
                                            }}
                                        />}

                                </div>



                            </div> : ""}

                        <hr className={context?.replySubject ? "reply-subject-line" : "subject-line"} style={{ opacity: opacity }} />


                        <div className="inputText-wrapper" onClick={() => {
                            input.current.focus()
                        }}>


                            <div className='inputText'>

                                <div className="subject-main-div">
                                    <div className={props?.subjectInput ? "subject-selected-div" : "subject-div" && context?.replySubject ? "subject-selected-div" : "subject-div"}
                                        onClick={() => {
                                            if (props.changeMargin) {
                                                props.changeMargin().then((objDiv) => {
                                                    if (objDiv) {
                                                        objDiv.scrollTop = objDiv.scrollHeight;
                                                    }
                                                })
                                            }
                                            if (props.subjectInput) {
                                                props.setSubject("")
                                                props.setSubjectInput(false)
                                            } else {
                                                props.setSubjectInput(true)

                                            }

                                        }}
                                    >Add Subject
                                    </div>

                                    <div>
                                        {context?.expandSelected ?
                                            <img
                                                alt=""
                                                className={props?.subjectInput ? "expand-btn-subject-selected" : "expand-button"}
                                                src={expand2}
                                                onClick={() => {
                                                    if (props.changeMargin) {
                                                        props.changeMargin().then((objDiv) => {
                                                            if (objDiv) {
                                                                objDiv.scrollTop = objDiv.scrollHeight;
                                                            }
                                                        })
                                                    }

                                                    context?.setExpandSelected(false);



                                                }}
                                            /> :

                                            <img
                                                alt=""
                                                className={props?.subjectInput ? "expand-btn-subject-selected" : "expand-button" && context?.replySubject ? "reply-expand" : "expand-button"}
                                                src={expand}
                                                onClick={() => {
                                                    if (props.changeMargin) {
                                                        props.changeMargin().then((objDiv) => {
                                                            if (objDiv) {
                                                                objDiv.scrollTop = objDiv.scrollHeight;
                                                            }
                                                        })
                                                    }

                                                    context?.setExpandSelected(true);



                                                }}
                                            />}

                                    </div>


                                </div>

                                <div
                                    className={context?.expandSelected ? "expand-selected-typingarea" : "typing-area" && context?.replySubject ? "reply-typing-area" : "typing-area"}>

                                    <ReactQuill
                                        type="text"
                                        id="inputEditor"
                                        ref={input}
                                        value={tag}
                                        onChange={handleChange}
                                        placeholder={props.placeholder}
                                        modules={modules}
                                        formats={formats}
                                        className={context?.expandSelected ? "expand-selected-text-area" : "editor-style"}

                                        onFocus={() => {
                                            setEditorBorder(true)
                                            setOpacity(1)
                                            setSubjectOpacity(1)
                                            if (!props.isNewMessage) {
                                                let chat = {
                                                    chatroom_id: secureLocalStorage.getItem(
                                                        "bixChat_selectedChatId"
                                                    ),
                                                    publicID: secureLocalStorage.getItem(
                                                        "bixChat_loggedUserPublicId"
                                                    ),
                                                    users: context?.selectedchatReceivers,
                                                };
                                                if (chat.chatroom_id !== "") {
                                                    if (ws.readyState === WebSocket.OPEN) {
                                                        ws.send(
                                                            JSON.stringify({
                                                                action: "typing",
                                                                chat,
                                                            })
                                                        );
                                                    }
                                                }
                                            }
                                        }}
                                        onBlur={() => {
                                            setOpacity(0.3)
                                            setSubjectOpacity(0.3)
                                            setEditorBorder(false)

                                            if (!props.isNewMessage) {
                                                let chat = {
                                                    chatroom_id: secureLocalStorage.getItem(
                                                        "bixChat_selectedChatId"
                                                    ),
                                                    publicID: secureLocalStorage.getItem(
                                                        "bixChat_loggedUserPublicId"
                                                    ),
                                                    users: context?.selectedchatReceivers,
                                                };
                                                if (chat.chatroom_id !== "") {
                                                    ws.send(
                                                        JSON.stringify({
                                                            action: "typingend",
                                                            chat,
                                                        })
                                                    );
                                                }
                                            }
                                        }}
                                    />


                                </div>



                            </div>
                            <div className="popOver-n" style={{ display: modal }}>
                                <div
                                    className="sections-n"
                                    style={{ cursor: "not-allowed" }}
                                >
                                    Create a group and send
                                </div>
                                <div className="sections-n" style={{ cursor: "not-allowed" }}>
                                    Send in a thread
                                </div>
                                <div
                                    className="sections-n"
                                    onClick={() => {
                                        // mixpanel.track("Send message to bixChat users and non bixXhat users")
                                        if ((context.tempChatroomStatus === false)) {
                                            let tempId = nanoid();

                                            props?.receivers.forEach(receiver => {
                                                if (isEmpty) {
                                                    context?.chatroomExists("",
                                                        props?.subject,
                                                        [receiver],
                                                        [],
                                                        context.files,
                                                        tempId,
                                                        null,
                                                        false,
                                                        false, false)
                                                } else {
                                                    context?.chatroomExists(getValue(tag),
                                                        props?.subject,
                                                        [receiver],
                                                        [],
                                                        context.files,
                                                        tempId,
                                                        null,
                                                        false,
                                                        false, false)

                                                }
                                            });

                                            settag("");
                                            context.setFiles([]);
                                            setModal("none");
                                            // props.setSendMessageModal(false);
                                        }
                                    }}
                                >
                                    Send Separetly
                                </div>
                            </div>




                        </div>

                        <div id='toolbar'
                            className='toolbar'

                        // style={{
                        //     marginTop:
                        //         props?.subjectInput ? "-40px" : "",

                        // }}
                        >


                            <div className="left-options" style={{ opacity: subjectpacity }}>

                                <div>

                                    {/* {textFormatButton ?

                                        // <button className={textFormatButton ? "text-format-selected" : "text-format"}
                                        //     onClick={() => {

                                        //         setTextFormatButton(false)
                                        //         setTextFormat(true)


                                        //     }}
                                        // >T</button>

                                        <span

                                        >
                                            <div className={textFormatButton ? "text-format-selected" : "text-formatbtn"}>
                                                <button

                                                    onClick={() => {

                                                        setTextFormatButton(false)
                                                        setTextFormat(true)


                                                    }}
                                                > <img className='text-option-btn' src={textoption} /> </button>
                                            </div>


                                        </span>
                                        : ""

                                    } */}


                                    <span
                                        // className={textFormatButton ? "ql-formats-selected" : "ql-formats"}
                                        className="ql-formats"

                                    >
                                        <button className='ql-bold' />
                                        <button className='ql-italic' />
                                        <button className='ql-underline' />
                                        <button className='ql-list' value='ordered' />
                                        <button className='ql-list' value='bullet' />
                                        <button className='ql-link' value='link' />

                                    </span>
                                    {/* 
                                    {textFormat ?
                          
                                        : ""} */}

                                </div>




                            </div>



                            <div className="right-options" id="right-options">
                                {generateBottomText(props.isOnline)}

                                <div className="single-option">

                                    <img alt="" src={plus} onClick={() => {
                                        context?.setAppIntregrationModal(true)

                                    }}
                                        className="plus-button"
                                    />

                                </div>
                                <div className="single-option">


                                    <img src={image} alt=""
                                        onClick={

                                            // context.UploadModalHandler
                                            props.onImageClick

                                        }
                                    />

                                    <UploadPopup
                                        isUploadFromCloud={props.isUploadFromCloud}
                                        setIsUploadFromCloud={props.setIsUploadFromCloud}
                                        fromLocalStorage={fromLocalStorage}
                                        setFromLocalStorage={setFromLocalStorage}
                                        onImageClick={props.onImageClick}
                                    />
                                    <input
                                        onChange={context.handleUploadFile}
                                        style={{ display: "none" }}
                                        ref={props.uploadImage}
                                        type="file"
                                        className="hidden"
                                        accept="video/*,image/*"
                                        multiple
                                    />

                                </div>
                                <div className="single-option">
                                    <img src={doc} alt="" onClick={props.onFileClick} />
                                    <input
                                        onChange={context.handleUploadFile}
                                        style={{ display: "none" }}
                                        ref={props.uploadFile}
                                        type="file"
                                        className="hidden"
                                        multiple
                                    />
                                </div>



                            </div>
                            <div className='sendBtn-div'

                                style={{ backgroundColor: context.files.length === 0 && getValue(tag) === "" ? "#6d6d6d" : "" }}
                                onClick={() => {
                                    context.showContactsNav('close')
                                    // mixpanel.track("Send message to bixChat user")
                                    setTextFormatButton(true)
                                    context?.setExpandSelected(false);
                                    context.setMessageTag(false)
                                    if (props.changeMargin) {
                                        props.changeMargin().then((objDiv) => {
                                            if (objDiv) {
                                                objDiv.scrollTop = objDiv.scrollHeight;
                                            }
                                        })
                                    }
                                    // if (isHtml) {
                                    let chat = {
                                        chatroom_id: secureLocalStorage.getItem(
                                            "bixChat_selectedChatId"
                                        ),
                                        publicID: secureLocalStorage.getItem(
                                            "bixChat_loggedUserPublicId"
                                        ),
                                        users: context?.selectedchatReceivers,
                                    };
                                    if (chat.chatroom_id !== "") {
                                        ws.send(
                                            JSON.stringify({
                                                action: "typingend",
                                                chat,
                                            })
                                        );
                                    }
                                    if (props?.receivers) {
                                        console.log(props?.receivers);
                                        if (props.ccReceivers.length > 0) {
                                            let tempId = nanoid();
                                            context?.chatroomExists(
                                                getValue(tag),
                                                props?.subject,
                                                props?.receivers,
                                                props?.ccReceivers,
                                                context?.files,
                                                tempId,
                                                props?.selectedReply?.length > 0
                                                    ? props?.selectedReply[0]?.thread_id
                                                    : null
                                                , true,
                                                false, false)

                                            settag("")
                                            context?.setFiles([]);
                                        } else {
                                            if (
                                                props.sendMessageModal &&
                                                props.receivers.length !== 0 &&
                                                props.receivers.length > 1
                                            ) {
                                                handleClick();
                                            } else if (
                                                props.sendMessageModal &&
                                                props.receivers.length !== 0 &&
                                                props.receivers.length === 1
                                            ) {
                                                let tempId = nanoid();
                                                if ((context.tempChatroomStatus === false)) {
                                                    if (!isEmpty) {
                                                        context?.chatroomExists(
                                                            getValue(tag),
                                                            props?.subject,
                                                            props?.receivers,
                                                            [],
                                                            context?.files,
                                                            tempId,
                                                            props?.selectedReply?.length > 0
                                                                ? props?.selectedReply[0]?.thread_id
                                                                : null
                                                            , false,
                                                            false, false)


                                                    } else if (context?.files.length > 0) {
                                                        context?.chatroomExists(
                                                            "",
                                                            props?.subject,
                                                            props?.receivers,
                                                            [],
                                                            context?.files,
                                                            tempId,
                                                            props?.selectedReply?.length > 0
                                                                ? props?.selectedReply[0]?.thread_id
                                                                : null
                                                            , false,
                                                            false, false)
                                                    }
                                                    settag("")
                                                    context?.setFiles([]);
                                                    props.setSubject("")


                                                }

                                                // settag("");
                                            }
                                        }


                                    } else {
                                        if (!isEmpty) {
                                            if ((context.tempChatroomStatus === false)) {
                                                let tempId = nanoid();
                                                context?.chatroomExists(
                                                    getValue(tag),
                                                    props?.subject,
                                                    props?.receivers,
                                                    [],
                                                    context?.files,
                                                    tempId,
                                                    props?.selectedReply?.length > 0
                                                        ? props?.selectedReply[0]?.thread_id
                                                        : null
                                                    , props.fromCompose,
                                                    false, false)
                                                settag("")
                                                props.setSubject("");
                                                context?.setFiles([]);
                                                if (props.changeMargin) {
                                                    props.changeMargin().then((objDiv) => {
                                                        if (objDiv) {
                                                            objDiv.scrollTop = objDiv.scrollHeight;
                                                        }
                                                    })
                                                }
                                            }
                                        } else if (context?.files.length > 0) {

                                            if ((context.tempChatroomStatus === false)) {
                                                let tempId = nanoid();
                                                context?.chatroomExists(
                                                    "",
                                                    props?.subject,
                                                    props?.receivers,
                                                    [],
                                                    context?.files,
                                                    tempId,
                                                    props?.selectedReply?.length > 0
                                                        ? props?.selectedReply[0]?.thread_id
                                                        : null
                                                    , false,
                                                    false, false)
                                                settag("")
                                                props.setSubject("");
                                                context?.setFiles([]);
                                                let chat = {
                                                    chatroom_id: secureLocalStorage.getItem(
                                                        "bixChat_selectedChatId"
                                                    ),
                                                    publicID: secureLocalStorage.getItem(
                                                        "bixChat_loggedUserPublicId"
                                                    ),
                                                    users: context?.selectedchatReceivers,
                                                };
                                                if (chat.chatroom_id !== "") {
                                                    ws.send(
                                                        JSON.stringify({
                                                            action: "typingend",
                                                            chat,
                                                        })
                                                    );
                                                }
                                                if (props.changeMargin) {
                                                    props.changeMargin().then((objDiv) => {
                                                        if (objDiv) {
                                                            objDiv.scrollTop = objDiv.scrollHeight;
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    }
                                    if (props.fromCompose === true) {

                                        let primaryChats = context.getPrimary(context.allChatrooms)
                                        for (let i = 0; i <= primaryChats?.length - 1; i++) {
                                            if (primaryChats[i]?.profile_email === props.receivers[0]) {
                                                context.setSavedChatsSelected(true)
                                                context.setPrimaryCount(0)
                                            }
                                            else (
                                                context.setSavedChatsSelected(false)
                                            )
                                        }

                                    }

                                    console.log(props?.receivers, "receivers")
                                    let chatroom_id = secureLocalStorage.getItem("bixChat_selectedChatId");
                                    let thread_id =
                                        props?.selectedReply?.length > 0
                                            ? props?.selectedReply[0]?.thread_id
                                            : "null";
                                    if (props.rec) {
                                        props.setRec([])
                                    }

                                }}
                            >

                                <div className='send-button-name'>
                                    Send
                                </div>
                                <img
                                    ref={sendBtn}
                                    className="img-send-new"
                                    src={sendWhite}
                                    alt=""

                                />
                            </div>




                        </div>

                    </div>
                    {context.files.length > 0 ? <ScrollingMenu /> : ""}

                    {/* {context.files.length > 0 ? <div className="attachment-con-new-ed">
                        {props.isNewMessage ? context.files.length < 5 ? context.files.map((file) => {
                            return (
                                <div className="attachement-container-n">
                                    {file.type.split("/")[1] === "mp3" || file.type.split("/")[1] === "mpeg" ?
                                        <img
                                            alt=""
                                            src={mic}
                                            className="attachment-mic"

                                        /> : <img
                                            alt=""
                                            src={getDocument(
                                                file, file.name
                                            )}
                                            className="attachment-image-n"

                                        />}

                                    <img
                                        alt=""
                                        src={close}
                                        className="close-icon-n"
                                        onClick={removeUploadedFile}
                                        name={file.name}
                                    />
                                </div>


                            )
                        }) : context.files.slice(0, 4).map((file, index) => {
                            return (
                                <div className="attachement-container-n">
                                    <div>
                                        {file.type.split("/")[1] === "mp3" || file.type.split("/")[1] === "mpeg" ?
                                            <img
                                                alt=""
                                                src={mic}
                                                className="attachment-mic"

                                            /> : <img
                                                alt=""
                                                src={getDocument(
                                                    file, file.name
                                                )}
                                                className="attachment-image-n"

                                            />}
                                        <div>
                                            <div>{file.name}</div>
                                        </div>
                                    </div>
                                    {index !== 3 ? <img
                                        alt=""
                                        src={close}
                                        className="close-icon-n"
                                        onClick={removeUploadedFile}
                                        name={file.name}
                                    /> : <div className="plus-more">+{context.files.length - 4}</div>}

                                </div>
                            )
                        }) : context.files.length < 8 ? context.files.map((file) => {
                            return (
                                <div className="attachement-container-n">
                                    <div className="attachment-container-editor">  {file.type.split("/")[1] === "mp3" || file.type.split("/")[1] === "mpeg" ?
                                        <img
                                            alt=""
                                            src={mic}
                                            className="attachment-mic"

                                        /> : <img
                                            alt=""
                                            src={getDocument(
                                                file, file.name
                                            )}
                                            className="attachment-image-n"

                                        />}
                                        <div className="file-text"> {file.name}
                                            <div className="file-type-text">{getType(file.name)}
                                            </div></div>
                                    </div>

                                    <img
                                        alt=""
                                        src={close}
                                        className="close-icon-n"
                                        onClick={removeUploadedFile}
                                        name={file.name}
                                    />
                                </div>
                            )
                        }) : context.files.slice(0, 7).map((file, index) => {
                            return (
                                <div className="attachement-container-n">
                                    {file.type.split("/")[1] === "mp3" || file.type.split("/")[1] === "mpeg" ?
                                        <img
                                            alt=""
                                            src={mic}
                                            className="attachment-mic"

                                        /> : <img
                                            alt=""
                                            src={getDocument(
                                                file, file.name
                                            )}
                                            className="attachment-image-n"

                                        />
                                    }
                                    {index !== 6 ? <img
                                        alt=""
                                        src={close}
                                        className="close-icon-n"
                                        onClick={removeUploadedFile}
                                        name={file.name}
                                    /> : <div className="plus-more">+{context.files.length - 7}</div>}

                                </div>
                            )
                        })}
                        <div className="add-more" onClick={onImageClick}>+</div>


                    </div> : ""}
                */}
                </div>
            )}
        </AppContext.Consumer>
    );

};
export default Editor

