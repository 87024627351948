import React, { useState } from "react";
import arrow from "../../../Assets/Icons/up-arrow.svg";
import "../SendComponent/SendComponentStyles.scss";
import AppContext from "../../../ContextAPI/AppContext";
import sendWhite from "../../../Assets/Icons/send-white.svg";
import search from "../../../Assets/Icons/search.svg";
import Avatar from '../../../Components/Avatar/avatar'
import { nanoid } from "nanoid";
import { getDPService } from "../../../services/DPService";

const SendComponent = (props) => {
    const [recievers, setRecivers] = useState("")
    const [searchVal, setSearchVal] = useState("")
    const getHeight = () => {
        let h = document?.getElementById('left-bar')?.clientHeight - 50 - document?.getElementById('top-navbar')?.clientHeight
        return h
    }
    return (
        <AppContext.Consumer>
            {(context) => (

                <div className="_SendComponent" id="left-bar">
                    <div className="inner-container">
                        <div className="topper" id="top-navbar">
                            <div className="top-container">

                                <div className="header-list">
                                    <img alt="" src={arrow} onClick={() => {
                                        context.setSendModal(false)
                                        console.log("close");
                                    }} />Contacts</div>
                                <div className="header-container"
                                >
                                    <Avatar
                                        size='xs'
                                        src={localStorage.getItem("bixChat_isPrivate") === "true" ? localStorage?.getItem("bixChat_profileImage")
                                            : localStorage?.getItem("bixChat_domainImage")}
                                    />
                                    <div className="header">{localStorage.getItem("bixChat_isPrivate") === "true" ? localStorage.getItem("bixChat_fullname").split(" ")[0] : context.domainName}</div>
                                </div>
                            </div>

                            <div className="search-bar-wrapper-new"
                                id='cont-search'
                            > <img
                                    src={search}
                                    alt=""
                                    className="img-icon-style"
                                />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    id="left-Searchbar"
                                    className="input-style-new"
                                    value={searchVal}
                                    onChange={e => {
                                        setSearchVal(e.target.value)
                                        context.filterContacts(e.target.value.toLowerCase())
                                    }}
                                />
                            </div>
                        </div>

                        <div className="list-container" style={{ height: window.screen.width < 600 ? getHeight() : "" }}>
                            {context.filterContacts(searchVal.toLowerCase()).map((contact) => {
                                return (
                                    <div className="list-itm">
                                        <Avatar
                                            size='m'
                                            src={contact.avatar === "null" || contact.avatar === null ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : getDPService().getDP("ChatRoom.PrivateChatRoom", contact.public_id)}
                                        />
                                        <div className="list-details">
                                            <div className="name">{contact.name}</div>
                                            <div className="designation">{contact.headline !== "" ? contact.headline : "bixUser"}</div>
                                        </div>
                                        {props.sendComponentFrom === "Hi" ?
                                            <div className="button-container">
                                                <div className="yes-button" onClick={async () => {
                                                    let tempId = nanoid();
                                                    setRecivers(contact.email)
                                                    context.setSendLoading(true)
                                                    await context.chatroomExists(
                                                        "Hi",
                                                        "",
                                                        [contact.email],
                                                        [],
                                                        context.files,
                                                        tempId,
                                                        null,
                                                        false,
                                                        false, true)

                                                }}>{context.sendLoding && recievers === contact.email ? <div className="loader" /> : <div style={{ display: "flex" }}>"Hi"<div className="line-h" /> <img alt="" src={sendWhite} /></div>}</div>
                                            </div>
                                            : ""}
                                    </div>
                                )
                            }
                            )}
                        </div>

                    </div>

                </div>
            )}
        </AppContext.Consumer>

    );
};
export default SendComponent;

