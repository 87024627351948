import React from "react";
import './BlockConfirm.scss'
import AppContext from "../../../../ContextAPI/AppContext";



const BlockConfirm = () => {
    return (
        <AppContext.Consumer>
            {context => (
                <div className="modal block-confirm-wrapper is-active">
                    <div className="confirm-body">
                        {context.isContact ?
                            <div className="confirm-desc">
                                Are you sure you want to block {context?.selectedChatroomDatas?.chatroomData?.contact.name.split(' ')[0]}?
                            </div> :
                            
                            <div className="confirm-desc">
                                Are you sure you want to block {context?.selectedChatroomDatas?.chatroomData?._cls === "ChatRoom.EmailChatRoom" ? context?.selectedChatroomDatas?.chatroomData?.profile_name : context?.selectedChatroomDatas?.chatroomData?.chatroom_name.split(' ')[0]}?
                            </div>
                        }

                        <div className="btn-wrapper">
                            <div className="block-btn1"
                                onClick={() => {
                                    context.blockContact(localStorage.getItem("bixChat_selectedChatId"), 'block',true)
                                    context.setBlockConfirm(false)
                                    context.setIsBlocker(true)

                                }}
                            >
                                Block
                            </div>
                            <div className="cancel-btn1"
                                onClick={() => {
                                    context.setBlockConfirm(false)
                                }}>
                                Cancel
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </AppContext.Consumer>
    )
}

export default BlockConfirm