import React, { useContext } from "react";
import AppContext from "../../../ContextAPI/AppContext"
import AdminInput from "../../../Components/InputComponents/AdminInput/AdminInput";

import "./AdminBetaStyles.scss";
import AdminSteps from "../AdminSteps/AdminSteps";
import MobileSteps from "../AdminSteps/MobileSteps/MobileSteps";
const AdminDomainSetup = (props) => {


    const conText = useContext(AppContext)


    const onBtnClick = () => {
        if (!getDisabled()) {
            if (props.email !== "") {
                if (localStorage.getItem("bix_business_OTP_verified") === "true") {
                    if (props.buyDomain || props.domain !== "") {
                        conText.setContractLoading(true)
                        conText.sendContract(
                            props.country,
                            props.name,
                            props.phone,
                            props.address,
                            props.organization,
                            props.regNo,
                            props.designation,
                            props.nic,
                            props.buyDomain,
                            props.setupDNS,
                            props.domain,
                            props.email)
                    }
                }

            }
        }
    }
    const getDisabled = () => {
        if (!props.buyDomain && props.domain === "") {
            return true
        } else {
            return false
        }
    }

    return (
        <AppContext.Consumer>
            {(context) => (
                <div>
                    <div className="AdminBetaContainer">
                        <div className="AdminBetaInnerContainerRight">
                            <div className="wrapper2">
                                <div className="onboard-header">
                                    <div className="bixHeader">bixBusiness</div>
                                    <div className="header-bottom-text">Recommended for companies or industries.</div>
                                </div>
                                <div className="step-header">
                                    <div className="step-hdr">Step 4</div>
                                    <div className="step-header-bottom-text">Enter your domain and email
                                        to very your domain</div>
                                </div>
                                <div className="stp">
                                    <AdminSteps step={4} />
                                </div>
                                <div className="stp-mobile">
                                    <MobileSteps step={4} />
                                </div>
                            </div>
                        </div>
                        <div className="AdminBetaInnerContainerLeft">
                            <div className="wrapper">
                                <div className="content-header">
                                    <div className="content-Header">Domain </div>
                                    <div className="content-header-bottom-text">Enter your domain and email</div>
                                </div>
                                <div className="form-wrapper">
                                    <div className="admin-form-cont">

                                        <AdminInput
                                            name="Domain"
                                            value={props.domain}
                                            onChange={e => {
                                                props.setDomain(e.target.value)
                                                localStorage.setItem("bix_business_personal_domain", e.target.value)
                                            }}
                                        />

                                        <div className="margin-top-style" />
                                        <div className="terms-beta" style={{ color: props.domain !== "" ? "#c9c9c9" : "" }}>
                                            <input
                                                type="checkbox"
                                                className="check"
                                                id="termsCheck"
                                                checked={props.buyDomain}
                                                onClick={() => {
                                                    props.setBuyDomain(!props.buyDomain);
                                                }}
                                                disabled={props.domain !== ""}
                                            />
                                            I want help for buy new domain

                                        </div>
                                        <div className="terms-beta">
                                            <input
                                                type="checkbox"
                                                className="check"
                                                id="termsCheck"
                                                checked={props.setupDNS}
                                                onClick={() => {
                                                    props.setSetupDNS(!props.setupDNS);
                                                }}
                                            />
                                            I want help for setup DNS record
                                        </div>

                                    </div>
                                    <div className="right-btn-container">
                                        <div className="scnd-btn" onClick={() => {
                                            localStorage.setItem("bix_business_page", 3)
                                            context.setPageToShow(3)
                                        }}>Back</div>
                                        <div className="prim-btn" style={{ backgroundColor: getDisabled() ? "#707070" : "" }} onClick={() => {
                                            onBtnClick()
                                        }}>{context.contractLoading ? <div className="prim-load-l" /> : "Finish"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </AppContext.Consumer>
    );
};
export default AdminDomainSetup;
