import React, { useState } from "react";
import Cropper from "react-easy-crop";
import minus from "../../../Assets/Icons/minus-sign-2.png";
import plus from "../../../Assets/Icons/plus.png";
import camera from "../../../Assets/Icons/camera.png";
import reupload from "../../../Assets/Icons/reload.png";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "../utils/cropImage";
import tick from "../../../Assets/Icons/tick.svg";
import AppContext from "../../../ContextAPI/AppContext";
import "./UploadDPModalStyles.scss";
import CloseIcon from "../../../Components/Button/CloseIcon/CloseIcon";
const UploadDpModal = (props) => {
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };


  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="_UploadDPModalStyles">
          <div className="modal-1">
            <div className="modal-content-new">
              <div className="modal-content-2">
                <div className="left">
                  <div className="camera">
                    <img alt="" src={camera} className="img" />
                  </div>
                  <div className="popup-div1-h1">Change Profile Picture</div>
                </div>

                <div className="right">
                  <div className="reupload" onClick={props.onImageClick}>
                    <img alt="" src={reupload} className="img" />
                    {context.windowDimensions.width <= 600 ? "" : "Reupload"}
                  </div>

                  <div style={{ marginTop: '16px' }}>
                    <CloseIcon
                      size='m'
                      onClick={() => {
                        props.setAddPhoto(false);
                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="inner-container-3">
                {props.imagePreviewModal ? (
                  <div className="cropper">
                    <Cropper
                      image={props.imagePreviewModal}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      cropShape="round"
                      showGrid={false}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : null}

                <div
                  style={{
                    display: "flex",
                    marginLeft: "7%",
                  }}
                >
                  <div className="minus-container">
                    <img alt="" src={minus} className="img" />
                  </div>
                  <div>
                    <Slider
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                  <div className="plus-container">
                    <img alt="" src={plus} className="img" />
                  </div>
                </div>
              </div>
              <div
                className="circle"
                onClick={async () => {
                  let newestImage = "";
                  props.setAddPhoto(false);

                  try {

                    const croppedImage = await getCroppedImg(
                      props.imagePreviewModal,
                      croppedArea
                    );
                    newestImage = croppedImage.toDataURL();


                    props.setNewCroppedImage(newestImage);
                    props.setProPicUploaded(true)

                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <img alt="" src={tick} style={{ height: 20, width: 30 }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default UploadDpModal;
