import React, { useState, useRef, useContext, useEffect } from "react"
import AppContext from "../../../ContextAPI/AppContext";
import image from "../../../Assets/Icons/logout-new.png";
import close from "../../../Assets/Icons/cross-square-button.svg";
import googleCalender from "../../../Assets/Icons/google-calendar.svg";
import tick from "../../../Assets/Icons/tick.svg";
import clear from "../../../Assets/Icons/close-black.svg";
import "./AppIntregrationModalStyles.scss";
import { getDPService } from "../../../services/DPService";
import next from "../../../Assets/Icons/next.png";
import GoogleLogin from 'react-google-login';


const AppIntregrationModal = (props) => {



  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="_appIntregationModal" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
          <div id="myModal" className="modal-1">
            <div className="modal-content-top" >
              <div className="modal-content-add-admin">



                <div className="add-header">Browse by app</div>
                <img alt="" src={close} className="close"
                  onClick={() => {
                    context?.setAppIntregrationModal(false)
                  }} />

              </div>

              <div className="inner-container"
              // onClick={handleClick}
              >
                <div className="search-container" onClick={() => {
                  context?.setGoogleCalenderOptionModal(true)
                  context?.setAppIntregrationModal(false)

                }}>

                  <div style={{ display: "flex", width: "90%" }} >

                    <img alt="" src={googleCalender} className="google-calender-img"
                    />

                    <div className="div-name" >


                      <div className="name">
                        Google Calender
                      </div>
                      <div className="email">Create Event</div>
                    </div>

       

                    <img alt="" src={next} className="appIntegration-arrow" />

                    <div className="div-name" >

                   


                    
                    </div>
           


                  </div>


                </div>

              </div>



            </div>
          </div>
        </div>
      )}</AppContext.Consumer>
  )
}
export default AppIntregrationModal