import mixpanel from "mixpanel-browser"

export const getMixPanel = () => {
    const login = (name, email) => {
        mixpanel.identify(email)
        mixpanel.people.set({ "$name": name, "$email": email })
        mixpanel.track("Login")
    }
    return {
        login
    }


}