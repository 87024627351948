import React from "react";
import Button from "@material-ui/core/Button";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "./cropImage";
const Popup = (props) => {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = React.useState(0);

  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [croppedImage, setCroppedImage] = React.useState(null);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const showCroppedImage = () => {
    try {
      const croppedImage = getCroppedImg(
        props.img,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
    }
  };

  return (
    <div className="popup-box">
      <div className="box">
        {/* <span className="close-icon" onClick={props.handleClose}>x</span> */}

        {/* <img src={setCroppedImage}></img> */}
        <div className="container-cropper">
          <div className="">
            <Cropper
              image={props.img}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>

          <div className="slider">
            <Slider
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
        </div>
        <div className="container-buttons">
          <Button
            variant="contained"
            color="primary"
            onClick={showCroppedImage}
          >
            Show cropped image
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
