import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import sw from "./swDev";
import { CookiesProvider } from 'react-cookie';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import Gleap from 'gleap';
import config from "./config"

Sentry.init({
  // release: `${process.env.REACT_APP_ENV === "stage" ? 'bix-web-stage-': 'bix-web-prod-'} ${process.env.REACT_APP_RELEASE_VERSION}`,
  release: process.env.REACT_APP_RELEASE_VERSION,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    new ExtraErrorDataIntegration({
      //depth: 5,
    }),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error', 'debug', 'assert']
    }),
  ],
  environment: process.env.REACT_APP_ENV,
  autoSessionTracking: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
Gleap.initialize(config.gleap);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
sw();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();