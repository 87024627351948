import React from 'react';
import close from "../../../Assets/Icons/cross-square-button.svg";
import './CloseIconStyle.scss'


const CloseIcon = (props)=> {
    return(
        <div>
            <img src={close} alt='' 
            className={props.size ==='s'?'close-s' : props.size==='m'?'close-m' : props.size==='l'?'close-l': ''}
            onClick={props.onClick}
            />
            
        </div>
    )
}



export default CloseIcon;