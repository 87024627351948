import React, { useContext, useEffect } from "react";
import AppContext from "../../../ContextAPI/AppContext";
import "./../Settings/Settings.scss";

const ProfileSettings = (props) => {

    const conText = useContext(AppContext)

    useEffect(() => {
        conText.getBlockList(conText.allChatrooms)
    }, []);



    return (
        <AppContext.Consumer>
            {context => (
                <div style={{ overflowY: 'auto', height: "65vh" }}>
                    <div className="pro-pic-and-bio">
                        <div className="pro-pic-wrapper">

                            {props.proPicChanged ?

                                <props.Avatar
                                    size='xl'
                                    src={
                                        props.base64Image !== ""
                                            ? props.base64Image
                                            : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                    }
                                />
                                :

                                <div style={{ marginTop: '2%', marginLeft: '5%' }}>

                                    <props.Avatar
                                        size='xl'
                                        src={
                                            props.profilePicture === "" || props.profilePicture === "null" || props.profilePicture === null
                                                ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : props.profilePicture

                                        }
                                    />
                                </div>

                            }

                            <img
                                className="add-photo-icon-setting"
                                src={props.ProImage}
                                alt=""
                                onClick={() => {
                                    props.onImageClick()

                                }}
                            />

                            <input
                                style={{ display: "none" }}
                                ref={props.uploadImage}
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    props.handleNewProPic(e)
                                }}
                            />

                        </div>
                        <div className="margin-left-15 name-des-div">
                            <label htmlFor="">{JSON.parse(localStorage.getItem("bixChat_loggedUserData"))?.full_name}</label>
                            <div className="desi"> {JSON.parse(localStorage.getItem("bixChat_loggedUserData"))?.designation}</div>
                        </div>
                    </div>
                    <div className="hr-div" />
                    <div className="wid">
                        {props.countChange >= 3 ? <div>
                            <div className="double-input-wrapper" >
                                <div className="input-wrapper margin-right">
                                    <label htmlFor="">First Name</label>
                                    <input className="text-input" name="firstName" value={props.firstName && props.firstName} disabled type="text" />
                                </div>
                                <div style={{ width: "20px" }} />
                                <div className="input-wrapper margin-left">
                                    <label htmlFor="">Last Name</label>
                                    <input className="text-input" name="lastName" value={props.lastName && props.lastName} disabled type="text" />
                                </div>
                            </div>

                            <div className="name-limit-error" >The Number of times you can change your name is over. You cant change first and last Name</div>

                        </div> :

                            <div className="double-input-wrapper" >
                                <div className="input-wrapper margin-right">
                                    <label htmlFor="">First Name</label>
                                    <input className={` ${props.firstNameNotValid ? "input-not-valid" : ""} text-input `} name="firstName" value={props.firstName && props.firstName} onChange={(e) => { props.handleInputs(e) }} type="text" />
                                </div>

                                <div className="input-wrapper margin-left">
                                    <label htmlFor="">Last Name</label>
                                    <input className={` ${props.lastNameNotValid ? "input-not-valid" : ""} text-input `} name="lastName" value={props.lastName && props.lastName} onChange={(e) => { props.handleInputs(e) }} type="text" />
                                </div>
                            </div>}


                        <div className="double-input-wrapper" >
                            <div className="input-wrapper margin-right">
                                <label htmlFor="">Display Name</label>
                                <input className="text-input"
                                    name="displayName"
                                    value={props.displayName && props.displayName}
                                    onChange={(e) => { props.handleInputs(e) }}
                                    type="text" />
                            </div>

                            <div className="input-wrapper margin-left">
                                <label htmlFor="">Phone Number</label>
                                <input className="text-input"
                                    name="phoneNumber"
                                    value={props.phoneNumber && props.phoneNumber}
                                    onChange={(e) => { props.handleInputs(e) }}
                                    type="text" />
                            </div>
                        </div>


                        <div className="single-input-wrapper">
                            <div className="input-wrapper">
                                <label htmlFor="">Headline</label>
                                <input className="text-input" name="headline" value={props.headline && props.headline} onChange={(e) => { props.handleInputs(e) }} type="text" />
                            </div>
                        </div>

                        <div className="single-input-wrapper">
                            <div className="input-wrapper">
                                <label htmlFor="">Address</label>
                                <input className="text-input" name="address" value={props.address && props.address} onChange={(e) => { props.handleInputs(e) }} type="text" />
                            </div>
                        </div>

                        <div className="error-message-wrapper">
                            {props.firstNameNotValid ? <div>
                                First name required
                            </div> : ""}
                            {props.lastNameNotValid ? <div>
                                Last name required
                            </div> : ""}
                        </div>
                    </div>
                    <div className="button-wrapper" >
                        {props.renderUpdatedBtn()}
                    </div>
                </div>
            )}
        </AppContext.Consumer>

    )

}

export default ProfileSettings