import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../../ContextAPI/AppContext";
import ContentEditable from "react-contenteditable";
import moment from "moment";
import tool from "../../../../Assets/Icons/tool.svg";
import "../LeftSidebarStyle.scss"
import Arrow from "../../../../Assets/Icons/next.png";
import ChatOptions from "./ChatOptions";
import PinChat from "../../../../Assets/Icons/pin-chat.svg";
import EmailAvatar from "../../../../Components/Avatar/EmailAvatar/EmailAvatar";
import AttachmentBubble from "../../../../Components/AttachmentBubble/AttachmentBubble";
import ContentLoader from "react-content-loader"
import { getDPService } from "../../../../services/DPService";


const SingleChat = (props) => {

    const [showOptions, setShowOption] = useState(false)
    const [showDropDown, setShowDropDown] = useState(false)
    const [loaderWidthSize, setLoaderWidthSize] = useState(0)

    let conText = useContext(AppContext)
    const removeTags = (message) => {
        let messageSender = message && message?.sender_id !== localStorage.getItem(
            "bixChat_loggedUserPublicId"
        ) ? message && message?.sender_name.split(" ")[0] + " : " : "You : "
        if (!message?.message_body?.includes("<!DOCTYPE html")) {
            let messageNew = message !== undefined ? message?.message_body?.replaceAll(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "") : ""
            return messageSender + messageNew
        } else {
            let dom = new DOMParser().parseFromString(message?.message_body, 'text/html')
            let body = dom.body.innerText
            return messageSender + body
        }
    }
    const showProPic = (e, name, type) => {
        if (e === "ChatRoom.PrivateChatRoom") {
            return (
                <img
                    className="profile-picture"
                    src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                    alt="test"
                />
            );
        } else if (e === "ChatRoom.EmailChatRoom") {
            let na = name?.charAt(0)
            return <EmailAvatar size="m" initial={na} />;
        } else if (e === "ChatRoom.GroupChatRoom") {
            return (
                <img
                    className="profile-picture"
                    src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/group_dp.png"
                    alt="test"
                />
            );
        }
    };
    const handleDate = (date) => {
        if (
            moment().subtract(1, "day").format("L") ===
            moment.utc(date).local().format("L")
        ) {
            return "Yesterday";
        }
        if (
            moment().subtract(2, "day").format("L") ===
            moment.utc(date).local().format("L")
        ) {
            return moment.utc(date).local().format("dddd");
        }
        if (
            moment().subtract(3, "day").format("L") ===
            moment.utc(date).local().format("L")
        ) {
            return moment.utc(date).local().format("dddd");
        }
        if (
            moment().subtract(4, "day").format("L") ===
            moment.utc(date).local().format("L")
        ) {
            return moment.utc(date).local().format("dddd");
        }
        if (
            moment().subtract(5, "day").format("L") ===
            moment.utc(date).local().format("L")
        ) {
            return moment.utc(date).local().format("dddd");
        } else {
            return moment.utc(date).local().format("L");
        }
    };



    const handleOptions = () => {
        setShowOption(!showOptions)
    }

    const handleChildClick = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        let divWidth = document.getElementById("loaderMessage")?.clientWidth
        if (divWidth) {
            setLoaderWidthSize(divWidth - 66)
        }
    }, [conText.showLoader])
    const getMessage = (lastMessage) => {
        return (<div className="message-style" style={{
            color: props.value?._id["$oid"] ===
                localStorage.getItem("bixChat_selectedChatId")
                ? "white" : "", display: "flex"
        }}>
            {props.value?.last_messages !== undefined ?
                props.value?._cls === "ChatRoom.GroupChatRoom" ? (
                    props.value?.last_messages === undefined ? (
                        props.value?.created_by?.public_id === localStorage.getItem(
                            "bixChat_loggedUserPublicId"
                        ) ? (
                            "You created group '' " +
                            props.value?.group_title +
                            " ''"
                        ) : (
                            props.value?.created_by?.full_name?.split(" ")[0] +
                            " created group '' " +
                            props.value?.group_title +
                            " ''"
                        )
                    ) : lastMessage.message_body === "" ? (
                        ""

                    ) : (
                        <div style={{
                            color: props.value?._id["$oid"] ===
                                localStorage.getItem("bixChat_selectedChatId")
                                ? "white" : "", display: "flex"
                        }}>
                            <ContentEditable
                                className={`pre-message-${conText.fontSizeType}`}
                                html={removeTags(lastMessage)} // innerHTML of the editable div
                                disabled={true} // use true to disable edition
                                style={{
                                    color: props.value._id["$oid"] ===
                                        localStorage.getItem("bixChat_selectedChatId")
                                        ? "white" : ""

                                }}

                            />

                        </div>
                    )
                ) : lastMessage?.message_body === "" ? (
                    ""

                ) : (
                    <div style={{
                        color: props.value._id["$oid"] ===
                            localStorage.getItem("bixChat_selectedChatId")
                            ? "white" : "", display: "flex"
                    }}>
                        <ContentEditable
                            className={`pre-message-${conText.fontSizeType}`}
                            html={removeTags(lastMessage)} // innerHTML of the editable div
                            disabled={true} // use true to disable edition
                            style={{
                                color: props.value._id["$oid"] ===
                                    localStorage.getItem("bixChat_selectedChatId")
                                    ? "white" : ""
                            }}
                        />
                    </div>
                ) : ""}
        </div>
        )
    }
    const getNewGroupContent = () => {
        return (<div className="message-style" style={{
            color: props.value?._id["$oid"] ===
                localStorage.getItem("bixChat_selectedChatId")
                ? "white" : "", display: "flex"
        }}>
            <div className={`pre-message-${conText.fontSizeType}`} style={{
                color: props.value._id["$oid"] ===
                    localStorage.getItem("bixChat_selectedChatId")
                    ? "white" : ""
            }}>{props.value?._cls === "ChatRoom.GroupChatRoom" ?
                props.value?.last_messages === undefined ?
                    props.value?.created_by?.public_id === localStorage.getItem(
                        "bixChat_loggedUserPublicId"
                    ) ? (
                        "You created group '' " +
                        props.value?.group_title +
                        " ''"
                    ) : (
                        props.value?.created_by?.full_name?.split(" ")[0] +
                        " created group '' " +
                        props.value?.group_title +
                        " ''"
                    )
                    : "" : ""}
            </div>

        </div>)
    }

    const getAttachment = () => {
        let attach = { attachment: false, position: 0 }
        if (props.value?.last_messages) {
            for (let i = 0; i < props.value?.last_messages?.length; i++) {
                if (props.value?.last_messages[i]?.attachments?.length > 0 && props.value?.last_messages[i]?.message_body === "") {
                    attach = { attachment: true, position: i };
                    break;
                }
            }
        }
        return attach
    }

    return (
        <AppContext.Consumer>
            {(context) => (
                <div key={props.i}
                    className={context.anim ? "animation" : ""}

                    onMouseEnter={() => {
                        setShowDropDown(true)

                    }}
                    onMouseLeave={() => {
                        setShowDropDown(false)
                        setShowOption(false)

                    }}
                >
                    <div
                        className={`${props.value._id["$oid"] ===
                            localStorage.getItem("bixChat_selectedChatId")
                            ? `single-message-selected-${context.fontSizeType}`
                            : `single-message-${context.fontSizeType}`} `}
                        key={props.i}
                        onClick={props.onClick}
                    >
                        <div id="loaderMessage">
                            {context?.showloader ?
                                <div>

                                    <div className="top-message-cont">
                                        <div className="single-message-left">
                                            <div className="profile-picture-wrapper">
                                                {props.value?._cls === "ChatRoom.GroupChatRoom" ? (
                                                    props.value?.group_image &&
                                                        props.value?.group_image !== "None" ? props.value?.group_image !== "null" ? (
                                                            <img
                                                                className="profile-picture"
                                                                src={getDPService().getDP(props.value?._cls, props.value._id["$oid"])}
                                                                alt=""
                                                            />
                                                        ) : <img
                                                        className="profile-picture"
                                                        src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/group_dp.png"
                                                        alt=""
                                                    /> : (
                                                        showProPic(props.value._cls, props.value?.group_title
                                                            ? props.value?.group_title
                                                            : props.value?.email_receiver, props.value?.group_title
                                                            ? "name"
                                                            : "email")
                                                    )
                                                )
                                                    :
                                                    props.value?._cls === "ChatRoom.EmailChatRoom" ?

                                                        props.value?.contact.email !== undefined ?
                                                            (props.value?.contact.avatar === '' ?
                                                                showProPic(props.value._cls, props.value?.group_title
                                                                    ? props.value?.group_title
                                                                    : props.value?.contact.name, props.value?.group_title
                                                                    ? "name"
                                                                    : "contact") :
                                                                <img
                                                                    className="profile-picture"
                                                                    src={props.value?.contact?.avatar}
                                                                    alt=""
                                                                />)
                                                            :


                                                            (
                                                                showProPic(props.value._cls, props.value?.group_title
                                                                    ? props.value?.group_title
                                                                    : props.value?.email_receiver, props.value?.group_title
                                                                    ? "name"
                                                                    : "email")
                                                            )
                                                        :
                                                        getDPService().getDP(props.value?._cls, props.value.profile_public_id) !== "null" && getDPService().getDP(props.value?._cls, props.value.profile_public_id) !== null ?
                                                            (
                                                                props.value?.contact && Object.keys(props.value?.contact).length > 0 && props.value?.contact?.email !== "support@bixchat.xyz" ?



                                                                    <img
                                                                        className="profile-picture"
                                                                        src={props.value?.contact.avatar !== null || props.value?.contact.avatar !== "null" || props.value?.contact.avatar !== "" ? props.value?.contact.avatar : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"}
                                                                        alt=""
                                                                    />
                                                                    :

                                                                    <img
                                                                        className="profile-picture"
                                                                        src={getDPService().getDP(props.value?._cls, props.value.profile_public_id)}
                                                                        alt=""
                                                                    />

                                                            )
                                                            :
                                                            (
                                                                props.value?.contact && Object.keys(props.value?.contact).length > 0 && props.value?.contact?.email !== "support@bixchat.xyz" ?
                                                                    <img
                                                                        className="profile-picture"
                                                                        src={props.value?.contact.avatar === null || props.value?.contact.avatar === "null" || props.value?.contact.avatar === "" ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : props.value?.contact.avatar}
                                                                        alt=""
                                                                    />
                                                                    :
                                                                    showProPic(props.value._cls, props.value?.group_title
                                                                        ? props.value?.group_title
                                                                        : props.value?.email_receiver, props.value?.group_title
                                                                        ? "name"
                                                                        : "email")

                                                            )


                                                }
                                            </div>

                                            <div className="sender-noti">
                                                <div className="sender" style={{ textTransform: props.value?._cls !== "ChatRoom.EmailChatRoom" ? "capitalize" : "" }}>
                                                    {props.value?.group_title
                                                        ? props.value?.group_title
                                                        :
                                                        props.value?.contact?.email !== undefined ? props.value?.contact?.name :
                                                            props.value?.profile_name ? props.value?.profile_name : props.value?.email_receiver}
                                                </div>

                                                {context?.newMessage.length > 0 ? (
                                                    context?.newMessage?.map((message) => {
                                                        if (message?._id["$oid"] === props.value._id["$oid"]) {
                                                            return (
                                                                <div
                                                                    className="noti-wrapper"
                                                                    style={{
                                                                        width:
                                                                            message.count.toString().length >= 3
                                                                                ? "28px" : ""
                                                                    }}
                                                                >
                                                                    <div className="noti">
                                                                        {message.count > 100
                                                                            ? "99+"
                                                                            : message?.count < 10 ? "0" + message?.count : message?.count}
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else if (message?._id === props.value.id) {
                                                            return (
                                                                <div className="noti-wrapper">
                                                                    <div className="noti">
                                                                        {message.count > 100
                                                                            ? "99+"
                                                                            : message.count}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })
                                                ) : (
                                                    <div className="time-span-style" />
                                                )}
                                            </div>


                                        </div>
                                        <div className="single-message-right">

                                            {props.value?.indexdb_chatroom_status === "sending" ? <div className="date-and-noti" style={{ fontSize: "12px" }}> Sending....</div> : <div className="date-and-noti">
                                                {props.value?._cls === "ChatRoom.GroupChatRoom" ? (
                                                    props.value?.last_message ? (
                                                        <div style={{ display: 'flex' }}>
                                                            {props?.value?.pinned === 'null' || props?.value?.pinned === null || props?.value?.pinned === '' || props?.value?.pinned === undefined ?
                                                                '' :
                                                                <img src={PinChat} style={{ marginRight: '2px' }} alt="" />
                                                            }
                                                            <div className="date">

                                                                {moment
                                                                    .utc(props.value?.last_message.message_timestamp)
                                                                    .local()
                                                                    .format("L") === moment().format("L")
                                                                    ? moment
                                                                        .utc(props.value?.last_message.message_timestamp)
                                                                        .local()
                                                                        .format("LT")
                                                                    : handleDate(props.value?.last_message.message_timestamp)}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ display: 'flex' }}>

                                                            {props?.value?.pinned === 'null' || props?.value?.pinned === null || props?.value?.pinned === '' || props?.value?.pinned === undefined ?
                                                                '' :
                                                                <img src={PinChat} style={{ marginRight: '2px' }}  alt="" />
                                                            }
                                                            <div className="date">


                                                                {moment
                                                                    .utc(props.value.last_activity_at)
                                                                    .local()
                                                                    .format("L") === moment().format("L")
                                                                    ? moment
                                                                        .utc(props.value.last_activity_at)
                                                                        .local()
                                                                        .format("LT")
                                                                    : handleDate(props.value.last_activity_at)}
                                                            </div>
                                                        </div>
                                                    )
                                                ) : props.value?.last_message_at ? (
                                                    <div style={{ display: 'flex' }}>

                                                        {props?.value?.pinned === 'null' || props?.value?.pinned === null || props?.value?.pinned === '' || props?.value?.pinned === undefined ?
                                                            '' :
                                                            <img src={PinChat} style={{ marginRight: '2px' }}  alt=""/>
                                                        }
                                                        <div className="date">

                                                            {moment
                                                                .utc(props.value?.last_message_at)
                                                                .local()
                                                                .format("L") === moment().format("L")
                                                                ? moment
                                                                    .utc(props.value?.last_message_at)
                                                                    .local()
                                                                    .format("LT")
                                                                : handleDate(props.value?.last_message_at)}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}<div style={{ display: "flex" }}>

                                                    {props.value.profile_email === "support@bixchat.xyz" ?
                                                        <div className="tool-tip-pre" onClick={() => {
                                                            localStorage.setItem("firstLogin", true)
                                                            localStorage.setItem("tooltipFun", true)
                                                        }}>
                                                            <img alt="" src={tool} />
                                                            <span class="tooltiptext">Click to view tooltips</span>
                                                        </div> : ""}



                                                    {!props.showOptions ? '' :
                                                        showDropDown ?
                                                            <div className="dropdown-div" onClick={(e) => {
                                                                handleOptions()
                                                                handleChildClick(e)

                                                            }}>
                                                                <img src={Arrow} className='arrow-dropdown'
                                                                    onClick={() => {

                                                                        context.chatSelected(props.value);
                                                                        // context.setIsChatSelected(false)
                                                                        context.setContactModal(false);
                                                                        context.setRightSideBarStatus(true)

                                                                    }
                                                                    }

                                                                    alt=""
                                                                />

                                                                {props.isOther && props.value?.contact?.email !== undefined ? ' ' :
                                                                    showOptions ?
                                                                        <ChatOptions
                                                                            value={props.value}
                                                                            setShowOption={setShowOption}
                                                                            isOther={props.isOther}
                                                                            setShowDropDown={setShowDropDown}

                                                                        />
                                                                        : ''
                                                                }

                                                            </div>
                                                            : ''
                                                    }
                                                </div>

                                            </div>}

                                        </div>
                                    </div>
                                    <div className="message-and-sender">
                                        <div className={`subject-text-${context.fontSizeType}`} style={{
                                            color: props.value?._id["$oid"] ===
                                                localStorage.getItem("bixChat_selectedChatId")
                                                ? "white" : ""
                                        }}>
                                            {props.value?._cls === "ChatRoom.GroupChatRoom" ?
                                                props.value?.last_messages === undefined ? "" :
                                                    props.value?.last_messages !== undefined && props.value?.last_messages[0]?.message_subject !== "" ? props.value?.last_messages[0]?.message_subject : "(No Subject)" : props.value?.last_messages !== undefined && props.value?.last_messages[0]?.message_subject !== "" ? props.value?.last_messages[0]?.message_subject : props.value?._cls !== "ChatRoom.EmailChatRoom" ?
                                                        props.value?.members_list?.map((member) => {
                                                            if (member.public_id !==
                                                                localStorage.getItem("bixChat_loggedUserPublicId")) {
                                                                if (member.headline === "") {
                                                                    return <div>ZapMail User</div>
                                                                } else {
                                                                    return <div>{member.headline}</div>
                                                                }

                                                            }

                                                        }) : "(No Subject)"}</div>

                                        {props.value?.indexdb_chatroom_status === "sending" ? <div className="message"> Sending.... </div> : <div className="message">

                                        </div>}
                                        <div className={`messages-container-${context.fontSizeType}`}>
                                            {getAttachment().attachment === true && getAttachment().position === 0 ? <AttachmentBubble name={JSON.parse(props.value.last_messages[getAttachment()?.position]?.attachments[0])?.name} length={props.value.last_messages[getAttachment()?.position]?.attachments?.length} /> :
                                                props.value?.last_messages !== undefined ? props.value?.last_messages?.map((lastMessage) => {
                                                    return getMessage(lastMessage)
                                                }) : getNewGroupContent()
                                            }
                                        </div>



                                    </div>
                                </div>
                                :
                                <ContentLoader
                                    speed={2}
                                    width={450}
                                    height={124}
                                    viewBox="0 0 450 124"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                    {...props}
                                >
                                    <rect x="48" y="18" rx="3" ry="3" width="108" height="12" />
                                    <rect x={loaderWidthSize + 9} y="20" rx="3" ry="3" width="38" height="10" />
                                    <rect x="48" y="39" rx="3" ry="3" width="70" height="8" />
                                    <rect x="48" y="50" rx="3" ry="3" width={loaderWidthSize} height="35" />

                                    <circle cx="20" cy="30" r="20" />
                                </ContentLoader>}

                        </div>

                    </div>
                    {/* <div className="border-bottom" /> */}
                </div>
            )}
        </AppContext.Consumer>
    )

}
export default SingleChat