const PrivacyContent = () => {
    return (
        <div className="pol">
            <div className="div-pol">
                <div className="head-pol">Protecting your privacy</div>
                <br />
                Binary Technologies International (Pvt) Ltd (“us,” “we,” “our” or the “Company”), have made our products accessible from mobile applications, web applications or any other media form (“portals”) to provide services (“services”). We respect and value the privacy of each person (“you”, “your”) who visits our portal, and we commit towards maintaining the highest standards required to protect any personal information you consent to share with us in accordance with the applicable laws and regulations.
                <div style={{ marginTop: "10px" }} />
                This Privacy Policy describes the practices of Binary Technologies International (Pvt) Ltd, regarding collection and storing, use and deletion of information from visitors to our portals. This Privacy Policy applies to the services offered by us at our Company portals. This Privacy Policy applies regardless of the type of device used to access the portals and our services.
                <br />

                <div className="head-pol">Collection of Personal Information </div>
                <br />
                We may request or require you to provide personal information to access services or engage in certain activities on the portals. The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information
                <div style={{ marginTop: "10px" }} />
                For this purpose, personal information is information that you will share with us about yourself, such as name, age, telephone number, email address, as well as other additional details which will be used to identify you as an individual who has used and accessed our portals in specific circumstances which require the sharing of personal information. For example, you may provide personal information to us when you send us an e-mail message or by filing an enquiry online.
                <br />

                <div className="head-pol">How we use your Information</div>
                <br />
                We may use the information collected through our portals for various purposes including, but not limited;
                <div style={{ marginLeft: "10px" }}>
                    •<span style={{ marginRight: "10px" }} />To provide services and/or information you requested through our portals<br />
                    •<span style={{ marginRight: "10px" }} />To improve, personalize, and enhance your experience on usage of our portals, including managing and recording your preferences<br />
                    •<span style={{ marginRight: "10px" }} />To communicate with you, including for customer service.<br />
                    •<span style={{ marginRight: "10px" }} />To provide you with updates and other information relating to the portals, and for marketing and promotional purposes<br />
                    •<span style={{ marginRight: "10px" }} />To respond to emails or requests from you<br />
                    •<span style={{ marginRight: "10px" }} />To process an application as requested by you<br />
                    •<span style={{ marginRight: "10px" }} />To develop new products, services, features, and functionality, marketing and research purposes <br />
                    •<span style={{ marginRight: "10px" }} />To perform data analyses and other processing to improve our portals.<br />
                    •<span style={{ marginRight: "10px" }} />To improve content, functionality, and usability of our portals<br />
                    •<span style={{ marginRight: "10px" }} />For recruiting purposes<br />
                    •<span style={{ marginRight: "10px" }} />To administer and troubleshoot the portals<br />
                    •<span style={{ marginRight: "10px" }} />As otherwise necessary or useful for us to conduct our business, so long as such use is permitted by law<br />

                    <br />
                </div>

                <div className="head-pol">Who is your Information shared with?</div>
                <br />
                We do not sell or share any personal information volunteered on the portal to any third party (public/private). Any information provided to the portal will be protected from loss, misuse, unauthorized access or disclosure, alteration, or destruction.<br />
                <div style={{ marginTop: "10px" }} />
                The personal information you provide on the portals will be used only for the purposes for which it is provided for by you. As a rule, we do not collect personal information about you when you visit our portals, unless you choose to provide such information to us. Submitting personal information through our portals is voluntary. By doing so, you are giving the permission to use the information for a specific, stated purpose. If you choose to provide us with personal information through such methods as completing an online form, we will use that information to help us provide you the information or service you have requested. The information we may receive from you varies based on what you do when visiting our portals.
                <div style={{ marginTop: "10px" }} />The personal information we collect, and process are done so within the limits provided by applicable law, and only after establishing reasonable security safeguards for such personal information. Where required by law, we will seek your consent before processing sensitive personal information.
                <div style={{ marginTop: "10px" }} />We may choose to retain your personal information to the full extent of any period as provided by law and statute. We may also choose to delete your information from our databases after a required number or years and/or if the intended purposes for which we have collated your information is completed or no longer relevant.
                <br />
                <div className="head-pol">Unsecure Information</div>
                <br />
                You are informed that any information you may choose to share via email may not be secure and we urge you to not share sensitive information such as bank account details, credit card details or any other personal details you would consider as being sensitive in nature via email.
                <div style={{ marginTop: "10px" }} />However, we undertake to not provide your personal information to any third party who does not have a legitimate right to view the same unless prior consent is obtained from you.
                <div style={{ marginTop: "10px" }} />In the event the portals provide links to external websites, please note that we do not have any hold over nor do we control any content, practices or any other function of those websites and we will not be responsible for any action taken by the said websites. Providing access to external third-party websites does not constitute our endorsement of the same and the contents of our Privacy Policy will not apply in respect of any such external / third party websites.
                <br />
                <div className="head-pol">Cookies and related technologies</div>
                <br />
                We use cookies, tracking pixels and related technologies to provide our services through our portals.
                <div style={{ marginTop: "10px" }} /> A cookie is a small file, typically of letters and numbers, downloaded to a user's computer or mobile device when the user accesses certain websites. Cookies are then sent back to originating website on each subsequent visit. Cookies are useful because they allow a website to recognise a user’s device. They are used for a wide variety of purposes such as website analytics, targeted advertising, recording user preferences or for authentication. You can refuse, consent to, or opt-out of our cookies at any time.
                <div style={{ marginTop: "10px" }} />We generally do not use cookies to identify you personally. If you continue to browse our portals without changing your web browser's or device's settings that control cookies, you agree to receive cookies when you use our services. However, if you prefer, you can change your cookie settings. Some browsers have options that allow the visitor to control whether the browser will accept cookies, reject cookies, or notify the visitor each time a cookie is sent. You may elect to reject cookies by adjusting your settings, but please note that by doing so some of the features of our portals and services may not function properly.
                <div style={{ marginTop: "10px" }} /> We use the software Mixpanel to do the analytics. Mixpanel allows us to analyze how users interact with our product, further understand user behaviors, engagement metrics and thus increase user engagement of our product. This will help  to make the product more user friendly and helpful. Mixpanel employs an event-based, user-centric model that connects each interaction to a single user. When user logins mixpanel starts to gather data of each event such as: each button click, number of user logins, number of users visits to our websites.
                <br />
                <div className="head-pol">What kinds of cookies do we use? </div>
                <br />
                <div style={{
                    marginLeft: "20px"
                }}>
                    a)<span style={{ marginRight: "10px" }} />Necessary cookies: Necessary cookies enable you to use our portals and all their features. Without these cookies you may not be able to use all the features of our portals.
                    <div style={{ marginTop: "10px" }} />
                    b)<span style={{ marginRight: "10px" }} />Performance cookies: Performance cookies collect information about how you use our portals so we can improve them for you in future. For example, they collect information on which pages you visit most often and any error messages you may get. The information collected by these cookies is anonymous. They do not collect any information that can identify you personally.
                    <div style={{ marginTop: "10px" }} />
                    c)<span style={{ marginRight: "10px" }} />Functionality cookies: We use functional cookies to provide enhanced functionality and personalization, to remember your preferences, to diagnose server and software errors, and in cases of abuse, track and mitigate the abuse. The information these cookies collect is anonymous. They cannot track your browsing activity on other websites.
                    <div style={{ marginTop: "10px" }} />
                    d)<span style={{ marginRight: "10px" }} />Targeting/advertising cookies: We and our service providers may use advertising cookies to deliver ads that we believe are relevant to you and your interests. For example, we may use targeting or advertising cookies to customize the advertising and content you receive on our portals, to limit the number of times you see the same ad on our poratls and to help measure the effectiveness of our advertising campaigns.
                    <div style={{ marginTop: "10px" }} />
                    e)<span style={{ marginRight: "10px" }} />Social media cookies: Our poratls uses third-party cookies from social media sites such as Facebook and LinkedIn to enable in-depth campaign reporting and to track social network users when they visit our portals, by using a tagging mechanism provided by those social networks. These cookies can also be used for event tracking and remarketing purposes. Any data collected with these tags will be used in accordance with our Privacy Policy and with the social network's privacy policies.
                </div>
                <br />
                <div className="head-pol">How do you change cookie preferences or block cookies? </div>
                <br />
                For the performance cookies and targeting cookies when starting a new session on our portals, you can choose to opt-out of these. It is possible that any advertising you receive will be less targeted as a result, but the portals should function correctly.
                <div style={{ marginTop: "10px" }} />If you wish to disable the strictly necessary cookies, then within your browser you can choose whether you want to accept cookies or not. Different browsers make different controls available to you. Generally, your browser will offer you the choice to accept, refuse or delete all cookies, third-party cookies, or specific website cookies. Each browser’s website should contain instructions on how you can do this.
                <div style={{ marginTop: "10px" }} />If you block these cookies on our portals, certain features and services may not function properly.
                <br />
                <div className="head-pol">Information collected by third parties on our services</div>
                <br />
                Some of our services include social network or other third-party plug-ins (such as link to our Facebook page), the providers of these plug-ins may be able to collect information about you even if you do not click on or otherwise interact with the plug-in or widget and regardless of whether you have an account or other relationship with these social networks and third parties. If you use social network tools or visit social networking sites, you should read their privacy policies to learn what information they collect, use, and share.<br />
                <div className="head-pol">Storage and retention</div>
                <br />
                We may keep information and content in our systems, backup files and archives. Your personal data will be retained if necessary to provide you with the services requested. When we no longer need to use your personal data to comply with business requirements, contractual or statutory obligations, we will remove it from our systems and records and/or take steps to properly anonymize it so that you can no longer be identified from it, unless we need to keep your personal data, including if we need to keep your personal data to comply with legal or regulatory obligations to which we are subject, e.g. statutory retention periods and usually contain retention periods, or if we need it to preserve evidence within the statutes of limitation<br />
                <div className="head-pol">Company Policy towards Minors</div>
                <br />
                The portals and the Services are not intended for the use of Minors. The Company does not knowingly collect Information from children. If anyone becomes aware that a child has provided the Company with his or her personal information, you may contact the Company at binarytechnologies.io  If the Company becomes aware that a child under thirteen (13) years of age has provided the Company with his or her Personal Information, the Company will endeavor to take reasonable steps to delete such information. <br />
                <div className="head-pol">
                    Updates and amendments to our Privacy Policy
                </div>
                <br />
                We reserve the right to amend this Privacy Policy at any time, without prior notice, which amendment(s) are to be fully effective upon posting on the portals. Using the services means you accept this Privacy Policy as it appears at the time of your access. By accessing the content and services made available on the portals you are agreeing to all the terms and conditions of this Privacy Policy. We want and encourage you to review our Privacy Policy and other policies on a regular basis. We will also provide appropriate notice and choices to you, by posting an announcement on the website or sending you an email, based on the scope and extent of changes.
                <br />
                <div className="head-pol">Contact</div>
                <br />
                You may contact us at any point with regards to any queries, complaints, concerns or otherwise regarding our Privacy Policy and we will take steps to revert to you within a reasonable period of time. Please contact us on the following:-
                <br /> <br />By email at binarytechnologies.io
                <br />By phone at  +94 76 870 8701
                <br />By registered post at No.92, First Floor, Capital Trust Tower 03, Glennie Street, Colombo 02.
                <br /> <br />
                We also welcome users' requests not to receive certain forms of communication from us and third parties and not to have certain information shared with nonaffiliated third parties. If you prefer that we not disclose your personal information to nonaffiliated third parties, you may request us not to make those disclosures (except as required by law) by contacting us as described above. If you elect to opt out of certain disclosures, we may not be able to offer you certain services and products. Please note that personal information does not include publicly available information that is lawfully made available to the general public from government records, widely distributed media or disclosures to the general public required by law.
                <br />
            </div>
        </div>

    )
}
export default PrivacyContent