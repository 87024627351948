import React from "react"
import "./AttachmentBubbleStyles.scss"

const AttachmentBubble = (props) => {
    const getColor = (attachment) => {
        if (attachment) {
            if (attachment.includes(".pdf")) {
                return "red";
            } else if (attachment.includes(".doc") || attachment.includes(".docx")) {
                return "#1755b8";
            } else if (attachment.includes(".ppt")) {
                return "#D04423";
            } else if (attachment.includes(".csv")) {
                return "#1D6f42";
            } else if (attachment.includes(".xlsx")) {
                return "#1D6f42";
            } else if (attachment.includes(".txt")) {
                return "#808080";
            } else {
                return "black";
            }
        }
    }
    const getName = () => {
        let g = /\.([0-9a-z]+)$/i;
        let docName = props.name?.match(g)[1]
        return docName
    }
    return (
        <div className="AttchmentBubble">
            <div className="bubbleContainer">
                <div className="leftBubble">
                    {getName()}
                </div>
                <div className="rightBubble" style={{ backgroundColor: getColor(props.name) }}>
                    <div className="line-1" />
                    <div className="line-2" />
                    <div className="line-3" />
                    <div className="line-4" />

                </div>
            </div>
            {props.length > 1 ?
                <div className="moreContainer">
                    +{props.length - 1}
                </div> : ""}

        </div>
    )

}
export default AttachmentBubble