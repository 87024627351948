import React, { useContext, useState } from "react"
import AppContext from "../../../ContextAPI/AppContext"
import config from "../../../config"
const ManageUsers = () => {
    const context = useContext(AppContext)
    const [emailAddress, setEmailAddress] = useState("")
    const [token, setToken] = useState("")
    const handleEmailAddress = () => {
        context.setPasswordLinkLoading(true)
        context.passwordResetLink(emailAddress).then((token) => {
            setToken(token)

        })
    }

    const renderUpdatedBtn = (() => {
        let validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ;
        if (emailAddress !== "" && emailAddress.toLowerCase().match(validRegex)) {
            return (
                <button className={`add-photo-button-sttings button ${context.passwordLinkLoading ? "is-loading" : ""} `}
                    onClick={handleEmailAddress}>Continue</button>
            )
        } else {
            return (
                <button className={`add-photo-button-sttings-disable`} >Continue</button>
            )
        }
    })

    const renderBtn = (() => {

        return (
            <button className={`add-photo-button-sttings button `}
                onClick={() => {
                    setEmailAddress("")
                    context.setShowPasswordLink(false)
                }}>Okay</button>
        )

    })


    return (
        <div className="manageUsers">
            <div className="users-head">Reset Password</div>
            {context.showPasswordLink ? <div><div className="users-sub">{emailAddress.split("@")[0]}'s reset password link</div>
                <div className="link" >
                    <a>{config.apiEndpoints.protocol}{config.domain.auth}{config.emailText}
                        /password-change?token={token}</a>
                </div>
                <div className="button-wrapper" style={{ marginTop: "40px" }}>
                    {renderBtn()}</div></div> : <div>   <div className="users-sub">Enter the email address assoiciated with your users's account we will send you a link to reset password </div>
                <div className="single-input-wrapper" style={{ marginLeft: "-5px", marginTop: "20px" }}>
                    <div className="input-wrapper">
                        <label htmlFor="">Email Address</label>
                        <input className="text-input" name="headline" value={emailAddress && emailAddress} onChange={(e) => { setEmailAddress(e.target.value) }} type="email" />
                    </div>
                </div>
                <div className="button-wrapper" style={{ marginTop: "40px" }}>
                    {renderUpdatedBtn()}</div></div>}


        </div>

    )
}
export default ManageUsers