import axios from "axios"
import config from "../config"
import CookieService from "./CookieService"
import { logoutService } from "./LogoutService";
//staging test
export const tokenService = () => {
    const expiresAt = 60 * 24;
    const checkToken = (refreshToken) => {

        return new Promise((resolve) => {
            if (refreshToken) {
                let token = `Bearer ${refreshToken}`
                let users = []
                axios({
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": token
                    },
                    method: "post",
                    baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
                    url: "refresh",
                }).then((response) => {
                    if (response.status === 200) {
                        let data = response.data
                        if (config.environment !== "development") {
                            let date = new Date();
                            date.setTime(date.getTime() + expiresAt * 60 * 1000 * 30);
                            users = CookieService.get("users")
                            let user = users.filter((user) => {
                                if (user.isLogged) {
                                    return user
                                }
                                return null
                            })[0]
                            user.x_access_token = data["x_access_token"]
                            user.expires = date;
                            localStorage.setItem("bixChat_accessToken", data["x_access_token"]);
                            const options = { path: "/", domain: config.cookieDomain, expires: date };
                            CookieService.set("users", users, options);
                            resolve("success")

                        } else {
                            localStorage.setItem("bixChat_accessToken", data["x_access_token"]);
                            resolve("success")
                        }

                    } else {
                        localStorage.clear()
                        logoutService().logoutRedirection()
                        resolve("failed")
                    }

                })
            }
        })

    }

    return {
        checkToken
    }

}