 const AgreementContent = () => {
    return (
        <div>
            <center className="center">Beta Terms and Conditions</center>
            Please read these Beta Test Terms and Conditions carefully (“Agreement”, “Terms and Conditions”).  This Agreement is legally binding and made by and between Binary Technologies International (Pvt) Ltd (the "Company", “our”, “us”, “we”) and each individual, business, organization or other person or entity (the “Beta Participant(s)”, “you”, “your”, “user(s)”) that uses and or accesses the website as well as any other media form, media channel, mobile website or mobile application related or connected thereto (collectively referred to as “Portals” provided by the Company for the purpose of becoming Beta Participants of the programme (“Beta Test Programme”). By accessing the Portals, selecting Beta Test Programme and agreeing to these Terms, you agree to be bound to Terms and Conditions described in this Agreement. If you do not agree to these Terms and Conditions, do not subscribe to, access, or use the Beta Test Programme. Each time any of the Company Portal is usedto access Beta Test Programme and the Agreement accepted, an affirmation is given for the acceptance of the Terms and Conditions herein.
            <br />
            <div className="head-ag">Purpose</div>
            The Company is in the business of software solutions development and is in the prerelease stage of development and testing of a digital platform which enables communication through electronic mail and instant messages within the platform (“Beta Product"). The Company through its Portals grants access to Beta Participant to be a part of the Beta Test Programme by providing input and other feedback regarding the Company’s proprietary, non-commercially available hosted software application, application platform interface, services, features and/or functionalities in connection with the Beta Product. The Company through this Beta Test Programme wishes to identify bugs, enhance the quality and functionality of the Beta Product and fine-tune the Beta Product and components testing the software changes and innovations of the Beta Product on qualified Beta Participants in their own environment.
            <div className="head-ag">Acceptance of Terms of Use</div>
            The participation on the Beta Test Programmeis offered to you conditional upon your acceptance of the terms, conditions, and notices contained herein and updated from time to time. We may modify these Terms and Conditions or any part thereof, or add or remove terms at any time, and such modifications, additions or deletions will be effective immediately upon posting.  Although we will endeavour to provide you with notice of material changes, you being a Beta Participant or continuing to be a Beta Participant after us posting of changes shall be deemed to constitute acceptance by you of such modifications, additions or deletions.TheCompany may modify, suspend, and discontinuethe Beta Test Programme at any time, without notice or liability.
            <div className="head-ag">Term</div>
            This Agreement between Beta Participant and the Company shall be in force and effect till the completion of the Beta Test Programme (“Term”) or till either party terminates according to this Agreement.
            <div className="sub-ag">Suspension and Termination</div>
            <div className="head-ag-2">Suspension</div>
            <div className="margin-l">(i)   The Company may immediately suspend you or one of your employees access to and use of the Beta Test Programme if you or one of your employees is in breach of any instructions provided herein and or through the Portal, provided that the suspension will continue only for as long as reasonably necessary for you to remedy the breach.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(ii)  The Company may suspend or terminate this Agreement immediately in the event of any wrongful or unauthorized access to or use of the Beta Product by you or other third party
            </div>
            <div className="margin-t" />
            <div className="head-ag">Termination</div>
            <div className="margin-l">(i)   The Company may terminate this Agreement with you with Two (02) weeks notice, for any reason whatsoever.
            </div>
            <div className="margin-t5" />
            <div className="margin-l">(ii)  Beta Participant may terminate this Agreement with Two (02) weeks notice. The Company will make every effort to terminate access to the account immediately upon receipt of such written notice of termination.  However, you cannot be assured that access has been terminated until you receive confirmation of the termination from the Company.
            </div>
            <div className="margin-t5" />
            <div className="margin-l">(iii) Termination of this Agreement does not affect the any accrued rights or obligations of either Party under the Agreement.
                <div className="margin-t5" />
            </div>
            <div className="margin-l">iv)   Upon Two (02) weeks from the notice of termination the Company shall delete all information the Company has of the Beta Participant.
            </div>
            <div className="margin-t5" />
            <div className="margin-l">(v)   Notwithstanding the foregoing provisions, the Company shall have the right to withhold, remove or discard any content, data or other information that the Beta Participant post or upload into the Beta Test Programme while using the Beta Product, even upon termination.
                <div className="margin-t5" />
            </div>
            <div className="margin-t" />

            <div className="head-ag">Acknowledgement of the Beta Participant</div>

            You acknowledge and agree:
            <div className="margin-t" />
            <div className="margin-l">(i)   that the Beta Product is released with the specific intention of allowing qualified Beta program participants to test upcoming releases in their own environment.
                <div className="margin-t5" />
            </div><div className="margin-l">(ii)    that the very nature of the Beta designation implies that the Beta Product is incomplete and not quite ready for full release.
                <div className="margin-t5" />
            </div><div className="margin-l">(iii)   that there shall be updates done to the Beta Product from time to time and such updates shall become part and parcel the Beta Product and thereby of this Agreement.
                <div className="margin-t5" />
            </div><div className="margin-l">(iv)    that the Beta Product may not operate properly, be in final form or fully functional, that the Beta Product may contain errors, design flaws or other problems;
                <div className="margin-t5" />
            </div><div className="margin-l">(v) that the information obtained using the Beta Product may not be accurate and may not accurately correspond to information extracted from any database or other source;
                <div className="margin-t5" />
            </div><div className="margin-l">(vi)    that the use of the Beta Product may result in unexpected results, loss of data or communications, project delays or other unpredictable damage or loss;
                <div className="margin-t5" />
            </div><div className="margin-l">(vii)   that the Company is under no obligation to release a commercial version of the Beta Product; and the Company has the right unilaterally to abandon development of the Beta Product, at any time and without any obligation or liability to the Beta Participant.
                <div className="margin-t5" />
            </div><div className="margin-l">(viii)  that it should not rely on the Beta Product for any reason.
                <div className="margin-t5" />
            </div><div className="margin-l">(ix)    at the Beta Product has not been released for sale, distribution or usage for the general public.
                <div className="margin-t5" />
            </div><div className="margin-l">(x) that the Company will be, during the Term of this Agreement collecting, using, storing, processing and analyzing diagnostic, technical, and usage logs and information- for improvement of the Beta Product, further enhancing the functionalities, for metrics calculations to track progress of the Beta Participant.
            </div>
            <div className="margin-t" />
            <div className="head-ag">Service Availability</div>
            <div className="margin-l">(i)   The Company shall through the Portal inform and educate you on the user instructions.
            </div><div className="margin-l">(ii)    The Company shall appoint an authorized representative as the technical contact point with you during the Term of this Agreement.
            </div><div className="margin-l">(iii)   The Company has no obligation to develop or provide any updates or revisions to the Beta Test Programme, and reserves the right to alter or adjust performance specifications for the Beta Test Programme as it deems necessary or desirable.
            </div><div className="margin-l">(iv)    The Company shall provide dedicated user support to assist you during any problems you may face when using the Beta Product.
            </div><div className="margin-l">(v) You will have early access to the newly released features of the Beta Product.
            </div><div className="margin-l">(vi)    As Beta Participants you shall have access to our premium features.
            </div>
            <div className="head-ag">Beta Participant Obligations</div>
            <div className="margin-l">(i)   You agree to participate in the Beta Test Programme and evaluate the Beta Product.
                <div className="margin-t5" />
            </div><div className="margin-l">(ii)    You shall provide reasonable feedback to the Company concerning the features and functionality of the Beta Product. Any type of Feedback you provide will be the sole and exclusive property of the Company.
                <div className="margin-t5" />
            </div><div className="margin-l">(iii)   You agree to familiarize yourself with the information provided by us and to only use or test the Beta Product as directed by us.
                <div className="margin-t5" />
            </div><div className="margin-l">(iv)    You shall not, and will not permit your employees to, share Beta Product access rights with any other individual other than the authorized employees.
                <div className="margin-t5" />
            </div><div className="margin-l">(v) You shall ensure that your employees comply with the terms and conditions set out by us on the usage of the Beta Product.
                <div className="margin-t5" />
            </div><div className="margin-l">(vi)    You shall appoint an authorized representative with full authority to provide necessary Feedback and/or maintain any communications on a regular basis as your single technical contact with us during the Term of this Agreement for the performance of this Agreement. In the event such designated representative has been changed you shall inform us of such change and share the details of the newly appointed representative.
                <div className="margin-t5" />
            </div><div className="margin-l">(vii)   You will not use the Beta Product for any purpose other than for the purpose of providing Feedback to us.
                <div className="margin-t5" />
            </div><div className="margin-l">(viii)  You are solely responsible for maintaining and protecting all data and information that is retrieved, extracted, transformed, loaded, stored or otherwise processed by the Beta Product.
                <div className="margin-t5" />
            </div><div className="margin-l">(ix)    You shall be responsible for all costs and expenses required to backup and restore any data and information that is lost or corrupted as a result of you use of the Beta Product.
                <div className="margin-t5" />
            </div><div className="margin-l">(x) You shallbare all incidental costs (such as, costs for Internet and phone services, accessories, cabling, etc.) associated with you testing of the Beta Product.
                <div className="margin-t5" />
            </div><div className="margin-l">(xi)    It is expressly understood, acknowledged and agreed that you shall, regardless of whether or not formally requested to do, provide to usreasonable suggestions, comments and Feedback regarding the Beta Product, including but not limited to all functional flaws, errors, anomalies and problems directly or indirectly associated with the Beta Product,usability, bug reports and test results (collectively, “Feedback”) known to or discovered by you.
                <div className="margin-t5" />
            </div><div className="margin-l">(xii)   In addition, you agree to provide us with the reports required with Feedback if any, and to promptly respond to any and all reasonable inquiries, questionnaires, surveys and other test documents submitted to you by us.
                <div className="margin-t5" />
            </div><div className="margin-l">(xiii)  You agree that your Feedback does not require usto comply with any additional obligations with respect to incorporate such Feedback to the Beta Product.
                <div className="margin-t5" />
            </div><div className="margin-l">(xiv)   In the event you encounter any bugs, glitches, lack of functionality or other problems on the Beta Product, you shall inform us immediately so it can be rectified accordingly.
            </div>
            <div className="margin-t" />
            <div className="head-ag">
                Content and User Conduct</div>
            You acknowledge and agree that all information, data, text, photographs, images, files, software, sounds, recordings, graphics, video, messages, tags and other materials, in whatever form and technical structure, whether publicly posted or privately transmitted or stored when using the Beta Product (“Content”), are the sole responsibility of the person(s) creating such Content and introducing such Content into the Beta Product. We reserve the right to delete or move any Content, without obligation to any person. However, the Company shall have no obligation, to pre-screen, reject, review, quarantine, delete or move any Content .
            <div className="margin-t" />
            You agree that you are responsible for your own conduct and all activities under your account, and for all Content that is created, stored, shared or published by anyone using your account and for any consequences arising as a result thereof. You agree to use the Beta Product only for purposes that are legal, proper and in accordance with Terms contained herein, and in accordance with all applicable laws and regulations in Sri Lanka.
            <div className="margin-t" />
            You further Agree to:
            <div className="margin-l">(a) represent yourself accurately and truthfully at all times;
                <div className="margin-t5" />
            </div><div className="margin-l">(b) grant us the right to verify independently by any lawful means any information that you post on the Portal about yourself;
            </div>
            <div className="margin-t" />
            <div className="margin-l">You further represent, warrant and agree to NOT:
                <div className="margin-t5" />
            </div><div className="margin-l">(a) use the Beta Product for illegal purposes or for promotion of dangerous activities;
                <div className="margin-t5" />
            </div><div className="margin-l">(b) use (or encourage or help others to use) the Beta Product for any purpose or in any manner that is prohibited by these Terms and Conditions of use or by applicable law;
                <div className="margin-t5" />
            </div><div className="margin-l">(c) impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with the Company or any other person or entity;
                <div className="margin-t5" />
            </div><div className="margin-l">(d) upload, post or otherwise transmit to or distribute or otherwise publish through the Beta Product any materials anonymously or under a false name;
                <div className="margin-t5" />
            </div><div className="margin-l">(e) upload, post or otherwise transmit any Content that you do not have a right to transmit under any law or under contractual duties (such as insider trading regulations, internal corporate policies or non-disclosure agreements);
                <div className="margin-t5" />
            </div><div className="margin-l">(f) violate, plagiarize or infringe the rights of third parties, including, without limitationupload, post or otherwise transmit any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;
                <div className="margin-t5" />
            </div><div className="margin-l">(g) upload, post or otherwise transmit any material that contains software viruses, worm, Trojan horse or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of the Beta Product;
                <div className="margin-t5" />
            </div><div className="margin-l">(h) violate through use of the Beta Product any applicable law;
                <div className="margin-t5" />
            </div><div className="margin-l">(i) “stalk” or otherwise harass another person or user of the Beta Product, or take any action invasive to another’s privacy (by posting, for example, another’s photograph, address, email, phone number or any other contact information) without permission from such other person;
                <div className="margin-t5" />
            </div><div className="margin-l">(j) delete or revise any material posted by any other person or entity;
                <div className="margin-t5" />
            </div><div className="margin-l">(k) upload any material that contains advertising of any kind,including unsolicited email advertisements, chain letters, pyramid schemes or like documents,opinions or notices commercial or otherwise or containing any information that is presented in a manner for any other purpose than that which is intended for the Portal and is acceptable by the Company.
                <div className="margin-t5" />
            </div><div className="margin-l">(l) promote or provide instructional information about illegal activities, promote physical harm or injury against any government, legal entity, group or individual;
                <div className="margin-t5" />
            </div><div className="margin-l">(m) solicit passwords or personal identifying information from other users for unintended, commercial or unlawful purposes;
                <div className="margin-t5" />
            </div><div className="margin-l">(n) exceed the scope of the Beta Test Programme you (or your employer or principal) have signed up for, for example, accessing features that you do not have a right to use, or deleting, adding to or otherwise changing another person’s entries or other Content when you have not been granted the right to do so;
                <div className="margin-t5" />
            </div><div className="margin-l">(o) access (or attempt to access) the Beta Test Programme by any means other than through the interface(s) provided by the Company, through any automated means (including use of scripts, web crawlers or the like) or otherwise use the Beta Product in a manner reasonably likely to be harmful to the systems operating the Beta Product or the access or use of the Beta Product by others;
                <div className="margin-t5" />
            </div><div className="margin-l">(p) you further represent and warrant NOT to upload or display publicly Content that:contains nudity, sexually graphic material, obscene, vulgar, profane, indecent or endorse or advocate illegal or immoral activity, or violate the rights of any third party or any material that is otherwise deemed explicit by the Company; orcontains threatening, abusive, harassing, defamatory, libelous, invasive, hateful, or racially, ethnically or otherwise objectionable.
            </div>
            If you encounter any of these prohibited uses, we encourage you to report them to us immediately.

            <div className="margin-t" />
            <div className="head-ag">User Password and Security</div>
            When registering with the Beta Test Programme, a user may be asked to create an account and provide us with specific information relating to user identity. By doing so, each user represents that the user is the individual or the authorized representative who is registering, and that the user has the right to provide information through the Portal for the Beta Test Programme.  You further acknowledge that we have no responsibility for verifying the identity of users and determining whether a particular user is authorized to use the Company Portal.
            <div className="margin-t" />Participating in the Beta Test Programme requires the use of a username and password. Safegurading the confidentiality of the username, password and account itself are the responsibility of the userand the user agrees to take any and all actions necessary to maintain the privacy of such information.Any activities that occur under users' accounts shall be the responsibility of the user.  You agree to notify the Company immediately of any unauthorized use of accounts or any other breach of security or if passwords are lost or stolen.  The use of another person's username and / or password is expressly prohibited.
            <div className="margin-t" />You acknowledge that the use of username and password is an adequate form of security and that any additional security measures such as dual authentication, while enhancing security, are not necessary. You are solely responsible for (1) authorizing, monitoring, controlling access to and maintaining strict confidentiality of your username and password, (2) not allowing another person to use your username or password, (3) any changes or damage that may be incurred as a result of your neglect to maintain the strict confidentiality of your username and password, and (4) promptly informing the Company in writing of any need to deactivate a username due to security concerns or otherwise.  The Company is not liable for any harm related to the misuse or theft of usernames or passwords, disclosure of usernames or passwords, or your authorization to allow another person or entity to access the Beta Product using your username or password.
            <div className="margin-t" />The Company will use its best efforts to make Beta Product secure from unauthorized access.  The third party provider of software and hosting for the Beta Product provides encryption on communication between the Portal hosting the Beta Product and your device and also provides other security measures, some not disclosed to us to keep them more secure.  However, you agree that you recognize that a risk still exists, however small, that a breach of such security may occur. You agree that you shall not attempt to disable or circumvent any security features on the Beta Product or ask any third party to do so on your behalf.
            <div className="margin-t" />THE COMPANY MAKES NO WARRANTY, EXPRESS OR IMPLIED, REGARDING THE EFFICACY OF THE SECURITY OF THE BETA PRODUCT AND SHALL NEVER BE LIABLE FOR ANY ACTUAL OR CONSEQUENTIAL DAMAGES, CLAIMED OR OTHERWISE, ARISING FROM ANY BREACH OR ALLEGED BREACH OF SECURITY OF THE BETA PRODUCT.
            <div className="margin-t" />
            <div className="head-ag">
                Beta ProductDisclaimer
            </div>
            <div className="margin-t" />We do not guarantee that our Beta Product shall be error free or that our servers are free of viruses or other potentially destructive entities.  You understand and agree thatwe cannot and do not guarantee or warrant that information or files downloaded from the Beta Product will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output. We are not liable for any costs associated with the contraction of viruses, damage to equipment (either hardware or software), or loss of data arising from the utilization of the Beta Product.  You use our Beta Product at your own risk and agree to hold us harmless from any and all liability arising from such use.
            <div className="margin-t" />You are aware that this is Beta Test Programme and the Beta Product is in the developing and fine tuning stage.We are not liable for any consequences of the Beta Product not being fully operational or available and shall be held harmless from any claims or disputes arising from such inoperability or unavailability.  You acknowledge that any damages resulting from lack of operability or availability are born solely by you.
            <div className="margin-t" />
            <div className="head-ag">Third Party Services</div>
            If applicable, the Beta Product may integrate with third party services. You hereby consent to the sharing of the information in the Beta Product with these third party services and certifies that it has any and all required consents for doing so. You agree that the Company is limited to the third party services available and used for the function of the Beta Product.
            <div className="margin-t" />
            <div className="head-ag">
                Confidentiality </div>
            You acknowledge and agree that participation in the Beta Test Programme under this Agreement will result in the Company disclosing certain confidential, proprietary and/or trade secret information related to the Beta Product and related information (the “Confidential Information”). Such Confidential Information includes, without limitation, the features, functionality and existence of the Beta Productand any know how, trade secrets, computer programs, source code, flowcharts, diagrams, manuals, schematics, development tools, specifications, design documents, marketing information, financial information, business plans or reports made available to you.
            <div className="margin-t" />You shall not, disclose any Confidential Information or any part thereof to any third party, except to the extent that such Confidential Information (a) is or becomes generally available to the public through any means other than as a result of any act or omission by you; (b) is rightfully received by you from a third party that is not subject to any obligation of confidentiality with respect thereto and without limitation as to its use; or (c) is independently developed by you without any reliance on any Confidential Information.
            <div className="margin-t" />You agree that it will not duplicate, translate, modify, copy, print, disassemble, decompile or otherwise tamper with the Beta Productor any Confidential Information.
            <div className="margin-t" />You shall use no less than all reasonable efforts to protect the Confidential Information from unauthorized use or disclosure.
            <div className="margin-t" />You shall ensure that you shall not share the account ID and password with any third party other than with its authorized employees.
            <div className="margin-t" />You may disclose Confidential Information only to those of its employees who have a bona fide need to know such information for your evaluation of the Beta Product and who have first executed a written agreement that contains use and nondisclosure restrictions at least as protective as those set forth herein.
            <div className="margin-t" />You acknowledge that Company’s purpose in pursuing the development of the said Beta Product is to gain a significant competitive advantage in the market placeand that such advantage will be jeopardized if any competitors of the Company learn of the negotiations, or any information relating to and/or performance of this Agreement. Accordingly, you agree to keep such negotiations and performance of its obligations hereunder strictly confidential and not to disclose any information to any third party or entity. In no event, shall you or your employees use the Company as a reference in marketing your services to any third party.
            <div className="margin-t" />
            <div className="head-ag">
                Intellectual Property</div>

            <div className="margin-t" />You agree that any Feedback, ideas, modifications, suggestions, improvements and the like made by you with respect to the Beta Product (hereinafter referred as “Supportive Information”) will be the sole property of the company. You agree to assign, and hereby assigns, all worldwide right, title and interest in the Supportive Information and the related intellectual property rights therein (collectively, “Intellectual Property Rights”) to the Company and agrees to assist the Company, at the Company’s expense, in perfecting and enforcing such rights.

            <div className="margin-t" />The Company may disclose or use Supp3ortive Information for any purposes whatsoever without any obligation to you and the Company shall have no confidentiality obligations with respect thereto. You agree that the Company may copy, modify, create derivative works, publicly display, disclose, distribute, license and sublicense, incorporate and otherwise use the Supportive Information, for any and all commercial and non-commercial purposes.

            <div className="margin-t" />You will not earn or acquire any rights to the Beta Product or in any of the Company Intellectual Property Rights on account of this Agreement or your performance under this Agreement, even if the Company incorporates any Feedback into the Beta Product.

            <div className="margin-t" />You may not rent, lease, distribute, or resell the Beta Product, or use the Beta Product as the basis for developing a competitive solution (or contract with a third party to do so), or remove or alter any of the logos, trademark, patent or copyright notices, confidentiality or proprietary legends or other notices or markings that are on or in the Beta Product.

            <div className="margin-t" />You undertake that you shall not challenge the title and ownership of the said Beta Product and any other inventions, designs, applications (including but not limited to computer programs, Source Codes, object codes, system software, system specifications, manuals, training material, documentation) developed and maintained by the Company for the Beta Product under any circumstances.

            <div className="margin-t" />
            <div className="head-ag">Limited Use Rights </div>

            During the Term of the Agreement, the Company grants to you a limited, non-transferable, non-exclusive, revocable right to access and use the Beta Product under the Beta Test Programme to test its functionality and provide Feedback to the Company. The Company hosts and retains physical control over the Beta Product and only makes the Beta Product available for access and use by you over the Internet through the Portal. Nothing in this Agreement obligates the Company deliver or make available any copies of or code from the Beta Product to you, whether in object code or source code form. The Company reserves the right, in its sole discretion, to revoke access and use of the Beta Product at any time. Further, the Company reserves the right to (i) monitor or measure use of the Beta Product; (ii) validate certain features or functionality of the Beta Product; and (iii) to provide services or support necessary to maintain the Beta Product.

            <div className="margin-t" />
            <div className="head-ag">Disclaimer of Warranties</div>
            THE BETA PRODUCT IS PROVIDED “AS IS”. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE BETA PRODUCT, INCLUDING ANY REPRESENTATION THAT THE SERVICES THEREUNDER WILL BE UNINTERRUPTED OR ERROR-FREE. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, THE COMPANY DISCLAIMS ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE IN RESPECT OF THE BETA PRODUCT. FOR THE AVOIDANCE OF DOUBT, THE BETA PRODUCT IS PRELEASE, ARE EXPECTED TO CONTAIN DEFECTS WHICH MAY BE MATERIAL, AND ARE NOT EXPECTED TO OPERATE AT THE LEVEL OF PERFORMANCE OR COMPATIBILITY OF A FINAL, GENERALLY AVAILABLE PRODUCT OFFERING. THE BETA PRODUCTMAY NOT OPERATE ACCURATELY AND MAY BE SUBSTANTIALLY MODIFIED PRIOR TO PUBLIC AVAILABILITY OR WITHDRAWN AT ANY TIME. ACCORDINGLY, ACCESS TO AND USE OF THE BETA PRODUCTIS ENTIRELY AT YOUR OWN RISK. IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DAMAGE WHATSOEVER ARISING OUT OF THE USE OF OR INABILITY TO USE THE BETA PRODUCT, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ARE ADVISED TO SAFEGUARD IMPORTANT DATA, TO USE CAUTION AND NOT TO RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR PERFORMANCE OF ANY BETA PRODUCT.
            <div className="margin-t" />
            <div className="head-ag">
                Indemnity </div>
            You agree to indemnify and hold us, our officers, directors and employees harmless from any losses (including attorneys’ fees) that result from any third party claims related to your (or its employees) access, use or misuse of the Beta Productor any act or omission by you or your  employees in violation of this Agreement.
            <div className="margin-t" />
            <div className="head-ag">
                Limitation of Liability</div>
            TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL WE LIABLE FOR ANY DAMAGES, INCLUDING PERSONAL INJURY, OR ANYINCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS OR LOST SAVINGS, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR PURCHASE OF THE BETA PRODUCT, OR THE INSTALLATION, USE OR INABILITY TO USE THE BETA PRODUCT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <div className="margin-t" />The limitations of liability set forth in this Agreement reflect the allocation of risk that you have agreed to. These limitations will apply notwithstanding any failure of essential purpose of any limited remedy.
            <div className="margin-t" />
            <div className="head-ag">Links to Third Party Websites</div>
            The Beta Product may contain links and pointers to websites operated by third parties.  These links are provided solely as a convenience to you and not as an endorsement by us of the contents of such third-party websites.  We are not responsible for the content of linked third-party websites and do not make any representations regarding the content or accuracy of materials on such third-party websites.  If you decide to access linked third-party websites, you do so at your own risk.
            <div className="margin-t" />
            <div className="head-ag">Privacy Policy</div>
            Our Privacy Policy, as it may change from time to time, is a part of this Agreement.  See <a href="https://bixmessage.com/bixchat-application-policies/" target="_blank" rel="noopener noreferrer">https://bixmessage.com/bixchat-application-policies/</a>
            <div className="margin-t" />
            <div className="head-ag">General</div>
            <div className="margin-l">(i)   <b>Entire Agreement</b>: This Agreement is the entire Agreement between the Company and you regarding the use of Beta Test Programme. The Company reserves the right to modify the terms, conditions, and notices under which the Beta Test Programme is offered.  Your continued use of the Beta Product after the postings of any amended terms and conditions shall constitute your agreement to be bound by any such changes.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(ii)  <b>Governing Law</b>: This Agreement will in all respects be governed exclusively by and construed in accordance with the laws of the Democratic Socialist Republic of Sri Lanka.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(iii) <b>Dispute Resolution:</b> All disputes, differences or questions between the Parties with respect to any matter arising out of or relating to this Agreement shall be finally resolved by a court of law according to the laws applicable for the time being in Sri Lanka.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(iv)  <b>Data Protection</b>: The Company will collect, process and use personal data of the Beta Participant and its employeesonly in accordance with applicable Data Protection Laws and Regulations, in accordance with Beta Participant’s instructions. Personal Data that the Company has received from the Beta Participant shall not be exported by the Company to, or accessed from, a country or territory outside the jurisdiction of the Beta Participant without prior written approval of the Beta Participant.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(v)   <b>Compliance with Applicable Laws</b>:The Company and the Beta Participant agree to comply with all relevant applicable laws and regulations during the performance of this Agreement.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(vi)  <b>Relationship of the Parties</b>: The Companyis an independent Contractor,neither party to this Agreement will be considered employees or agents of either party. Nothing in this Agreement shall create, or be deemed to create a partnership, joint venture, principal and agent or the relationship of employer and employee between the Parties.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(vii) <b>Severability</b>: If any portion of this Agreement is declared invalid or unenforceable, in whole or in part, it shall not be deemed to affect or impair the validity or enforceability of any other covenant or provisions herein, and such unenforceable portion shall be severed from the remainder of the Agreement.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(viii)    <b>Waivers</b>: A waiver of any default, breach, or non-compliance under this Agreement is not effective unless in writing and signed by the party to be bound by the waiver. No waiver will be inferred from or implied by any failure to act or delay in acting by a party in respect of any default, breach or non-observance or by anything done or omitted to be done by the other party. Any waiver by a party of any default, breach or non-compliance under this Agreement will not operate as a waiver of that party’s right under this Agreement in respect of any continuing or subsequent default, breach or non-observance.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(ix)  <b>Assignment</b>: We may transfer, assign, sub-contract or otherwise deal with our rights and/or obligations under this Agreement.  You may not transfer or assign this Agreement or your obligations thereunder.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(x)   <b>Notices</b>: Any notice, demand, request or report to be given or made under the Agreement shall be given or made in English language communicated via the Company Portal.
            </div>
            <div className="margin-t" />
            <div className="margin-l">(xi)  <b>Force Majeure</b>:The Company is not liable under this Agreement for the non-performance caused by acts of war, terrorism, natural disasters (e.g., hurricanes, earthquakes), human-made disasters, other acts of God or nature, public health emergencies (including pandemics), public safety incidents, telecommunication or internet service interruption, strikes or other labor disputes or unrest, civil insurrections, riots, or other acts of civil disorder, acts of military, civil, or regulatory authority or any other events, conditions or causes beyond the Company’s reasonable control.
            </div>
        </div>
    )
}
export default AgreementContent