import React from "react"
import AppContext from "../../../ContextAPI/AppContext";
import tickSquare from "../../../Assets/Icons/tick-square.svg";
import close from "../../../Assets/Icons/cross-square-button.svg";

import "./ReportSent.scss"


const ReportSentModal = (props) => {

    // const [reportDescription, setReportDescription] = useState("");


    // const handleChange = event => {
    //     setReportDescription(event.target.value);
    // };



    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_reportSentModal" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
                    <div id="myModal" className="modal-2">
                        <div className="modal-content-top-2" >

                            <div className="inner-container">

                                <div className="report-sent-close-div">
                                    <img className="close" src={close} onClick={() => {
                                        context?.setReportSent(false)

                                    }}
                                        alt="" />

                                </div>

                                <div className="report-sent-main">


                                    <div className="tick-square-div">
                                        <img className="tick-square" src={tickSquare} alt="" />

                                    </div>
                                    <div className="report-sent-name">
                                        Report Sent
                                    </div>
                                    <div className="report-sent-user-random-des">
                                        You will be contacted by bixSupport within 24 hours
                                    </div>




                                </div>




                            </div>



                        </div>
                    </div>
                </div>
            )}</AppContext.Consumer>
    )
}
export default ReportSentModal