import React from "react";
import AppContext from "../../../ContextAPI/AppContext";
import { getDPService } from "../../../services/DPService";
import "../RightSidebar/RightSideBarStyle.scss";

const UserDetailsBar = (props) => {

    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="profile-wrapper">
                    <div>
                        {/* {props?.member?.public_id !==
                            localStorage.getItem(
                                "bixChat_loggedUserPublicId"
                            ) ? props?.onlineList?.length > 0 ? props?.onlineList?.filter((item) => (item.publicID === props.member.public_id)
                            )[0].state === "online" ? <div className="online-status" /> : "" : "" : ""} */}
                        {console.log(props.member, "member")}

                        {getDPService().getDP("ChatRoom.PrivateChatRoom", props.member.public_id) ? (
                            <img
                                className="profile-picture"
                                src={
                                    getDPService().getDP("ChatRoom.PrivateChatRoom", props.member.public_id)
                                }
                                alt=""
                                onClick={() => {
                                    props.setImagePreview(true);
                                    props.setCurrentImage(1);
                                    let image = {
                                        attachment_name: props.member.full_name,
                                        attachment_link:
                                            getDPService().getDP("ChatRoom.PrivateChatRoom", props.member.public_id),
                                    };
                                    let array = [];
                                    array.push(image);
                                    props.setImage(array);
                                }}
                            />
                        ) : (
                            <img
                                className="profile-picture"
                                src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                alt=""
                            />
                        )}

                    </div>

                    <div className="name">
                        {props.member.full_name}
                    </div>
                    <div className="designation">
                        <div className="org">
                            {props.member.designation !== ""
                                ? props.member.designation
                                : "ZapMail User"}
                        </div>

                    </div>
                    <div className="organization"> {props.member.headline}

                    </div>
                    {!props.member.is_admin ? <div className="added">Added by {props.member.added_by.full_name} on {props.member.added_time.split(" ")[0]}

                    </div> : ""}
                    <div className="button-container"><div className="message-button" onClick={() => {
                        context?.findChatroom(props.member)
                        props.setRightSideBarStatus(false)
                    }}>Message</div><div className="call-button">Call</div></div>
                    <div className="details-container">
                        <div className="deatils-header">Email -</div>
                        <div className="details-content">{props.member.email}</div>
                        <div className="deatils-header">Address -</div>
                        <div className="details-content">{props.member.address}</div>
                        {props.member.about !== "" ? (<div> <div className="deatils-header">Bio -</div>
                            <div className="details-content">{props.member.about}</div></div>) : ""}

                    </div>
                </div>
            )}
        </AppContext.Consumer>

    )




}
export default UserDetailsBar