import React from "react"
import ErrorPage from "./ErrorPages/ErrorPage/ErrorPage";

class ErrorBoundry extends React.Component {

    state = {
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    };
    componentDidCatch(error, errorInfo) {
        // A custom error logging function
        console.log("error", error, errorInfo);
    };

    render() {
        return this.state.hasError ? <ErrorPage/> : this.props.children;
    }

}
export default ErrorBoundry