import React, { useContext, useEffect } from "react";
import { useState } from "react";
import AppContext from "../../../ContextAPI/AppContext";
import SingleContact from "../LeftSidebar/SingleContact/SingleContact";
import "./BlockList.scss"

const BlockList = () => {

  const [selected, setSelected] = useState('all')
  const [showArr, setShowArr] = useState(false)

  const conText = useContext(AppContext)

  useEffect(() => {
    conText.getBlockList(conText.allChatrooms)
  }, []);



  return (
    <AppContext.Consumer>
      {context => (
        <div style={{ height: "65vh", overflowY: 'auto' }}>
          <div className="block-nav">
            <div className={selected === 'all' ? "block-option selected-option" : "block-option"}
              onClick={() => {
                setSelected('all')
              }}

            >All</div>
            <div className={selected === 'contacts' ? "block-option selected-option" : "block-option"}
              onClick={() => {
                setSelected('contacts')
              }}
            >Contacts</div>
            <div className={selected === 'emails' ? "block-option selected-option" : "block-option"}
              onClick={() => {
                setSelected('emails')
              }}
            >Emails</div>


          </div>

          {selected === 'all' ?

            <div className="list-wrapper">
              {context?.blockList?.length > 0 ?
                <div>
                  {context?.blockList?.map((value, i) => {
                    return (

                      <div>
                        <SingleContact
                          value={value}
                          blocklist={true}
                          showArr={showArr}
                          isBlocked={true}
                        />


                      </div>
                    )
                  })}
                </div>
                :
                <div className="empty-blocklist">You have no blocked chats</div>
              }
            </div>
            : ""}

          {selected === 'contacts' ?

            <div className="list-wrapper">
              {context?.blockedContacts?.length > 0 ?
                <div>
                  {context?.blockedContacts?.map((value, i) => {
                    return (

                      <div>
                        <SingleContact
                          value={value}
                          blocklist={true}
                          showArr={showArr}
                          isBlocked={true}
                        />


                      </div>
                    )
                  })}
                </div>
                :
                <div className="empty-blocklist">You have no blocked contacts</div>
              }
            </div>


            : ""}

          {selected === 'emails' ?

            <div className="list-wrapper">
              {context?.blockedNonContacts?.length > 0 ?
                <div>
                  {context?.blockedNonContacts?.map((value, i) => {
                    return (

                      <div>
                        <SingleContact
                          value={value}
                          blocklist={true}
                          showArr={showArr}
                          isBlocked={true}
                        />


                      </div>
                    )
                  })}
                </div>
                :
                <div className="empty-blocklist">You have no blocked emails</div>
              }
            </div>

            
            : ""}

        </div>
      )}
    </AppContext.Consumer>

  )

}

export default BlockList