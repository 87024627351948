import React from "react"
import { useHistory } from "react-router-dom";
import home from "../../../../Assets/Icons/home-link.svg"
import error from "../../../../Assets/Images/404.png"

import "./PageNotFoundStyles.scss"

const PageNotFound = () => {
    const history = useHistory()
    return (
        <div className="PageNotFoundStyles">
            <div className="img-cont"><img alt="" src={error} /></div>
            <div className="bix-error">Oops!</div>
            <div className="sub-error-text">We can't seem to find page you are looking for </div>
            <div className="home-link" onClick={() => {
                history.push({
                    pathname: "/",
                });
            }}>
                <img alt="" src={home} />
            </div>
        </div>
    )
}
export default PageNotFound