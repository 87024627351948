import React, { useEffect, useContext } from 'react'
import "../ToolTips/ToolTip.scss"
import rightArrow from "../../../Assets/Icons/arrow-right-new.svg"
import leftArrow from "../../../Assets/Icons/arrow-right-2.svg"
import AppContext from '../../../ContextAPI/AppContext'
import CookieService from '../../../services/CookieService'
import config from "../../../config"


const Tooltip = (props) => {
    const context = useContext(AppContext)

    let steps;

    steps = [
        {
            element: 'profile-img',
            title: 'Customize your space !',
            intro: 'Customize your profile, including picture, signature, font sizes and more.',
            position: 'top2-tooltip',
        },
        {
            element: 'new-message',
            title: 'Email anyone',
            intro: 'Send emails from your custom domain.',
            position: window.screen.width > 600 ? "top-tooltip" : "bottom-tooltip ",
        },
        {
            element: 'add-account',
            title: 'Manage multiple accounts',
            intro: 'Add and switch between Zapmail accounts.',
            position: 'top3-tooltip',
        },
        {
            element: 'more',
            title: 'Create Channels',
            intro: 'Have channels for projects, departments, or anything you name.',
            position: props.mobile ? 'top2-tooltip' : 'right-tooltip',

        },
        {
            element: 'primary',
            title: 'Focused inbox',
            intro: 'View important emails from your colleagues and contacts.',
            position: props.mobile ? 'top4-tooltip' : 'top-tooltip'

        },
        {
            element: 'other',
            title: 'Other Inbox',
            intro: 'View all other incoming emails.',
            position: 'top-tooltip'

        }
    ];
    const expiresAt = 60 * 24

    useEffect(() => {

        if (props.step === 0) {
            if (props.mobile) {
                document.getElementById("demo").style.right = "2%"
            }
            else {
                document.getElementById("demo").style.right = "25px"
            }
            document.getElementById("demo").style.top = "65px"
            document.getElementById("demo").style.left = ""



        }

        else if (props.step === 1) {
            if (window.screen.width < 600) {
                document.getElementById("demo").style.bottom = "90px"
                document.getElementById("demo").style.top = ''
                document.getElementById("demo").style.left = ''
                document.getElementById("demo").style.right = "5%"
            } else {
                document.getElementById('new-message').style.zIndex = 3
                document.getElementById("demo").style.top = '110px'
                document.getElementById("demo").style.left = '3%'
                document.getElementById("demo").style.right = ""
                document.getElementById("demo").style.bottom = ""
            }
        }
        else if (props.step === 2) {
            props.setPopup(false)
            if (props.mobile) {
                document.getElementById("demo").style.right = "2%"
                document.getElementById("demo").style.left = ""

            }
            else {
                document.getElementById("demo").style.left = '0.5%'
                document.getElementById("demo").style.right = ""

            }
            document.getElementById("demo").style.top = "115px"
        }
        else if (props.step === 3) {
            props.setPopup(true)
            document.getElementById("demo").style.bottom = ""

            if (props.mobile === true) {
                document.getElementById("demo").style.left = ""
                document.getElementById("demo").style.right = "20%"
                document.getElementById("demo").style.top = "80px"


            }
            else {
                document.getElementById("demo").style.left = "36%"
                document.getElementById("demo").style.right = ""
                document.getElementById("demo").style.top = '90px'
            }
        } else if (props.step === 4) {
            props.setPopup(false)
            let someDiv = document.getElementById('primary');
            let distanceToTop = someDiv.getBoundingClientRect().top;
            if (props.mobile) {
                document.getElementById("demo").style.left = '12%'
                document.getElementById("demo").style.top = distanceToTop + 45 + "px"

            }
            else {
                document.getElementById("demo").style.left = '6%'
                document.getElementById("demo").style.top = distanceToTop + 40 + "px"
            }
            document.getElementById("demo").style.right = ""

        }
        else if (props.step === 5) {
            props.setPopup(false)
            let someDiv = document.getElementById('other');
            let distanceToTop = someDiv.getBoundingClientRect().top;
            if (props.mobile) {
                document.getElementById("demo").style.right = "2%"
                document.getElementById("demo").style.top = distanceToTop + 45 + "px"
            }
            else {
                document.getElementById("demo").style.left = '14%'
                document.getElementById("demo").style.top = distanceToTop + 40 + "px"
            }
            document.getElementById("demo").style.right = ""

        }

    }
        // }
        , [props.step])
    const setIsLogin = () => {
        let users = CookieService.get("users")
        let date = new Date()
        date.setTime(date.getTime() + (expiresAt * 60 * 1000))
        let user = users.filter((user) => {
            if (user.isLogged) {
                return user
            }
            return null
        })[0]
        user.is_first_login = false
        const options = { path: "/", domain: config.cookieDomain, expires: date };
        CookieService.set("users", users, options);
    }
    const nextClick = () => {
        if (props.step !== 5) {
            props.setStep(props.step + 1)
        } else if (props.step === 5) {
            props.setPopup(false)
            props.setSignupStatus("false")
            localStorage.setItem("firstLogin", false)
            setIsLogin()
            if (localStorage.getItem("tooltipFun") !== "true") {
                props.setNewUiModalVisibility(true)
            } else {
                localStorage.setItem("tooltipFun", false)
                context.getAllChatroom()
            }
            props.setStep(0)
        }
    }
    const backClick = () => {
        if (props.step === 0) {
            setIsLogin()
            localStorage.setItem("firstLogin", false)
        } else {
            props.setStep(props.step - 1)

        }
    }
    const skipClick = () => {
        props.setPopup(false)
        props.setSignupStatus("false")
        setIsLogin()
        localStorage.setItem("firstLogin", false)
        if (localStorage.getItem("tooltipFun") !== "true") {
            props.setNewUiModalVisibility(true)
        } else {
            localStorage.setItem("tooltipFun", false)
            context.getAllChatroom()
        }
        props.setStep(0)

    }
    return (
        <div className="tooltip-background">
            <div className="tooltip" id="demo">
                <div className="header-container">
                    <div className="steps">{steps[props.step].title}</div>
                    <div className="arrow-container">
                        <img alt="" src={props.step === 0 ? leftArrow : rightArrow} className="left-arrow" onClick={backClick} />
                        <img alt="" src={props.step === 5 ? leftArrow : rightArrow} onClick={nextClick} />

                    </div>
                </div>

                {steps[props.step].intro}
                <div className="btn-container">
                    <div className="backbutton" onClick={skipClick}>Skip</div>
                    <div className="nextbutton" onClick={nextClick}>{props.step !== 5 ? "Next" : "Got it"}</div>
                </div>
                <span className={steps[props.step].position} />
            </div>
        </div>
    )
}
export default Tooltip
