import React, { useState } from "react";
import "./NewGroupModalStyle.scss";
import arrow from "../../../Assets/Icons/right-arrow.svg";
import image from "../../../Assets/Icons/plus.png";
import close from "../../../Assets/Icons/close-black.svg";
import AppContext from "../../../ContextAPI/AppContext";
import tick from "../../../Assets/Icons/tick.svg";
import Avatar from '../../../Components/Avatar/avatar'
import checkMark from "../../../Assets/Icons/checkmark.svg";
import { getDPService } from "../../../services/DPService";


const NewGroupModal1 = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchVal, setSearchVal] = useState("")


  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="_NewGroupMessage">
          <div id="myModal" className="modal-1">
            <div
              style={{ height: "100%", backgroundColor: "transparent" }}
              onClick={() => {
                props.setNewGroupModal1(false);
                context?.setNewGroupMembers([]);
                context?.setAllFilteredUsers([]);
              }}
            />
            <div className="modal-content-top" style={{ marginTop: "-75vh" }}>
              <div className="modal-content-add-members">
                <div className="plus-container-left">
                  <img alt="" src={image} className="plus" />
                </div>

                <div className="add-header">Add Members</div>
              </div>

              <div className="inner-container">
                <div
                  style={{
                    padding: "10px",
                    justifyContent: "center",
                  }}
                >
                  <div className="search-bar-wrapper-1">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {context?.newGroupMembers.map((value) => {

                        return (
                          <div className="selected">
                            {value.name}
                            <img
                              alt=""
                              src={close}
                              className="close-ic"
                              onClick={() => {
                                let arr = context?.newGroupMembers.filter
                                  (
                                    (item) => item !== value
                                  );


                                context?.setNewGroupMembers(arr);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div></div>
                    <input
                      type="text"
                      placeholder="Search group members"
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                        context.searchAllUsers(e);
                        setSearchVal(e.target.value)
                        context.filterContacts(e.target.value.toLowerCase())
                      }}
                      style={{
                        marginTop:
                          context?.newGroupMembers.length > 0 ? "5px" : "",
                      }}
                    />
                  </div>

                  {/* {context?.allFilteredUsers.length > 0
                    ? context?.allFilteredUsers.map((value, i) => {
                      return (
                        <div
                          className="sideBarSingleMessageLeft "
                          onClick={() => {
                            if (
                              !context?.newGroupMembers?.filter(
                                (e) => e.public_id === value.public_id
                              ).length > 0
                            ) {
                              const obj = {
                                name: value?.full_name
                                  ? value?.full_name
                                  : value?.email?.split("@")[0],
                                public_id: value?.public_id,
                              };
                              context?.setNewGroupMembers([
                                ...context?.newGroupMembers,
                                obj,
                              ]);
                              setSearchInput("");
                              context?.setAllFilteredUsers([]);
                            } else {
                              setSearchInput("");
                              context?.setAllFilteredUsers([]);
                            }
                          }}
                        >
                          <div className="sideBarSingleMessageProPicWrapper">
                            {value?.profile_image ? (
                              <img
                                className="sideBarSingleMessageProPic"
                                src={value?.profile_image}
                              />
                            ) : (
                              <img
                                className="sideBarSingleMessageProPic"
                                src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                              />
                            )}
                          </div>

                          <div className="sideBarSingleMessageAndSender ">
                            <div className="sideBarSingleMessageSender">
                              {value?.full_name
                                ? value?.full_name
                                : value?.email?.split("@")[0]}
                            </div>
                            <div className="sideBarSingleMessage">
                              {value.headline !== "" ? value.headline : "bixUser"}
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : ""} */}

                  <div className="list-container">
                    {context.filterContacts(searchVal.toLowerCase()).map((contact) => {
                      return (
                        <div className="list-itm"
                          onClick={() => {
                            // if (context?.newGroupMembers?.filter(
                            //   (e) => e.public_id === contact.public_id
                            // ).length > 0) {
                            //   setSelected(true)

                            // }
                            if (
                              !context?.newGroupMembers?.filter(
                                (e) => e.public_id === contact.public_id
                              ).length > 0
                            ) {
                              const obj = {
                                name: contact?.full_name
                                  ? contact?.full_name
                                  : contact?.email?.split("@")[0],
                                public_id: contact?.public_id,
                              };
                              context?.setNewGroupMembers([
                                ...context?.newGroupMembers,
                                obj,
                              ]);

                              console.log(context?.newGroupMembers[0]?.public_id)

                              setSearchInput("");
                              context?.setAllFilteredUsers([]);
                            }

                            // else if(context?.newGroupMembers[0].public_id){



                            // }
                            else {
                              setSearchInput("");
                              context?.setAllFilteredUsers([]);
                            }

                          }}>
                          <Avatar
                            size='m'
                            src={contact.avatar === "null" || contact.avatar === null ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : getDPService().getDP("ChatRoom.PrivateChatRoom", contact.public_id)}
                          />
                          <div className="list-details">
                            <div className="name">{contact.name}</div>
                            <div className="designation">{contact.headline !== "" ? contact.headline : "bixUser"}</div>

                          </div>

                          {context?.newGroupMembers?.filter(
                            (e) => e.public_id === contact.public_id
                          ).length > 0 ?

                            <div>
                              <img className="chack-mark" src={checkMark} alt="" />
                            </div>

                            :
                            ""
                          }



                        </div>
                      )
                    }
                    )}
                  </div>

                </div>
              </div>
              <div
                className="circle"
                onClick={() => {
                  if (
                    context?.newGroupMembers.length > 0 &&
                    props.addMembersFrom === "Left"
                  ) {
                    props.setNewGroupModal1(false);
                    props.setNewGroupFrom("newGroup");
                    props.setNewGroupModal2(true);
                  } else if (
                    context?.newGroupMembers.length > 0 &&
                    props.addMembersFrom === "Right"
                  ) {
                    let chatroom_id = localStorage.getItem(
                      "bixChat_selectedChatId"
                    );
                    context?.addMembersToTheGroup(chatroom_id);
                    props.setNewGroupModal1(false);
                  }
                }}
              >
                {props.addMembersFrom === "Left" ? (
                  <img alt="" src={arrow} style={{ height: 30, width: 30 }} />
                ) : (
                  <img alt="" src={tick} style={{ height: 30, width: 30 }} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default NewGroupModal1;
