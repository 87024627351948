import React, { useState, useEffect, useContext, useRef } from "react";
import "./LeftSidebarStyle.scss";
import moment from "moment";
import AppContext from "../../../ContextAPI/AppContext";
import pen from "../../../Assets/Icons/pen.png";
import search from "../../../Assets/Icons/search.svg";
import search2 from "../../../Assets/Icons/search.png";

import arrow from "../../../Assets/Icons/leftArrow.svg";
import filter from "../../../Assets/Icons/settings-4.png";
import filter2 from "../../../Assets/Icons/settings-3.png";
import Arrow from "../../../Assets/Icons/next.png";
import logout from "../../../Assets/Icons/vuesax-linear-logout.svg";
import settings from "../../../Assets/Icons/vuesax-linear-setting.svg";
import moreIcon from "../../../Assets/Icons/more1.svg";
import newGrpIcon from "../../../Assets/Icons/newGrp.svg";
import drawer from "../../../Assets/Icons/apps.png";
import photos from "../../../Assets/Icons/b2.png";
import close from "../../../Assets/Icons/close-2.png";
import dots from "../../../Assets/Icons/dots-icon.svg";
import add from "../../../Assets/Icons/vuesax-linear-user-cirlce-add.svg"
import signal from "../../../Assets/Icons/no-wifi.png"
import contactsIcon from "../../../Assets/Icons/contact-book.png"



import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import mixpanel from 'mixpanel-browser';
import SingleChat from "./SingleChat/SingleChat";
import Avatar from '../../../Components/Avatar/avatar'
import Feature from "../../../Feature/Feature";
import { useHistory } from "react-router-dom";
import { useSwipeable } from 'react-swipeable';
import { ws } from "../../../App"
import config from "../../../config"
import CookieService from "../../../services/CookieService";
import MatesComponents from "../../../Components/MatesComponent/MatesComponent";
import { getDPService } from "../../../services/DPService";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LeftSidebar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChat, setSelectedChat] = useState("");
  const [isSearchCollapsed, setIsSearchCollapsed] = useState(false);
  const [showUnread, setShowUnread] = useState(false)
  const [showLabelChats, setShowLabelChats] = useState(false)
  const [labelSelected, setLabelSelected] = useState('')
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(true)
  const [showLeftArrow, setShowLeftArrow] = useState(false)




  const history = useHistory()

  const [contactsSorted, setContactsSorted] = useState(false)

  const conText = useContext(AppContext)

  let contacts = []

  let savedChats = []
  let unSavedChats = []
  let unReadList = []
  let pinnedChats = []
  let labelChats = []
  let scrollWidth
  let outerWidth

  // const nonContacts = conText.getNonContacts()

  useEffect(() => {

    setTimeout(() => {
      mixpanel.time_event('In primary')
    }, 1000)

  }, [])


  const handlers = useSwipeable({
    onSwipedRight: () => {
      conText.setSavedChatsSelected(true)
      if (conText.clickOther === false) {
        conText.setClickOther(true)
      }
    },
    onSwipedLeft: () => {
      conText.setSavedChatsSelected(false)
      if (conText.clickOther === false) {
        conText.setClickOther(true)
      }
    },
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleDate = (date) => {
  //   if (
  //     moment().subtract(1, "day").format("L") ===
  //     moment.utc(date).local().format("L")
  //   ) {
  //     return "Yesterday";
  //   }
  //   if (
  //     moment().subtract(2, "day").format("L") ===
  //     moment.utc(date).local().format("L")
  //   ) {
  //     return moment.utc(date).local().format("dddd");
  //   }
  //   if (
  //     moment().subtract(3, "day").format("L") ===
  //     moment.utc(date).local().format("L")
  //   ) {
  //     return moment.utc(date).local().format("dddd");
  //   }
  //   if (
  //     moment().subtract(4, "day").format("L") ===
  //     moment.utc(date).local().format("L")
  //   ) {
  //     return moment.utc(date).local().format("dddd");
  //   }
  //   if (
  //     moment().subtract(5, "day").format("L") ===
  //     moment.utc(date).local().format("L")
  //   ) {
  //     return moment.utc(date).local().format("dddd");
  //   } else {
  //     return moment.utc(date).local().format("L");
  //   }
  // };

  const archiveChat = () => {
    return (
      <div>
        <MenuItem>

          <p style={{ marginLeft: "25px" }}>Archived chats</p>
        </MenuItem>

      </div>
    )
  }

  const newLabel = () => {
    return (
      <div>
        <MenuItem>
          <p style={{ marginLeft: "25px" }}>New label</p>
        </MenuItem>
      </div>
    )
  }

  const compare = (a, b) => {

    if (a.contact.name < b.contact.name) {
      return -1;
    }
    if (a.last_nom > b.last_nom) {
      return 1;
    }
    return 0;
  }
  let count = 0

  const handleScrollPrimary = (e) => {

    const currentScrollY = e.target.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;
    count = count + 1
    if (count === 10) {

      if (conText.savedChatsSelected === true) {
        // mixpanel.track('Scrolling in Primary')
      }
      else {
        // mixpanel.track('Scrolling in Other')
      }

    }


  }



  const getChats = (type) => {

    if (type === 'saved') {
      savedChats = []
      savedChats = conText.getPrimaryUnpinned()
      unReadList = []
      unReadList = conText.unreadChats(savedChats)




    }
    else if (type === 'other') {
      unSavedChats = []
      unSavedChats = conText.getNonContacts(conText.allChatrooms)
    }
    else if (type === 'pinned') {
      pinnedChats = []
      if (conText.getPinnedChats()) {
        pinnedChats = conText.getPinnedChats()
      }
    }

  }



  const getLabeledChats = (label) => {
    labelChats = conText.filterLabel(label)
  }


  const savedUnreadCount = () => {
    let count = 0;


    getChats('saved')

    for (let i = 0; i <= savedChats.length; i++) {
      if (savedChats[i]?.unread_message_count !== 0) {

        if (savedChats[i]?.unread_message_count === undefined) {
          continue
        }
        else if (savedChats[i]?.unread_message_count !== 0) {
          count = count + 1

        }

      }

    }
    conText.setSavedUnreadMsgs(count)

  }

  const unsavedUnreadCount = () => {
    conText.setUnsavedUnreadMsgs(0)
    let count = 0;


    for (let i = 0; i <= unSavedChats.length; i++) {
      if (unSavedChats[i]?.unread_message_count !== 0) {

        if (unSavedChats[i]?.unread_message_count === undefined) {
          continue
        }
        else if (unSavedChats[i]?.unread_message_count !== 0) {
          // conText.setSavedUnreadMsgs(conText.savedUnreadMsgs + 1)
          count = count + 1
        }


      }

    }


    conText.setUnsavedUnreadMsgs(count)

  }

  const label_scroll_right = () => {
    const left = document.getElementById("labeldiv");
    // left?.animate({

    // })
    let divPosition = left.scrollLeft

    left.scrollLeft = divPosition + 80


    scrollWidth = left.scrollWidth
    outerWidth = left.offsetWidth

    if ((scrollWidth - outerWidth < Math.round(left.scrollLeft) + 5) && (scrollWidth - outerWidth > Math.round(left.scrollLeft) - 5)) {
      setShowRightArrow(false)
    }
    else if (scrollWidth - outerWidth === Math.round(left.scrollLeft)) {
      setShowRightArrow(false)
    }
    else {
      setShowRightArrow(true)

    }

    if (left.scrollLeft === 0) {
      setShowLeftArrow(false)
    }
    else {
      setShowLeftArrow(true)

    }




  }

  const label_scroll_left = () => {
    const left = document.getElementById("labeldiv");
    // left?.animate({

    // })
    let divPosition = left.scrollLeft

    if (scrollWidth - outerWidth === Math.round(left.scrollLeft)) {
      setShowRightArrow(true)
    }

    left.scrollLeft = divPosition - 80

    if (left.scrollLeft === 0) {
      setShowLeftArrow(false)
    }
    else {
      setShowLeftArrow(true)

    }

    if (scrollWidth - outerWidth === Math.round(left.scrollLeft)) {
      setShowRightArrow(false)
    }
    else {
      setShowRightArrow(true)

    }


  }

  const handlePrimaryClick = (e) => {

    if (conText.primaryCount === 0) {
      conText.setPrimaryCount(1)
    }

    else if (conText.primaryCount === 1) {
      conText.setPrimaryCount(0)
    }

  }

  const getHeight = () => {
    let height = window.screen.width < 600 ? 18 : 50
    let h = document?.getElementById('left-bar')?.clientHeight - height - document?.getElementById('top-navbar')?.clientHeight
    return h
  }

  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="__SidebarWrapper" id="left-bar" onClick={() => {
          context.leftNavPopupHandler("closeOnly")
          context.UploadModalHandler("closeOnly")
        }}
          style={{ display: window.screen.width > 600 && window.screen.width < 1199 && props.rightSideBarStatus ? "none" : "flex" }}
        >
          <div className="left-option-bar"
            onClick={() => {
              context.setShowReactionTab(false)

            }}
          >
            <div className="header-container"
              onClick={() => {
                context.RightNavPopupHandler()
                // mixpanel.track("account list dropdown")
                // mixpanel.track("switch the account")
              }}
            >
              <img className="profile-img2" src={localStorage.getItem("bixChat_isPrivate") === "true" ? photos
                : localStorage?.getItem("bixChat_domainImage")} alt="" />


              {/* <div className="header">{localStorage.getItem("bixChat_isPrivate") === "true" ? localStorage.getItem("bixChat_fullname").split(" ")[0] : context.domainName}</div>
                <img src={next} className="arrow-left" alt="" /> */}
            </div>
            <div className="popOver" style={{ display: context.rightNavPopup }}>
              {JSON.parse(localStorage.getItem("bixChat_users"))?.map(user => {
                return (
                  <div className="img-cont" onClick={async (e) => {
                    props.setRightSideBarStatus(false);
                    // mixpanel.track("switch account")
                    // mixpanel.time_event("switch the account")
                    if (ws.readyState === WebSocket.OPEN) {
                      ws.send(
                        JSON.stringify({
                          action: "logout",
                          publicID: localStorage.getItem("bixChat_loggedUserPublicId"),
                        })
                      );
                    }

                    if (user.publicId !== localStorage.getItem(
                      "bixChat_loggedUserPublicId")) {
                      if (config.environment !== "development") {
                        let switchingUser = {}
                        new Promise((resolve) => {
                          const options = { path: "/", domain: config.cookieDomain }
                          let users = CookieService.get("users")
                          for (let i in users) {
                            if (users[i].isLogged) {
                              users[i].isLogged = false
                            }
                            if (users[i].public_id === user.publicId) {
                              users[i].isLogged = true
                            }

                          }
                          let switchedUser = users.filter(el => { return el.isLogged === true })[0]
                          switchedUser.isLogged = false
                          switchingUser = users.filter(el => { return el.public_id === user.publicId })[0]
                          switchingUser.isLogged = true
                          CookieService.set("users", users, options)
                          resolve("success")
                        }).then((success) => {
                          if (success === "success") {
                            let fullname = switchingUser.first_name + " " + switchingUser.last_name;
                            let profile_pic = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${switchingUser.public_id}`

                            context.setIsSwitching(true)
                            context.switchAccount(switchingUser.public_id, switchingUser.x_access_token, switchingUser.email, fullname, profile_pic, switchingUser.role, switchingUser.refresh_token)

                          }
                        })
                      } else {
                        context.setIsSwitching(true)
                        await context.switchAccount(user.publicId, user.accessToken, user.email, user.fullName, user.profileImage, user.role, user.refresh_token)

                      }
                    }
                    context.RightNavPopupHandler(e)
                  }}>
                    <div className={localStorage.getItem(
                      "bixChat_loggedUserPublicId") === user.publicId ? "selected" : "unSelected"}>
                      <Avatar
                        src={`${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${user.publicId}`}
                        size='xs'
                        withBorder={true}
                      />
                      <div className="data"><span className="name">{user.fullName}</span><div className="email">{user.email}</div></div>

                    </div>
                  </div>
                )

              })}

              <div className="sections"
                onClick={() => {
                  // context.setSelectedChat('')
                  localStorage.setItem("bixChat_selectedChatId", "");
                  context.setIsChatSelected(false)
                  context.setsearchInput("")
                  context.setFilteredUsers([])
                  // mixpanel.track("add account")
                  if (config.environment !== "development") {
                    const options = { path: "/", domain: config.cookieDomain }
                    let users = CookieService.get("users")
                    for (let i in users) {
                      if (users[i].isLogged) {
                        users[i].isLogged = false
                      }
                    }
                    CookieService.set("users", users, options)
                    let url = `${config.apiEndpoints.protocol}${config.domain.auth}${config.emailText}`;
                    window.location.href = url;
                  } else {
                    history.push({
                      pathname: "/login",
                    });
                  }
                }}
              >
                <img alt="" src={add} className="img-sec" />
                Add account
              </div>
            </div>



          </div>

          <div className="right-option-bar">
            <div className={`top-navbar-${context.fontSizeType}`} id="top-navbar" >
              <div className="top-div">
                <div className="icons">

                  <div
                    className="new-chat-btn"
                    onClick={() => {
                      context.setViewContacts(true)
                      context.setIsChatSelected(false)
                      context.setShowTabs(false)
                      context.showContactsNav('open')
                      // mixpanel.track("new chat")
                      // mixpanel.track('Compose mail clicked')
                      if (props.sendMessageModal) {
                      } else {
                        props.setSendMessageModal(true);
                        context.setSendMessageType("multiUser");
                      }
                      context.RightNavPopupHandler("closeOnly")
                      context.UploadModalHandler("closeOnly")
                      props.setRightSideBarStatus(false)
                      conText?.setReplySubject(false);
                      localStorage.setItem("bixChat_selectedChatId", "")
                    }}
                    id='new-message'
                  >
                    <span className="new-Chat">Compose</span>
                  </div>
                  <div className="right-search-wrapper">
                    <div className="search-bar-wrapper-new" onClick={() =>
                      context.RightNavPopupHandler("closeOnly")
                    }>
                      {isSearchCollapsed ? (
                        <img
                          src={arrow}
                          alt=""
                          className="img-icon-style"
                          onClick={() => {
                            if (isSearchCollapsed) {
                              setIsSearchCollapsed(false);
                            }
                          }}
                        />
                      ) : (
                        <img
                          src={search}
                          alt=""
                          className="img-icon-style"
                          onClick={() => {
                            if (!isSearchCollapsed) {
                              setIsSearchCollapsed(true);
                            }
                          }}
                        />
                      )}
                      <input
                        type="text"
                        placeholder="Search"
                        id="left-Searchbar"
                        value={context?.searchInput}
                        onChange={(e) => {
                          context?.setsearchInput(e.target.value);
                          context.filterChatrooms(e);
                          context.searchUsers(e);
                        }}
                        className="input-style-new"
                        onClick={() => {
                          // mixpanel.track("search clicked")
                          // if (!isSearchCollapsed) {
                          //   setIsSearchCollapsed(true);
                          // }
                        }}
                      />
                    </div>




                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      style={{
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                      onClose={() => {
                        setAnchorEl(null);
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          // mixpanel.track("new group popup")
                          setAnchorEl(null);
                          props.setNewGroupModal1(true);
                          props.setAddMembersFrom("Left")
                        }}
                      >
                        <img
                          src={newGrpIcon}
                          alt=""
                          style={{ height: 15, width: 15, marginRight: "10px" }}
                        />
                        New Channel
                      </MenuItem>
                      {localStorage.getItem("bixChat_isPrivate") === "false" ?
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            conText.setSendModal(true)
                            props.setSendComponentFrom("Left")
                          }}
                        >
                          <img
                            src={contactsIcon}
                            alt=""
                            style={{ height: 15, width: 15, marginRight: "10px" }}
                          />
                          My Contacts
                        </MenuItem> : ""}

                      {window.screen.width < 600 ?
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            if (context.settingsModal) {
                              context.setSettingsModal(false);
                            } else {
                              context.setSettingsModal(true);
                            }
                          }}
                        >
                          <img
                            src={settings}
                            alt=""
                            style={{ height: 15, width: 15, marginRight: "10px" }}
                          />
                          Settings
                        </MenuItem>
                        : ""}
                      {window.screen.width < 600 ?
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            context.logoutFunction()
                          }}
                        >
                          <img
                            src={logout}
                            alt=""
                            style={{ height: 15, width: 15, marginRight: "10px" }}
                          />
                          Logout
                        </MenuItem>
                        : ""}

                      {/* <MenuItem
                    onClick={ () => {
                    context.setContactModal(true);
                  }
                  }>
                    <img
                      alt=""
                      style={{ height: 15, width: 15, marginRight: "10px" }}
                    />
                    New Contact
                  </MenuItem> */}
                      {/* <MenuItem onClick={() => {
                    setAnchorEl(null);
                    context.setSendModal(true)
                  }}>
                    <img
                      src={clockIcon}
                      alt=""
                      style={{ height: 15, width: 15, marginRight: "10px" }}
                    />
                    Reminded message
                  </MenuItem> */}
                      {/* <p style={{ marginLeft: "25px" }}>Archived chats</p> */}
                      <Feature name='archived-chats' activeComponent={archiveChat()} inactivecomponent={null} />

                      {/* <MenuItem>
                    <p style={{ marginLeft: "25px" }}>New label</p>
                  </MenuItem> */}

                      <Feature name='new-label' activeComponent={newLabel()} inactivecomponent={null} />

                    </Menu>

                    {props.popup ? <div className="popOver-2">
                      <div className="sections-2">
                        <img
                          src={newGrpIcon}
                          alt=""
                          style={{ height: 15, width: 15, marginRight: "10px" }}
                        />
                        New Channel
                      </div>
                    </div> : ""}

                    <div>
                    </div>
                  </div>
                  <div className="mobile-topnavbar" onClick={() => { context.RightNavPopupHandler("closeOnly") }}>

                    <div className="header-bixspace"> {!searchFocus ? <div>ZapMail</div> : ""}</div>
                    <div className="icon-cont">
                      <div className="search-content">
                        <div className="search-container">
                          <input className="search expandright" id="searchright" type="search" name="q" placeholder="Search" value={context?.searchInput}
                            onChange={(e) => {
                              context?.setsearchInput(e.target.value);
                              context.filterChatrooms(e);
                              context.searchUsers(e);

                            }}
                            onFocus={() => {
                              setSearchFocus(true)
                            }}
                            onBlur={(e) => {
                              setSearchFocus(false)
                              context?.setsearchInput("");
                              context.filterChatrooms(e);


                            }}
                          />
                          {!searchFocus ?
                            <label className="search-ic searchbutton" for="searchright"><span class="mglass"><img
                              src={search2}
                              alt="" /></span></label> : ""}

                        </div>
                      </div>
                      {searchFocus ?
                        <div className="close">
                          <img alt="" src={close} />
                        </div> : ""
                      }

                      <img alt="" src={dots} className="more-ic" onClick={e => {
                        handleClick(e)
                        context.RightNavPopupHandler("closeOnly")
                        // mixpanel.track("More menu clicked")
                      }}
                        id='more'
                        ref={props.newPopup} />
                    </div>
                    {props.popup ?
                      <div className="popOver-mobile">
                        <div className="sections-mobile">
                          <img
                            src={newGrpIcon}
                            alt=""
                            style={{ height: 15, width: 15, marginRight: "10px" }}
                          />
                          New Channel
                        </div>
                        <div className="sections-mobile">
                          <img
                            src={settings}
                            alt=""
                            style={{ height: 15, width: 15, marginRight: "10px" }}
                          />
                          Settings
                        </div><div className="sections-mobile">
                          <img
                            src={logout}
                            alt=""
                            style={{ height: 15, width: 15, marginRight: "10px" }}
                          />
                          Logout
                        </div>
                      </div> : ""}

                  </div>
                </div>

                <img
                  src={moreIcon}
                  alt=""
                  className="more-icon"
                  style={{ paddingLeft: " -10px" }}
                  onClick={e => {
                    handleClick(e)
                    context.RightNavPopupHandler("closeOnly")
                    // mixpanel.track("More menu clicked")
                  }}
                  id='more'
                  ref={props.newPopup}
                />


              </div>


              <div className="saved-other-wrapper">
                {savedUnreadCount()}
                {unsavedUnreadCount()}
                <div className="drawer">
                  <img src={drawer} alt="" className="drawer" onClick={() => {
                    context.RightNavPopupHandler()
                    // mixpanel.track("account list dropdown")
                    // mixpanel.track("switch the account")
                  }} />
                  <div className="popOver" style={{ display: context.rightNavPopup }}>
                    {JSON.parse(localStorage.getItem("bixChat_users"))?.map(user => {
                      return (
                        <div className="img-cont" onClick={async (e) => {
                          props.setRightSideBarStatus(false);
                          // mixpanel.track("switch account")
                          // mixpanel.time_event("switch the account")
                          if (ws.readyState === WebSocket.OPEN) {
                            ws.send(
                              JSON.stringify({
                                action: "logout",
                                publicID: localStorage.getItem("bixChat_loggedUserPublicId"),
                              })
                            );
                          }

                          if (user.publicId !== localStorage.getItem(
                            "bixChat_loggedUserPublicId")) {
                            if (config.environment !== "development") {
                              let switchingUser = {}
                              new Promise((resolve) => {
                                const options = { path: "/", domain: config.cookieDomain }
                                let users = CookieService.get("users")
                                for (let i in users) {
                                  if (users[i].isLogged) {
                                    users[i].isLogged = false
                                  }
                                  if (users[i].public_id === user.publicId) {
                                    users[i].isLogged = true
                                  }

                                }
                                let switchedUser = users.filter(el => { return el.isLogged === true })[0]
                                switchedUser.isLogged = false
                                switchingUser = users.filter(el => { return el.public_id === user.publicId })[0]
                                switchingUser.isLogged = true
                                CookieService.set("users", users, options)
                                resolve("success")
                              }).then((success) => {
                                if (success === "success") {
                                  let fullname = switchingUser.first_name + " " + switchingUser.last_name;
                                  let profile_pic = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${switchingUser.public_id}`

                                  context.setIsSwitching(true)
                                  context.switchAccount(switchingUser.public_id, switchingUser.x_access_token, switchingUser.email, fullname, profile_pic, switchingUser.role, switchingUser.refresh_token)

                                }
                              })
                            } else {
                              context.setIsSwitching(true)
                              await context.switchAccount(user.publicId, user.accessToken, user.email, user.fullName, user.profileImage, user.role, user.refresh_token)

                            }
                          }
                          context.RightNavPopupHandler(e)
                        }}>
                          <div className={localStorage.getItem(
                            "bixChat_loggedUserPublicId") === user.publicId ? "selected" : "unSelected"}>
                            <Avatar
                              src={user.profileImage}
                              size='xs'
                              withBorder={true}
                            />
                            <div className="data"><span className="name">{user.fullName}</span><div className="email">{user.email}</div></div>

                          </div>
                        </div>
                      )

                    })}

                    <div className="sections"
                      onClick={() => {
                        // context.setSelectedChat('')
                        localStorage.setItem("bixChat_selectedChatId", "");
                        context.setIsChatSelected(false)
                        context.setsearchInput("")
                        context.setFilteredUsers([])
                        // mixpanel.track("add account")
                        if (config.environment !== "development") {
                          const options = { path: "/", domain: config.cookieDomain }
                          let users = CookieService.get("users")
                          for (let i in users) {
                            if (users[i].isLogged) {
                              users[i].isLogged = false
                            }
                          }
                          CookieService.set("users", users, options)
                          let url = `${config.apiEndpoints.protocol}${config.domain.auth}${config.emailText}`;
                          window.location.href = url;
                        } else {
                          history.push({
                            pathname: "/login",
                          });
                        }
                      }}
                    >
                      <img alt="" src={add} className="img-sec" />
                      Add account
                    </div>
                  </div>


                </div>
                <div className="tab-div">
                  <div className={context.savedChatsSelected ? 'tab-view border-btm' : 'tab-view'} style={{ display: 'flex' }}>
                    <div className={context.savedChatsSelected ? 'saved  selected-tab' : 'saved unselected-tab'}
                      onClick={(e) => {
                        // mixpanel.time_event('In primary')
                        // mixpanel.track('In Other')
                        // mixpanel.track('Primary clicked ' + context?.loggedUserData?.domain)
                        context.setSavedChatsSelected(true)
                        setShowLabelChats(false)
                        if (context.clickOther === false) {
                          context.setClickOther(true)
                        }
                      }}
                      id="primary"
                    >
                      Focused

                      {context.savedContactsList.length === 0 ? '' : <div className="number-block" > {context.savedContactsList.length < 10 ? "0" : ""}{context.savedContactsList.length}
                      </div>}
                    </div>
                    {/* {context.savedChatsSelected && !showUnread ? <img src={filter}
                    onClick={() => {
                      setShowUnread(true)
                      setShowLabelChats(false)
                      setLabelSelected('')

                    }} /> : context.savedChatsSelected && showUnread ? <img src={filter}
                      onClick={(e) => {
                        setShowUnread(false)
                        setLabelSelected('')
                        conText.setPrimaryCount(0)

                      }} /> : ''} */}
                  </div>
                </div>
                <div className="tab-div">
                  <div className={!context.savedChatsSelected ? 'tab-view border-btm' : 'tab-view'} style={{ display: 'flex' }}>
                    <div className={context.savedChatsSelected ? 'other unselected-tab' : 'other selected-tab '}
                      onClick={() => {
                        context.setSavedChatsSelected(false)
                        context.getLabels()
                        setShowLabelChats(false)
                        setLabelSelected('')

                        // mixpanel.track('In primary')
                        // mixpanel.time_event('In Other')

                        if (context.clickOther === false) {
                          // mixpanel.track('Open Other Chats Stright away onload')
                          context.setClickOther(true)

                        }
                      }}
                      id='other'

                    >
                      Other
                      {context.unsavedchatList.length === 0 ? '' : <div className="number-block">  {context.unsavedchatList.length < 10 ? "0" : ""}{context.unsavedchatList.length} </div>}

                      {/* {!context.savedChatsSelected && !showUnread ? <img src={flash1}
                      onClick={() => {
                        setShowUnread(true)
                        context.setPrimaryCount(0)
                        setShowLabelChats(false)
                        setLabelSelected('')
                        setShowRightArrow(true)
                        setShowLeftArrow(false)


                      }} /> : !context.savedChatsSelected && showUnread ? <img src={flash}
                        onClick={(e) => {
                          setShowUnread(false)
                          handlePrimaryClick(e)
                          context.setPrimaryCount(0)
                          setLabelSelected('')
                        }} /> : ''} */}


                    </div>
                  </div>
                </div>
                <img src={window.screen.width < 600 ? filter2 : filter} alt="" className="filter" onClick={(e) => {
                  if (context.savedChatsSelected && !showUnread) {
                    setShowUnread(true)
                    setShowLabelChats(false)
                    setLabelSelected('')
                  } else if (context.savedChatsSelected && showUnread) {
                    setShowUnread(false)
                    setLabelSelected('')
                    conText.setPrimaryCount(0)
                  } else if (!context.savedChatsSelected && !showUnread) {

                    setShowUnread(true)
                    context.setPrimaryCount(0)
                    setShowLabelChats(false)
                    setLabelSelected('')
                    setShowRightArrow(true)
                    setShowLeftArrow(false)
                  } else if (!context.savedChatsSelected && showUnread) {
                    setShowUnread(false)
                    handlePrimaryClick(e)
                    context.setPrimaryCount(0)
                    setLabelSelected('')
                  }
                  console.log("jhjh", showUnread);
                }} />
              </div>

            </div>


            <React.Fragment >
              <div onClick={() => {
                context.RightNavPopupHandler("closeOnly")
                context.setShowReactionTab(false)

              }}>
                {context.isOnline ? "" :
                  <div className="connection-status" >
                    <div className="error-icon">
                      <img alt="" src={signal} />
                    </div>
                    <div className="network-header"> Network Connection Error
                      <div className="network-error">You don't seem to have an active internet connection. Please check your connection and try again.</div>
                    </div></div>
                }

                {context?.chatroomsLoaded && (
                  <div className="messages-wrapper" style={{ height: getHeight() }}
                    onScroll={handleScrollPrimary}>
                    {context?.searchInput.length > 0 && context?.allChatrooms.length > 0 ? (
                      <div className="message-seconed-header">
                        {/* Frequently Used contacts */}
                      </div>
                    ) : (
                      ""
                    )}

                    {context.savedChatsSelected && showUnread ?
                      <div className="unread-btn-wrapper">
                        <div className={conText.primaryCount === 0 ? "unread-btn unread-btn-unselected" : 'unread-btn unread-btn-selected'} onClick={(e) => {
                          handlePrimaryClick(e)

                        }}>Unread</div>
                      </div>
                      : ''}

                    {context?.labels?.length > 0 ?
                      !context.savedChatsSelected && showUnread ?
                        <div style={{ display: 'flex' }}>

                          <div className="back-arrow-div"
                            onClick={() => {
                              if (showLeftArrow) {
                                label_scroll_left()
                              }
                            }}>
                            {!showLeftArrow ? '' : <img src={Arrow} className='back-arrow' />

                            }
                          </div>
                          <div id='labeldiv' className="label-wrapper label-div" >

                            {document.getElementById('labeldiv')?.scrollWidth > document.getElementById('labeldiv')?.clientWidth ? setShowRightArrow(true) : setShowRightArrow(false)}
                            {/* {if ((scrollWidth - outerWidth < Math.round(left.scrollLeft) + 5) && (scrollWidth - outerWidth > Math.round(left.scrollLeft) - 5)) {
      setShowRightArrow(false)
    }} */}

                            {(document.getElementById('labeldiv')?.scrollWidth - document.getElementById('labeldiv')?.clientWidth < Math.round(document.getElementById('labeldiv')?.scrollLeft) + 5)

                              && (document.getElementById('labeldiv')?.scrollWidth - document.getElementById('labeldiv')?.clientWidth > Math.round(document.getElementById('labeldiv')?.scrollLeft) - 5) ? setShowRightArrow(false) : ''}

                            {context?.labels?.map((value, i) => {
                              return (
                                <div style={{ paddingLeft: '20px' }}>
                                  <div
                                    key={value}
                                    className={labelSelected === value.name ? " unread-btn unread-btn-selected" : "unread-btn unread-btn-unselected"}
                                    onClick={() => {

                                      if (labelSelected === '') {
                                        setShowLabelChats(false)
                                        setLabelSelected(value.name)
                                        setShowLabelChats(true)
                                        context.filterLabel(value.name)
                                      }
                                      else if (labelSelected == value.name) {
                                        setLabelSelected('')
                                        setShowLabelChats(false)

                                      }
                                      else if (labelSelected !== value.name) {
                                        setShowLabelChats(false)
                                        setLabelSelected(value.name)
                                        setShowLabelChats(true)
                                        context.filterLabel(value.name)
                                      }


                                      // getLabeledChats(value.name)


                                    }}
                                  >{value.name}</div>

                                </div>

                              )


                            })}

                          </div>
                          <div className="forward-arrow-div"
                            onClick={() => {
                              if (showRightArrow) {
                                label_scroll_right()
                              }
                            }}>


                          </div>
                        </div>
                        : ''
                      : ''}





                    <div {...handlers} style={{ height: context?.filteredUsers.length === 0 ? "100%" : "" }}>
                      {context.savedChatsSelected && conText.primaryCount === 0 ?
                        <div>

                          <div>
                            {getChats('pinned')}
                            {pinnedChats?.map((value, i) => {
                              return (
                                <div>
                                  <SingleChat
                                    i={i}
                                    value={value}
                                    showOptions={true}
                                    setSelectedChat={setSelectedChat}
                                    onClick={() => {
                                      context.setContentToShow("All")
                                      props.setTextFocus(false)
                                      props.setFooterFixedBottom("0%")
                                      context.setMessageTag(false)
                                      // mixpanel.track("chatroom click")
                                      if (context.windowDimensions.width <= 600) {
                                        props.setSelectedChatID(value._id["$oid"])
                                      }
                                      context?.setNewMessage(context?.newMessage?.filter(
                                        (item) => item._id["$oid"] !== value._id["$oid"]
                                      ));
                                      context.setSavedContactsList(context?.savedContactsList?.filter(
                                        (item) => item._id["$oid"] !== value._id["$oid"]
                                      ))
                                      if (
                                        localStorage.getItem("bixChat_selectedChatId") !==
                                        value._id["$oid"]
                                      ) {
                                        setSelectedChat(value._id["$oid"]);
                                        localStorage.setItem(
                                          "bixChat_selectedChatId",
                                          value._id["$oid"]
                                        );
                                        if (value._cls === "ChatRoom.PrivateChatRoom") {
                                          if (!context.sendMessageModal) {
                                            context.setSendMessageType("newUser");
                                          }
                                        } else if (value._cls === "ChatRoom.EmailChatRoom") {
                                          if (!context.sendMessageModal) {
                                            context.setSendMessageType("emailChat");
                                          }
                                        } else if (value._cls === "ChatRoom.GroupChatRoom") {
                                          if (!context.sendMessageModal) {
                                            context.setSendMessageType("groupChat");
                                          }
                                        }

                                        if (value.unread_message_count > 0) {
                                          context?.setUnreadChatList(context?.unreadChatList?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ));
                                        }

                                        context.chatSelected(value, "chat_selected");
                                        context.setContactModal(false);


                                      }

                                      if (context.clickOther === false) {
                                        context.setClickOther(true)
                                      }

                                      context.UploadModalHandler("closeOnly");
                                      context.setContactModal(false)
                                      let ids = []
                                      context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                        (data) => {
                                          ids.push(data.public_id)
                                        })
                                      if (ws.readyState === WebSocket.OPEN) {
                                        ws.send(
                                          JSON.stringify({
                                            action: "groupStateCheck",
                                            publicIDs: ids,
                                          })
                                        );
                                      }
                                      if (window.screen.width > 1199) {
                                        props.setRightSideBarStatus(true);
                                      }

                                      props.setSelectedChat(context?.selectedChatroomDatas?.chatroomData)
                                      if (
                                        context?.selectedChatroomDatas?.chatroomData?._cls ===
                                        "ChatRoom.PrivateChatRoom"
                                      ) {
                                        context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                          (data) => {
                                            if (
                                              data.public_id !==
                                              localStorage.getItem("bixChat_loggedUserPublicId")
                                            ) {
                                              context.setSelectedSender(data);
                                            }
                                            context.setSelectedSender(data);
                                            return null;
                                          }
                                        );
                                      }
                                      context?.setAttachmentListLoading(true)
                                      context?.getAttachments()
                                    }}
                                    typingChats={props.typingChats}
                                    length={context?.allChatrooms?.length}
                                  />
                                </div>
                              );
                            })}
                          </div>

                          <div>
                            {getChats('saved')}
                            {savedChats?.map((value, i) => {
                              return (
                                <div>
                                  <SingleChat
                                    i={i}
                                    value={value}
                                    showOptions={true}
                                    setSelectedChat={setSelectedChat}
                                    onClick={() => {
                                      context.setContentToShow("All")
                                      props.setTextFocus(false)
                                      props.setFooterFixedBottom("0%")
                                      context.setMessageTag(false)
                                      // mixpanel.track("chatroom click")
                                      if (context.windowDimensions.width <= 600) {
                                        props.setSelectedChatID(value._id["$oid"])
                                      }


                                      context?.setNewMessage(context?.newMessage?.filter(
                                        (item) => item._id["$oid"] !== value._id["$oid"]
                                      ));

                                      context.setSavedContactsList(context?.savedContactsList?.filter(
                                        (item) => item._id["$oid"] !== value._id["$oid"]
                                      ))

                                      if (
                                        localStorage.getItem("bixChat_selectedChatId") !==
                                        value._id["$oid"]
                                      ) {
                                        setSelectedChat(value._id["$oid"]);

                                        localStorage.setItem(
                                          "bixChat_selectedChatId",
                                          value._id["$oid"]
                                        );
                                        if (value._cls === "ChatRoom.PrivateChatRoom") {
                                          if (!context.sendMessageModal) {
                                            context.setSendMessageType("newUser");
                                          }
                                        } else if (value._cls === "ChatRoom.EmailChatRoom") {
                                          if (!context.sendMessageModal) {
                                            context.setSendMessageType("emailChat");
                                          }
                                        } else if (value._cls === "ChatRoom.GroupChatRoom") {
                                          if (!context.sendMessageModal) {
                                            context.setSendMessageType("groupChat");
                                          }
                                        }

                                        if (value.unread_message_count > 0) {
                                          context?.setUnreadChatList(context?.unreadChatList?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ));
                                        }

                                        context.chatSelected(value, "chat_selected");
                                        context.setContactModal(false);


                                      }
                                      if (context.clickOther === false) {
                                        context.setClickOther(true)
                                      }

                                      context.UploadModalHandler("closeOnly");
                                      context.setContactModal(false)
                                      let ids = []
                                      context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                        (data) => {
                                          ids.push(data.public_id)
                                        })
                                      if (ws.readyState === WebSocket.OPEN) {
                                        ws.send(
                                          JSON.stringify({
                                            action: "groupStateCheck",
                                            publicIDs: ids,
                                          })
                                        );
                                      }

                                      if (window.screen.width > 1199) {
                                        props.setRightSideBarStatus(true);
                                      }
                                      setSelectedChat(context?.selectedChatroomDatas?.chatroomData)
                                      if (
                                        context?.selectedChatroomDatas?.chatroomData?._cls ===
                                        "ChatRoom.PrivateChatRoom"
                                      ) {
                                        context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                          (data) => {
                                            if (
                                              data.public_id !==
                                              localStorage.getItem("bixChat_loggedUserPublicId")
                                            ) {
                                              context.setSelectedSender(data);
                                            }
                                            context.setSelectedSender(data);
                                            return null;
                                          }
                                        );
                                      }
                                      context?.setAttachmentListLoading(true)
                                      context?.getAttachments()

                                    }}
                                    typingChats={props.typingChats}
                                    length={context?.allChatrooms?.length}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        :

                        context.savedChatsSelected && conText.primaryCount === 1 ?
                          <div style={{ paddingTop: "10px" }}>
                            {context.unreadChatList?.length > 0 ?
                              <div>
                                {context.unreadChatList?.map((value, i) => {
                                  return (
                                    <div>
                                      <SingleChat
                                        i={i}
                                        value={value}
                                        showOptions={true}
                                        setSelectedChat={setSelectedChat}
                                        onClick={() => {
                                          context.setContentToShow("All")
                                          props.setTextFocus(false)
                                          props.setFooterFixedBottom("0%")
                                          context.setMessageTag(false)
                                          // mixpanel.track("chatroom click")
                                          if (context.windowDimensions.width <= 600) {
                                            props.setSelectedChatID(value._id["$oid"])
                                          }
                                          context?.setNewMessage(context?.newMessage?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ));
                                          context.setSavedContactsList(context?.savedContactsList?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ))
                                          context?.setUnreadChatList(context?.unreadChatList?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ));
                                          if (
                                            localStorage.getItem("bixChat_selectedChatId") !==
                                            value._id["$oid"]
                                          ) {
                                            setSelectedChat(value._id["$oid"]);

                                            localStorage.setItem(
                                              "bixChat_selectedChatId",
                                              value._id["$oid"]
                                            );
                                            if (value._cls === "ChatRoom.PrivateChatRoom") {
                                              if (!context.sendMessageModal) {
                                                context.setSendMessageType("newUser");
                                              }
                                            } else if (value._cls === "ChatRoom.EmailChatRoom") {
                                              if (!context.sendMessageModal) {
                                                context.setSendMessageType("emailChat");
                                              }
                                            } else if (value._cls === "ChatRoom.GroupChatRoom") {
                                              if (!context.sendMessageModal) {
                                                context.setSendMessageType("groupChat");
                                              }
                                            }
                                            context.chatSelected(value, "chat_selected");
                                            context.setContactModal(false);
                                          }
                                          if (context.clickOther === false) {
                                            context.setClickOther(true)
                                          }


                                          context.UploadModalHandler("closeOnly");
                                          context.setContactModal(false)
                                          let ids = []
                                          context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                            (data) => {
                                              ids.push(data.public_id)
                                            })
                                          if (ws.readyState === WebSocket.OPEN) {
                                            ws.send(
                                              JSON.stringify({
                                                action: "groupStateCheck",
                                                publicIDs: ids,
                                              })
                                            );
                                          }

                                          if (window.screen.width > 1199) {
                                            props.setRightSideBarStatus(true);
                                          }
                                          setSelectedChat(context?.selectedChatroomDatas?.chatroomData)
                                          if (
                                            context?.selectedChatroomDatas?.chatroomData?._cls ===
                                            "ChatRoom.PrivateChatRoom"
                                          ) {
                                            context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                              (data) => {
                                                if (
                                                  data.public_id !==
                                                  localStorage.getItem("bixChat_loggedUserPublicId")
                                                ) {
                                                  context.setSelectedSender(data);
                                                }
                                                context.setSelectedSender(data);
                                                return null;
                                              }
                                            );
                                          }

                                          context?.setAttachmentListLoading(true)
                                          context?.getAttachments()

                                        }}
                                        typingChats={props.typingChats}
                                        length={context?.allChatrooms?.length}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                              :
                              <div className="empty-unread">You have no unread messages</div>
                            }
                          </div>
                          :
                          !context.savedChatsSelected && !showLabelChats && !showLabelChats ?
                            <div style={{ paddingTop: "10px" }}>
                              {getChats('other')}
                              {unSavedChats?.map((value, i) => {
                                return (
                                  <div>
                                    {/* {JSON.stringify(value.blocked) !== '{}' && value._cls !== 'ChatRoom.GroupChatRoom'? '': */}
                                    <SingleChat
                                      i={i}
                                      value={value}
                                      showOptions={true}
                                      isOther={true}
                                      setSelectedChat={setSelectedChat}
                                      onClick={() => {
                                        context.setContentToShow("All")
                                        props.setTextFocus(false)
                                        props.setFooterFixedBottom("0%")
                                        // mixpanel.track("chatroom click")
                                        if (context.windowDimensions.width <= 600) {
                                          props.setSelectedChatID(value._id["$oid"])
                                        }
                                        context?.setNewMessage(context?.newMessage?.filter(
                                          (item) => item._id["$oid"] !== value._id["$oid"]
                                        ));
                                        context.setSavedContactsList(context?.savedContactsList?.filter(
                                          (item) => item._id["$oid"] !== value._id["$oid"]
                                        ))
                                        if (
                                          localStorage.getItem("bixChat_selectedChatId") !==
                                          value._id["$oid"]
                                        ) {
                                          setSelectedChat(value._id["$oid"]);

                                          localStorage.setItem(
                                            "bixChat_selectedChatId",
                                            value._id["$oid"]
                                          );
                                          if (value._cls === "ChatRoom.PrivateChatRoom") {
                                            if (!context.sendMessageModal) {
                                              context.setSendMessageType("newUser");
                                            }
                                          } else if (value._cls === "ChatRoom.EmailChatRoom") {
                                            if (!context.sendMessageModal) {
                                              context.setSendMessageType("emailChat");
                                            }
                                          } else if (value._cls === "ChatRoom.GroupChatRoom") {
                                            if (!context.sendMessageModal) {
                                              context.setSendMessageType("groupChat");
                                            }
                                          }
                                          context.chatSelected(value, "chat_selected");
                                          context.setContactModal(false);

                                        }

                                        context?.setunSavedchatList(context?.unsavedchatList?.filter(
                                          (item) => item._id["$oid"] !== value._id["$oid"]
                                        ));

                                        context.UploadModalHandler("closeOnly");
                                        context.setContactModal(false)
                                        let ids = []
                                        context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                          (data) => {
                                            ids.push(data.public_id)
                                          })
                                        if (ws.readyState === WebSocket.OPEN) {
                                          ws.send(
                                            JSON.stringify({
                                              action: "groupStateCheck",
                                              publicIDs: ids,
                                            })
                                          );
                                        }

                                        if (window.screen.width > 1199) {
                                          props.setRightSideBarStatus(true);
                                        }
                                        setSelectedChat(context?.selectedChatroomDatas?.chatroomData)
                                        if (
                                          context?.selectedChatroomDatas?.chatroomData?._cls ===
                                          "ChatRoom.PrivateChatRoom"
                                        ) {
                                          context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                            (data) => {
                                              if (
                                                data.public_id !==
                                                localStorage.getItem("bixChat_loggedUserPublicId")
                                              ) {
                                                context.setSelectedSender(data);
                                              }
                                              context.setSelectedSender(data);
                                              return null;
                                            }
                                          );
                                        }
                                        context?.setAttachmentListLoading(true)
                                        context?.getAttachments()
                                      }}
                                      typingChats={props.typingChats}
                                      length={context?.allChatrooms?.length}

                                    />
                                    {/* } */}
                                  </div>
                                );
                              })}
                            </div>
                            :

                            showLabelChats ?
                              <div style={{ paddingTop: "10px" }}>
                                {getLabeledChats(labelSelected)}
                                {labelChats?.map((value, i) => {
                                  return (
                                    <div>
                                      {/* {JSON.stringify(value.blocked) !== '{}' && value._cls !== 'ChatRoom.GroupChatRoom'? '': */}
                                      <SingleChat
                                        i={i}
                                        value={value}
                                        showOptions={true}
                                        isOther={true}
                                        setSelectedChat={setSelectedChat}
                                        onClick={() => {
                                          context.setContentToShow("All")
                                          props.setTextFocus(false)
                                          props.setFooterFixedBottom("0%")
                                          // mixpanel.track("chatroom click")
                                          if (context.windowDimensions.width <= 600) {
                                            props.setSelectedChatID(value._id["$oid"])
                                          }
                                          context?.setNewMessage(context?.newMessage?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ));
                                          context.setSavedContactsList(context?.savedContactsList?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ))
                                          if (
                                            localStorage.getItem("bixChat_selectedChatId") !==
                                            value._id["$oid"]
                                          ) {
                                            setSelectedChat(value._id["$oid"]);

                                            localStorage.setItem(
                                              "bixChat_selectedChatId",
                                              value._id["$oid"]
                                            );
                                            if (value._cls === "ChatRoom.PrivateChatRoom") {
                                              if (!context.sendMessageModal) {
                                                context.setSendMessageType("newUser");
                                              }
                                            } else if (value._cls === "ChatRoom.EmailChatRoom") {
                                              if (!context.sendMessageModal) {
                                                context.setSendMessageType("emailChat");
                                              }
                                            } else if (value._cls === "ChatRoom.GroupChatRoom") {
                                              if (!context.sendMessageModal) {
                                                context.setSendMessageType("groupChat");
                                              }
                                            }
                                            context.chatSelected(value, "chat_selected");
                                            context.setContactModal(false);

                                          }

                                          context?.setunSavedchatList(context?.unsavedchatList?.filter(
                                            (item) => item._id["$oid"] !== value._id["$oid"]
                                          ));

                                          context.UploadModalHandler("closeOnly");
                                          context.setContactModal(false)
                                          let ids = []
                                          context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                            (data) => {
                                              ids.push(data.public_id)
                                            })
                                          if (ws.readyState === WebSocket.OPEN) {
                                            ws.send(
                                              JSON.stringify({
                                                action: "groupStateCheck",
                                                publicIDs: ids,
                                              })
                                            );
                                          }

                                          if (window.screen.width > 1199) {
                                            props.setRightSideBarStatus(true);
                                          }
                                          setSelectedChat(context?.selectedChatroomDatas?.chatroomData)
                                          if (
                                            context?.selectedChatroomDatas?.chatroomData?._cls ===
                                            "ChatRoom.PrivateChatRoom"
                                          ) {
                                            context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                              (data) => {
                                                if (
                                                  data.public_id !==
                                                  localStorage.getItem("bixChat_loggedUserPublicId")
                                                ) {
                                                  context.setSelectedSender(data);
                                                }
                                                context.setSelectedSender(data);
                                                return null;
                                              }
                                            );
                                          }


                                        }}
                                        typingChats={props.typingChats}
                                        length={context?.allChatrooms?.length}

                                      />
                                      {/* } */}
                                    </div>
                                  );
                                })}
                              </div>
                              :
                              ''
                      }
                      {context.savedChatsSelected && localStorage.getItem("bixChat_isPrivate") === "false" ? <MatesComponents setSendComponentFrom={props.setSendComponentFrom} /> : ""}
                    </div>
                    {/* : ''} */}


                    {context?.filteredUsers.length > 0 ? (
                      <div className="message-seconed-header">
                        Contacts from BixChat
                      </div>
                    ) : (
                      ""
                    )}

                    {context?.filteredUsers.map((value, i) => {
                      return (

                        <div key={i}>
                          <div
                            className={`${value._id === selectedChat
                              ? `single-message-selected-${context.fontSizeType}`
                              : `single-message-${context.fontSizeType}`
                              } `}
                            onClick={() => {
                              setSelectedChat(value._id);
                              let x = {
                                profile_image: getDPService().getDP("ChatRoom.PrivateChatRoom", value.public_id) !== null
                                  ? getDPService().getDP("ChatRoom.PrivateChatRoom", value.public_id)
                                  : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png",
                                profile_name:
                                  value.full_name || value.email.split("@")[0],
                                profile_email: value.email,
                                chatroom_name: value.display_name,
                                profile_headline: value.headline,
                                profile_public_id: value.public_id,
                                members: [value._id],
                                members_list: [value],
                                messagesData: [],
                                threads: [],
                                _cls: "ChatRoom.PrivateChatRoom",
                                _id: value._id,
                                isNew: true,
                              };

                              if (!context.sendMessageModal) {
                                context.setSendMessageType("newUser");
                                context.setNewMessageFromSearch(true)
                              }


                              context.openNewChat(x);
                              if (window.screen.width > 1199) {
                                props.setRightSideBarStatus(true);
                              }
                            }}
                          >
                            <div className="top-message-cont">
                              <div className="single-message-left">
                                <div className="profile-picture-wrapper">

                                  {getDPService().getDP("ChatRoom.PrivateChatRoom", value.public_id) ? <img
                                    className="profile-picture"
                                    src={getDPService().getDP("ChatRoom.PrivateChatRoom", value.public_id)}
                                    alt="pp"
                                  /> : <img
                                    className="profile-picture"
                                    src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                    alt="pp"
                                  />}
                                </div>
                                <div className="sender-noti">

                                  <div className="sender">
                                    {value?.full_name
                                      ? value?.full_name
                                      : value?.email?.split("@")[0]}
                                  </div>
                                </div>
                              </div>

                              <div className="single-message-right">
                                <div className="date-and-noti">
                                  <div className="date">

                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="message-and-sender">
                              <div className={`subject-text-${context.fontSizeType}`} style={{
                                color: props.value?._id["$oid"] ===
                                  localStorage.getItem("bixChat_selectedChatId")
                                  ? "white" : ""
                              }}>{value.headline} </div>
                              <div className="message-style">
                                <div className={`pre-message-${conText.fontSizeType}`} style={{ marginLeft: "48px" }}>
                                  {value?.email}</div></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                )}
              </div>
            </React.Fragment>


          </div>

          <div className="floating-btn" onClick={() => {
            context.RightNavPopupHandler("closeOnly")
            context.setViewContacts(true)
            context.setIsChatSelected(false)
            context.setShowTabs(false)
            context.showContactsNav('open')
            // mixpanel.track("new chat")
            // mixpanel.track('Compose mail clicked')
            if (props.sendMessageModal) {
            } else {
              props.setSendMessageModal(true);
              context.setSendMessageType("multiUser");
            }
            context.RightNavPopupHandler("closeOnly")
            context.UploadModalHandler("closeOnly")
            props.setRightSideBarStatus(false)
            conText?.setReplySubject(false);
            localStorage.setItem("bixChat_selectedChatId", "")

          }}
            id='new-message'>
            <img alt="" src={pen} />Compose</div>

        </div>
      )}
    </AppContext.Consumer>
  );
};

export default LeftSidebar;