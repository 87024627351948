import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from 'react-player'

import "./ImagePreviewStyle.scss";
import image from "../../../Assets/Icons/attach-file.png";
import fileAtt from "../../../Assets/Icons/attach-file.png";
import download from "../../../Assets/Icons/cloud-computing.svg";
import heirachy from "../../../Assets/Icons/vuesax-linear-hierarchy-2.svg";
import next from "../../../Assets/Icons/next.png";
import pdf from "../../../Assets/Icons/pdf.png";
import doc from "../../../Assets/Icons/doc.jpg";
import csv from "../../../Assets/Icons/csv.jpg";
import excel from "../../../Assets/Icons/excel.jpg";
import ppt from "../../../Assets/Icons/ppt.png";
import txt from "../../../Assets/Icons/txt.png";
import mic from "../../../Assets/Icons/audio-mic.svg";
import CloseIcon from "../../Button/CloseIcon/CloseIcon";


import defaultVideo from "../../../Assets/Images/defaultVideo.jpg"
import AudioComponent from "../../AudioComponent/AudioComponent";
import FilePreview from "react-file-preview-latest";



const ImagePreview = (props) => {
  let slideIndex = props.currentImage;
  // const [selected, setSelected] = useState("");
  // const [selectedD, setSelectedD] = useState(false);

  const [url, setUrl] = useState('https://static.videezy.com/system/resources/previews/000/000/563/original/100_0001.mp4')
  const [pip, setPip] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [controls, setControls] = useState(true)
  const [light, setLight] = useState(false)
  const [volume, setVolume] = useState(0.8)
  const [muted, setMuted] = useState(false)
  const [played, setPlayed] = useState(0)
  const [loaded, setLoaded] = useState(0)
  const [duration, setDuration] = useState(0)
  const [playbackRate, setPlayBackRate] = useState(1.0)
  const [loop, setLoop] = useState(false)
  const [seeking, setSeeking] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [currentImg, setCurrentImg] = useState("")



  const load = ((url) => {
    setUrl(url)
    setPlayed(0)
    setLoaded(0)
    setPip(false)
  })

  // const handlePlayPause = () => {
  //   setPlaying(!playing)
  // }

  // const handleStop = () => {
  //   setUrl(null)
  //   setPlaying(false)
  // }

  // const handleToggleControls = () => {
  //   const url = url

  //   setControls(!controls)
  //   setUrl(null)

  //   load(url)

  // }

  // const handleToggleLight = () => {
  //   setLight(!light)
  // }

  // const handleToggleLoop = () => {
  //   setLoop(!loop)
  // }

  // const handleVolumeChange = e => {
  //   setVolume(parseFloat(e.target.value))
  // }

  // const handleToggleMuted = () => {
  //   setMuted(!muted)
  // }

  const handleSetPlaybackRate = e => {
    setPlayBackRate(parseFloat(e.target.value))
  }

  const handleOnPlaybackRateChange = (speed) => {
    setPlayBackRate(parseFloat(speed))
  }

  const handleTogglePIP = () => {
    // this.setState({ pip: !this.state.pip })
    setPip(!pip)
  }

  const handlePlay = () => {
    setPlaying(true)
  }

  const handleEnablePIP = () => {
    setPip(true)
  }

  const handleDisablePIP = () => {
    setPip(false)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  const handleSeekMouseDown = e => {
    setSeeking(true)
  }

  const handleSeekChange = e => {
    setPlayed(parseFloat(e.target.value))

  }

  const handleSeekMouseUp = e => {

    setSeeking(false)

    // this.player.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = (e) => {
    // console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      // this.setState(state)

      setPlayed(e.played)

    }
  }

  const handleEnded = () => {
    setPlaying(loop)
  }

  const handleDuration = (duration) => {


    setDuration(duration)
  }

  const renderLoadButton = (url, label) => {
    return (
      <button onClick={() => load(url)}>
        {label}
      </button>
    )
  }

  const player = useRef();


  let right = useRef(null);
  let left = useRef(null);
  let slide = 0;
  useEffect(() => {
    showDivs(slideIndex);
  }, []);
  const plusDivs = (n) => {
    showDivs((slideIndex += n));
  };
  const currentDiv = (n) => {
    showDivs((slideIndex = n));
  };
  useEffect(() => {
    const listener = (event) => {
      if (props.image.length > 1) {
        if (event.key === "ArrowRight") {
          right?.current.click();
        }
        if (event.key === "ArrowLeft") {
          left?.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);


  const onModalClick = (image) => {
    setCurrentImg(image)
    setOpenModal(!openModal);
  }



  const showDivs = (n) => {
    let i;
    let x = document.getElementsByClassName("imagePrev");
    let dots = document.getElementsByClassName("bottom-images");
    let name = document.getElementsByClassName("attachment-name");
    let link = document.getElementsByClassName("image-download");

    if (dots !== undefined) {
      if (n > x.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = x.length;
      }

      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
        name[i].style.display = "none";
        link[i].style.display = "none"
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(
          " bottom-images-selected",
          ""
        );
      }
      slide = slideIndex;

      x[slideIndex - 1].style.display = "block";
      name[slideIndex - 1].style.display = "block";
      link[slideIndex - 1].style.display = "block"
      dots[slideIndex - 1].className += " bottom-images-selected";
    }
  };
  const getDocument = (attachment) => {
    if (attachment.includes(".pdf")) {
      return pdf;
    } else if (attachment.includes(".doc") || attachment.includes(".docx")) {
      return doc;
    } else if (attachment.includes(".ppt")) {
      return ppt;
    } else if (attachment.includes(".csv")) {
      return csv;
    } else if (attachment.includes(".xlsx")) {
      return excel;
    } else if (attachment.includes(".txt")) {
      return txt;
    } else {
      return attachment
    }
  };



  return (
    <div className="Image_Preview">
      <div className="image-preview-container">
        <div className="header">
          <div className="right-container">
            <div className="img-cont">
              <img alt="" src={image} className="image" />
            </div>
            <div className="text" style={{ overflow: "hidden" }} >
              {props.image.map((image) => {
                return (
                  <div className="attachment-name">{image.attachment_name}</div>
                );
              })}
            </div>
          </div>
          <div className="left-container ">
            <div className="image-container1">

              {openModal ?
                <div
                  className="pop-up-img box-style"
                >
                  <div
                    className="item"
                  >
                    <a
                      href={currentImg.attachment_link}
                      download
                      className="download-img-d"
                      style={{ color: 'black' }}
                    >
                      <img src={fileAtt} alt="" className="img" />

                      Download
                    </a>
                  </div>

                  <div className="item"
                  // onClick={uploadFromCloud}
                  >
                    <img src={heirachy} alt="" className="img"
                    />
                    Save to bixCloud
                  </div>

                </div>
                : ""}

              {props.image.map((image) => {

                return (
                  <a
                  >
                    <img alt="" src={download} className="image-download"
                      onClick={() => onModalClick(image)}
                    />
                  </a>
                );
              })}
              <div style={{ alignSelf: 'center' }}>
                <CloseIcon
                  size='s'
                  onClick={() => {
                    props.setImagePreview(false);
                    setPlayed(0)
                  }}
                /></div>


              {/* <img
                alt=""
                src={close}
                className="image"
                onClick={() => {
                  props.setImagePreview(false);
                  setPlayed(0)
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className="bottom-conatiner">
          <div className="arrow-container">
            {props.image.length >= 2 ? (
              <div
                className="arrow-div"
                onClick={() => plusDivs(-1)}
                ref={left}
              >
                <img alt="" src={next} className="arrow-style" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="image-container">
            {props.image.map((image, index) => {

              {
                if (getDocument(image.attachment_name).includes(".mp4")) {
                  return (

                    // <VideoPlayer />

                    // <div className="imagePrev">

                    /* <div className="video-player-wrapper"> */



                    <div className="imagePrev">
                      <div className="video-player-wrapper">

                        <ReactPlayer
                          ref={player}
                          className='video-player'
                          width='90%'
                          height='80vh'
                          url={image.attachment_link}
                          pip={pip}
                          playing={playing}
                          controls={controls}
                          light={light}
                          loop={loop}
                          playbackRate={playbackRate}
                          volume={volume}
                          muted={muted}
                          onPlay={handlePlay}
                          onEnablePIP={handleEnablePIP}
                          onDisablePIP={handleDisablePIP}
                          onPause={handlePause}
                          onBuffer={() => console.log('onBuffer')}
                          onPlaybackRateChange={handleOnPlaybackRateChange}
                          onSeek={e => console.log('onSeek', e)}
                          onEnded={handleEnded}
                          onError={e => console.log('onError', e)}
                          onProgress={handleProgress}
                          onDuration={handleDuration}
                        />

                        {/* <div className="video-Controler">
                          <div className="play-push" >
                            <img src={playIcon} alt="" onClick={() => {

                              handlePlayPause()
                              // console.log(videoControls)

                            }} />
                            <img src={pauseIcon} alt="" onClick={() => {

                              handlePlayPause()
                              // console.log(videoControls)

                            }} />
                          </div>
                          <div className="seeker">
                            <input
                              style={{ cursor: "pointer" }}
                              type='range' min={0} max={0.999999} step='any'
                              value={played}
                              onMouseDown={handleSeekMouseDown}
                              onChange={handleSeekChange}
                              onMouseUp={handleSeekMouseUp}
                            />
                          </div>
                          <div className="time-styles">30:00</div>
                          <div className="volume">
                            <img src={volumeIcon} alt="" />
                            <input
                              type='range' min={0} max={0.999999} step='any' className="contr"
                              value={volume} onChange={handleVolumeChange}
                              style={{ cursor: "pointer" }}

                            />
                          </div>
                          <div className="expand">
                            <img src={expand} alt="" />
                          </div>
                        </div> */}
                      </div>
                    </div>



                    // </div>
                  )
                } else if (image.attachment_name.includes(".mp3")) {
                  return (
                    <div className="imagePrev">

                      <div className="attachment">
                        <img
                          id="preview"
                          alt=""
                          key={index}
                          src={mic}
                          className="img-mic"
                        /></div>
                      <div className="audioWidth">
                        <AudioComponent audio={image.attachment_link} />
                      </div>
                    </div>)
                } else if (image.attachment_name.includes(".pdf")) {
                  return (
                    <div className="imagePrev" id="preview"
                      alt="">
                      <div className="filePrev">
                        <FilePreview
                          type='url'
                          url={image.attachment_link}
                          
                          />
                      </div>
                    </div>)
                } else {
                  return (
                    <img
                      id="preview"
                      alt=""
                      key={index}
                      src={getDocument(image.attachment_link)}
                      className="imagePrev"
                    />
                  );
                }
              }



            })}
          </div>
          <div className="arrow-container-right">
            {props.image.length >= 2 ? (
              <div
                className="arrow-div"
                onClick={() => plusDivs(1)}
                ref={right}
              >
                <img alt="" src={next} className="arrow-style" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {props.image.length >= 2 ? (
          <div className="image-list-container">



            {props.image.map((image) => {

              if (image.attachment_name.includes(".mp4")) {
                return (
                  <img
                    className="bottom-images"
                    alt=""
                    src={defaultVideo}
                    onClick={() => {
                      currentDiv(props.image.indexOf(image) + 1);
                    }}
                  />
                );

              } else if (image.attachment_name.includes(".mp3")) {
                return (
                  <div className="bottom-images">
                    <div className="attachment2">
                      <img
                        className="img-mic2"
                        alt=""
                        src={mic}
                        onClick={() => {
                          currentDiv(props.image.indexOf(image) + 1);
                        }}
                      />
                    </div>
                  </div>

                );

              } else {
                return (
                  <img
                    className="bottom-images"
                    alt=""
                    src={getDocument(image.attachment_link)}
                    onClick={() => {
                      currentDiv(props.image.indexOf(image) + 1);
                    }}
                  />
                );
              }


            })}
          </div>
        ) : (
          <span className="bottom-images" />
        )}
      </div>
    </div >
  );
};
export default ImagePreview;