import React, { useState } from "react";
import Cropper from "react-easy-crop";
import minus from "../../../Assets/Icons/minus-sign-2.png";
import plus from "../../../Assets/Icons/plus.png";
import camera from "../../../Assets/Icons/camera.png";
import tick from "../../../Assets/Icons/tickNew.png";

import reupload from "../../../Assets/Icons/reload.png";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "../../../pages/Auth/utils/cropImage";
import AppContext from "../../../ContextAPI/AppContext";
import "./UploadDPModalStyles.scss";
import CloseIcon from "../../Button/CloseIcon/CloseIcon";
const UploadDpModal = (props) => {
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const resizeImage = (base64Str, maxWidth = 300, maxHeight = 300) => {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement('canvas')
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }
  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="_UploadDPModalStyles">
          <div className="modal-1">
            <div className="modal-content-new">
              <div className="modal-content-2">
                <div className="left">
                  <div className="camera">
                    <img alt="" src={camera} className="img" />
                  </div>
                  <div className="popup-div1-h1">Change Profile Picture</div>
                </div>

                <div className="right">
                  <div className="reupload" onClick={props.onImageClick}>
                    <img alt="" src={reupload} className="img" />
                    {context.windowDimensions.width <= 600 ? "" : "Reupload"}
                  </div>

                  <div style={{ marginTop: '16px' }}>
                    <CloseIcon
                      size='m'
                      onClick={() => {
                        props.setAddPhoto(false);
                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="inner-container-3">
                {props.imagePreviewModal ? (
                  <div className="cropper">
                    <Cropper
                      image={props.imagePreviewModal}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      cropShape="round"
                      showGrid={false}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : null}

                <div
                  style={{
                    display: "flex",
                    marginLeft: "7%",
                  }}
                >
                  <div className="minus-container">
                    <img alt="" src={minus} className="img" />
                  </div>
                  <div>
                    <Slider
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                  <div className="plus-container">
                    <img alt="" src={plus} className="img" />
                  </div>
                </div>
              </div>
              <div
                className="circle"
                onClick={async () => {
                  let newestImage = "";
                  if (props.relatedComponent === "settings") {
                    document.querySelector(".__SettingsWrapper").style = "display : flex"
                  }
                  // props.setProPicUploaded(true); ///???
                  try {

                    const croppedImage = await getCroppedImg(
                      props.imagePreviewModal,
                      croppedArea
                    );
                    newestImage = croppedImage.toDataURL();
                    let img = await resizeImage(newestImage)

                    props.setNewCroppedImage(img);

                    if (img !== "") {
                      if (props.relatedComponent === "groupImage") {
                        context.updateGroupDP(img);
                        props.setAddPhoto(false);
                      } else if (props.relatedComponent === "settings") {
                        context.updateUserDP(img)
                        props.setAddPhoto(false);
                      }
                    }

                  } catch (e) {
                    console.error(e);
                  }

                }}
              >
                <img alt="" src={tick} style={{ height: 20, width: 25 }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default UploadDpModal;
