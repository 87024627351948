import React, { useState, useRef, useContext, useEffect } from "react"
import AppContext from "../../../ContextAPI/AppContext";
import image from "../../../Assets/Icons/logout-new.png";
import close from "../../../Assets/Icons/cross-square-button.svg";
import googleCalender from "../../../Assets/Icons/google-calendar.svg";
import tick from "../../../Assets/Icons/tick.svg";
import clear from "../../../Assets/Icons/close-black.svg";
import "./AppIntregrationModalStyles.scss";
import { getDPService } from "../../../services/DPService";
import next from "../../../Assets/Icons/next.png";
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script'


const GoggleCalenderOptions = (props) => {
    const conText = useContext(AppContext)

    const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
    var SCOPES = "https://www.googleapis.com/auth/calendar"
    var API_KEY = "AIzaSyCDOQpUzBGUj0GztD0_22BiI4AoSvd43OE"
    var clientId = "364576970381-vndtfgdm2oubeut2jt2i0uahhbcdlc74.apps.googleusercontent.com"
    // useEffect(() => {
    //     gapi.load('client:auth2', () => {
    //         gapi.auth2.init({ clientId: clientId })
    //     })

    // }, [])


    const responseGoogle = (response) => {
        conText?.sendGoogleId(
            response.code)
        console.log("working")


    }



    console.log(conText.googleAccessKey, 'klklkl')





    console.log(conText.googleId, "gId")
    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_GoogleCalenderOptions" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
                    <div id="myModal" className="modal-1">
                        <div className="modal-content-top" >
                            <div className="modal-content-add-admin">



                                <div className="add-header">Google Calendar</div>
                                <img alt="" src={close} className="close"
                                    onClick={() => {
                                        context?.setGoogleCalenderOptionModal(false)
                                    }} />

                            </div>



                            {localStorage.getItem("google_accessToken")=== ""  ?

                            <GoogleLogin
                                clientId={clientId}
                                buttonText="Sign in with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                responseType="code"
                                accessType="offline"
                                scope={SCOPES}
                                className="rounded-circle"

                            />


                            :
                            <div className="inner-container">
                                <div className="search-container" onClick={() => {
                                    context?.setGoogleCalenderInputModal(true)
                                    context?.setGoogleCalenderOptionModal(false)

                                }}>

                                    <div style={{ display: "flex", width: "90%" }} >

                                        <img alt="" src={googleCalender} className="google-calender-img"
                                        />

                                        <div className="div-name" >


                                            <div className="name">
                                                Create Event
                                            </div>
                                            <div className="email">Create Event</div>
                                        </div>






                                    </div>

                                </div>
                            </div>

                            }






                        </div>




                    </div>
                </div>
            )}</AppContext.Consumer>
    )
}
export default GoggleCalenderOptions