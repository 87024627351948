import config from "../config"
import CookieService from "./CookieService"
export const logoutService = () => {

    let users = []
    const logoutRedirection = () => {
        users = CookieService.get("users")
        const options = { path: "/", domain: config.cookieDomain }
        if (users) {
            console.log(users, "users");
            if (users.length === 1) {
                console.log("loooog");
                CookieService.remove("users", options)
                let url = `${config.apiEndpoints.protocol}${config.domain.auth}${config.emailText}`;
                window.location.href = url;
            } else if (users.length > 1) {
                let userList = users.filter(user => {
                    return user.isLogged === false
                })
                userList[0].isLogged = true
                CookieService.set("users", users, options)
            }
        }
    }
    return {
        logoutRedirection
    }
}