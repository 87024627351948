import React, { useState, useRef, useContext } from "react";
import AppContext from "../../../ContextAPI/AppContext";
import "../RightSidebar/RightSideBarStyle.scss";
import edit from "../../../Assets/Icons/edit.svg";
import cam from "../../../Assets/Icons/cam.svg";
import tick from "../../../Assets/Icons/tick.svg";
import add from "../../../Assets/Icons/add-3.svg";
import next from "../../../Assets/Icons/next.png";
import minusCircle from "../../../Assets/Icons/minus-circle.svg";
import profileRemove from "../../../Assets/Icons/profile-remove.svg";
import userSqaure from "../../../Assets/Icons/user-square.svg";
import config from "../../../config"
import Resizer from "react-image-file-resizer";
import UserDetailsBar from "./UserDetailsBar";
import DetailsContainer from "./DetailsContainer";
import MembersComponent from "./MembersComponent";
import Avatar from '../../../Components/Avatar/avatar'
import CloseIcon from "../../../Components/Button/CloseIcon/CloseIcon";
import Feature from "../../../Feature/Feature";
import LeftArrow from "../../../Assets/Icons/arrow-grey.svg";
import SaveContactRight from "../SaveToContact/SaveToContact-Right";
import moment from "moment";
import { getDPService } from "../../../services/DPService";

import upload from "../../../Assets/Icons/upload.png";

const RightSideBar = (props) => {
    const newContext = useContext(AppContext)



    const [editName, setEditName] = useState(true);
    const [editGroupEmail, setEditGroupEmail] = useState(true);
    const [groupName, setGroupName] = useState(
        props.selectedChat._cls === "ChatRoom.GroupChatRoom"
            ? props.selectedChat.group_title
            : ""
    );

    const [groupEmail, setGroupEmail] = useState(
        props.selectedChat._cls === "ChatRoom.GroupChatRoom"
            ? props.selectedChat.group_email
            : ""
    );
    const [groupDescription, setGroupDescription] = useState(
        props.selectedChat._cls === "ChatRoom.GroupChatRoom"
            ? props.selectedChat.group_description
            : ""
    );

    const groupEmailName = useRef(null);
    const name = useRef(null);
    const [viewMembers, setViewMembers] = useState(false);
    const description = useRef(null);
    const [editDes, setEditDes] = useState(true);
    const [removePopup, setRemovePopup] = useState("");
    const [selectedMember, setSelectedMember] = useState("")
    const contextValue = useContext(AppContext)
    const [editButtonActive, setEditButtonActive] = useState(false);
    const [editDesButtonActive, setEditDesButtonActive] = useState(false);

    // const message = newContext?.selectedChatroomDatas?.threads !== undefined ? newContext?.selectedChatroomDatas?.threads : {}


    const handleProPic = (event) => {
        const reader = new FileReader();

        reader.onload = function () {
            props.setImagePreviewModal(reader.result);

            props.setAddPhoto(true);

        };

        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }

    };

    const labelledMessages = () => {
        return (
            <div className="labeled-message-wrapper">
                <div className="header">Labelled Messages</div>

                <img
                    src={next}
                    alt=""
                    style={{
                        height: "14px",
                        width: "14px",
                        alignSelf: "center",
                    }}
                />
            </div>

        )
    }



    const resizeFile = (newcroppedImage) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                newcroppedImage,

                300,

                300,

                "PNG",

                100,

                0,

                (uri) => {
                    resolve(uri);
                },

                "base64"
            );

        });

    const onChange = async (event) => {
        await resizeFile(event.target.files[0]);

    };
    const renderContent = (chatroom) => {
        if (newContext?.contentToShow === "All") {
            return (
                <div>{chatroom._cls ===
                    "ChatRoom.PrivateChatRoom" ||
                    chatroom._cls ===
                    "ChatRoom.EmailChatRoom" ? (
                    ""
                ) : (
                    ""
                )}

                    <Feature name='labelled-messages' activeComponent={labelledMessages()} inactivecomponent={null} />

                    <div>
                        <DetailsContainer
                            setImagePreview={props.setImagePreview}
                            setImage={props.setImage}
                            setCurrentImage={props.setCurrentImage}
                            attachments={props.attachments} />
                    </div>
                </div>
            )
        }
        else if (newContext?.contentToShow === "details") {
            return (
                <div className="details-container">
                    <div className="deatils-header">Email -</div>
                    <div className="details-content">
                        {chatroom?._cls === "ChatRoom.EmailChatRoom" ? <div className="em">{chatroom?.profile_email}</div> :
                            <div>
                                {chatroom.members_list?.map((value, i) => {
                                    if (value?.public_id !== localStorage?.getItem(
                                        "bixChat_loggedUserPublicId")) {
                                        return (
                                            <div key={i} className="em">
                                                {value?.email}
                                            </div>)
                                    }
                                    return ""
                                })}</div>}

                    </div>



                    {newContext?.messageInfo === "" ? ""
                        :

                        <div>

                            <div className="deatils-header">Time -</div>

                            <div className="details-content">
                                {moment().format("L") ===
                                    moment(newContext?.messageInfo?.timestamp["$date"]).format("L")
                                    ? ` Today ${moment(
                                        newContext?.messageInfo?.timestamp["$date"]
                                    ).format("LT")}`
                                    : moment(newContext?.messageInfo?.timestamp["$date"]).format(
                                        "LLL"
                                    )}
                            </div>
                        </div>
                    }





                    {newContext?.messageInfo?.message_subject === "" ?

                        ""
                        :

                        <div>
                            <div className="deatils-header">Subject -</div>

                            <div className="details-content">
                                {newContext?.messageInfo?.message_subject}
                            </div>

                        </div>

                    }


                    {contextValue.ccMessage === "Message.CCMessage" ?
                        <div>

                            <div>
                                <div className="deatils-header">To -</div>

                                <div className="details-content">
                                    {newContext?.toList}


                                </div>
                            </div>


                            <div>
                                <div className="deatils-header">Cc -</div>

                                <div className="details-content">
                                    {newContext?.ccList}


                                </div>
                            </div>



                        </div>

                        : ""}





                    {chatroom._cls === "ChatRoom.EmailChatRoom" ? "" : (<div> <div className="deatils-header">Address -</div>
                        <div className="details-content">
                            {chatroom.members_list.map((value, i) => {
                                if (value.public_id !== localStorage.getItem(
                                    "bixChat_loggedUserPublicId")) { return (<div key={i}>{value.address}</div>) }
                            })}</div></div>)}


                    <div>
                        <div className="deatils-header">{newContext?.messageSeenList?.length > 0 ? "Read By" : ""}</div>

                        <div className="seen-list">
                            {newContext?.messageSeenList?.map((messageVal, i) => {
                                return (


                                    <div key={i}>
                                        <div className="seen-main">
                                            <img
                                                className="seen-img"
                                                src={
                                                    getDPService()?.getDP("ChatRoom.PrivateChatRoom", messageVal?.public_id)
                                                        ? getDPService()?.getDP("ChatRoom.PrivateChatRoom", messageVal?.public_id)
                                                        : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                                }
                                                alt=""


                                            />
                                            <div className="div-name" >

                                                <div className="seen-name">   {messageVal.public_id ===
                                                    localStorage.getItem(
                                                        "bixChat_loggedUserPublicId"
                                                    )
                                                    ? "You"
                                                    : messageVal.full_name}</div>
                                            </div>


                                        </div>

                                    </div>)

                            })}

                        </div>

                        <div className="deatils-header">{newContext?.forwardedList?.length > 0 ? "Forwarded Message" : ""}</div>
                        <div style={{ width: "90%", fontSize: "12px", fontFamily: "Roboto", color: "#0d2a4c" }}>
                            {newContext?.forwardedList?.map((messageInfo, i) => {
                                return (


                                    <div key={i}>
                                        <div className="seen-main">
                                            <div className="div-name" style={{ marginBottom: "20px", display: "flex" }} >
                                                <div>
                                                    <img alt="" src={upload} style={{ height: "11px", width: "11px", marginRight: "6px" }} />
                                                </div>
                                                <div>
                                                    <div className="seen-name">From<span style={{ marginLeft: "14px" }} />- {messageInfo.from_email}</div>
                                                    <div className="seen-name">Date<span style={{ marginLeft: "17px" }} />- 	{moment(
                                                        messageInfo.timestamp["$date"]
                                                    ).format("LLL")}</div>
                                                    <div className="seen-name" style={{ display: "flex" }}><div>Sent to<span style={{ marginLeft: "4px" }} />-</div> <div style={{ marginLeft: "3px" }}> {messageInfo.to.map((to) => {
                                                        return (
                                                            <span style={{ cursor: "pointer" }} onClick={(() => {
                                                                if (!props.sendMessageModal) {
                                                                    if (to.email !== "" && to.email !== null && to.email !== "null") {
                                                                        props.setSendMessageModal(true);
                                                                        newContext.setSendMessageType("multiUser");
                                                                        props.setRec(to.email)
                                                                    }
                                                                }
                                                            })}>{to.name}<br />{to.email && to.email !== "" && to.email !== null && to.email !== "null" ? <span>{"<"}{to.email}{">"}</span> : ""}<br /></span>
                                                        )
                                                    })}</div></div>
                                                </div>

                                            </div>


                                        </div>

                                    </div>)

                            })}

                        </div>



                    </div>
                    {chatroom.group_title ?
                        ""
                        :
                        !contextValue.isContact ?

                            contextValue.isBlocked ?
                                contextValue.isBlocker ?
                                    <div className="block-btn"
                                        onClick={() => {
                                            contextValue.blockContact(localStorage.getItem("bixChat_selectedChatId"), "unblock", true)
                                            contextValue.setIsBlocked(false)

                                        }}
                                    >Unblock<div className="blck-name"> {chatroom._cls === "ChatRoom.EmailChatRoom" ? contextValue?.selectedChatroomDatas?.chatroomData?.profile_name : contextValue?.selectedChatroomDatas?.chatroomData?.chatroom_name.split(' ')[0]}</div></div>
                                    : ''
                                :
                                (
                                    contextValue?.selectedChatroomDatas?.chatroomData?.profile_email === 'support@bixchat.xyz' ? '' :
                                        <div className="block-btn"
                                            onClick={() => {
                                                // contextValue.blockContact(localStorage.getItem("bixChat_selectedChatId"),"block")
                                                contextValue.setBlockConfirm(true)

                                            }}
                                        >Block <div className="blck-name">{chatroom._cls === "ChatRoom.EmailChatRoom" ? contextValue?.selectedChatroomDatas?.chatroomData?.profile_name : contextValue?.selectedChatroomDatas?.chatroomData?.chatroom_name.split(' ')[0]}</div></div>
                                )
                            :


                            <div className="ed-btn-container">
                                <div className="edit-btn" onClick={() => {
                                    contextValue.setIsEdit(true);
                                    contextValue.setContactModal(true);
                                    // props.setRightSideBarStatus(false);
                                }}>Edit Contact</div>
                                <div className="delete-btn"
                                    onClick={() => {
                                        contextValue.deleteContact()
                                        contextValue.setContactModal(false)
                                    }}
                                >Delete Contact</div>

                                {contextValue.isBlocked ?
                                    contextValue.isBlocker ?
                                        <div className="block-btn"
                                            onClick={() => {
                                                contextValue.blockContact(localStorage.getItem("bixChat_selectedChatId"), 'unblock', true)
                                            }}
                                        >

                                            Unblock {contextValue?.selectedChatroomDatas?.chatroomData?.contact.name.split(' ')[0]}</div>
                                        : ''
                                    :
                                    <div className="block-btn"
                                        onClick={() => {
                                            // contextValue.blockContact(localStorage.getItem("bixChat_selectedChatId"),'block')
                                            contextValue.setBlockConfirm(true)
                                        }}
                                    >Block {contextValue?.selectedChatroomDatas?.chatroomData?.contact.name.split(' ')[0]}</div>
                                }


                            </div>

                    }







                </div>


            )
        }

    }

    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="__RightSideBarWrapper"
                    onClick={() => {
                        context.leftNavPopupHandler("closeOnly")
                        context.RightNavPopupHandler("closeOnly")
                        context.UploadModalHandler("closeOnly")
                        context.setShowReactionTab(false)




                    }
                    }>

                    {context.contactModal ? <SaveContactRight setRightSideBarStatus={context.setRightSideBarStatus}
                    />

                        :

                        <div>
                            <div className="top-navbar">
                                <div
                                    style={{ alignSelf: "center" }}
                                    onClick={() => {
                                        if (context?.setttingsTab && selectedMember === "") {
                                            context?.setSettingsTab(false);
                                        } if (context?.setttingsTab && selectedMember !== "") {
                                            setSelectedMember("")
                                            context?.setSettingsTab(true);
                                        } else if (!context?.setttingsTab && selectedMember === "") {
                                            if (context?.contentToShow === "All") {
                                                props.setRightSideBarStatus(false);
                                            } else {
                                                context?.setContentToShow("All")
                                            }

                                        }
                                    }}
                                >
                                    {context?.setttingsTab ? (
                                        <img src={LeftArrow} alt="close" className="close" />
                                    ) : context?.contentToShow === "All" ? (
                                        window.screen.width < 600 ?
                                            <div style={{ marginTop: '-3px' }} className="overview-header">
                                                <CloseIcon
                                                    onClick={context.setCcMessage("")
                                                    }
                                                    size='l'
                                                />
                                            </div> : ""
                                    ) :
                                        <img src={LeftArrow} alt="close" className="close" />
                                    }
                                </div>

                                <div className="topic">
                                    {context?.setttingsTab
                                        ? context?.selectedChatroomDatas?.chatroomData?._cls ===
                                            "ChatRoom.GroupChatRoom"
                                            ? "Group Settings"
                                            : ""
                                        : context?.selectedChatroomDatas?.chatroomData?._cls ===
                                            "ChatRoom.GroupChatRoom"
                                            ? ""
                                            : context?.contentToShow === "All" ? "" : "Details"}
                                </div>
                                {context?.selectedChatroomDatas?.chatroomData?._cls ===
                                    "ChatRoom.GroupChatRoom" && !context?.setttingsTab ? (

                                    <div style={{ alignSelf: "center", marginTop: "-20px" }}>
                                        <div className={`details-header-${context.fontSizeType}`}
                                            onClick={() => {
                                                if (
                                                    context?.selectedChatroomDatas?.chatroomData?._cls ===
                                                    "ChatRoom.GroupChatRoom"
                                                ) {
                                                    context?.setSettingsTab(true);
                                                }
                                            }}>
                                            Details
                                        </div>
                                    </div>
                                ) : <div>

                                    {context?.selectedChatroomDatas?.chatroomData?._cls !==
                                        "ChatRoom.GroupChatRoom" && !context?.setttingsTab?.setttingsTab && context?.contentToShow === "All" ? (
                                        <div className={`details-header-${context.fontSizeType}`}
                                            onClick={() => { context?.setContentToShow("details") }}>
                                            Details
                                        </div>) : ""}
                                </div>
                                }


                            </div>
                            {context?.setttingsTab && selectedMember === "" &&
                                context?.selectedChatroomDatas?.chatroomData?._cls ===
                                "ChatRoom.GroupChatRoom" ? (
                                <div className="body-wrapper">
                                    <div className="body-container">
                                        <div className="profile-wrapper">
                                            <div>
                                                {!props?.chatObject?.is_deleted ? !props?.chatObject?.is_removed ? props?.chatObject?.is_admin || props?.chatObject?.is_moderator ? <div className="edit-icon" onClick={props.onImageClick}>
                                                    <img className="edit-image" src={cam} alt="edit" />
                                                    <input
                                                        onChange={(e) => {
                                                            handleProPic(e);
                                                            onChange(e);
                                                        }}
                                                        style={{ display: "none" }}
                                                        ref={props.uploadImage}
                                                        type="file"
                                                        accept="image/*"
                                                        className="hidden"
                                                    />
                                                </div> : "" : "" : ""}

                                                {context?.selectedChatroomDatas?.chatroomData?._cls ===
                                                    "ChatRoom.GroupChatRoom" ? (
                                                    getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"]) &&
                                                        getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"]) !== "null" ? (
                                                        <div style={{ marginTop: '-90px' }}>
                                                            <Avatar
                                                                size='xxl'
                                                                src={
                                                                    props.proPicUploaded
                                                                        ? props.newcroppedImage
                                                                        : getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"])
                                                                }
                                                                onClick={() => {
                                                                    props.setImagePreview(true);
                                                                    props.setCurrentImage(1);
                                                                    let image = {
                                                                        attachment_name:
                                                                            context?.selectedChatroomDatas?.chatroomData
                                                                                ?.group_title,
                                                                        attachment_link:
                                                                            getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"]),
                                                                    };
                                                                    let array = [];
                                                                    array.push(image);
                                                                    props.setImage(array);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Avatar
                                                            size='xxl'
                                                            src={
                                                                props.proPicUploaded
                                                                    ? props.newcroppedImage
                                                                    : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/group_dp.png"
                                                            }
                                                        />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="name-edit">
                                                <div className="header-name">Group Name</div>

                                                <div className="edit" >
                                                    <input
                                                        value={groupName}
                                                        type="text"
                                                        className="input"
                                                        readOnly={editName}
                                                        ref={name}
                                                        placeholder={context?.selectedChatroomDatas?.chatroomData?.group_title ?
                                                            context?.selectedChatroomDatas?.chatroomData?.group_title :

                                                            (context.isContact ?
                                                                context?.selectedChatroomDatas?.chatroomData?.contact?.name :
                                                                context?.selectedChatroomDatas?.chatroomData?.profile_name)}
                                                        onChange={(e) => {
                                                            setGroupName(e.target.value);
                                                            setEditButtonActive(true)
                                                        }}
                                                    />
                                                    {!editButtonActive ?

                                                        (
                                                            <img
                                                                src={edit}
                                                                className="edit-img"
                                                                onClick={() => {
                                                                    setEditName(false);
                                                                    name.current.focus();

                                                                }}
                                                                alt="edit"
                                                            />
                                                        )

                                                        :

                                                        (
                                                            <div className="tick-container">
                                                                {context?.isGroupNameChanging ?
                                                                    <div class="loader" /> :

                                                                    <img
                                                                        src={tick}
                                                                        className="tick"
                                                                        onClick={() => {

                                                                            setEditName(true);
                                                                            context?.setIsGroupNameChanging(true)
                                                                            context?.updateGroupName(
                                                                                context?.selectedChatroomDatas?.chatroomData
                                                                                    ?._id["$oid"],
                                                                                groupName
                                                                            );
                                                                            setEditButtonActive(false)


                                                                        }}
                                                                        alt=""
                                                                    />}

                                                            </div>
                                                        )

                                                    }

                                                </div>
                                            </div>
                                            <div className="name-edit">
                                                <div className="header-name">Group Description</div>
                                                <div className="edit" >
                                                    <textarea
                                                        value={groupDescription}
                                                        type="text"
                                                        className="textarea"
                                                        readOnly={editDes}
                                                        ref={description}
                                                        placeholder={context?.selectedChatroomDatas?.chatroomData
                                                            ?.group_description === "" ? "No description" : context?.selectedChatroomDatas?.chatroomData?.group_description ?
                                                            context?.selectedChatroomDatas?.chatroomData?.group_description :

                                                            context?.selectedChatroomDatas?.chatroomData?.group_description}
                                                        onChange={(e) => {
                                                            setGroupDescription(e.target.value);
                                                            setEditDesButtonActive(true)

                                                        }}
                                                    />

                                                    {!editDesButtonActive ?

                                                        (
                                                            <img
                                                                src={edit}
                                                                className="edit-img"
                                                                onClick={() => {
                                                                    setEditDes(false);

                                                                    description.current.focus();


                                                                }}
                                                                alt="edit"
                                                            />
                                                        )

                                                        :
                                                        (
                                                            <div className="tick-container">
                                                                {context?.isGroupDescriptionChanging ?
                                                                    <div class="loader" /> :
                                                                    <img
                                                                        src={tick}
                                                                        className="tick"
                                                                        onClick={() => {

                                                                            setEditDes(true);
                                                                            context?.setIsGroupDescriptionChanging(true)
                                                                            context?.updateGroupDescription(
                                                                                context?.selectedChatroomDatas?.chatroomData
                                                                                    ?._id["$oid"],
                                                                                groupDescription
                                                                            );


                                                                            setEditDesButtonActive(false)

                                                                        }}
                                                                        alt="tick"
                                                                    />}
                                                            </div>
                                                        )

                                                    }
                                                </div>
                                            </div>

                                            <div className="name-edit">
                                                <div className="header-name">Group Email</div>


                                                <div className="edit" >
                                                    <input
                                                        value={groupEmail}
                                                        type="text"
                                                        className="input"
                                                        readOnly={editGroupEmail}
                                                        ref={groupEmailName}
                                                        placeholder={context?.selectedChatroomDatas?.chatroomData.group_email}
                                                        onChange={(e) => {
                                                            setGroupEmail(e.target.value);
                                                            setEditButtonActive(true)
                                                        }}
                                                    />
                                                    {!editButtonActive ?

                                                        (
                                                            <img
                                                                src={edit}
                                                                className="edit-img"
                                                                onClick={() => {
                                                                    setEditGroupEmail(false);
                                                                    groupEmailName.current.focus();

                                                                }}
                                                                alt="edit"
                                                            />
                                                        )

                                                        :

                                                        (
                                                            <div className="tick-container">
                                                                {context?.isGroupEmailChanging ?
                                                                    <div class="loader" /> :

                                                                    <img
                                                                        src={tick}
                                                                        className="tick"
                                                                        onClick={() => {

                                                                            setEditGroupEmail(true);
                                                                            context?.setIsGroupEmailChanging(true)
                                                                            context?.updateGroupEmail(
                                                                                context?.selectedChatroomDatas?.chatroomData
                                                                                    ?._id["$oid"],
                                                                                groupEmail

                                                                            );
                                                                            setEditButtonActive(false)


                                                                        }}
                                                                        alt=""
                                                                    />}

                                                            </div>
                                                        )

                                                    }

                                                </div>

                                            </div>
                                            <div className="header-name">Group Members</div>
                                            {!props?.chatObject?.is_deleted ? !props?.chatObject?.is_removed ? props?.chatObject?._admin || props?.chatObject?.is_moderator ? <div
                                                className="add-members"
                                                onClick={() => {
                                                    props.setNewGroupModal1(true);
                                                    props.setAddMembersFrom("Right");
                                                }}
                                            >

                                                <div className="circle-add">
                                                    <img src={add} className="add" alt="add" />
                                                </div>
                                                <div className="add-members-text">Add Members</div>
                                            </div> : "" : "" : ""}

                                            <div>
                                                {viewMembers ? (
                                                    <div>
                                                        {context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                                                            (value, i) => {
                                                                return (
                                                                    <div className="members" key={i}>
                                                                        <MembersComponent
                                                                            onAvatarClick={() => {
                                                                                props.setImagePreview(true);
                                                                                props.setCurrentImage(1);
                                                                                let image = {
                                                                                    attachment_name: value?.full_name,
                                                                                    attachment_link:
                                                                                        getDPService().getDP(value?._cls, value.profile_public_id)
                                                                                            ? getDPService().getDP(value?._cls, value.profile_public_id)
                                                                                            : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"

                                                                                };
                                                                                let array = [];
                                                                                array.push(image);
                                                                                props.setImage(array);
                                                                            }}

                                                                            isAdmin={value?.is_admin}
                                                                            value={value}
                                                                            isModerator={value?.is_moderator}
                                                                            isDeleted={props?.chatObject?.is_deleted}
                                                                            isRemoved={props?.chatObject?.is_removed}
                                                                            onlineList={props?.onlineList}
                                                                            onMemberClick={() => {
                                                                                if (value.public_id !== localStorage?.getItem(
                                                                                    "bixChat_loggedUserPublicId"
                                                                                )) {
                                                                                    setSelectedMember(value)
                                                                                }
                                                                            }}

                                                                        />


                                                                        {/* {!props?.chatObject?.is_deleted ? !props?.chatObject?.is_removed ? value?.public_id !==
                                                                            localStorage.getItem(
                                                                                "bixChat_loggedUserPublicId"
                                                                            ) ? props?.onlineList !== undefined ? props?.onlineList?.filter((item) => (item?.publicID === value?.public_id)
                                                                        )[0]?.state === "online" ? <div className="online" /> : "" : "" : "" : "" : ""} */}


                                                                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                                                                            {!props?.chatObject?.is_deleted ? !props?.chatObject?.is_removed ? props?.chatObject?.is_admin || props?.chatObject?.is_moderator ? !value.is_admin ? value?.public_id !==
                                                                                localStorage.getItem(
                                                                                    "bixChat_loggedUserPublicId"
                                                                                ) ? (
                                                                                context?.isLoading && context?.selectedUser === value?.public_id ? <div className="loader-blue" /> :
                                                                                    <img
                                                                                        src={next}
                                                                                        className="arrow-right"
                                                                                        onClick={() => {
                                                                                            if (removePopup === "") {
                                                                                                setRemovePopup(value?.public_id);
                                                                                            } else {
                                                                                                setRemovePopup("");
                                                                                            }

                                                                                        }}
                                                                                        alt="arrow"
                                                                                    />
                                                                            ) : (
                                                                                ""
                                                                            ) : "" : "" : "" : ""}</div>
                                                                        <div
                                                                            className="remove-popup"
                                                                            style={{
                                                                                display:
                                                                                    value.public_id === removePopup
                                                                                        ? "block"
                                                                                        : "none",
                                                                            }}
                                                                        >
                                                                            <div className="popup-item" onClick={() => {
                                                                                context.setCenterModalVisibility(true)
                                                                                props.setModalHeader("Remove Member");
                                                                                props.setModalDescription(
                                                                                    "Do you want to remove this member?"
                                                                                );
                                                                                props.setModalWarnig("")
                                                                                props.setModalFunction("removeMember")
                                                                                localStorage.setItem("removeChatId", value?.public_id)
                                                                                setRemovePopup("");
                                                                            }}>
                                                                                <img
                                                                                    src={minusCircle}
                                                                                    className="icon-item"
                                                                                    alt="remove"
                                                                                />
                                                                                Remove
                                                                            </div>
                                                                            {value?.is_moderator ? <div className="popup-item"
                                                                                onClick={() => {
                                                                                    context?.setSelectedUser(value?.public_id)
                                                                                    context?.setIsLoading(true)
                                                                                    let chatroom_id = localStorage.getItem("bixChat_selectedChatId")
                                                                                    context?.removeModerator(chatroom_id, value?.public_id)
                                                                                    setRemovePopup("");
                                                                                }}>
                                                                                <img
                                                                                    src={profileRemove}
                                                                                    className="icon-item"
                                                                                    alt="remove"

                                                                                />
                                                                                Remove Group Moderator
                                                                            </div> : <div className="popup-item" onClick={() => {
                                                                                context?.setSelectedUser(value?.public_id)

                                                                                context?.setIsLoading(true)
                                                                                let chatroom_id = localStorage.getItem("bixChat_selectedChatId")
                                                                                context?.addModerator(chatroom_id, value?.public_id)
                                                                                setRemovePopup("");
                                                                            }}>
                                                                                <img
                                                                                    src={userSqaure}
                                                                                    className="icon-item"
                                                                                    alt=""


                                                                                />
                                                                                Make Group Moderator
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                        <div
                                                            className="view-members"
                                                            onClick={() => setViewMembers(false)}
                                                        >
                                                            <div className="circle-add">
                                                                <div className="text-minus">-</div>
                                                            </div>
                                                            <div className="more-text">Minimize</div>
                                                            <img src={next} className="arrow-top" alt="" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {context?.selectedChatroomDatas?.chatroomData?.members_list
                                                            ?.slice(0, 4)
                                                            .map((value, i) => {
                                                                return (
                                                                    <div className="members" key={i}>
                                                                        <MembersComponent onAvatarClick={() => {
                                                                            props.setImagePreview(true);
                                                                            props.setCurrentImage(1);
                                                                            let image = {
                                                                                attachment_name: value?.full_name,
                                                                                attachment_link:
                                                                                    getDPService().getDP(value?._cls, value.profile_public_id)
                                                                                        ? getDPService().getDP(value?._cls, value.profile_public_id)
                                                                                        : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"

                                                                            };
                                                                            let array = [];
                                                                            array.push(image);
                                                                            props.setImage(array);
                                                                        }}
                                                                            isAdmin={value?.is_admin}
                                                                            value={value}
                                                                            isModerator={value?.is_moderator}
                                                                            isDeleted={props?.chatObject?.is_deleted}
                                                                            isRemoved={props?.chatObject?.is_removed}
                                                                            onlineList={props?.onlineList}
                                                                            onMemberClick={() => {
                                                                                if (value.public_id !== localStorage.getItem(
                                                                                    "bixChat_loggedUserPublicId"
                                                                                )) {
                                                                                    setSelectedMember(value)
                                                                                }
                                                                            }}

                                                                        />



                                                                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                                                                            {!props?.chatObject?.is_deleted ? !props?.chatObject?.is_removed ? props?.chatObject?.is_admin || props?.chatObject?.is_moderator ? !value.is_admin ? value.public_id !==
                                                                                localStorage.getItem(
                                                                                    "bixChat_loggedUserPublicId"
                                                                                ) ? (
                                                                                context?.isLoading && context?.selectedUser === value.public_id ? <div className="loader-blue" /> :
                                                                                    <img
                                                                                        src={next}
                                                                                        className="arrow-right"
                                                                                        onClick={() => {
                                                                                            if (removePopup === "") {
                                                                                                setRemovePopup(value.public_id);
                                                                                            } else {
                                                                                                setRemovePopup("");
                                                                                            }

                                                                                        }}
                                                                                        alt=""
                                                                                    />
                                                                            ) : (
                                                                                ""
                                                                            ) : "" : "" : "" : ""}</div>
                                                                        <div
                                                                            className="remove-popup"
                                                                            style={{
                                                                                display:
                                                                                    value.public_id === removePopup
                                                                                        ? "block"
                                                                                        : "none",
                                                                            }}
                                                                        >
                                                                            <div className="popup-item" onClick={() => {
                                                                                context.setCenterModalVisibility(true)
                                                                                props.setModalHeader("Remove Member");
                                                                                props.setModalDescription(
                                                                                    "Do you want to remove this member?"
                                                                                );
                                                                                props.setModalWarnig("")
                                                                                props.setModalFunction("removeMember")
                                                                                localStorage.setItem("removeChatId", value?.public_id)
                                                                                setRemovePopup("");
                                                                            }}>
                                                                                <img
                                                                                    src={minusCircle}
                                                                                    className="icon-item"
                                                                                    alt=""
                                                                                />
                                                                                Remove
                                                                            </div>
                                                                            {value?.is_moderator ? <div className="popup-item" onClick={() => {
                                                                                context?.setSelectedUser(value?.public_id)
                                                                                context?.setIsLoading(true)
                                                                                let chatroom_id = localStorage.getItem("bixChat_selectedChatId")
                                                                                context?.removeModerator(chatroom_id, value?.public_id)
                                                                                setRemovePopup("");
                                                                            }}>
                                                                                <img
                                                                                    src={profileRemove}
                                                                                    className="icon-item"
                                                                                    alt=""
                                                                                />
                                                                                Remove Group Moderator
                                                                            </div> : <div className="popup-item" onClick={() => {
                                                                                context?.setSelectedUser(value?.public_id)
                                                                                context?.setIsLoading(true)
                                                                                let chatroom_id = localStorage.getItem("bixChat_selectedChatId")
                                                                                context?.addModerator(chatroom_id, value?.public_id)
                                                                                setRemovePopup("");
                                                                            }}>
                                                                                <img
                                                                                    src={userSqaure}
                                                                                    className="icon-item"
                                                                                    alt=""

                                                                                />
                                                                                Make Group Moderator
                                                                            </div>}

                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        {context?.selectedChatroomDatas?.chatroomData
                                                            ?.members_list.length > 4 ? (
                                                            <div
                                                                className="view-members"
                                                                onClick={() => setViewMembers(true)}
                                                            >
                                                                <div className="circle-add">
                                                                    <div className="text">
                                                                        +
                                                                        {context?.selectedChatroomDatas?.chatroomData
                                                                            ?.members_list.length - 4}
                                                                    </div>
                                                                </div>
                                                                <div className="more-text">More</div>
                                                                <img src={next} className="arrow-more" alt="" />
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {!props?.chatObject?.is_deleted ? props?.chatObject?.is_removed ? "" : <div
                                                className="leave-group"
                                                onClick={() => {
                                                    context.setCenterModalVisibility(true)
                                                    props.setModalHeader("Leave Group");
                                                    props.setModalDescription(
                                                        "Do you want to leave this group?"
                                                    );
                                                    props.setModalWarnig("")
                                                    props.setModalFunction("leaveGroup")
                                                    if (props?.chatObject?.is_admin) {
                                                        props.setMembersList(context?.selectedChatroomDatas?.chatroomData?.members_list)
                                                    }

                                                }}
                                            >
                                                Leave Group
                                            </div> : ""}

                                            {!props?.chatObject?.is_deleted ? !props?.chatObject?.is_removed ? props?.chatObject?.is_admin ? <div
                                                className="delete-group"
                                                onClick={() => {
                                                    context.setCenterModalVisibility(true)
                                                    props.setModalHeader("Deactivate Group");
                                                    props.setModalDescription(
                                                        "Do you want to deactivate this group?"
                                                    );
                                                    props.setModalWarnig(
                                                        "no one can chat in this group"
                                                    );
                                                    props.setModalFunction("deleteGroup")

                                                }}
                                            >
                                                Deactivate Group
                                            </div> : "" : "" : ""}

                                        </div>
                                    </div>
                                </div>
                            ) : context?.setttingsTab && selectedMember !== "" ? (<div><div className="member-text">Group Members</div><div className="body-wrapper">
                                <div className="body-container">
                                    <UserDetailsBar
                                        member={selectedMember}
                                        setCurrentImage={props.setCurrentImage}
                                        setImage={props.setImage}
                                        onlineList={props?.onlineList}
                                        setRightSideBarStatus={props.setRightSideBarStatus} />


                                </div></div></div>) : (
                                <div className="body-wrapper">
                                    <div className="body-container">
                                        <div className="profile-wrapper">
                                            <div>
                                                {/* {context?.selectedChatroomDatas?.chatroomData?._cls ===
                                            "ChatRoom.PrivateChatRoom" ? (
                                            props.activeStatus !== "online" ? (
                                                <div className="offline-status" />
                                            ) : (
                                                <div className="online-status" />
                                                
                                            )
                                        ) : (
                                            ""
                                        )} */}
                                                {context?.selectedChatroomDatas?.chatroomData?._cls ===
                                                    "ChatRoom.GroupChatRoom" ? (
                                                    getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"]) &&
                                                        getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"]) !== "null" ? (

                                                        <div style={{ marginTop: '-100px' }}>
                                                            <Avatar
                                                                size='xxl'
                                                                src={
                                                                    getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"])
                                                                }
                                                                onClick={() => {
                                                                    props.setImagePreview(true);
                                                                    props.setCurrentImage(1);
                                                                    let image = {
                                                                        attachment_name:
                                                                            context?.selectedChatroomDatas?.chatroomData
                                                                                ?.group_title,
                                                                        attachment_link:
                                                                            getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"])
                                                                    };
                                                                    let array = [];
                                                                    array.push(image);
                                                                    props.setImage(array);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div style={{ marginTop: '-103px' }}>
                                                            <Avatar
                                                                size='xxl'
                                                                src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/group_dp.png"
                                                            />
                                                        </div>
                                                    )
                                                ) : context?.selectedChatroomDatas?.chatroomData?._cls ===
                                                    "ChatRoom.EmailChatRoom" ? (
                                                    context.isContact ? (
                                                        context?.selectedChatroomDatas?.chatroomData?.contact?.avatar !== '' ?
                                                            <div style={{ marginTop: '-103px' }}>
                                                                <Avatar
                                                                    size='xxl'
                                                                    src={context?.selectedChatroomDatas?.chatroomData?.contact?.avatar}
                                                                />
                                                            </div>
                                                            :
                                                            <div className="profile-picture-email">
                                                                {context?.selectedChatroomDatas?.chatroomData?.contact.name.charAt(0)}

                                                            </div>

                                                    )
                                                        :
                                                        <div className="profile-picture-email">
                                                            {context?.selectedChatroomDatas?.chatroomData?.email_receiver.charAt(
                                                                0
                                                            )}
                                                        </div>

                                                ) : (
                                                    getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id) === null || getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id) === "null" ? (

                                                        <div style={{ marginTop: '-100px' }}>
                                                            <Avatar
                                                                size='xxl'
                                                                src='https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png'
                                                                onClick={() => {
                                                                    props.setImagePreview(true);
                                                                    props.setCurrentImage(1);
                                                                    let image = {
                                                                        attachment_name: context?.selectedChatroomDatas?.chatroomData
                                                                            ?.profile_name,
                                                                        attachment_link: "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png",
                                                                    };
                                                                    let array = [];
                                                                    array.push(image);
                                                                    props.setImage(array);
                                                                }}
                                                            />

                                                        </div>
                                                    ) : (context.isContact ?
                                                        (<div style={{ marginTop: '-100px' }}>
                                                            <Avatar
                                                                size='xxl'
                                                                src={context?.selectedChatroomDatas?.chatroomData?.contact?.avatar ? context?.selectedChatroomDatas?.chatroomData?.contact?.avatar : getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id)}
                                                            />
                                                        </div>)
                                                        :
                                                        (<div style={{ marginTop: '-100px' }}>
                                                            <Avatar
                                                                size='xxl'
                                                                src={
                                                                    getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id)
                                                                }
                                                                onClick={() => {
                                                                    props.setImagePreview(true);
                                                                    props.setCurrentImage(1);
                                                                    let image = {
                                                                        attachment_name: context?.selectedChatroomDatas?.chatroomData
                                                                            ?.profile_name,
                                                                        attachment_link:
                                                                            getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id),
                                                                    };
                                                                    let array = [];
                                                                    array.push(image);
                                                                    props.setImage(array);
                                                                }}

                                                                showOnlineStatus={true}
                                                                isOnline={props.activeStatus}
                                                            />
                                                        </div>)
                                                    ))}
                                            </div>

                                            <div className="name">
                                                {context?.selectedChatroomDatas?.chatroomData?.group_title ?
                                                    context?.selectedChatroomDatas?.chatroomData?.group_title :

                                                    (context.isContact ?
                                                        context?.selectedChatroomDatas?.chatroomData?.contact?.name :
                                                        context?.selectedChatroomDatas?.chatroomData?.profile_name)}
                                            </div>


                                            {/* {context?.selectedChatroomDatas?.chatroomData?._cls ===
                                                "ChatRoom.GroupChatRoom" ? props?.onlineList !== undefined ? props?.onlineList?.length > 0 && props?.onlineList?.filter((item) => item.state === "online").length > 0 ? (<div className="online-list"><div className="online">{props?.onlineList?.filter((item) => item.state === "online").length} Online, </div><div className="all-members">{context?.selectedChatroomDatas?.chatroomData?.members_list.length} Members</div></div>) : "" : "" : ""} */}

                                            <div className="designation">
                                                {context?.selectedChatroomDatas?.chatroomData?._cls ===
                                                    "ChatRoom.PrivateChatRoom"
                                                    ? context?.selectedChatroomDatas?.chatroomData?.members_list.map(
                                                        (data, i) => {
                                                            if (
                                                                data.public_id !==
                                                                localStorage.getItem("bixChat_loggedUserPublicId")
                                                            ) {
                                                                return (
                                                                    <div className="org" key={i}>
                                                                        {data.headline !== ""
                                                                            ? data.headline
                                                                            : "ZapMail User"}
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    )
                                                    : ""}
                                                {context?.selectedChatroomDatas?.chatroomData?.group_title ?

                                                    ""
                                                    :
                                                    context?.isContact ? '' :
                                                        (
                                                            context?.selectedChatroomDatas?.chatroomData?.profile_email === `support${config?.emailProvider}` || context?.selectedChatroomDatas?.chatroomData?.profile_email.split('@')[1] === context?.loggedUserData?.domain ? '' :
                                                                <div>
                                                                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                                                        <div className="save-contact-border"
                                                                            onClick={() => {
                                                                                context.setContactModal(true);
                                                                                // props.setRightSideBarStatus(false);
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className="save-contact-btn"

                                                                            >
                                                                                Save Contact
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="report-spam">
                                                                        {/* Report as Spam */}
                                                                    </div>

                                                                </div>
                                                        )
                                                }

                                            </div>

                                            <div className="group-description">
                                                {context?.selectedChatroomDatas?.chatroomData?.group_description ?
                                                    context?.selectedChatroomDatas?.chatroomData?.group_description :

                                                    context?.selectedChatroomDatas?.chatroomData?.group_description}
                                            </div>



                                        </div>
                                        {context?.selectedChatroomDatas?.chatroomData ? renderContent(context?.selectedChatroomDatas?.chatroomData) : ""}

                                    </div>
                                </div>
                            )}

                        </div>}
                </div>
            )
            }
        </AppContext.Consumer >
    );
};

export default RightSideBar;