import React, {useContext} from "react";
import AppContext from "../../../ContextAPI/AppContext";
import Button from '../../../Components/Button/Button';

const ProfileSettings = (props) => {

    const conText = useContext(AppContext)

    return (
        <AppContext.Consumer>
            {context => (
                <div className="signature">
                <div className="signature-head">
                  {/* <img alt="" src={trash} /> */}
                  Edit Your Signature Here</div>
        
                <div dangerouslySetInnerHTML={{ __html: conText.signatureData }} />
        
        
        
        
                <div className="slidercontainer" >
                  <div>An email signature is text, such as your contact information or a favorite quote, that is automatically added as a footer at the end of Gmail messages.</div>
                </div>
        
        
                <Button buttonText="Add New Signature" onClick={props.onSignatureClick} />
              </div>
            )}
        </AppContext.Consumer>

    )
}


export default ProfileSettings