import React, {useContext } from "react";
import monitor from "../../Assets/Icons/monitor.svg";
import cloud from "../../Assets/Icons/vuesax-linear-cloud-add.svg";
import "./BixTextEditorStyle.scss"
import Feature from "../../Feature/Feature";
import AppContext from "../../ContextAPI/AppContext";




const UploadPopup = (props) => {


  const uploadFromCloud = () => {
    if (!props.isUploadFromCloud) {
      props.setIsUploadFromCloud(true);
    }
  }


  const uploadFromLocal = () => {
    if (!props.fromLocalStorage) {
      props.setFromLocalStorage(true)
    }
  }
  const conText = useContext(AppContext)


  const importBixCloud = () => {
    return (


      <div className="item"
        onClick={() => {
          conText?.setShowMainFolders(true)
          conText.setUpFolderId()
          conText.getSubFolderData();
          conText.getData();
          conText.setUpFolderId()
          uploadFromCloud()
          }}>
        <img alt="" src={cloud} className="img"
        />
        bixCloud
      </div>


    )
  }


  return (
    <AppContext.Consumer>
      {(context) =>

        <div
          className="pop-up box-style"
          style={{
            display: context.uploadImgModal,
          }}
        >

          <div
            className="item"
            onClick={props.onImageClick}
          >
            <img alt="" src={monitor} className="img" />
            Local Storage
          </div>

          <Feature name='import-from-bix-cloud' activeComponent={importBixCloud()} inactivecomponent={null} />






        </div>


      }
    </AppContext.Consumer>
  )


}
export default UploadPopup