import trash from "./Assets/Icons/trash-white.svg"
const getData = (type) => {
    let data = {}
    switch (type) {
        case "deleteAccount":
            data = {
                header: "Delete my account",
                primaryText: "Delete Account",
                secondaryText: "Cancel",
                icon: trash
            }
          break;

        default:
          break
    }
    return data
}
export default getData