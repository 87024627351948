import React, { forwardRef } from "react";
import "../SecondaryButton/SecondaryButtonStyles.scss";

const SecondaryButton = forwardRef((props, ref) => {
    return (
        <div
            ref={ref}
            className="secondary-styles"
            onClick={props.onClick}
            style={{
                width: props.width ? props.width : ""
            }}
        >
            {props.secondaryText}
        </div>
    );
});
export default SecondaryButton;
