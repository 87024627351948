import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../ContextAPI/AppContext";
import "./AdminBetaStyles.scss";
import AdminSteps from "../AdminSteps/AdminSteps";
import MobileSteps from "../AdminSteps/MobileSteps/MobileSteps";
import config from '../../../config'

const AdminBetaSuccess = (props) => {
    const passwordLinkBtn = useRef(null);
    const history = useHistory();


    return (
        <AppContext.Consumer>
            {(context) => (
                <div>
                    <div className="AdminBetaContainer">
                        <div className="AdminBetaInnerContainerRight">
                            <div className="wrapper2">
                                <div className="onboard-header-thanks">
                                    <div className="bixHeader">bixBusiness</div>
                                    <div className="header-bottom-text">Recommended for companies or industries.</div>
                                </div>
                                <div className="stp" style={{ marginTop: "16vh" }}>
                                    <AdminSteps step={5} />
                                </div>
                                <div className="stp-mobile">
                                    <MobileSteps step={5} />
                                </div>
                            </div>
                        </div>
                        <div className="AdminBetaInnerContainerLeft">
                            <div className="wrapper">
                                <div className="form-wrapper">
                                    <div className="welcome-div-1">
                                        <div className="thanks">Thank You</div>
                                        <div className="sb">Our team will contact you soon</div>



                                    </div>
                                    <div className="snd-button" onClick={() => {
                                        if (config.environment !== "development"){
                                            let url = `${config.apiEndpoints.protocol}${config.domain.auth}${config.emailText}`;
                                            window.location.href = url;
                                        } else {
                                            history.push({
                                                pathname: "/login",
                                            });
                                        }
                                    }}>
                                        Try bixPersonal
                                    </div>



                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </AppContext.Consumer>
    );
};
export default AdminBetaSuccess;
