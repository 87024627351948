import CookieService from "../../../../services/CookieService"
import { LoginHelper, setAccountType, setUsersToLocalStorage, setUsersToLocalStorageDev } from "../../AuthHelper"
import config from "../../../../config"
import { getMixPanel } from "../../../../MixPanel/MixPanel"
import Gleap from "gleap"
import axios from "axios"
export const loginService = () => {

    const expiresAt = 60 * 24
    let users = []
    const validateToken = (accessToken) => {
        return new Promise((resolve, reject) => {
            let token = `Bearer ${accessToken}`
            axios({
                method: "post",
                baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
                url: `validate`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": token
                },
            })
                .then((response) => {
                    if (response.data.public_id) {
                        resolve(response.data.public_id)
                    } else {
                        resolve("")
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    };
    const loginResponsHandler = async (data, setIsLogged, setLoginIsLoading, getProfile, getAllChatroom) => {

        if (data) {
            await LoginHelper(data)
            setIsLogged(true)
            setAccountType(data.email)
            let fullname = data.first_name + " " + data.last_name
            let profile_image = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${data.public_id}`
            if (config !== "development") {
                setUsersToLocalStorage(data.public_id, data["x_access_token"], fullname, data.email, profile_image, data.google_access_token)
            } else {
                setUsersToLocalStorageDev(data.public_id, data.x_access_token, fullname, data.email, profile_image, data.refresh_token, data.google_access_token)
            }
            setLoginIsLoading(false);
            Gleap.identify(data.public_id, {
                name: fullname,
                email: data.email,
            })
            await getProfile()
            await getAllChatroom()
        }
        else {
            setLoginIsLoading(false);
        }

    }

    const handleSuccessResponse = (response, remember) => {

        let fullname = response.first_name + " " + response.last_name
        let profile_pic = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${response.public_id}`
        let userObject = {
            accessToken: response["x_access_token"],
            publicId: response.public_id,
            firstName: fullname,
            email: response.email,
            image: profile_pic,
            userId: response.user_id
        }
        if (!remember) {
            const options = { path: "/", domain: config.cookieDomain }
            CookieService.set("access-token", response["x_access_token"], options)
            CookieService.set("userObject", JSON.stringify(userObject), options)
            return true
        } else {
            let date = new Date()
            date.setTime(date.getTime() + (expiresAt * 60 * 1000))
            const options = { path: "/", domain: config.cookieDomain, expires: date }
            CookieService.set("access-token", response["x_access_token"], options)
            CookieService.set("userObject", JSON.stringify(userObject), options)
            return true
        }


    }
    const getLoginData = async () => {
        return new Promise((resolve) => {
            users = CookieService.get("users")
            if (users) {
                let data = users.filter((user) => {
                    return user.isLogged === true
                }
                )[0]
                if (data) {
                    LoginHelper(data).then((success) => {
                        if (success) {
                            setAccountType(data.email)
                            let fullname = data.first_name + " " + data.last_name;
                            let profile_pic = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${data.public_id}`
                            setUsersToLocalStorage(
                                data.public_id,
                                data["x_access_token"],
                                fullname,
                                data.email,
                                profile_pic,
                                data.role
                            );
                            window.postMessage('zap-X-access-token-mobile' + data["x_access_token"], '*')
                            console.log('zap-X-access-token-mobile' + data["x_access_token"]);
                            getMixPanel().login(fullname, data.email)
                            Gleap.identify(data.public_id, {
                                name: fullname,
                                email: data.email,
                            })
                        }
                    })




                    resolve(true)
                } else {
                    localStorage.clear()
                    let url = `${config.apiEndpoints.protocol}${config.domain.auth}${config.emailText}`;
                    window.location.href = url;
                }
            }
            else {
                localStorage.clear()
                let url = `${config.apiEndpoints.protocol}${config.domain.auth}${config.emailText}`;
                window.location.href = url;
            }


            resolve(false)
        })

    }
    return {
        loginResponsHandler, handleSuccessResponse, getLoginData
    }

}