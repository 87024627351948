
import Avatar from "../Avatar/avatar"
import React from "react"
import AppContext from "../../ContextAPI/AppContext"
import "./MatesComponentStyles.scss"
import { getDPService } from "../../services/DPService"
const MatesComponents = (props) => {
    return (
        <AppContext.Consumer>{(context) => (
            <div className="MatesComponents">
                <div className="contacts-list">

                    {JSON.parse(localStorage.getItem("bix_domainContacts"))?.slice(0, 6).map((contact) => {
                        return (
                            <div>

                                <Avatar
                                    size='ml'
                                    src={contact.avatar === "null" || contact.avatar === null ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : getDPService().getDP("ChatRoom.PrivateChatRoom", contact.public_id)}
                                />
                            </div>

                        )
                    }
                    )}
                </div>
                <div className="viewButton" onClick={() => {
                    context.setIsChatSelected(false)
                    context.setSelectedChat()
                    context.setRightSideBarStatus(false)
                    context.setSendModal(true)
                    props.setSendComponentFrom("Hi")
                }}>View Mates</div>
            </div>
        )}
        </AppContext.Consumer>
    )

}
export default MatesComponents