import React, { useState, useContext } from "react"
import AppContext from "../../../ContextAPI/AppContext";
import image from "../../../Assets/Icons/report.svg";
import "./ReportModal.scss"


const ReportModal = (props) => {
    const newContext = useContext(AppContext)


    const [reportDescription, setReportDescription] = useState("");


    const handleChange = event => {
        setReportDescription(event.target.value);
    };

    function reportSentModalClose() {
        setTimeout(() => newContext?.setReportSent(false)
            , 4000)
    }

    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_reportModal" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
                    <div id="myModal" className="modal-1">
                        <div className="modal-content-top" >
                            <div className="modal-content-add-admin">
                                <div className="plus-container-left">
                                    <img alt="" src={image} className="plus" />
                                </div>

                                <div className="add-header">Report Modal</div>


                            </div>

                            <div className="inner-container">

                                <div className="report-main">
                                    <div className="report-user-random">
                                        Report Message?
                                    </div>
                                    <div className="report-user-random-des">
                                        This message will be forwarded to bixSupport. The sender will not be notified.
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            id="message"
                                            name="message"
                                            onChange={handleChange}
                                            value={reportDescription}
                                            className="report-input"
                                            placeholder="Describe the issue (Optional)" />

                                    </div>


                                    <div className="report-bottom">
                                        <div className="report-bottom-div">
                                            <span className="report-cancel" onClick={() => {
                                                context?.setReportModal(false)
                                            }}>Cancel</span>
                                            <span className="report-btn"
                                                onClick={() => {
                                                    context.sendReportMessage(
                                                        reportDescription,

                                                    );

                                                    context?.setReportModal(false)
                                                    context?.setReportSent(true)
                                                    reportSentModalClose()

                                                }}
                                            >Report</span>

                                        </div>

                                    </div>




                                </div>




                            </div>



                        </div>

                    </div>

                </div>
            )}</AppContext.Consumer>
    )
}
export default ReportModal 