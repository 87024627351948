import React from 'react';
import pdf from "../../../Assets/Icons/pdf.png";
import doc from "../../../Assets/Icons/doc.jpg";
import csv from "../../../Assets/Icons/csv.jpg";
import excel from "../../../Assets/Icons/excel.jpg";
import ppt from "../../../Assets/Icons/ppt.png";
import txt from "../../../Assets/Icons/txt.png";
import xd from "../../../Assets/Icons/xd.png"
import html from "../../../Assets/Icons/html.png"




const FilePreviewComponent = (props) => {
    const getType = (attachment) => {
        let g = /\.([0-9a-z]+)$/i;
        if (attachment.includes(".pdf")) {
            return "PDF";
        } else if (attachment.includes(".doc") || attachment.includes(".docx")) {
            return "Word Document";
        } else if (attachment.includes(".ppt")) {
            return "Power Point";
        } else if (attachment.includes(".csv")) {
            return "CSV";
        } else if (attachment.includes(".xlsx")) {
            return "Excel Sheet";
        } else if (attachment.includes(".txt")) {
            return "Text File";
        }
        else if (attachment.includes(".xd")) {
            return "XD";
        } else if (attachment.includes(".html")) {
            return "HTML";
        } else {
            return props.name.match(g)[0].replace(".", "")
        }
    }


    const getDocument = (attachment) => {
        if (attachment?.includes(".pdf")) {
            return pdf;
        } else if (attachment?.includes(".doc") || attachment?.includes(".docx")) {
            return doc;
        } else if (attachment?.includes(".ppt")) {
            return ppt;
        } else if (attachment?.includes(".csv")) {
            return csv;
        } else if (attachment?.includes(".xlsx")) {
            return excel;
        } else if (attachment?.includes(".txt")) {
            return txt;
        } else if (attachment?.includes(".xd")) {
            return xd;
        } else if (attachment?.includes(".html")) {
            return html;
        } else {
            return props?.link;
        }
    }
    return (
        <div className="file-styles">
            <div className="file-type-container">
                <img src={getDocument(props.name)} alt="" />
            </div>
            <div className="file-text"> {props.name}
                <div className="file-type-text">{getType(props.name)}
                </div></div>
        </div>
    )
}


export default FilePreviewComponent