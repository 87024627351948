import React, { forwardRef } from "react";
import "../Button/ButtonStyles.scss";

const Button = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className={`${props.getStarted ? "gt-started" : "submit-button button"} ${props.loading ? " is-loading" : ""}`}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{
        cursor: props.notAllowed ? "not-allowed" : "pointer",
        marginTop: props.marginTop ? props.marginTop : "",
        height: props.height ? props.height : "",
        width: props.width ? props.width : ""

      }}
    >
      {props.buttonText}
    </div>
  );
});
export default Button;
