import React, { useState } from "react";
import download from ".././../../Assets/Icons/arrow-download.png";
import videoTemp from "../../../Assets/Images/defaultVideo.jpg"
import mic from "../../../Assets/Icons/audio-mic.svg";
import FilePreviewComponent from "./FilePreviewComponent";


const AttachmentPreviewComponent = (props) => {
    const [style, setStyle] = useState({ display: 'none' });


    const getDocument = () => {
        let att = props?.attachments
        let isDoc = false
        if (props?.attachments !== undefined) {
            if (!props.sending) {
                for (let i = 0; i < att?.length; i++) {
                    let g = /\.([0-9a-z]+)$/i;
                    let doc = JSON.parse(att[i] && att[i])?.name?.match(g)[1]
                    if (doc === "pdf" || doc === "doc" || doc === "ppt" || doc === "csv" || doc === "docx" || doc === "pptx" || doc === "xlsx" || doc === "txt" || doc === "xd" || doc === "html") {
                        isDoc = true
                        break
                    } else {
                        isDoc = false
                    }

                }
            } else {
                for (let i = 0; i < att?.length; i++) {
                    let g = /\.([0-9a-z]+)$/i;
                    let doc = att[i]?.name?.match(g)[1]
                    if (doc === "pdf" || doc === "doc" || doc === "ppt" || doc === "csv" || doc === "docx" || doc === "pptx" || doc === "xlsx" || doc === "txt" || doc === "xd" || doc === "html") {
                        isDoc = true
                        break
                    } else {
                        isDoc = false
                    }
                }
            }
        }

        return isDoc
    }
    return (
        <div style={{ position: "relative" }} onMouseEnter={e => {
            if (window.screen.width > 600) {
                setStyle({ display: 'block' });
            }
        }}
            onMouseLeave={e => {
                if (window.screen.width > 600) {
                    setStyle({ display: 'none' });
                }
            }}>
            {props.name?.includes(".mp3") ? <div className="attachment3">
                <img alt="" src={mic} className="img-mic" /></div> : props.name?.includes(".mp4") ?
                <img

                    alt=""
                    src={videoTemp}
                    className={"attachment-image"}
                /> : getDocument() ?

                    <FilePreviewComponent
                        name={props.name}
                        link={props.download}
                    /> : <img
                        alt=""
                        src={props.link ? props.link : props.download}
                        className={"attachment-image"}

                    />}

            <div className="name" style={window.screen.width > 600 ? style : { display: "block" }}>
                <div className="left">
                    <a
                        href={
                            props.download ? props.download : ""
                        }
                        download
                        className="download-img-a"
                    >
                        <img
                            alt=""
                            src={download}
                            className="img"
                        />
                    </a>
                </div>
            </div>
        </div>
    )

}
export default AttachmentPreviewComponent