export const userServices = () => {
    const handleDeleteUser = (response, setIsModalVisible, setModalLoading, logoutFunction) => {
        if (response.status === 200) {
            setIsModalVisible(false)
            setModalLoading(false)
            logoutFunction()
        }
    }

    return {
        handleDeleteUser
    }
}