import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import config from "../../../config";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
import calender from "../../../Assets/Icons/vuesax-linear-calendar.svg";

import { useHistory } from "react-router-dom";

import AppContext from "../../../ContextAPI/AppContext";
import Carousel from "../../../Components/Carousel/Carousel";

import "./ForgotPassword.scss";
import UsernameInput from "../../../Components/InputComponents/UsernameInput/UsernameInput";


const ForgetPassword = () => {
  const history = useHistory();

  const [takenUsername, setTakenUsername] = useState([]);
  const [users, setUsers] = useState([]);
  const [forgetpageToShow, setForgetpageToShow] = useState(1);
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailInputStatus, setEmailInputStatus] = useState(0); //Zero No Touch, 1 Valid, 2 Not Valid
  const [passwordInputStatus, setPasswordInputStatus] = useState(0);
  const [confirmPassStatus, setConfirmPassStatus] = useState(0);
  const [isDOBError, setIsDOBError] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [generatedYears, setGeneratedYears] = useState([]);
  const [generatedMonths, setGeneratedMonths] = useState([]);
  const [generatedDays, setGeneratedDays] = useState([]);
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  // const [dateOfBirth, setDateOfBirth] = useState("");
  const [isUsernameFocus, setIsUsernameFocus] = useState(false);
  const [isPwdFocus, setIsPwdFocus] = useState(false);
  const [isConPwdFocus, setIsConPwdFocus] = useState(false);
  const [isMonthFocus, setIsMonthFocus] = useState(false);
  const [isDayFocus, setIsDayFocus] = useState(false);
  const [isYearFocus, setIsYearFocus] = useState(false);
  const passwordFocus = useRef(null)





  useEffect(() => {
    generateYears();
  }, []);
  useEffect(() => {
    localStorage.clear();
    getUsers();
  }, []);

  const generateYears = () => {
    let thisYear = moment().format("YYYY");
    let generatedYears = [];
    let x = 100;

    for (x; x >= 0; x--) {
      let y = parseInt(thisYear) - parseInt(x);

      generatedYears.push(y);
    }

    setGeneratedYears(generatedYears);
    generateMonths();
  };

  const generateMonths = () => {
    let getMonths = moment().month(1);
    let allMonths = getMonths._locale._months;

    setGeneratedMonths(allMonths);
    generateDays();
  };

  const generateDays = () => {
    let selectedMonthX =
      localStorage.getItem("selected_reset_birthday_month") ||
      "February";
    let selectedYearX =
      localStorage.getItem("selected_reset_birthday_year") || "1990";
    let daysInMonth = [];
    let selectedMonthNumber =
      selectedMonthX && moment().month(selectedMonthX).format("M");
    let selectedMonthAndYear = `${selectedYearX +
      "-" +
      (selectedMonthNumber > 10
        ? selectedMonthNumber
        : "0" + selectedMonthNumber)
      }`;
    let monthDate = moment(selectedMonthAndYear).startOf("month");
    for (let i = 0; i < monthDate.daysInMonth(); i++) {
      let newDay = monthDate.clone().add(i, "days");
      daysInMonth.push(newDay.format("DD"));
    }
    setGeneratedDays(daysInMonth);
  };

  const handlePassword = (e) => {
    let passwordValue = e.target.value;
    setPassword(passwordValue);

    if (passwordValue.length === 0) {
      setPasswordInputStatus(0);
    } else if (passwordValue.length < 8) {
      setPasswordInputStatus(2);
    } else {
      let mediumRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-_`~])(?=.{8,})"
      );
      if (mediumRegex.test(passwordValue)) {
        setPasswordInputStatus(1);
      } else {
        setPasswordInputStatus(2);
      }
    }
  };

  const handleConfirm = (e) => {
    let confirmPassValue = e.target.value;

    setConfirmPass(confirmPassValue);

    if (confirmPassValue.length > 0) {
      if (confirmPassValue !== password) {
        setConfirmPassStatus(2);
      } else {
        setConfirmPassStatus(1);
      }
    } else {
      setConfirmPassStatus(0);
    }
  };

  const passwordInputValidation = () => {
    // Use 8 or more characters with a mix of letters, numbers & symbols
    if (passwordInputStatus === 0) {
      return "";
    } else if (passwordInputStatus === 1) {
      return "";
    } else {
      return (
        <div className="form-fail">
          <img src={danger} alt="" className="danger-forget" />
          <p>Please choose a stronger Password</p>
        </div>
      );
    }
  };

  const confirmPassValidation = () => {
    if (confirmPass.length > 0) {
      if (confirmPass !== password) {
        return (
          <div className="form-fail">
            <img src={danger} alt="" className="danger-forget" />
            <p>Please make sure passwords match.</p>
          </div>
        );
      } else if (confirmPassStatus === 1) {
        return "";
      }
    }
  };
  const handleEmail = (e) => {
    let emailVal = e.target.value.trim();
    setEmail(emailVal);

    if (email.length === 0) {
      setEmailInputStatus(0);
    } else if (email.length !== 0) {
      setEmailInputStatus(1);
    }
  };
  const getUsers = () => {
    axios({
      headers: { "Access-Control-Allow-Origin": "*" },
      method: "get",
      url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/users`,
    }).then((response) => {
      let takenUsernames = [];
      let users = [];

      response.data.map((singleData) => {
        let username = singleData.email.split("@")[0];
        let date = "";
        if (singleData.date_of_birth !== undefined) {
          date =
            moment(singleData.date_of_birth["$date"]).format("L") ===
              moment().format("L")
              ? moment(singleData.date_of_birth["$date"]).format("LT")
              : moment(singleData.date_of_birth["$date"]).format("L");
        } else {
          date = "";
        }
        let user = {
          username: username,
          dob: date,
        };

        takenUsernames.push(username);
        users.push(user);

        return null;
      });
      setTakenUsername(takenUsernames);
      setUsers(users);
    });
  };
  const validateEmail = () => {
    if (!takenUsername.includes(email.toLowerCase())) {
      setEmailInputStatus(2);
    } else {
      setForgetpageToShow(2);
    }
  };
  const emailInputValidation = () => {
    // Use 8 or more characters with a mix of letters, numbers & symbols
    if (emailInputStatus === 0) {
      return "";
    } else if (emailInputStatus === 1) {
      return "";
    } else {
      return (
        <div className="form-fail">
          <img src={danger} alt="" className="danger-forget-password" />
          <p>Email does not exist</p>
        </div>
      );
    }
  };
  const dobValidation = () => {
    if (!isDOBError) {
      return "";
    } else {
      return (
        <div className="form-fail dob">
          <img src={danger} alt="" className="danger-forget-password" />
          <p> You have entered a Wrong Date of Birth</p>
        </div>
      );
    }
  };

  const validateBirthDate = () => {
    let month = moment().month(selectedMonth).format("MM");
    let year = selectedYear;
    let day = selectedDay;
    // let selectDate =
    //   `${selectedYear}` + "-" + `${month}` + "-" + `${selectedDay}`;

    let selectDate =
      year + "-" + month + "-" + day;

    setDob(selectDate);

    localStorage.setItem(
      "selectedDateOfBirth",
      month + "/" + day + "/" + year
    );
    if (
      users.filter(
        (user) =>
          user.username === email &&
          user.dob === localStorage.getItem("selectedDateOfBirth")
      ).length > 0
    ) {
      setForgetpageToShow(3);
      setIsDOBError(false);
    } else {
      setIsDOBError(true);
    }
  };

  const backNav = () => {
    if (forgetpageToShow === 1) {
      history.push({
        pathname: "/login"
      })
    }
    else if (forgetpageToShow === 2) {
      setForgetpageToShow(1);
    }
    else if (forgetpageToShow === 3) {
      setForgetpageToShow(2);
    }
  }

  const renderPage = () => {
    if (forgetpageToShow === 1) {
      return (
        <div className="_ForgotPwdContainer">
          <div className="ForgotPwdInnercontainerLeft">
            <div className="wrapper">
              <div className="bixHeader">ZapMail</div>
              <div className="header-bottom-text">A smarter way to connect.</div>
              <div className="form-wrapper">
                <div className="input-wrapper username-margin-top-login">
                  <div className="headerLabel">Recover Password</div>
                  <div className="sub-text">
                    Please enter your username
                  </div>
                  <br />
                  <div>
                    {
                      isUsernameFocus || email !== "" ?
                        <label className="input-label" style={{ color: isUsernameFocus ? "#0094ff" : "" }}>Email</label>
                        :
                        ('')
                    }

                  </div>

                  <div
                    className="inputdiv"
                    style={{
                      borderColor:
                        email !== "" && emailInputStatus === 2
                          ? "#ff0000"
                          : isUsernameFocus
                            ? "#0d2a4c"
                            : "#9d9d9d",
                      borderWidth:
                        email !== "" && emailInputStatus === 2
                          ? "1px"
                          : isUsernameFocus
                            ? "1px"
                            : "1px",
                    }}
                  >
                    <UsernameInput
                      type="text"
                      name="username"
                      value={email}
                      isUsernameFocus={isUsernameFocus}
                      placeholder={isUsernameFocus ? "" : "Email"}
                      onChange={(e) => {
                        handleEmail(e);
                      }}
                      onFocus={() => setIsUsernameFocus(true)}
                      onBlur={() => {
                        setIsUsernameFocus(false);
                      }} />

                  </div>
                  {emailInputValidation()}
                </div>
                <div
                  className="skip-btn button "
                  onClick={backNav}
                >
                  Back
                </div>
                <div
                  disabled={email === ""}
                  className="submit-btn button"
                  onClick={validateEmail}
                  style={{ marginTop: "10px" }}
                >
                  Next
                </div>
                <div className="footerText">
                  Don't have an account?{" "}
                  <span
                    className="footerTextLink"
                    onClick={() => {
                      history.push({
                        pathname: "/signup",
                      });
                    }}
                  >
                    Register
                  </span>
                </div>
              </div>

            </div>
          </div>
          <div className="ForgotPwInnercontainerRight">
            <div className="wrapper2">
              <Carousel />
            </div>
          </div>
        </div>
      );
    } else if (forgetpageToShow === 2) {
      return (
        <div className="_ForgotPwdContainer">
          <div className="ForgotPwdInnercontainerLeft">
            <div className="wrapper">
              <div className="bixHeader">ZapMail</div>
              <div className="form-wrapper">
                <div className="input-wrapper username-margin-top-login">
                  <div className="headerLabel">Recover Password</div>
                  <div className="sub-text">
                    Please enter your date of birth 
                    <br/>
                    You'll be able to reset your password shortly
                  </div>
                  <div className="form-wrapper">
                    <div className="multiple-input-wrapper">
                      <div className="input-wrapper  month-margin"
                        style={{
                          borderBottom:
                            (selectedMonth === "" && isDOBError === true) ||
                              (selectedMonth === "Day" && isDOBError === true)
                              ? "#ff0000 1px solid"
                              : isMonthFocus
                                ? "#0d2a4c 1px solid"
                                : "#9d9d9d 1px solid",
                          width: "30%"
                        }}
                      >
                        <br />
                        {isMonthFocus || selectedMonth !== "" ? <label className="input-label" style={{ color: isMonthFocus ? "#0094ff" : "" }}>Month</label> : ""}
                        <br />
                        {(isMonthFocus && selectedMonth !== "") || selectedMonth !== "" ? "" : <img src={calender} style={{ height: "16px", width: "16px" }} />}

                        <select
                          className={
                            selectedMonth === ""
                              ? "form-input date-input input-height minimal-gray-day date-select"
                              : "form-input date-input input-height minimal-black-day date-select"
                          }
                          style={{
                            // fontSize: "16px",
                            // borderColor:
                            //   (selectedMonth === "" && isDOBError === true) ||
                            //     (selectedMonth === "Month" && isDOBError === true) ||
                            //     isDOBError === true
                            //     ? "#ff0000"
                            //     : isMonthFocus
                            //       ? "#0d2a4c"
                            //       : "#9d9d9d",
                            borderWidth:
                              (selectedMonth === "" && isDOBError === true) ||
                                (selectedMonth === "Month" && isDOBError === true) ||
                                isDOBError === true
                                ? "1px"
                                : isMonthFocus
                                  ? "1px"
                                  : "1px",
                            color: selectedMonth === "" ? "#9d9d9d" : "#414141",
                            WebkitAppearance: "-moz-initial",
                            MozAppearance: "none",
                            width: (selectedMonth === "" ? "85%" : "99%"),
                            marginLeft: (selectedMonth === "" || isMonthFocus === "true" ? "5px" : "2px")
                          }}
                          onFocus={() => setIsMonthFocus(true)}
                          onBlur={() => setIsMonthFocus(false)}
                          id="month"
                          onChange={(e) => {
                            localStorage.setItem(
                              "selected_reset_birthday_month",
                              e.target.value
                            );
                            setSelectedMonth(e.target.value);
                            generateDays();
                            setIsDOBError(false);
                          }}
                        >
                          <option className="select-option-style" value="">
                            Month
                          </option>

                          {generatedMonths.map((months, i) => {
                            return (
                              <option
                                className="select-option-style"
                                key={i}
                                value={months}
                              >
                                {months}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="input-wrapper  month-margin"
                        style={{
                          borderBottom:
                            (selectedDay === "" && isDOBError === true) ||
                              (selectedDay === "Day" && isDOBError === true)
                              ? "#ff0000 1px solid"
                              : isDayFocus
                                ? "#0d2a4c 1px solid"
                                : "#9d9d9d 1px solid",
                          width: "30%"
                        }}
                      >
                        <br />
                        {isDayFocus || selectedDay !== "" ? <label className="input-label" style={{ color: isDayFocus ? "#0094ff" : "" }}>Day</label> : ""}
                        <br />
                        {(isDayFocus && selectedDay !== "") || selectedDay !== "" ? "" : <img src={calender} style={{ height: "16px", width: "16px", }} />}

                        <select
                          className={
                            selectedDay === ""
                              ? "form-input date-input input-height minimal-gray-day date-select"
                              : "form-input date-input input-height minimal-black-day date-select"
                          }
                          style={{
                            // borderColor:
                            //   (selectedDay === "" && isDOBError === true) ||
                            //     (selectedDay !== "" && isDOBError === true)
                            //     ? "#ff0000"
                            //     : isDayFocus
                            //       ? "#0d2a4c"
                            //       : "#9d9d9d",
                            borderWidth:
                              (selectedDay === "" && isDOBError === true) ||
                                (selectedDay !== "" && isDOBError === true)
                                ? "1px"
                                : isDayFocus
                                  ? "2px"
                                  : "1px",
                            color:
                              selectedDay === "Day" || selectedDay === ""
                                ? "#9d9d9d"
                                : "#414141",
                            WebkitAppearance: "-moz-initial",
                            MozAppearance: "none",
                            width: (selectedDay === "" ? "85%" : "99%"),
                            marginLeft: (selectedDay === "" || isDayFocus === "true" ? "5px" : "2px")

                          }}
                          onChange={(e) => {
                            setSelectedDay(e.target.value);
                            setIsDOBError(false);
                          }}
                          onFocus={() => setIsDayFocus(true)}
                          onBlur={() => setIsDayFocus(false)}
                        >
                          <option className="select-option-style">Day</option>
                          {generatedDays.map((days, i) => {
                            return (
                              <option
                                className="select-option-style"
                                key={i}
                                value={days}
                              >
                                {days}
                              </option>
                            );
                          })}
                        </select>
                      </div>


                      <div className="input-wrapper  month-margin"
                        style={{
                          borderBottom:
                            (selectedDay === "" && isDOBError === true) ||
                              (selectedDay === "Day" && isDOBError === true)
                              ? "#ff0000 1px solid"
                              : isDayFocus
                                ? "#0d2a4c 1px solid"
                                : "#9d9d9d 1px solid",
                          width: "30%",
                        }}
                      >
                        <br />
                        {isYearFocus || selectedYear !== "" ? <label className="input-label" style={{ color: isYearFocus ? "#0094ff" : "" }}>Year</label> : ""}
                        <br />
                        {(isYearFocus && selectedYear !== "") || selectedYear !== "" ? "" : <img src={calender} style={{ height: "16px", width: "16px" }} />}

                        <select
                          className={
                            selectedYear === ""
                              ? "form-input date-input input-height minimal-gray-day date-select"
                              : "form-input date-input input-height minimal-black-day date-select"
                          }
                          style={{
                            // borderColor:
                            //   (selectedYear === "" && isDOBError === true) ||
                            //     (selectedYear !== "" && isDOBError === true)
                            //     ? "#ff0000"
                            //     : isYearFocus
                            //       ? "#0d2a4c"
                            //       : "#9d9d9d",
                            borderWidth:
                              (selectedYear === "" && isDOBError === true) ||
                                (selectedYear !== "" && isDOBError === true)
                                ? "1px"
                                : isYearFocus
                                  ? "2px"
                                  : "1px",
                            color: selectedYear === "" ? "#9d9d9d" : "#414141",
                            WebkitAppearance: "-moz-initial",
                            MozAppearance: "none",
                            width: (selectedYear === "" ? "85%" : "99%"),
                            marginLeft: (selectedYear === "" || isYearFocus === "true" ? "5px" : "1px"),


                          }}
                          onChange={(e) => {
                            localStorage.setItem(
                              "selected_reset_birthday_year",
                              e.target.value
                            );
                            setSelectedYear(e.target.value);
                            setIsDOBError(false);
                            generateDays();
                          }}
                          onFocus={() => setIsYearFocus(true)}
                          onBlur={() => setIsYearFocus(false)}
                        >
                          <option className="select-option-style" value="">
                            Year
                          </option>

                          {generatedYears
                            .slice()
                            .reverse()
                            .map((year, i) => {
                              return (
                                <option
                                  className="select-option-style"
                                  key={i}
                                  value={year}
                                >
                                  {year}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    {dobValidation()}
                    <div
                      className="skip-btn button"
                      onClick={backNav}
                      style={{ marginTop: "50px" }}
                    >
                      Back
                    </div>
                    <div
                      disabled={
                        selectedYear === "" ||
                        selectedMonth === "" ||
                        selectedDay === ""
                      }
                      className="submit-btn button"
                      onClick={validateBirthDate}
                      style={{ marginTop: "10px" }}
                    >
                      Next
                    </div>
                  </div>
                  <div className="footerText">
                    Don't have an account?{" "}
                    <span
                      className="footerTextLink"
                      onClick={() => {
                        history.push({
                          pathname: "/signup",
                        });
                      }}
                    >
                      Register
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ForgotPwInnercontainerRight">
            <div className="wrapper2">
              <Carousel />
            </div>
          </div>
        </div>
      );
    } else if (forgetpageToShow === 3) {
      return (
        <AppContext.Consumer>
          {(context) => (
            <div className="_ForgotPwdContainer">
              <div className="ForgotPwdInnercontainerLeft">
                <div className="wrapper">
                  <div className="bixHeader">ZapMail</div>
                  <div className="form-wrapper">
                    <div className="headerLabel">Enter New Password</div>
                    <div className="input-wrapper username-margin-top-login">

                      {isPwdFocus || password !== "" ? <label className="input-label" style={{ color: isPwdFocus ? "#0094ff" : "" }}>Password</label> : ""}

                      <div
                        style={{
                          borderColor:
                            (password === "" && passwordInputStatus === 1) ||
                              (password !== "" && passwordInputStatus !== 1)
                              ? "#ff0000"
                              : isPwdFocus
                                ? "#0d2a4c"
                                : "#9d9d9d",
                        }}
                        className="inputdiv"
                      >
                        <UsernameInput
                          type={showPassword ? "text" : "password"}
                          placeholder={isPwdFocus ? "" : "Password"}
                          onChange={(e) => {
                            handlePassword(e);
                          }}
                          onFocus={() => setIsPwdFocus(true)}
                          onBlur={() => setIsPwdFocus(false)}
                          value={password}
                          name="confirmPass"
                          maxlength="30"
                        />
                      </div>


                      <div className="form-wrapper">


                        <div className="input-wrapper wrap-forget">
                          {/* <label className="form-label">Confirm Password</label> */}
                          {isConPwdFocus || confirmPass !== "" ? <label className="input-label" style={{ color: isConPwdFocus ? "#0094ff" : "" }}>Confirm Password</label> : ""}

                          <br />
                          <div
                            style={{
                              borderColor:
                                (confirmPass === "" && confirmPassStatus === 1) ||
                                  (confirmPass !== "" && confirmPassStatus !== 1)
                                  ? "#ff0000"
                                  : isPwdFocus
                                    ? "#0d2a4c"
                                    : "#9d9d9d",
                            }}
                            className="inputdiv"
                          >
                            <UsernameInput
                              type={showPassword ? "text" : "password"}
                              placeholder={isConPwdFocus ? "" : "Confirm Password"}
                              onChange={(e) => {
                                handleConfirm(e);
                              }}
                              onFocus={() => setIsConPwdFocus(true)}
                              onBlur={() => setIsConPwdFocus(false)}
                              value={confirmPass}
                              name="confirmPass"
                              maxlength="30"
                            />

                          </div>
                          {confirmPassValidation()}

                        </div>
                        <div
                          className="skip-btn button"
                          onClick={backNav}
                          style={{ marginTop: "50px" }}
                        >
                          Back
                        </div>
                        <div
                          style={{ marginTop: "8px" }}
                          disabled={
                            !(
                              passwordInputStatus === 1 && confirmPassStatus === 1
                            ) || context?.forgetPwdLoading
                          }
                          className={`submit-btn button ${context?.forgetPwdLoading ? "is-loading" : ""
                            }`}
                          onClick={() => {
                            context?.forgetPassword(
                              email,
                              dob,
                              password,
                              confirmPass
                            );
                          }}
                        >
                          Reset Password
                        </div>
                      </div>
                      <div className="footerText">
                        Don't have an account?{" "}
                        <span
                          className="footerTextLink"
                          onClick={() => {
                            history.push({
                              pathname: "/signup",
                            });
                          }}
                        >
                          Register
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="ForgotPwInnercontainerRight">
                <div className="wrapper2">
                  <Carousel />
                </div>
              </div>
            </div>
          )}
        </AppContext.Consumer>
      );
    }
  };

  return (<div>

    {renderPage()}
  </div>);
};

export default ForgetPassword;
