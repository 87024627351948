import "./App.css";
import "bulma/css/bulma.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import Routes from "./Routes";
import mixpanel from 'mixpanel-browser';
import { useEffect } from "react";
import config from "./config"



export const ws = new WebSocket(`${config.websocketURL}`);

ws.addEventListener('close', function (event) {
  console.log('websocket closed ', new Date(), ' ', event);
});

ws.addEventListener('error', function (event) {
  console.log('websocket disconnected because of error', new Date(), ' ', event);
});

ws.addEventListener('open', () => {
  // console.log('websocket connected ', new Date());
  setInterval(() => {
    ws.send(JSON.stringify({ action: "ping" }));
  }, 300000);
})



function App() {
  useEffect(() => {
    mixpanel.init(config.mixpanel, { debug: true });
  }, [])
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
