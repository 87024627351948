import config from "../../config"
export const LoginHelper = (data) => {
    return new Promise((resolve) => {
        localStorage.setItem("bixChat_loggedUserId", data.user_id);
        localStorage.setItem("bixChat_loggedUserPublicId", data.public_id);
        localStorage.setItem("bixChat_accessToken", data["x_access_token"]);
        localStorage.setItem("bixChat_isLogged", true);
        localStorage.setItem("bixChat_mobileView", 1);
        localStorage.setItem("bixChat_email", data.email);
        let fullname = data.first_name + " " + data.last_name;
        localStorage.setItem("bixChat_fullname", fullname);
        let profile_image = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${data.public_id}`
        localStorage.setItem("bixChat_profileImage", profile_image);
        localStorage.setItem("firstLogin", data.is_first_login)
        localStorage.setItem("tooltipFun", false)
        localStorage.setItem("bixChat_role", data.role)
        if (config.environment === "development") {
            localStorage.setItem("bixChat_refreshToken", data.refresh_token)
        }
        localStorage.setItem("google_accessToken", data.google_access_token);

        resolve(true)
    })
}
// set account type to local 
export const setAccountType = (email) => {
    if (email.includes(`${config.emailProvider}`)) {
        localStorage.setItem("bixChat_isPrivate", "true")
    } else {
        localStorage.setItem("bixChat_isPrivate", "false")

    }
}
//set users local storage
export const setUsersToLocalStorage = (publicId, accessToken, fullname, email, image, role, google_access_token) => {
    let users = JSON.parse(localStorage.getItem("bixChat_users") || "[]");
    const isFound = users.some(value => {
        if (value.publicId === publicId) {
            return true
        }
    })
    if (isFound) {
        users.map(user =>
            user.publicId == publicId ? {
                ...user, accessToken: accessToken,
                email: email,
                fullName: fullname,
                profileImage: image,
                googleAccessToken: google_access_token,
                role: role
            } : user
        )

    } else {
        let user = {
            accessToken: accessToken,
            publicId: publicId,
            email: email,
            fullName: fullname,
            googleAccessToken: google_access_token,
            role: role
        }
        users.push(user)

    }
    localStorage.setItem("bixChat_users", JSON.stringify(users));
}
//set users local storage
export const setUsersToLocalStorageDev = (publicId, accessToken, fullname, email, image, refresh_token, role, google_access_token) => {
    let users = JSON.parse(localStorage.getItem("bixChat_users") || "[]");
    const isFound = users.some(value => {
        if (value.publicId === publicId) {
            return true
        }
    })
    if (isFound) {
        users.map(user =>
            user.publicId == publicId ? {
                ...user, accessToken: accessToken,
                email: email,
                fullName: fullname,
                profileImage: image,
                refresh_token:  refresh_token,
                googleAccessToken: google_access_token,
                role: role
            } : user
        )

    } else {
        let user = {
            accessToken: accessToken,
            publicId: publicId,
            email: email,
            fullName: fullname,
            refresh_token: refresh_token,
            googleAccessToken: google_access_token,
            role: role
        }
        users.push(user)

    }
    localStorage.setItem("bixChat_users", JSON.stringify(users));
}