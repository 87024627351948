import React from "react"
import ChatUIWrapper from "../ChatUIComponents/ChatUIWrapper"
import ErrorBoundry from "../ErrorHandler/ErrorBoundry"

const Home = () => {
    return (
        <ErrorBoundry>
            <ChatUIWrapper />
        </ErrorBoundry>
    )

}
export default Home