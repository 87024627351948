import React, { useContext } from "react";
import AdminInput from "../../../Components/InputComponents/AdminInput/AdminInput";
import "./AdminBetaStyles.scss";
import AdminSteps from "../AdminSteps/AdminSteps";
import MobileSteps from "../AdminSteps/MobileSteps/MobileSteps";
import AppContext from "../../../ContextAPI/AppContext";
const AdminCompanyForm = (props) => {
    const context = useContext(AppContext)
    const changeHandler = (e) => {
        if (e.target.files.length > 0) {
            props.setLogo(e.target.files[0].name)
            localStorage.setItem("bix_business_logo_name", e.target.files[0].name)
        }
    }
    const handleProPic = (event) => {
        const reader = new FileReader();

        reader.onload = function () {
            let baseImage = reader.result.split("base64,")[1]
            localStorage.setItem("bix_business_logo", baseImage)

        };
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    const onBtnClick = () => {
        if (
            props.country !== "" &&
            props.address !== "" &&
            props.designation !== "" &&
            props.organization !== "" && props.regNo !== "" && props.logo !== "") {
            localStorage.setItem("bix_business_page", 3)
            context.setPageToShow(3)
        }
    }
    let disabled = props.country === "" ||
        props.address === "" ||
        props.designation === "" ||
        props.organization === "" || props.regNo === "" || props.logo === ""
    return (

        <div>
            <div className="AdminBetaContainer">
                <div className="AdminBetaInnerContainerRight">
                    <div className="wrapper2">
                        <div className="onboard-header">
                            <div className="bixHeader">bixBusiness</div>
                            <div className="header-bottom-text">Recommended for companies or industries.</div>
                        </div>
                        <div className="step-header">
                            <div className="step-hdr">Step 2</div>
                            <div className="step-header-bottom-text">Enter your Company information
                                to very your identification</div>
                        </div>
                        <div className="stp">
                            <AdminSteps step={2} />
                        </div>
                        <div className="stp-mobile">
                            <MobileSteps step={2} />
                        </div>
                    </div>
                </div>
                <div className="AdminBetaInnerContainerLeft">
                    <div className="wrapper">
                        <div className="content-header">
                            <div className="content-Header">Company  Information</div>
                            <div className="content-header-bottom-text">Enter your Company information</div>
                        </div>
                        <div className="form-wrapper">
                            <div className="admin-form-cont">


                                <AdminInput
                                    name="Name of the Organization"
                                    value={props.organization}
                                    onChange={e => {
                                        props.setOrganization(e.target.value)
                                        localStorage.setItem("bix_business_organization", e.target.value)
                                    }}

                                />
                                <div className="margin-top-style" />
                                <AdminInput
                                    name="Organization Registration No."
                                    value={props.regNo}
                                    onChange={e => {
                                        props.setregNo(e.target.value)
                                        localStorage.setItem("bix_business_regNo", e.target.value)

                                    }}

                                /> <div className="margin-top-style" />

                                <AdminInput
                                    name="Jurisdiction/Country"
                                    value={props.country}
                                    onChange={e => {
                                        props.setCountry(e.target.value)
                                        localStorage.setItem("bix_business_country", e.target.value)

                                    }}
                                />

                                <div className="margin-top-style" />
                                <AdminInput
                                    name="Address"
                                    value={props.address}
                                    onChange={e => {
                                        props.setAddress(e.target.value)
                                        localStorage.setItem("bix_business_address", e.target.value)

                                    }}
                                /> <div className="margin-top-style" />

                                <AdminInput
                                    name="Upload Company Logo"
                                    value={props.logo}
                                    onClick={props.onImageClick}
                                    readonly={true}
                                />
                                <input onChange={(e) => {
                                    changeHandler(e)
                                    handleProPic(e);
                                }}
                                    ref={props.uploadImage}
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="image/*"
                                />

                            </div>
                            <div className="margin-top-style" /><div className="margin-top-style" />
                            <div className="right-btn-container">
                                <div className="scnd-btn" onClick={() => {
                                    localStorage.setItem("bix_business_page", 1)
                                    context.setPageToShow(1)
                                }}>Back</div>
                                <div className="prim-btn" style={{ backgroundColor: disabled ? "#707070" : "" }} onClick={onBtnClick}>Next</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};
export default AdminCompanyForm;
