import React, { useContext } from "react"
import AppContext from "../../../ContextAPI/AppContext"
const DisplaySettings = () => {
  const context = useContext(AppContext)
  return (
    <div className="display">
      <div className="font-head">Font Size</div>
      <div className="slidercontainer" >
        <select className="select" onChange={(e) => {
          localStorage.setItem("bix_fontSize", e.target.value)
          context.setFontSizeType(e.target.value)
        }}
          value={context.fontSizeType}>
          <option value="small">
            Small
          </option>
          <option value="medium">
            Medium
          </option>  <option value="large">
            Large
          </option>
        </select>
      </div>
    </div>

  )
}
export default DisplaySettings