import React from "react";
import './ImageUpload.scss'
import CloudAdd from "../../../Assets/Icons/vuesax-linear-cloud-add-white.svg"
import FolderIcon from "../../../Assets/Icons/folder-icon.svg"
import search from "../../../Assets/Icons/search.svg";
import checkbox from "../../../Assets/Icons/check-box.svg";
import img from "../../../Assets/Images/squirrel.jpg";
import CloseSelected from "../../../Assets/Icons/closeSelected.svg";
import AppContext from "../../../ContextAPI/AppContext";


import { useState } from "react";
import CloseIcon from "../../Button/CloseIcon/CloseIcon";

const ImageUpload = (props) => {

    const [filesActive, setFilesActive] = useState(true);
    const [chatActive, setChatActive] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    let selectedCount = 0;


    const filesClick = () => {
        setFilesActive(true);
        setChatActive(false);
    }

    const chatClick = () => {
        setChatActive(true);
        setFilesActive(false);
    }

    const cancelClick = () => {
        setCancel(true);
        props.setIsUploadFromCloud(false);

    }

    const onSelect = () => {
        if (!isSelected) {
            setIsSelected(true);
            selectedCount = selectedCount + 1;
        }
        else {
            setIsSelected(false);
            if (selectedCount !== 0) {
                selectedCount = selectedCount - 1;
            }


        }
    }



    return (
        <AppContext.Consumer>
            {context => (

                <div className={cancel ? "modal save-wrapper" : "modal is-active save-wrapper"}>

                    <div
                        className="modal-content"
                    >
                        <div>
                            <div className="icon-placeholder" style={{ color: 'white', float: 'left' }}
                            >
                                <img src={CloudAdd} alt=""
                                    className="cloud-icon"
                                />
                            </div>

                            <div className="bix-title">bixCloud</div>

                        </div>

                        <br />

                        <div style={{ marginTop: "30px" }}>
                            <hr style={{ backgroundColor: '#0d2a4c', opacity: '40%' }} />
                        </div>

                        <div style={{ width: '97.5%' }}>

                            <div className='folders-div'>
                                <div
                                    style={{
                                        float: 'left',
                                        fontWeight: 'bold',
                                        fontSize: '17px',
                                        borderBottom: filesActive ? '2px #0d2a4c solid' : 'transparent',
                                        color: 'black',
                                        cursor: 'pointer'
                                    }}
                                    onClick={filesClick}
                                >
                                    My Files
                                </div>
                                <div
                                    style={{
                                        float: 'left',
                                        fontWeight: 'bold',
                                        fontSize: '17px',
                                        borderBottom: chatActive ? '2px #0d2a4c solid' : 'transparent',
                                        color: 'black',
                                        cursor: 'pointer',
                                        marginLeft: '40px'
                                    }}
                                    onClick={chatClick}
                                >
                                    bixChat
                                </div>

                            </div>
                            <div style={{ float: 'right' }} className='search-bar'>
                                <input
                                    className="input-style"
                                    placeholder="Find Document"
                                />

                                <img src={search} className='add' alt="" />

                            </div>





                        </div>


                        <br /><br />

                        <div className="folder-display-div ">
                            <br />
                            {filesActive ?
                                <div>
                                    {localStorage.getItem('bixCloud-selectedFolderId') ? (



                                        <div>

                                            {context?.showMainFolders ? (

                                                <div>

                                                    {context?.dataFolders.map((todo, i) => {
                                                        return (
                                                            <div style={{ marginLeft: '15px' }}>


                                                                <div className="float-child">
                                                                    <div className="single-folder-div" onClick={() => {
                                                                        context?.setShowMainFolders(false)

                                                                        localStorage.setItem(
                                                                            'bixCloud-selectedFolderId',
                                                                            todo.id
                                                                        );


                                                                        context?.getSubFolderData();
                                                                    }}>
                                                                        <div className="folder-icon">
                                                                            <img src={FolderIcon} alt="" />

                                                                            <div className="folder-title">
                                                                                {todo.file_name}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>


                                                            </div>
                                                        );
                                                    })}


                                                </div>
                                            ) : (
                                                ''
                                            )

                                            }






                                            {context?.dataSubFolders.map((todo, i) => {
                                                return (
                                                    <div style={{ marginLeft: '15px' }}>


                                                        <div className="float-child">
                                                            <div className="single-folder-div" onClick={() => {

                                                                localStorage.setItem(
                                                                    'bixCloud-selectedFolderId',
                                                                    todo.id
                                                                );


                                                                context?.getSubFolderData();
                                                            }}>
                                                                <div className="folder-icon">
                                                                    <img src={FolderIcon} alt="" />

                                                                    <div className="folder-title">
                                                                        {todo.file_name}
                                                                    </div>
                                                                </div>

                                                            </div>




                                                        </div>


                                                    </div>
                                                );
                                            })}



                                            {context?.dataFiles.map((todo, i) => {
                                                return (
                                                    <div style={{ marginLeft: '15px' }}>

                                                        <div className="float-child" onClick={onSelect}>
                                                            <div className="img-single-folder-div">
                                                                <div className="folder-icon">

                                                                    <img src={img} className='img-view' alt="" />

                                                                    {isSelected ?
                                                                        <img src={checkbox} className='checkbox' alt="" /> : ""
                                                                    }

                                                                    {/* <div className="image-type">{todo.extention.split('/')[1]}</div> */}


                                                                    <div className="image-type">JPEG</div>
                                                                    <div className="folder-title">
                                                                        {todo.file_name}

                                                                    </div>
                                                                </div>

                                                            </div>



                                                        </div>


                                                    </div>
                                                );
                                            })}


                                        </div>

                                    ) : (
                                        <div>
                                            <div className='lottie'>

                                            </div>

                                            <div>
                                                <p className='empty-folder'>Welcome to Bix Cloud</p>
                                                <p className='empty-folder2'>Good to see you here</p>
                                            </div>
                                        </div>
                                    )}







                                    {/* <div className="float-child" onClick={onSelect}>
                                        <div className="img-single-folder-div">
                                            <div className="folder-icon">

                                                <img src={img} className='img-view' />

                                                {isSelected ?
                                                    <img src={checkbox} className='checkbox' /> : ""
                                                }

                                                <div className="image-type">JPEG</div>
                                                <div className="folder-title">
                                                    New
                                                </div>
                                            </div>

                                        </div>



                                    </div> */}
                                </div>

                                :
                                chatActive ?

                                    <div>
                                        <div style={{ marginLeft: '15px' }}>
                                            <div className="float-child">
                                                <div className="single-folder-div">
                                                    <div className="folder-icon">
                                                        <img src={FolderIcon} alt="" />

                                                        <div className="folder-title">
                                                            Bix Chat UI
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="float-child">
                                                <div className="single-folder-div">
                                                    <div className="folder-icon">
                                                        <img src={FolderIcon} alt=""  />

                                                        <div className="folder-title">
                                                            Martin Scorsese
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    : ""}





                        </div>
                        <br /> <br />

                        <div>

                            <div className="select-count">
                                {selectedCount > 1 ?
                                    <div>
                                        {/* <img src={close} alt="" style={{ width: '15px', color: 'black' }} /> */}
                                        <div style={{ float: 'left', paddingRight: '10px', marginTop: '-1px' }}>
                                            <CloseIcon
                                                size='m' />
                                        </div>

                                        <span> {' '}{selectedCount}  {' '} Files Selected</span></div>
                                    :
                                    selectedCount === 1 ?
                                        <div>
                                            <div style={{ float: 'left', paddingRight: '10px', marginTop: '-1px' }}>
                                                <CloseIcon
                                                    size='m' />
                                            </div>
                                            <span> {' '}{selectedCount}  {' '} File Selected</span></div>
                                        : ''}




                            </div>

                            <div className="bottom-div">
                                <img src={CloseSelected} className='img-view' alt="" />
                                <div className='selectedCount' >
                                    1 File Selected
                                </div>
                            </div>



                            <div className="btn-div">


                                <div className='CancelBtn' onClick={() => {
                                    cancelClick()
                                    context.getData();
                                    context.getSubFolderData();
                                }}>
                                    Cancel
                                </div>

                                <div className='ShareBtn' onClick={() => { context.getData() }}>
                                    Share
                                </div>

                                <div className='SendBtn'>
                                    Send
                                </div>



                                {/* <div className='cancel-btn' onClick={cancelClick}>
                Cancel
            </div> */}
                            </div>


                        </div>
                    </div>



                </div>
            )}
        </AppContext.Consumer>

    )
}

export default ImageUpload;