import React from 'react';
import './EmailAvatarStyles.scss'


const EmailAvatar = (props) => {
    return (

        <div className="EmailAvatar" >
            <div
                className={props.size === 'xs' ?
                    props.withBorder === true ? 'avatar-img-xs-border' : 'avatar-img-xs' :
                    props.size === 's' ? "avatar-img-s" :
                        props.size === 'm' ? 'avatar-img-m' :
                        props.size === 'mn' ? 'avatar-img-mn' :
                            props.size === 'l' ? 'avatar-img-l' :

                                props.size === 'xxl' ? 'avatar-img-xxl' :
                                    props.size === 'xl' ? 'avatar-img-xl' :
                                        ''}
                onClick={props.onClick}
            >
                <div className="letters">
                 {props.initial}
                </div>
            </div>

        </div>

    )
}



export default EmailAvatar;