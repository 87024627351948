import React, { useState, useRef } from "react";
// import AppContext from "../../../ContextAPI/AppContext";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }


const Input = (props) => {

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
      );

    return (
        <div className="input-style">
            <input
                className="input-box"
                type={props.type}
                placeholder={props.placeholder}
                // style={{props.width != null? props.width : '280px'}}
                style={{
                    width:windowDimensions.width < 600 ? '95%':
                    props.width != null ? props.width : '280px', 
                    marginLeft:props.marginLeft != null? props.marginLeft : ''}}
                onChange={props.onChange}
                value={props.value}
                size={props.max}
                readOnly={props.readOnly}


            />

        </div>
    )
}


export default Input