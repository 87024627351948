import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import AppContext from "../../../ContextAPI/AppContext";
import ContentEditable from "react-contenteditable";
import AudioComponent from "../../../Components/AudioComponent/AudioComponent";
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import CloseIcon from "../../../Components/Button/CloseIcon/CloseIcon";
import AttachmentPreviewComponent from "./AttachmentPreviewComponent";
import ReactionsComponent from "./ReactionsComponent";




const ReplyComponent = (props) => {
  useEffect(() => {
    newContext.selectedReply?.map((message) => {
      newContext.setToList(message.to_list)
      newContext.setCcList(message.cc_list)
      newContext.setCcMessage(message._cls)


    }
    )


  })
  const newContext = useContext(AppContext)



  // const getDocument = (attachment) => {
  //   if (attachment.includes(".pdf")) {
  //     return pdf;
  //   } else if (attachment.includes(".doc") || attachment.includes(".docx")) {
  //     return doc;
  //   } else if (attachment.includes(".ppt")) {
  //     return ppt;
  //   } else if (attachment.includes(".csv")) {
  //     return csv;
  //   } else if (attachment.includes(".xlsx")) {
  //     return excel;
  //   } else if (attachment.includes(".txt")) {
  //     return txt;
  //   } else {
  //     return attachment;
  //   }
  // };

  const [copied, setCopied] = useState("")
  const [isHyperlink, setIsHyperlink] = useState(false)
  const [newMessageBody, setNewMessageBody] = useState("")


  let messageText;
  let url;

  const checkURL = (messageBody) => {
    let linkPattern = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/);
    return linkPattern.test(messageBody);
  }

  //checking if string is an email
  const checkEmail = (messageBody) => {
    let emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    return emailPattern.test(messageBody);
  }

  //checking if email contains a protocol
  const checkProtocol = (messageBody) => {
    if (messageBody.indexOf("http://") === 0 || messageBody.indexOf("https://") === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  const replaceURLs = (message) => {
    if (!message) return;
    let test;
    var Rexp =
      /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;

    if (message.includes("</a>")) {
      test = message
    } else {
      test = message.replace(Rexp, (url) => {
        var hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
      }
      );
    }
    return test;
  }

  const replaceEmails = (message) => {
    if (!message) return;

    var emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
    return message.replace(emailRegex, (email) => {
      var mail = email;
      return '<a target="_blank" rel="noopener noreferrer">' + mail + '</a>'
    });
  }


  const containsEmail = (message) => {

    var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;


    return re.test(message);

  }


  const findUrl = (message) => {

    let array = message.split(" ")
    let array2 = message.split("<br>")
    let url = false
    for (let i = 1; i < array.length; i++) {
      if (checkURL(array[i])) {
        url = false

      } else {
        url = true

        break
      }
    }
    for (let i = 1; i < array2.length; i++) {
      if (checkURL(array[i])) {
        url = false

      } else {
        url = true
        break
      }
    }
    setNewMessageBody(props.singleThread?.message_body.replace(/<\/?p[^>]*>/g, ""));

    return url


  }

  const findEmail = (message) => {


    let array = message.split(" ")
    let array2 = message.split("<br>")
    let email = false


    for (let i = 0; i < array.length; i++) {
      if (checkEmail(array[i])) {
        email = true;


      } else {
        email = false;
        // break

      }



    }

    for (let i = 1; i < array2.length; i++) {
      if (checkEmail(array[i])) {
        email = true
      } else {
        email = false
        // break
      }


    }


    return email;

  }


  const copyEmail = ((text) => {
    navigator.clipboard.writeText(text.replace(/<[^>]+>/g, ''))
  })
  useEffect(() => {
    setTimeout(() => {
      if (copied !== "") {
        setCopied("")
      }
    }, 1000)

    // containsEmail(props.singleThread?.message_body);

  }, [copied])


  //check if pasted text is wrapped in html tags
  const checkTags = (messageBody) => {
    let htmlString = messageBody;
    let plainText = htmlString.replace(/<[^>]+>/g, '');
    messageText = plainText;

    return messageText;
  }

  const checkTagsExists = (messageBody) => {
    // let pattern = new RegExp(/<[^>]+>/g);
    // if (pattern.test(messageBody)) {
    // 	checkHyperlinks(messageBody);
    // 	return true;
    // }
  }

  // const checkHyperlinks = (messageBody) => {
  //   let link = messageBody;

  //   let temp = document.createElement('div');
  //   temp.innerHTML = link;
  //   if (temp.querySelector("a")) {
  //     url = temp.querySelector("a").getAttribute('href');
  //     setIsHyperlink(true);
  //   }
  //   else {
  //     setIsHyperlink(false);
  //   }
  //   let plainText = link.replace(/<[^>]+>/g, '');
  //   messageText = plainText.replace(/&nbsp;/, ' ');


  // }




  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }




  return (
    <AppContext.Consumer>
      {(context) =>
        <div className="reply-container" style={{ display: props.isReply }}>
          <div
            className="inner-reply"
            style={{
              width: props.width
            }}
          >
            <div
              className={props.selectedTab === "reply" ? "span" : "span-2"}
              style={{
                visibility: !props.isAttachmentView ? "visible" : "hidden",
              }}
            />
            <div className="inner-container" id="replyContainer">
              <div className="header" id="header">
                <div className="right">
                  <div className="topic">
                    {context.selectedReply[0]?.message_subject !== "" ?

                      (context.selectedReply[0]?.message_subject)

                      : context.selectedReply[0]?.message_subject === "" &&
                        context.selectedReply[0]?.message_body === "" ?

                        (
                          <div className="body_text">
                            <ContentEditable
                              className="pre"
                              html={context.selectedReply[0]?.message_body.replace(
                                /<\/?span[^>]*>/g,
                                ""
                              ).replace(/<img .*?>/g, "")} // innerHTML of the editable div
                              disabled={true} // use true to disable edition
                            />
                          </div>
                        )

                        :

                        (
                          <div className="italics">(No subject)</div>
                        )}
                  </div>
                  <div className="sub-topic">
                    {props.selectedTab === "reply"
                      ? "Replying Thread"
                      : props.selectedTab === "reactions"
                        ? "Reaction"
                        : "Attachments"}
                  </div>

                  {context.ccMessage === "Message.CCMessage" ?

                    <div>
                      <div className="to-list">
                        to - {newContext.toList}

                      </div>

                      <div className="cc-list">
                        cc - {newContext.ccList + ""}

                      </div>
                    </div>

                    :
                    ""
                  }








                </div>

                <div className="left">
                  <div style={{ alignSelf: 'center', marginTop: '-5px' }}>
                    <CloseIcon
                      size='m'
                      onClick={() => {
                        props.setIsReply("none");
                        props.setSelectedTabs("reply");
                        context.setSelectedReply([]);
                        props.setIsAttachmentView(false);
                        props.setIsReactionsView(false);
                        context?.setReplySubject(false);
                      }}
                    />
                  </div>
                </div>
              </div>

              {props.isReactionsView ?
                <ReactionsComponent

                  setSelectedReactionTab={props.setSelectedReactionTab}
                  selectedReactionTab={props.selectedReactionTab}
                />

                : ""
              }





              <div
                className="message-container"
                id="reply-message-container"
              >
                {context.selectedReply &&
                  context.selectedReply?.map((message, i) => {
                    return (
                      // Incoming -message
                      <div
                        key={i}
                        className="incoming-message-container"
                        style={{
                          backgroundColor:
                            message._id === context.selectedReply[0]._id
                              ? "#e7eaf8"
                              : "white",
                        }}
                      >
                        <div
                          className="incoming-message"
                          id="messageContainer"
                        >
                          <div className="reply-main-head">
                            <div className="reply-name-sender" style={{ textTransform: !isValidEmail(message.sender_name) ? "capitalize" : "" }}>
                              {message?.sender_name}
                            </div>

                            <div className="reply-time-messsages">
                              {moment().format("L") ===
                                moment(message.timestamp["$date"]).format("L")
                                ? ` Today ${moment(
                                  message.timestamp["$date"]
                                ).format("LT")}`
                                : moment(message.timestamp["$date"]).format(
                                  "LLL"
                                )}
                            </div>
                          </div>
                          {message?.message_body !== "" ? <div>
                            {checkEmail(checkTags(message?.message_body)) ?
                              <div className="linkMsgBox">
                                <div className="emailText" onClick={() => {
                                  copyEmail(message?.message_body)
                                  setCopied(props.singleThread?.sender_public_id)
                                }} >
                                  {messageText}
                                </div>
                              </div>
                              :
                              checkTagsExists(message?.message_body) ?
                                isHyperlink ?
                                  <div className="linkMsgBox">
                                    <a href={url} target="_blank">{messageText}</a>
                                  </div>
                                  :
                                  <div className="message-reply">
                                    <div dangerouslySetInnerHTML={{ __html: messageText }} />
                                  </div>
                                : containsEmail(messageText) ? props.singleThread?.attachments?.length > 0 ?
                                  <div className="linkMsgBox">

                                    <div className="message-reply">
                                      <div dangerouslySetInnerHTML={{ __html: replaceEmails({ messageText }) }} />
                                    </div>
                                  </div> :
                                  <div className="linkMsgBox">

                                    <div className="message-reply">
                                      <div dangerouslySetInnerHTML={{ __html: replaceEmails(message?.message_body) }} />
                                    </div>

                                    {findEmail(message?.message_body) ? "" : <div className="linkBorder">
                                      <div className="borderLine" />
                                      <LinkPreview url={newMessageBody} className="linkPreviewComponent" backgroundColor="#ebedfa" borderColor="#ebedfa" width={300} margin={3} ></LinkPreview>
                                    </div>}

                                  </div>
                                  : checkURL(message?.message_body) ?
                                    checkProtocol(message?.message_body) ?
                                      props.singleThread?.attachments.length > 0 ?
                                        <div className="linkMsgBox">

                                          <div className="message-reply">
                                            <div dangerouslySetInnerHTML={{ __html: replaceURLs(message?.message_body) }} />
                                          </div>
                                          {!findUrl(message?.message_body) ? "" : <div className="linkBorder">
                                            <div className="borderLine" />
                                            <LinkPreview url={newMessageBody} className="linkPreviewComponent" backgroundColor="#ebedfa" borderColor="#ebedfa" width={300} margin={3} ></LinkPreview>
                                          </div>}

                                        </div>
                                        :
                                        <div className="linkMsgBox">
                                          <div className="message-reply">
                                            <div dangerouslySetInnerHTML={{ __html: replaceURLs(message?.message_body) }} />
                                          </div>
                                          {!findUrl(message?.message_body) ? "" : <div className="linkBorder">
                                            <div className="borderLine" />
                                            <LinkPreview url={newMessageBody} className="linkPreviewComponent" backgroundColor="#ebedfa" borderColor="#ebedfa" width={300} margin={3} ></LinkPreview>
                                          </div>}
                                        </div>

                                      :
                                      <div className="linkMsgBox">

                                        <div className="message-reply">
                                          <div dangerouslySetInnerHTML={{ __html: replaceURLs(message?.message_body) }} />
                                        </div>
                                        {findUrl(message?.message_body) ? "" : <div className="linkBorder">
                                          <div className="borderLine" />
                                          <LinkPreview url={"" + newMessageBody} className="linkPreviewComponent" backgroundColor="#ebedfa" borderColor="#ebedfa" width={300} margin={3} ></LinkPreview>
                                        </div>}

                                      </div>



                                    :
                                    <div className="message-reply">
                                      <div dangerouslySetInnerHTML={{ __html: message?.message_body }} />
                                    </div>

                            }
                          </div> : ""}

                          <div className="attachment-container-wrapper">
                            {message?.indexdb_status === "delivered" && message?.attachments.length > 0? message?.attachments?.map((attachment) => {
                              if (attachment?.attachment_name?.includes(".mp3")) {
                                {
                                  context?.attachmentList?.map((attachmentL, index) => {
                                    if (JSON.parse(attachment).id === attachmentL.id) {

                                      return (
                                        <div className="audio-styles">
                                          <AudioComponent audio={message
                                            ?.attachments[0]
                                            .attachment_link} /></div>
                                      )
                                    }
                                  })
                                }
                              } else {
                                return (
                                  <div>
                                    {
                                      context?.attachmentList?.map((attachmentL, index) => {
                                        // console.log(message,"Message",attachmentL)

                                        if (JSON?.parse(attachment)?.id === attachmentL.id) {
                                          return (
                                            <div className="attachement-container-1">
                                              <AttachmentPreviewComponent message={props.singleThread}
                                                link={attachmentL?.thumb_url}
                                                name={attachmentL?.file_name}
                                                attachments={message?.attachments}
                                                download={attachmentL?.file_url}
                                                sending={false} />

                                            </div>
                                          )
                                        } else {
                                          return (
                                            ""
                                          )
                                        }



                                      })

                                    }

                                  </div>
                                )
                              }

                            }) : message?.attachments?.map((attachment) => {

                              if (attachment?.attachment_name?.includes(".mp3")) {
                                return (
                                  <div className="audio-styles">
                                    <AudioComponent audio={message
                                      ?.attachments[0]
                                      .attachment_link} /></div>
                                )
                              } else {
                                return (
                                  <div
                                    className="attachement-container-1"
                                  >

                                    <AttachmentPreviewComponent message={message}
                                      link={attachment?.thumbnail_link}
                                      name={attachment.name}
                                      attachments={message.attachments}
                                      download={attachment.attachment_link}
                                      sending={true} />



                                  </div>
                                )
                              }

                            })}
                          </div>	</div>


                      </div>
                    );
                  })}
              </div>

            </div>
          </div>
        </div>

      }</AppContext.Consumer>
  )
}
export default ReplyComponent;