import React, { useState } from "react";
import "./AddGuestStyles.scss";
import arrow from "../../../Assets/Icons/right-arrow.svg";
import image from "../../../Assets/Icons/plus.png";
import close from "../../../Assets/Icons/close-black.svg";
import AppContext from "../../../ContextAPI/AppContext";
import tick from "../../../Assets/Icons/tick.svg";
import Avatar from '../../../Components/Avatar/avatar'
import checkMark from "../../../Assets/Icons/checkmark.svg";


const AddGuest = (props) => {
    const [searchInput, setSearchInput] = useState("");
    const [searchVal, setSearchVal] = useState("")
    const [recievers, setRecivers] = useState("")
    const [checkmark, setCheckMark] = useState(false)
    const [selected, setSelected] = useState(false)


    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_NewGroupMessage">
                    <div id="myModal" className="modal-1">
                        <div
                            style={{ height: "100%", backgroundColor: "transparent" }}
                            onClick={() => {
                                props.setNewGroupModal1(false);
                                context?.setNewGroupMembers([]);
                                context?.setAllFilteredUsers([]);
                            }}
                        />
                        <div className="modal-content-top" style={{ marginTop: "-82vh" }}>
                            <div className="modal-content-add-members">
                                <div className="plus-container-left">
                                    <img alt="" src={image} className="plus" />
                                </div>

                                <div className="add-header">Add Guests</div>

                                <img alt="" src={close} className="close-guest"
                                    onClick={() => {

                                        context?.setAddGuestModal(false)

                                    }} />
                            </div>



                            <div className="inner-container">
                                <div
                                    style={{
                                        padding: "10px",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div className="search-bar-wrapper-1">
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            {context?.newGroupMembers.map((value) => {
                                                return (
                                                    <div className="selected">
                                                        {value.name}
                                                        <img
                                                            alt=""
                                                            src={close}
                                                            className="close-ic"
                                                            onClick={() => {
                                                                let arr = context?.newGroupMembers.filter
                                                                    (
                                                                        (item) => item !== value
                                                                    );


                                                                context?.setNewGroupMembers(arr);
                                                                context?.setGuestList(arr)

                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div></div>
                                        <input
                                            type="text"
                                            placeholder="Search group members"
                                            value={searchInput}
                                            onChange={(e) => {
                                                setSearchInput(e.target.value);
                                                context.searchAllUsers(e);
                                                setSearchVal(e.target.value)
                                                context.filterContacts(e.target.value)
                                            }}
                                            style={{
                                                marginTop:
                                                    context?.newGroupMembers.length > 0 ? "5px" : "",
                                            }}
                                        />
                                    </div>


                                    <div className="list-container">
                                        {context.filterContacts(searchVal).map((contact) => {
                                            return (
                                                <div className="list-itm"
                                                    onClick={() => {
                                                        // if (context?.newGroupMembers?.filter(
                                                        //   (e) => e.public_id === contact.public_id
                                                        // ).length > 0) {
                                                        //   setSelected(true)

                                                        // }
                                                        if (
                                                            !context?.newGroupMembers?.filter(
                                                                (e) => e.public_id === contact.public_id
                                                            ).length > 0
                                                        ) {
                                                            const obj = {
                                                                email:contact?.email,
                                                                name: contact?.full_name
                                                                    ? contact?.full_name
                                                                    : contact?.email?.split("@")[0],
                                                                public_id: contact?.public_id,
                                                            };
                                                            context?.setNewGroupMembers([
                                                                ...context?.newGroupMembers,
                                                                obj,

                                                            ]);

                                                            context?.setGuestList([
                                                                ...context?.newGroupMembers,
                                                                obj,
                                                            ]);




                                                            console.log(context?.newGroupMembers[0]?.public_id)

                                                            setSearchInput("");
                                                            context?.setAllFilteredUsers([]);
                                                        }

                                                        // else if(context?.newGroupMembers[0].public_id){



                                                        // }
                                                        else {
                                                            setSearchInput("");
                                                            context?.setAllFilteredUsers([]);
                                                        }



                                                    }}>
                                                    <Avatar
                                                        size='m'
                                                        src={contact.avatar === "null" || contact.avatar === null ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : contact.avatar}
                                                    />
                                                    <div className="list-details">
                                                        <div className="name">{contact.name}</div>
                                                        <div className="designation">{contact.headline !== "" ? contact.headline : "bixUser"}</div>

                                                    </div>

                                                    {context?.newGroupMembers?.filter(
                                                        (e) => e.public_id === contact.public_id
                                                    ).length > 0 ?

                                                        <div>
                                                            <img className="chack-mark" src={checkMark} alt="" />
                                                        </div>

                                                        :
                                                        ""
                                                    }



                                                </div>
                                            )
                                        }
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div
                                className="circle"
                                onClick={() => {

                                    context?.setAddGuestModal(false)
                                    console.log(context?.guestList, "listt")

                                }}
                            >

                                <img alt="" src={tick} style={{ height: 30, width: 30 }} />


                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    );
};
export default AddGuest;
