
export const domainServices = () => {
    const handleDomainName = (response, setDomainName) => {
        if (response.status === 200) {
            setDomainName(response.data.display_name.split(" ")[0])
            localStorage?.setItem("bixChat_domainImage", response.data.display_image)
        }

    }
    const handleContract = (response, setContractLoading, setPageToShow) => {
        setContractLoading(false)
        if (response.status === 200) {
            localStorage.clear()
            localStorage.setItem("bix_business_page", 5)
            setPageToShow(5)
        }
    }
    const handleSendOTP = (setSendingOTP, setRunTimer) => {
        setSendingOTP(false)
        setRunTimer(true)
        localStorage.setItem("bix_business_timer", true)
    }
    const handleVerifyOTP = (response, setRunTimer, setOTPVerifying, setOTPVerified,setOTPError) => {
        if (response.status === 200) {
            localStorage.setItem("bix_business_OTP_verified", true)
            localStorage.setItem("bix_business_timer", false)
            setRunTimer(false)
            setOTPVerifying(false)
            setOTPVerified(true)
        } else {
            setOTPVerifying(false)
            setOTPError(true)
        }
    }
    return {
        handleDomainName,
        handleContract,
        handleSendOTP,
        handleVerifyOTP
    }
}