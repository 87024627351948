import React, { useRef, useState, useContext } from "react"
import AdminAgreement from "./AdminAgreement"
import AdminBetaSuccess from "./AdminBetaSuccess"
import AdminCompanyForm from "./AdminCompanyForm"
import AdminForm from "./AdminForm"
import AdminDomainSetup from "./AdminDomainSetup"
import AppContext from "../../../ContextAPI/AppContext"
const AdminBeta = () => {
    let localName = localStorage.getItem("bix_business_personal_name")
    let localCountry = localStorage.getItem("bix_business_country")
    let localContact = localStorage.getItem("bix_business_personal_contact")
    let localNIC = localStorage.getItem("bix_business_personal_nic")
    let localDesignation = localStorage.getItem("bix_business_personal_designation")
    let localAddress = localStorage.getItem("bix_business_address")
    let localOrganization = localStorage.getItem("bix_business_organization")
    let localRegNo = localStorage.getItem("bix_business_regNo")
    let localAgreement = localStorage.getItem("bix_business_agreement") === "true" ? true : false
    let localDomain = localStorage.getItem("bix_business_domain")
    let localEmail = localStorage.getItem("bix_business_personal_email")
    let localLogoName = localStorage.getItem("bix_business_logo_name")

    const [country, setCountry] = useState(localCountry === null ? "" : localCountry);
    const [name, setName] = useState(localName === null ? "" : localName);
    const [phone, setPhone] = useState(localContact === null ? "" : localContact);
    const [address, setAddress] = useState(localAddress === null ? "" : localAddress);
    const [nic, setNic] = useState(localNIC === null ? "" : localNIC);
    const [organization, setOrganization] = useState(localOrganization === null ? "" : localOrganization);
    const [designation, setDEsignation] = useState(localDesignation === null ? "" : localDesignation)
    const [regNo, setregNo] = useState(localRegNo === null ? "" : localRegNo);
    const [logo, setLogo] = useState(localLogoName === null ? "" : localLogoName);
    const [checked, setChecked] = useState(localAgreement === null ? false : localAgreement);
    const [domain, setDomain] = useState(localDomain === null ? "" : localDomain);
    const [email, setEmail] = useState(localEmail === null ? "" : localEmail);
    const [OTP, setOTP] = useState("");
    const [buyDomain, setBuyDomain] = useState(false);
    const [setupDNS, setSetupDNS] = useState(false);
    const uploadImage = useRef(null);
    const context = useContext(AppContext)

    const getPage = () => {
        if (context.pageToShow === 1) {
            return <AdminForm
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                OTP={OTP}
                setOTP={setOTP}
                phone={phone}
                setPhone={setPhone}
                nic={nic}
                setNic={setNic}
                designation={designation}
                setDEsignation={setDEsignation}
            />
        } else if (context.pageToShow === 2) {
            return <AdminCompanyForm
                country={country}
                setCountry={setCountry}
                address={address}
                setAddress={setAddress}
                organization={organization}
                setOrganization={setOrganization}
                setregNo={setregNo}
                regNo={regNo}
                logo={logo}
                setLogo={setLogo}
                uploadImage={uploadImage}
                onImageClick={onImageClick}
            />
        }

        else if (context.pageToShow === 3) {
            return <AdminAgreement
                checked={checked}
                setChecked={setChecked}
            />
        }
        else if (context.pageToShow === 4) {
            return <AdminDomainSetup
                domain={domain}
                setDomain={setDomain}
                buyDomain={buyDomain}
                setBuyDomain={setBuyDomain}
                setupDNS={setupDNS}
                setSetupDNS={setSetupDNS}
                name={name}
                phone={phone}
                nic={nic}
                designation={designation}
                country={country}
                address={address}
                organization={organization}
                regNo={regNo}
                email={email} />
        }
        else if (context.pageToShow === 5) {
            return <AdminBetaSuccess />
        }

    }
    const onImageClick = () => {
        uploadImage.current.click();
    };
    return (getPage()
    )
}
export default AdminBeta