import React, { useContext } from "react";
import "./AdminBetaStyles.scss";
import AdminSteps from "../AdminSteps/AdminSteps";
import Agreement from "../Agreement/Agreement";
import MobileSteps from "../AdminSteps/MobileSteps/MobileSteps";
import AppContext from "../../../ContextAPI/AppContext";
const AdminAgreement = (props) => {
    const context = useContext(AppContext)
    return (
        <div>
            <div className="AdminBetaContainer">
                <div className="AdminBetaInnerContainerRight">
                    <div className="wrapper2">
                        <div className="onboard-header">
                            <div className="bixHeader">bixBusiness</div>
                            <div className="header-bottom-text">Recommended for companies or industries.</div>
                        </div>
                        <div className="step-header">
                            <div className="step-hdr">Step 3</div>
                            <div className="step-header-bottom-text">Read our agreement and accept</div>
                        </div>
                        <div className="stp" style={{ marginTop: "4.5vh" }}>
                            <AdminSteps step={3} />
                        </div>
                        <div className="stp-mobile">
                            <MobileSteps step={3} />
                        </div>
                    </div>
                </div>
                <div className="AdminBetaInnerContainerLeft">
                    <div className="wrapper">
                        <div className="content-header">
                            <div className="content-Header">Agreement</div>
                            <div className="content-header-bottom-text">Read our agreement</div>
                        </div>
                        <div className="form-wrapper">
                            <div className="agreement-div">
                                <Agreement />
                                <div className="terms">
                                    <input
                                        type="checkbox"
                                        className="check"
                                        id="termsCheck"
                                        checked={props.checked}
                                        onChange={() => {
                                            props.setChecked(!props.checked);
                                            localStorage.setItem("bix_business_agreement", !props.checked)
                                        }}
                                    />
                                    I accept the beta test agreement
                                </div>
                            </div>
                            <div className="right-btn-container">
                                <div className="scnd-btn" onClick={() => {
                                    localStorage.setItem("bix_business_page", 2)
                                    context.setPageToShow(2)
                                }}>Back</div>
                                <div className="prim-btn" style={{ backgroundColor: !props.checked ? "#707070" : "" }} onClick={() => {
                                    if (props.checked) {
                                        localStorage.setItem("bix_business_page", 4)
                                        context.setPageToShow(4)
                                    }

                                }}>Next</div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};
export default AdminAgreement;
