import React, { useEffect } from "react"
import home from "../../../../Assets/Icons/refresh.svg"
import error from "../../../../Assets/Images/error.png"

import "./ErrorPageStyles.scss"

const ErrorPage = () => {
    useEffect(() => {
        setTimeout(function () {
            window.location.reload();
        }, 5000);
    }, [])
    return (
        <div className="ErrorPageStyles">
            <div className="img-err">
                <img alt="" src={error} />
            </div>
            <div className="bix-error">Something went wrong</div>
            <div className="sub-error-text">We are working on fixing the problem. Please refresh the page and try again. </div>
            <div className="home-link" onClick={() => {
                window.location.reload()
            }}>
                <img alt="" src={home} />
            </div>
        </div>
    )
}
export default ErrorPage