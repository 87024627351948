import React, { useState, useContext } from "react"
import Loader from "react-js-loader";
import AppContext from "../../../ContextAPI/AppContext";
import "../RightSidebar/RightSideBarStyle.scss";
import next from "../../../Assets/Icons/next.png";
import videoTemp from "../../../Assets/Images/defaultVideo.jpg"
import pdf from "../../../Assets/Icons/pdf.png";
import doc from "../../../Assets/Icons/doc.jpg";
import csv from "../../../Assets/Icons/csv.jpg";
import excel from "../../../Assets/Icons/excel.jpg";
import ppt from "../../../Assets/Icons/ppt.png";
import txt from "../../../Assets/Icons/txt.png";
import mic from "../../../Assets/Icons/audio-mic.svg";
import photo from "../../../Assets/Icons/photo-camera.svg";

import Feature from "../../../Feature/Feature";


const DetailsContainer = (props) => {
    const [media, setMedia] = useState(false);
    const [showAttachments, setShowAttachments] = useState(true);
    const [showSubjectMessages, setShowSubjectMessages] = useState(true);

    const newContext = useContext(AppContext)

    const [viewMore, setViewMore] = useState(false)
    const message = newContext?.selectedChatroomDatas?.threads !== undefined ? newContext?.selectedChatroomDatas?.threads : {}

    const remindMessages = () => {
        return (
            <div className="reminded-message-wrapper">
                <div className="header">Remind Messages</div>
                <img src={next} alt="" className="icon" />
            </div>

        )
    }


    const getDocument = (attachment) => {
        if (attachment.file_name.includes(".pdf")) {
            return pdf;
        } else if (attachment.file_name.includes(".doc") || attachment.file_name.includes(".docx")) {
            return doc;
        } else if (attachment.file_name.includes(".ppt")) {
            return ppt;
        } else if (attachment.file_name.includes(".csv")) {
            return csv;
        } else if (attachment.file_name.includes(".xlsx")) {
            return excel;
        } else if (attachment.file_name.includes(".txt")) {
            return txt;
        } else if (attachment.file_name.includes(".mp4")) {
            return videoTemp;
        }
        else {
            return photo;
        }
    };



    return (
        <AppContext.Consumer>
            {(context) => (
                <div>
                    <Feature name='remind-messages' activeComponent={remindMessages()} inactivecomponent={null} />


                    <div className="attachments-wrapper">
                        <div className="flex-container">
                            <img src={next} alt="" className="icon" style={{ transform: showAttachments ? "rotate(90deg)" : "rotate(0deg)" }} />
                            <div className="header" onClick={() => {

                                if (!showAttachments) {
                                    setShowAttachments(true)


                                } else {
                                    setShowAttachments(false)

                                }


                            }}>Attachments</div>

                        </div>

                        {showAttachments ?
                            context?.attachmentListLoading ?


                                (<div className="loader-container">
                                    <Loader
                                        type="bubble-top"
                                        bgColor={"#65b4fe"}
                                        color={"#65b4fe"}
                                        size={22}
                                    /></div>)
                                :
                                (<div>
                                    <div className="attachment-wrapper" style={{ height: context?.attachmentList?.length > 0 ? "150px" : "" }}>
                                        {context?.attachmentList?.length > 0 ?
                                            viewMore ? context?.attachmentList?.map((value, index) => {

                                                return (
                                                    !value.file_name.includes(".mp3") ?
                                                        <a href={value.file_url} download>  <div className="attach-prev">

                                                            <img
                                                                className="img"
                                                                alt=""
                                                                id={index}
                                                                src={getDocument(value)}
                                                            />

                                                            <div className="attachment-name-hov">{value.file_name}</div>
                                                        </div></a>

                                                        : <a href={value.file_url} download><div className="attachment">
                                                            <img className="img-mic" alt="" src={mic} />
                                                        </div></a>
                                                )



                                            }) : context?.attachmentList?.slice(0, 6).map((value, index) => {
                                                return (
                                                    !value.file_name.includes(".mp3") ?
                                                        <a href={value.file_url} download>   <div className="attach-prev" onClick={() => {
                                                        }}>
                                                            <img
                                                                className="img"
                                                                alt=""
                                                                id={index}
                                                                src={getDocument(value)}
                                                            />

                                                            <div className="attachment-name-hov">{value.file_name}</div>
                                                        </div></a>
                                                        : <a href={value.file_url} download><div className="attachment">
                                                            <img className="img-mic" alt="" src={mic} />
                                                        </div></a>
                                                )



                                            }) : <div className="empty-attachment">Send attachments to {context?.selectedChatroomDatas.chatroomData.profile_name ? context?.selectedChatroomDatas.chatroomData.profile_name : context?.selectedChatroomDatas.chatroomData.group_title}</div>
                                        }
                                    </div>
                                    {!viewMore && context?.attachmentList.length > 6 ? <div className="view-more" onClick={() => { setViewMore(true) }}>View More</div> : ""}
                                </div>)


                            :
                            ""

                        }

                    </div>

                    <div className="photos-and-media-wrapper">
                        <div className="flex-container">
                            <img src={next} alt="" className="icon" style={{ transform: media ? "rotate(90deg)" : "" }} />
                            <div className="header" onClick={() => {
                                if (!media) {
                                    context?.setMediaListLoading(true)
                                    context?.getMedia()
                                    setMedia(true)
                                    if (props.class === 'ChatRoom.GroupChatRoom') {
                                        // mixpanel.track('Photos and multimedia (group)')
                                    }
                                    else {
                                        // mixpanel.track('Photos and multimedia (private chat)')
                                    }

                                } else {
                                    context?.setMediaList([])
                                    setMedia(false)

                                }



                            }}>Photos and Multimedia</div>
                        </div>
                        {media ?
                            context?.mediaListLoading ?
                                (<div className="loader-container">
                                    <Loader
                                        type="bubble-top"
                                        bgColor={"#65b4fe"}
                                        color={"#65b4fe"}
                                        size={22}
                                    /></div>) :
                                <div className="photos-wrapper">
                                    {context?.mediaList?.length > 0 ?
                                        context?.mediaList?.map((value, index) => {
                                            return (
                                                <img
                                                    alt=""
                                                    id={index}
                                                    src={
                                                        value.attachment_name.includes(
                                                            ".png"
                                                        ) ||
                                                            value.attachment_name.includes(
                                                                ".jpg"
                                                            ) ||
                                                            value.attachment_name.includes(
                                                                ".jpeg"
                                                            ) ?
                                                            value.attachment_link : videoTemp}
                                                    onClick={() => {

                                                        props.setImage(context?.mediaList);
                                                        props.setCurrentImage(1);
                                                        props.setImagePreview(true);
                                                    }}
                                                />
                                            )
                                        }) : <div className="empty-media"><div className="empty-attachment">Send photos to {context?.selectedChatroomDatas.chatroomData.profile_name ? context?.selectedChatroomDatas.chatroomData.profile_name : context?.selectedChatroomDatas.chatroomData.group_title}</div></div>
                                    }




                                </div> : ""}

                    </div>
                    {/* {console.log(context?.unreadSelectedChat[0]?.thread["$oid"], "pppppp")} */}


                    <div className="subject-wrapper">
                        <div className="flex-container">
                            <img src={next} alt="" className="icon" style={{ transform: showSubjectMessages ? "rotate(90deg)" : "rotate(0deg)" }}
                                onClick={() => {
                                    if (!showSubjectMessages) {
                                        setShowSubjectMessages(true)


                                    } else {
                                        setShowSubjectMessages(false)

                                    }
                                }}
                            />
                            <div className="header" onClick={() => {

                                if (!showSubjectMessages) {
                                    setShowSubjectMessages(true)


                                } else {
                                    setShowSubjectMessages(false)

                                }


                            }}>Subject Messages</div>

                        </div>

                        {showSubjectMessages ?
                            context?.subjectListLoading ?
                                (
                                    <div className="loader-container">
                                        {
                                            <Loader
                                                type="bubble-top"
                                                bgColor={"#65b4fe"}
                                                color={"#65b4fe"}
                                                size={22}
                                            />
                                        }
                                    </div>
                                )
                                :
                                <div className="subject-mes">

                                    <div className="sub-wrapper">
                                        {message && Object.entries(message)?.map(([key, value]) => {
                                            return (
                                                <div className="subject-messages-main">
                                                    {/* {value[0][0]?.indexdb_status === "delivered" ? <div className="message-time">{getDate(key)}</div> : ""} */}

                                                    {value?.map((singleTread, i) => {
                                                        if (!singleTread[0]?.message_subject === "") {
                                                            return (
                                                                <>
                                                                    {singleTread[0]?.message_subject.length ?

                                                                        <div className="rightside-subject">
                                                                            {singleTread[0]?.message_subject}

                                                                        </div>
                                                                        :
                                                                        ""}
                                                                </>
                                                            );

                                                        }
                                                    }
                                                    )}
                                                </div>
                                            )

                                        })}
                                    </div>

                                </div>


                            : ""

                        }




                    </div>

                </div>
            )}
        </AppContext.Consumer>

    )


}
export default DetailsContainer