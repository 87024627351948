import React, { useState, useRef } from "react"
import AppContext from "../../../ContextAPI/AppContext";
import image from "../../../Assets/Icons/user-square-white.svg";
import close from "../../../Assets/Icons/cross-square-button.svg";
import tick from "../../../Assets/Icons/tick.svg";
import clear from "../../../Assets/Icons/close-black.svg";
import "./AdminModalStyles.scss"
import { getDPService } from "../../../services/DPService";

const AdminModal = (props) => {
    const [members, setMembers] = useState(props.membersList)
    const [selected, setSelected] = useState(props.membersList[1])
    localStorage.setItem("bixChat_adminPublicId", selected.public_id)
    const input = useRef(null)
    const searchMember = (e) => {
        let array = props.membersList.filter((item) => item.full_name.toLowerCase().includes(e.target.value))
        setMembers(array)
    }
    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_adminModal" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
                    <div id="myModal" className="modal-1">
                        <div className="modal-content-top" >
                            <div className="modal-content-add-admin">
                                <div className="plus-container-left">
                                    <img alt="" src={image} className="plus" />
                                </div>

                                <div className="add-header">Group Admin</div>
                                <img alt="" src={close} className="close"
                                    onClick={() => { context?.setAdminModalVisibility(false) }} />

                            </div>

                            <div className="inner-container">
                                <div className="search-container">
                                    <input type="text"
                                        className="search-input" id="searchInput"
                                        ref={input}
                                        onChange={(e) => searchMember(e)} style={{ display: selected === "" ? "block" : "none" }} />
                                    <div className="search-div" style={{ display: selected !== "" ? "block" : "none" }}>
                                        <div className="name-container">
                                            <div className="name-div">{selected.full_name}</div>
                                            <img alt="" src={clear} className="clear" onClick={() => {
                                                setSelected("")
                                                input.current.focus()
                                            }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="group-members-container">
                                    <div className="header-members">Group Members</div>
                                    <div className="memebrs-list" id="myList">
                                        {members.map((value, i) => {
                                            if (value.public_id !== localStorage.getItem(
                                                "bixChat_loggedUserPublicId"
                                            )) {
                                                return (

                                                    <div className="members" key={i}
                                                        style={{ marginBottom: i !== props.membersList.length - 1 ? "10px" : "" }}
                                                        id="item"
                                                        onClick={() => {
                                                            setSelected(value)
                                                            localStorage.setItem("bixChat_adminPublicId", value.public_id)
                                                        }}>
                                                        <div className="circle-add" style={{ border: value.is_admin ? "3px solid #0d2a4c" : value?.isModerator ? "3px solid #F7AF5F" : "" }}>
                                                            <img
                                                                src={
                                                                    getDPService().getDP("ChatRoom.PrivateChatRoom", value.public_id)
                                                                        ? getDPService().getDP("ChatRoom.PrivateChatRoom", value.public_id)
                                                                        : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                                                }
                                                                className="img"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="div-name">
                                                            <div className="name" id="name">
                                                                {value.public_id ===
                                                                    localStorage.getItem(
                                                                        "bixChat_loggedUserPublicId"
                                                                    )
                                                                    ? "You"
                                                                    : value.full_name}
                                                            </div>
                                                            <div className="email">{value.email}</div>
                                                        </div>
                                                    </div>


                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="circle" onClick={() => {
                                if (selected !== "") {
                                    let chatroom_id = localStorage.getItem("bixChat_selectedChatId")
                                    context?.setIsDeleting(true)
                                    context?.leaveGroup(chatroom_id, localStorage.getItem("bixChat_adminPublicId"))
                                } else {
                                }
                            }}>
                                <img alt="" src={tick} style={{ height: 30, width: 30 }} />

                            </div>


                        </div>
                    </div>
                </div>
            )}</AppContext.Consumer>
    )
}
export default AdminModal