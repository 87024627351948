
export const chatroomServices = () => {

    const handleSupportResponse = async (response, setAnim, getAllChatroom, setSendModal) => {
        if (response.status === 200) {
            setAnim(true)
            await getAllChatroom()
            if (localStorage.getItem("bixChat_isPrivate") !== "true") {
                setTimeout(() => {
                    setSendModal(true)
                }, 2000);
            }
        }
    }
    return {
        handleSupportResponse
    }

}