import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import config from "../../../config";
import "../Login.scss";
import "../SignupWrapper/Signup.scss"
import Button from "../../../Components/Button/Button";

const SignupSuccess = (props) => {
  const container = useRef(null);
  const startButton = useRef(null);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        startButton.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  useEffect(() => {
    let animate = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("../../../Assets/Animations/success.json"),
    });
    animate.setSpeed(0.6);
  }, []);
  const getStart = () => {
    return (
      <Button
        ref={startButton}
        getStarted={true}
        loading={props.getStartedLoading}
        onClick={props.getStartedOnClick}
        buttonText="Get Started"
      />
       
      
    );
  };
  return (
    <div className="success-background">
      <div className="__SuccessPage" style={{paddingBottom:"20px"}}>
        <div className="wrapper">
          <div className="header-bix">bixChat</div>
          <div className="sub-head">Registration Successful</div>
          <div className="form-wrapper">
            <div className="inner-container">
              <div className="anime-container" ref={container}></div>

              <div className="welcome-message-style">
                Welcome {props.fullname}!
              </div>
              <div className="desc">
                Your bixChat account email
                <br/>
                <span className="email-style">
                  {" "}
                  {props.username}@{config.emailText}
                </span>
              </div>
            </div>
            {getStart()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignupSuccess;
