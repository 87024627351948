import React, { useState, useRef, useContext, useEffect } from "react";
import AppContext from "../../../ContextAPI/AppContext";
import '../SaveToContact/SaveToContactStyles.scss'
import LeftArrow from "../../../Assets/Icons/arrow-grey.svg";
import Logo from '../../../Assets/Images/sample-logo.png'
import Resizer from "react-image-file-resizer";
import Avatar from "../../../Components/Avatar/avatar";
import GalleryAdd from '../../../Assets/Icons/gallery-add.svg'
import Input from "../../../Components/InputComponents/InputComponent";
import UploadDpModal from "../../../Components/Modal/UploadDPModal/UploadDPModal";
import { getDPService } from "../../../services/DPService";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}



const SaveContactRight = (props) => {

    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [organization, setOrganization] = useState("")
    const [department, setDepartment] = useState("")
    const [role, setRole] = useState("")
    const inputRef = useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [addPhoto, setAddPhoto] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [isChanged, setIsChanged] = useState(false)
    const [base64Image, setBase64Image] = useState("")
    const conText = useContext(AppContext)
    const [fnameErr, setIsFnameErr] = useState("true")
    const [lnameErr, setIsLnameErr] = useState("true")
    const [emailErr, setEmailErr] = useState("true")
    const [codeErr, setCodeErr] = useState("true")
    const [phoneErr, setPhoneErr] = useState("true")
    const [orgErr, setOrgErr] = useState("true")
    const [deptErr, setDeptErr] = useState("true")
    const [roleErr, setRoleErr] = useState("true")
    const [more, setMore] = useState(false)

    let codes = ['+94', '+93', '+355'];
    let chatroomData = conText.selectedChatroomDatas?.chatroomData?.contact
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    // const handleErrors


    useEffect(() => {
        let chatId = localStorage.getItem("bixChat_selectedChatId")
        let chatroom = conText?.selectedChatroomDatas?.chatroomData
        let contact = chatroom?.contact

        if (chatId != null) {
            if (conText?.selectedChatroomDatas?.chatroomData?._cls ===
                "ChatRoom.PrivateChatRoom") {
                if (conText.isEdit === true) {
                    setBase64Image(contact?.avatar)
                    setFirstName(contact?.name.split(' ')[0])
                    setLastName(contact?.name.split(' ')[1])
                    setEmail(contact?.email)
                    setCode(contact?.code)
                    setPhoneNum(contact?.phoneNum)
                    setOrganization(contact?.organization)
                    setRole(contact?.role)
                    setDepartment(contact?.department)
                    // setDepartment
                }
                else {
                    setFirstName(chatroom.profile_name.split(' ')[0])
                    setLastName(chatroom.profile_name.split(' ')[1])
                    setEmail(chatroom.profile_email)

                    if (getDPService().getDP(chatroom._cls, chatroom.profile_public_id)) {
                        setBase64Image(getDPService().getDP(chatroom._cls, chatroom.profile_public_id))
                        setAddPhoto(true)
                    }
                    else {
                        setBase64Image(GalleryAdd)
                    }
                }


                setIsFnameErr("false")
                setIsLnameErr("false")
                setEmailErr("false")



            }
            else {
                setEmail(conText.selectedChatroomDatas?.chatroomData?.profile_email)
                setIsFnameErr("true")
                setIsLnameErr("true")
                setEmailErr("false")

            }

        }



    }, []);
    // const handleTitle = (e) => {

    //     let titleValue = e.target.value;

    //     if (titleValue === '') {
    //         setTitle(" ");
    //     } else {
    //         setTitle(titleValue)
    //     }


    // }


    const handleFirstName = (e) => {

        let fnameValue = e.target.value;

        if (fnameValue === '') {
            setFirstName("");
            setIsFnameErr("true")
        } else {
            if (conText.isEdit) {
                if (chatroomData.name.split(' ')[0] !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setFirstName(fnameValue)
            setIsFnameErr("false")

        }



    }

    const handleLastName = (e) => {

        let lnameValue = e.target.value;

        if (lnameValue === '') {
            setLastName("");
            setIsLnameErr("true")

        } else {
            if (conText.isEdit) {
                if (chatroomData.name.split(' ')[1] !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setLastName(lnameValue)
            setIsLnameErr("false")

        }


    }


    const handleEmail = (e) => {

        let emailValue = e.target.value;

        if (emailValue === '' || emailValue === undefined) {
            setEmail("");
            setEmailErr("true")

        } else {
            if (conText.isEdit) {
                if (chatroomData.email !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setEmail(emailValue)
            setEmailErr("false")

        }


    }

    const handleCode = (e) => {

        let codeValue = e.target.value;

        if (codeValue === '') {
            setCode("");
            setCodeErr("true")

        } else {
            if (conText.isEdit) {
                if (chatroomData.code !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setCode(codeValue)
            setCodeErr("false")


        }



    }

    const handlePhoneNum = (e) => {

        let phoneValue = e.target.value;

        if (phoneValue === '') {
            setPhoneNum("");
            setPhoneErr("true")

        } else if (phoneValue.length <= 10) {
            if (conText.isEdit) {
                if (chatroomData.phoneNum !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setPhoneNum(phoneValue)
            setPhoneErr("false")

        }


    }

    const handleOrganization = (e) => {

        let orgValue = e.target.value;

        if (orgValue === '') {
            setOrganization("");
            setOrgErr("true")

        } else {
            if (conText.isEdit) {
                if (chatroomData.organization !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setOrganization(orgValue)
            setOrgErr("false")

        }


    }
    const handleDept = (e) => {

        let deptValue = e.target.value;

        if (deptValue === '') {
            setDepartment("");
            setDeptErr("true")

        } else {
            if (conText.isEdit) {
                if (chatroomData.department !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setDepartment(deptValue)
            setDeptErr("false")

        }


    }

    const handleRole = (e) => {

        let roleValue = e.target.value;

        if (roleValue === '') {
            setRole("");
            setRoleErr("true")

        } else {
            if (conText.isEdit) {
                if (chatroomData.role !== e.target.value) {
                    setIsChanged(true)
                } else {
                    setIsChanged(false)
                }
            }
            setRole(roleValue)
            setRoleErr("false")

        }


    }



    // const handleIndustry = (e) => {

    //     let indusValue = e.target.value;

    //     if (indusValue === '') {
    //         setIndustry(" ");
    //         setIndErr("true")

    //     } else {
    //         setIndustry(indusValue)
    //         setIndErr("false")

    //     }


    // }


    const handleContactImage = (e) => {
        setIsChanged(true)

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            let base64Only = reader.result;

            setBase64Image(base64Only)
            setUploadModal(true)
            setAddPhoto(true);
        };

        reader.onerror = ((err) => {

        })

    }



    const onChange = async (event) => {
        await resizeFile(event.target.files[0]).then((res) => {
        });

    };


    const resizeFile = (newcroppedImage) =>

        new Promise((resolve) => {
            Resizer.imageFileResizer(
                newcroppedImage,

                300,

                300,

                "PNG",

                100,

                0,

                (uri) => {
                    resolve(uri);
                },

                "base64"
            );


        });

    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="__SaveContactRightWrapper">
                    <div className="navbar">
                        <img src={LeftArrow} alt="" className="back-arrow"
                            onClick={() => {
                                if (context.isEdit) {
                                    props.setRightSideBarStatus(true)
                                }
                                context.setContactModal(false);
                                context.setIsEdit(false)

                            }}
                        />
                        <div className="save-tite">{context.isEdit ? "Edit" : "Save"} Contact</div>
                    </div>

                    <div className="formWrapper" style={{ paddingBottom: '20px' }}>
                        <div className="workspace-div">

                            <div className="workspace-div-title" style={{ width: '20%' }}>
                                Save to
                            </div>
                            <div style={{ width: '100%' }}>
                                <div className=" workspace-select-div">
                                    <div className="logo">
                                        <img src={Logo} style={{ borderRadius: '50%', height: '23px' }} alt="" />
                                    </div>
                                    <select className="workspace-select">
                                        <option>
                                            Binary Technologies
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="personalinfor">
                            Personal info
                        </div>

                        {addPhoto ?
                            <div style={{ marginTop: '13px' }} >
                                <div

                                >
                                    <Avatar
                                        size='l'
                                        src={base64Image}
                                        onClick={triggerFileSelectPopup}
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        ref={inputRef}
                                        onChange={(e) => {
                                            handleContactImage(e);
                                            onChange(e);

                                        }}

                                    />


                                </div>

                            </div> :
                            <div style={{ marginTop: '13px' }} >
                                <div className="gallery-add"
                                    onClick={triggerFileSelectPopup}
                                >
                                    < img
                                        src={context.isContact ? conText.selectedChatroomDatas?.chatroomData?.contact?.avatar : GalleryAdd}
                                        style={{ borderRadius: "50%" }}
                                    />
                                </div>

                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    ref={inputRef}
                                    onChange={(e) => {
                                        handleContactImage(e);
                                        onChange(e);

                                    }}

                                />


                            </div>


                        }

                        <div style={{ display: 'flex', paddingTop: '15px' }}>
                            <div>
                                <div className="input-heading">First Name</div>
                                <div>
                                    <Input
                                        placeholder='First Name'
                                        type='text'
                                        value={firstName && firstName}
                                        width='110px'
                                        onChange={(e) => {
                                            handleFirstName(e)
                                        }

                                        }

                                    />

                                </div>
                            </div>
                            <div style={{ paddingLeft: '10px' }}>
                                <div className="input-heading">Last Name</div>
                                <div>
                                    <Input
                                        placeholder='Surname'
                                        type='text'
                                        value={lastName}
                                        width='110px'
                                        onChange={(e) => { handleLastName(e) }}

                                    />
                                </div>
                            </div>
                        </div>

                        <div >
                            <div className="input-heading">Email Address</div>
                            <div>
                                <Input
                                    placeholder='Email'
                                    type='email'
                                    value={email}
                                    readOnly={'readOnly'}
                                    width='230px'
                                    onChange={(e) => { handleEmail(e) }}

                                />
                            </div>
                        </div>
                        <div>
                            <div className="input-heading">Phone Number</div>

                            <div style={{ display: 'flex'}}>
                                <div className="input-style-phone">
                                    <select
                                        className="input-box"
                                        style={{ width: '50px', paddingLeft: '5px' }}
                                        placeholder='Code'
                                        value={code}
                                        onChange={(e) => { handleCode(e) }} >
                                        {codes.map((codes, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    value={codes}
                                                >
                                                    {codes}
                                                </option>
                                            )
                                        })}
                                    </select>

                                </div>
                                <Input
                                    placeholder='Primary Phone Number'
                                    type='number'
                                    width='180px'
                                    value={phoneNum}
                                    max={5}
                                    onChange={(e) => { handlePhoneNum(e) }}

                                />


                            </div>

                        </div>

                        <div className="more-div">
                            <div className="personalinfor">
                                More info
                            </div>
                            <div>
                                <label class="switch" >
                                    <input type="checkbox" onClick={() => setMore(!more)} />
                                    <span class="slider round"></span>
                                </label>


                            </div>
                        </div>
                        {more ? <div> <div>
                            <div style={{ display: 'flex', paddingTop: '15px' }}>
                                <div>
                                    <div className="input-heading">Organization</div>
                                    <Input
                                        placeholder='Organization'
                                        type='text'
                                        value={organization}
                                        width='110px'
                                        onChange={(e) => { handleOrganization(e) }}

                                    />
                                </div>

                                <div style={{ paddingLeft: '10px' }}>
                                    <div className="input-heading">Role</div>
                                    <Input
                                        placeholder='Role'
                                        type='text'
                                        value={role}
                                        width='110px'
                                        onChange={(e) => { handleRole(e) }}

                                    />
                                </div>




                            </div>
                            <div>
                                <div className="input-heading">Department/Sector</div>
                                <Input
                                    placeholder='Department'
                                    type='text'
                                    value={department}
                                    width='230px'
                                    onChange={(e) => { handleDept(e) }}

                                />
                            </div>
                        </div>
                            <div style={{ width: windowDimensions.width < 600 ? '95%' : '230px', borderBottom: '1px solid #dcdcdc' }} />
                        </div>

                            : ""}

                        <div className="btn-grp">
                            <div className="cancel-btn center-content"
                                onClick={() => {
                                    context.setContactModal(false);
                                }
                                }
                            >
                                Cancel
                            </div>

                            <div className={fnameErr === 'false' && lnameErr === 'false' && emailErr === 'false'
                                ? "save-btn center-content" : "save-btn-disable center-content"}
                                style={{ backgroundColor: context.isEdit && !isChanged ? "#9d9d9d" : "" }}
                                onClick={() => {
                                    if (fnameErr === 'false' && lnameErr === 'false' && emailErr === 'false'
                                    ) {
                                        if (context.isEdit && isChanged) {
                                            context.setIsSaving(true)
                                            context?.saveContact(
                                                base64Image,
                                                title,
                                                firstName,
                                                lastName,
                                                email,
                                                code,
                                                phoneNum,
                                                organization,
                                                department,
                                                role,
                                            );
                                        } else if (!context.isEdit) {
                                            context.setIsSaving(true)
                                            context?.saveContact(
                                                base64Image,
                                                title,
                                                firstName,
                                                lastName,
                                                email,
                                                code,
                                                phoneNum,
                                                organization,
                                                department,
                                                role,
                                            );
                                        }
                                    }

                                }}

                            >
                                {context.isSaving ? <div className="loader" /> : "Save"}

                            </div>


                        </div>


                    </div>
                    {uploadModal ? (
                        <UploadDpModal
                            // addPhoto={addPhoto}
                            setAddPhoto={setUploadModal}
                            imagePreviewModal={base64Image}
                            setImagePreviewModal={setBase64Image}
                            // uploadImage={uploadImage}
                            onImageClick={triggerFileSelectPopup}
                            relatedComponent={"settings"}
                            // setProPicUploaded={setProPicUploaded}
                            // proPicUploaded={proPicUploaded}
                            // newcroppedImage={newcroppedImage}
                            setNewCroppedImage={setBase64Image}
                        />
                    ) : (
                        ""
                    )}

                </div>
            )}
        </AppContext.Consumer>
    )
}

export default SaveContactRight