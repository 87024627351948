import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AppProvider from './ContextAPI/AppProvider'
import AdminBeta from "./pages/Admin/AdminBeta/AdminBeta";
import PageNotFound from "./pages/ErrorHandler/ErrorPages/PageNotFound/PageNotFound";
import Login from "./pages/Auth/Login/Login"
import Signup from "./pages/Auth/SignupWrapper/Signup"
import ForgetPassword from "./pages/Auth/ForgetPassword/ForgetPassword"
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword"
import PasswordChange from "./pages/Auth/PasswordChange/PasswordChange"
import ForgotPasswordLink from "./pages/Auth/ForgotPasswordLink/ForgotPasswordLink"
import config from "./config"
import Home from "./pages/Home/Home";


export default function Routes() {

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

  }, [])
  return (
    <div>
      <BrowserRouter>
        <AppProvider>

          {(config.environment === "development") ?
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/forget-password" component={ForgetPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/password-change" component={PasswordChange} />
              <Route
                exact
                path="/reset-password-business"
                component={ForgotPasswordLink}
              />
              <Route exact path="/" component={Home} />
              <Route exact path="/onboard" component={AdminBeta} />
              <Route exact path="*" component={PageNotFound} />
            </Switch> : <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/onboard" component={AdminBeta} />
              <Route exact path="*" component={PageNotFound} />
            </Switch>
          }



        </AppProvider>
      </BrowserRouter>
    </div>
  );
}
