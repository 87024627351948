import config from "../config"
export const getDPService = () => {
    const getDP = (cls, id) => {
        if (cls === "ChatRoom.PrivateChatRoom") {
            return `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${id}`
        } else {
            return `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/chat/groupdp/${id}/`
        }

    }
    return {
        getDP
    }
}