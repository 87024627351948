import React, { useState, useRef, useContext, useEffect } from "react"
import AppContext from "../../../ContextAPI/AppContext";
import image from "../../../Assets/Icons/logout-new.png";
import close from "../../../Assets/Icons/cross-square-button.svg";
import googleCalender from "../../../Assets/Icons/google-calendar.svg";
import tick from "../../../Assets/Icons/tick.svg";
import clear from "../../../Assets/Icons/close-black.svg";
import "./AppIntregrationModalStyles.scss";
import { getDPService } from "../../../services/DPService";
import next from "../../../Assets/Icons/next.png";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { gapi } from 'gapi-script'
import plus from "../../../../src/Assets/Icons/plus-2.png";
import { nanoid } from "nanoid";

const GoogleCalenderInputs = (props) => {
    const conText = useContext(AppContext)

    const [startDate, setStartDate] = useState(new Date());



    const [titleRequired, setTitleRequired] = useState(false);

    const [calenderTitle, setCalenderTitle] = useState("");
    const [duration, setDuration] = useState(15);
    const [durationValue, setDurationValue] = useState("15 Minutes");
    const [calenderGuests, setCalenderGuests] = useState([]);


    const [calenderDescription, setCalenderDescription] = useState("");


    const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
    var SCOPES = "https://www.googleapis.com/auth/calendar"
    var API_KEY = "AIzaSyCDOQpUzBGUj0GztD0_22BiI4AoSvd43OE"
    var clientId = "364576970381-vndtfgdm2oubeut2jt2i0uahhbcdlc74.apps.googleusercontent.com"



    const getGuestList = () => {
        return new Promise((resolve) => {
            let arry = []
            for (let index = 0; index < conText.guestList.length; index++) {
                let obj = {
                    "email": conText.guestList[index].email
                }
                console.log(obj)
                arry.push(obj)
            }


            resolve(arry)

        })
    }




    // const sendEventData = (receivers) => {
    //     console.log("working")
    //     if ((conText.tempChatroomStatus === false)) {
    //         let tempId = nanoid();
    //         conText?.chatroomExists(
    //             calenderDescription,
    //             calenderTitle,
    //             receivers,
    //             [],
    //             conText?.files,
    //             tempId,
    //             props?.selectedReply?.length > 0
    //                 ? props?.selectedReply[0]?.thread_id
    //                 : null
    //             , false,
    //             false, false)

    //             console.log("jjjjj")


    //     }



    // }





    const handleClick = () => {
        getGuestList().then((list) => {
            if (list) {
                gapi.load('client:auth2', () => {
                    gapi.client.init({

                        apiKey: API_KEY,
                        clientId: clientId,
                        discoveryDocs: DISCOVERY_DOC,
                        scope: SCOPES,
                    })




                    gapi.client.load('calendar', 'v3', () => console.log('bam!'))

                    gapi.auth2.getAuthInstance().signIn()
                        .then(() => {
                            let date = new Date(startDate
                            )
                            date.setMinutes(date.getMinutes() + duration); // timestamp
                            date = new Date(date);


                            let event = {
                                'summary': calenderTitle,
                                'location': '',
                                'description': calenderDescription,
                                'start': {
                                    'dateTime': startDate,
                                    'timeZone': 'America/Los_Angeles'
                                },
                                'end': {
                                    'dateTime': date,
                                    'timeZone': 'America/Los_Angeles'
                                },
                                'recurrence': [
                                    'RRULE:FREQ=DAILY;COUNT=1'
                                ],
                                'attendees': list,
                                'reminders': {
                                    'useDefault': false,
                                    'overrides': [
                                    ]
                                }
                            }
                            console.log(event)
                            let request = gapi.client.calendar.events.insert({
                                'calendarId': 'primary',
                                'resource': event,
                            })

                            request.execute((events) => {
                                console.log(events)

                                // window.open(event.htmlLink)
                            })






                        })
                })
            }
            // sendEventData(list)
        })

        conText?.setNewGroupMembers([])

        conText?.setGoogleCalenderInputModal(false)


    }






    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_goggleCalenderInputs" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
                    <div id="myModal" className="modal-1">
                        <div className="modal-content-top" >
                            <div className="modal-content-add-admin">


                                <div className="add-header">Create Event</div>
                                <img alt="" src={close} className="close"
                                    onClick={() => {
                                        context?.setGoogleCalenderInputModal(false)
                                        context?.setAppIntregrationModal(false)

                                    }} />

                            </div>

                            <div className="inner-container">
                                <div>





                                    <div className="input-list">

                                        <div className="input-wrapper-margin-right">
                                            <label className=" event-labels" htmlFor="">Title</label>
                                            <input
                                                className="event-inputs"
                                                placeholder="Name Your Event"
                                                name="event-name"
                                                type="text"
                                                onChange={(e) => {
                                                    setCalenderTitle(e.target.value);
                                                    console.log(calenderTitle, "title")
                                                    setTitleRequired(false)
                                                }}
                                            />

                                            {titleRequired ?
                                                <div className="title-required">Title Required</div>
                                                :
                                                ""}



                                            <label className=" event-labels" htmlFor="">Date</label>
                                            <DatePicker
                                                minDate={(new Date())}
                                                className="date-picker"
                                                selected={startDate}
                                                onChange={d =>
                                                    setStartDate(d)
                                                } />





                                            {/* <label className=" event-labels" htmlFor="">End Date</label>
                                            <DatePicker className="date-picker" selected={endDate} onChange={(date) => setEndDate(date)} /> */}

                                            <div className="times-main">
                                                <div className="available-times">
                                                    <label className=" event-labels" htmlFor="">Start Times</label>

                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            setStartDate(date)
                                                        }
                                                        }
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        className="time-picker"
                                                        filterTime={filterPassedTime}

                                                    />

                                                </div>



                                                <div className="available-times">

                                                    <label className="event-labels" htmlFor="">Duration </label>
                                                    <div class="dropdown">
                                                        <input class="dropbtn" defaultValue={durationValue} value={durationValue} />
                                                        <div class="dropdown-content">
                                                            <div onClick={() => {
                                                                setDuration(15)
                                                                setDurationValue("15 Minutes")
                                                            }}

                                                                className="times"

                                                            >15 Minutes</div>


                                                            <div onClick={() => {
                                                                setDuration(30)
                                                                setDurationValue("30 Minutes")

                                                            }}
                                                                className="times"

                                                            >30 Minutes</div>

                                                            <div onClick={() => {
                                                                setDuration(45)
                                                                setDurationValue("45 Minutes")

                                                            }}
                                                                className="times"
                                                            >45 Minutes</div>

                                                            <div onClick={() => {
                                                                setDuration(60)
                                                                setDurationValue("1 hour")

                                                            }} className="times"
                                                            >1 hour</div>

                                                            <div onClick={() => {
                                                                setDuration(90)
                                                                setDurationValue("1.5 hour")

                                                            }} className="times"
                                                            >1.5 hour</div>

                                                            <div onClick={() => {
                                                                setDuration(120)
                                                                setDurationValue("2 hour")

                                                            }} className="times"
                                                            >2 hour</div>

                                                        </div>
                                                    </div>



                                                </div>
                                            </div>

                                            <label className=" event-labels" htmlFor="">Description</label>
                                            <input
                                                className="event-inputs-des"
                                                placeholder="Add a description"
                                                name="description"
                                                type="text"
                                                onChange={(e) => {
                                                    setCalenderDescription(e.target.value);
                                                    console.log(calenderDescription, "des")
                                                }}
                                            />

                                            <label className=" event-labels" htmlFor="">Guests                         <img src={plus}
                                                onClick={() => {
                                                    context?.setAddGuestModal(true)

                                                }}

                                                className="plus-button-guest"
                                            /></label>

                                            <div className="search-bar-wrapper-1" onClick={() => {
                                                context?.setAddGuestModal(true)

                                            }}>
                                                <div style={{ height:"5.5vh" ,display: "flex", flexWrap: "wrap", overflowY:"auto", marginTop:"-1%"}}>
                                                    {context?.newGroupMembers.map((value) => {

                                                        return (
                                                            <div className="selected">
                                                                {value.name}

                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div></div>

                                            </div>






                                            <button className="event-button" onClick={() => {
                                                if (calenderTitle === "") {
                                                    setTitleRequired(true)
                                                } else {
                                                    handleClick()

                                                }

                                            }}>
                                                Create
                                            </button>


                                        </div>

                                    </div>








                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            )}</AppContext.Consumer>
    )
}
export default GoogleCalenderInputs