import React from "react";
import { getDPService } from "../../../services/DPService";
import "../RightSidebar/RightSideBarStyle.scss";



const MembersComponent = (props) => {
    return (
        <div style={{ display: "flex", width: "90%" }}>
            <div className="circle-add" style={{ border: props.isAdmin ? "3px solid #0d2a4c" : props.isModerator ? "3px solid #F7AF5F" : "" }}
                onClick={props.onAvatarClick} >
                <img
                    src={
                        getDPService().getDP("ChatRoom.PrivateChatRoom", props.value.public_id)
                            ? getDPService().getDP("ChatRoom.PrivateChatRoom", props.value.public_id)
                            : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                    }
                    className="img"
                    alt=""
                    style={{ height: props.isAdmin || props.isModerator ? "44px" : "", width: props.isAdmin || props.isModerator ? "44px" : "" }}
                />
            </div>
            <div className="div-name" onClick={props.onMemberClick}>
                <div className="name">
                    {props.value.public_id ===
                        localStorage.getItem(
                            "bixChat_loggedUserPublicId"
                        )
                        ? "You"
                        : props.value.full_name}
                </div>
                <div className="email">{props.value.email}</div>
            </div>


        </div>

    )

}
export default MembersComponent