import Resizer from "react-image-file-resizer";
export const fileServices = () => {

    const handleMedia = (response, setMediaList, setMediaListLoading) => {
        if (response.status === 200) {
            setMediaList(response.data)
            setMediaListLoading(false)
        }
    }
    const handleAttachments = (response, setAttachmentList, setAttachmentListLoading) => {
        if (response.status === 200) {
            setAttachmentList(response.data)
            setAttachmentListLoading(false)
        }
    }
    const resizeFile = (image) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                image,

                300,

                300,

                "PNG",

                100,

                0,

                (uri) => {
                    resolve(uri);
                },

                "file"
            );

        });
    }

    return {
        handleMedia,
        handleAttachments,
        resizeFile
    }

}
