import React, { useState, useContext } from "react";
import "../Modal/ModalStyles.scss";
import AppContext from "../../ContextAPI/AppContext";
import CloseIcon from "../Button/CloseIcon/CloseIcon";
import SecondaryButton from "../Button/SecondaryButton/SecondaryButton";
import getData from "../../modalData";
import PasswordInput from "../InputComponents/PasswordInput/PasswordInput";
import danger from "../../Assets/Icons/vuesax-linear-danger.svg";




const Modal = (props) => {
    const [password, setPassword] = useState("");
    const [pwdError, setPwdError] = useState(false);
    const [required, setRequired] = useState(false)
    const data = getData(props.modalType)
    const contextApp = useContext(AppContext)
    const handlePassword = (e) => {
        let passwordValue = e.target.value;
        if (pwdError) {
            if (passwordValue !== "") {
                setPwdError(false);
            }
        }
        if (contextApp.deleteError) {
            if (passwordValue !== "") {
                contextApp.setDeleteError(false);
            }
        }
        setPassword(passwordValue);
    }
    const deleteContent = () => {




        return (

            <div className="delete-container">

                {localStorage.getItem("bixChat_isPrivate") === "true" ?


                    <div>
                        <div className="password-labl">Enter your password
                        </div>
                        <div className="password-cont">
                            <PasswordInput
                                name="Password"
                                value={password}
                                onChange={handlePassword}
                                required={required}
                                isError={contextApp.deleteError}

                            />
                            {contextApp.deleteError ?
                                <div className="pwd-err">
                                    <img src={danger} alt="" className="password-err" />You've entered an invalid password.</div> : ""}
                        </div>
                    </div>
                    :

                    <div>

                        <div className="password-labl">Request sent to your business admin
                        </div>



                    </div>

                }




            </div>)
    }
    const getContent = () => {
        let func = () => { }
        switch (props.modalType) {
            case "deleteAccount":
                func = deleteContent()
                break;

            default:
                break;
        }
        return func

    }
    // const deleteAccount = () => {
    //     if (password === "") {
    //         setRequired(true)
    //     } else {
    //         setRequired(false)
    //         contextApp.setModalLoading(true)
    //         contextApp.deleteUser(password)
    //     }

    // }
    // const getPrimaryFunction = () => {
    //     let func = () => { }
    //     switch (props.modalType) {
    //         case "deleteAccount":
    //             func = deleteAccount()
    //             break;

    //         default:
    //             break;
    //     }
    //     return func
    // }
    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_Modal">
                    <div className="modal-black-background">
                        <div className="modal-content-inner">
                            <div className="modal-content-center">
                                <div className="plus-container-left" style={{ backgroundColor: !props.isPimary ? "#ff0000" : "" }}>
                                    <img alt="" src={data.icon} className="info" />
                                </div>

                                <div className="header" style={{ color: !props.isPimary ? "#ff0000" : "" }}>{data.header}</div>
                                <div style={{ marginTop: '10px' }}>
                                    <CloseIcon
                                        size='s'
                                        onClick={() => {
                                            context.setIsModalVisible(false)
                                            context.setDeleteError(false);
                                        }}
                                    />
                                </div>
                            </div><div>{getContent()}
                            </div>
                            <div className="inner-container">
                                <div className="button-container">
                                    <SecondaryButton secondaryText={data.secondaryText} onClick={() => {
                                        context.setIsModalVisible(false)
                                        context.setDeleteError(false)
                                    }} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>

    );
};
export default Modal;
