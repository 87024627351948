import React, { useState, useEffect, useRef, useContext } from "react";
import AppContext from "../../ContextAPI/AppContext";

import Sidebar from "../ChatUIComponents/LeftSidebar/LeftSidebar";

import { ws } from "../../App";
import axios from "axios";

import "./MainWrapper.scss"
import MessageBox from "../ChatUIComponents/MessageBox/MessageBox";
import RightSideBar from "../ChatUIComponents/RightSidebar/RightSideBar";
import SendNewMessage from "../ChatUIComponents/SendNewMessage/SendNewMessage";
import NewGroupModal1 from "../ChatUIComponents/NewGroupModal1/NewGroupModal1";
import NewGroupModal2 from "../ChatUIComponents/NewGroupModal1/NewGroupModal2";
import AdminModal from "../../Components/Modal/AdminModal/AdminModal";
import ImagePreview from "../../Components/Modal/ImagePreview/ImagePreview";
import addNotification from "react-push-notification";
import SettingsWrapper from "../ChatUIComponents/Settings/SettingsWrapper";
import UploadDpModal from "../../Components/Modal/UploadDPModal/UploadDPModal";
import CenterModal from "../../Components/Modal/CenterModal/CenterModal";
import config from "../../config";
import Tooltip from "../ChatUIComponents/ToolTips/ToolTip";

import { useHistory } from "react-router-dom";
import SaveToCloud from "../../Components/Modal/SaveToBixCloud/SaveToCloudModal";
import ImageUpload from "../../Components/Modal/ImageUploadModal/ImageUploadModal";
import ForwardModal from "../../Components/Modal/ForwardModal/ForwardModal";
import ReportModal from "../../Components/Modal/ReportModal/ReportModal";
import ReportSentModal from "../../Components/Modal/ReportModal/ReportSentModal";
import Modal from "../../Components/Modal/Modal";
import BlockConfirm from "../ChatUIComponents/BlockList/BlockConfirm/BlockConfirm";
import { logoutService } from "../../services/LogoutService";
import LoadingScreen from "../ChatUIComponents/LoadingScreen/LoadingScreen";
import SendComponent from "../ChatUIComponents/SendComponent/SendComponent";
import NewUiModal from "../ChatUIComponents/NewUiModal/NewUiModal";



export default function MainWrapper() {

  const [newGroupModal1, setNewGroupModal1] = useState(false);
  const [newGroupModal2, setNewGroupModal2] = useState(false);
  const [activeStatus, setActiveStatus] = useState("");
  const [newGroupFrom, setNewGroupFrom] = useState("newGroup");
  const [test, setTest] = useState({});
  const [imagePreview, setImagePreview] = useState(false);
  const [image, setImage] = useState("");
  const [currentImage, setCurrentImage] = useState(1);
  const [selectedChat, setSelectedChat] = useState("");
  const history = useHistory();
  const [imagePreviewModal, setImagePreviewModal] = useState(null);
  const [addPhoto, setAddPhoto] = useState(false);
  const [newcroppedImage, setNewCroppedImage] = useState(null);
  const [proPicUploaded, setProPicUploaded] = useState(false);
  const [addMembersFrom, setAddMembersFrom] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalWarnig, setModalWarnig] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalFunction, setModalFunction] = useState("")
  const [membersList, setMembersList] = useState([])
  const uploadImage = useRef(null);
  const [onlineGroupUsers, setOnlineGroupUsers] = useState([])
  const conText = useContext(AppContext)
  const [signupStatus, setSignupStatus] = useState(false);
  const [step, setStep] = useState(0)
  const [isSaveToCloud, setIsSaveToCloud] = useState(null);
  const [isUploadFromCloud, setIsUploadFromCloud] = useState(false);
  const [isPrimary, setIsPrimary] = useState(true)
  const [modalType, setModalType] = useState("")
  const [popup, setPopup] = useState(false)
  const [textFocus, setTextFocus] = useState(false)
  const [footerFixedBottom, setFooterFixedBottom] = useState("")
  const [sendComponentFrom, setSendComponentFrom] = useState("")
  const [rec, setRec] = useState("")
  const [newUiModalVisibility, setNewUiModalVisibility] = useState(false)


  useEffect(() => {
    if (config.environment !== "development") {
      if (window.screen.width < 600) {
        conText.loginFunction()
      }
    }
  }, [])

  const auth = async () => {

    let accessToken = localStorage.getItem('bixChat_accessToken');
    if (accessToken) {
      let email = localStorage.getItem("bixChat_email");
      window.clarity("set", "username", email)

      if (ws.readyState === WebSocket.CONNECTING) {
        ws.addEventListener("open", function (event) {
          ws.send(
            JSON.stringify({
              action: "login",
              publicID: localStorage.getItem("bixChat_loggedUserPublicId"),
              userEmail: localStorage.getItem("bixChat_email"),
            })
          );
        });
      } else if (ws.readyState === WebSocket.OPEN) {
        ws.send(
          JSON.stringify({
            action: "login",
            publicID: localStorage.getItem("bixChat_loggedUserPublicId"),
            userEmail: localStorage.getItem("bixChat_email"),
          })
        );
      }
      ws.onmessage = (message) => {
        let data = JSON.parse(message.data);
        switch (data.action) {
          // active status start ...................................................
          case "activeStatus":
            setActiveStatus(data.status);
            break;
          case "online":
            if (
              data.onlineUserPublicID ===
              localStorage.getItem("selectedChat_receiverId")
            ) {
              setActiveStatus("online");
            }
            break;
          case "offline":
            if (
              data.offlineUserPublicID ===
              localStorage.getItem("selectedChat_receiverId")
            ) {
              setActiveStatus("offline");
            }
            break;
          case "message":
            if (
              data?.body?.sender_id !==
              localStorage.getItem("bixChat_loggedUserPublicId")
            ) {
              let message = data?.body;
              listener(message);
            }
            break;
          case "pong":
            break;
          case "groupActiveStatus":
            setOnlineGroupUsers(data?.result)
            break;
          // active status end .....................................................
          default:
            break;
        }
      };
      ws.addEventListener("message", (event) => {
        let message = JSON.parse(event.data);
        if (message.action === "typing") {
          setTest((test) => ({
            ...test,
            [message.chat.chatroom_id]: message.chat,
          }));
        }
      });

      ws.addEventListener("message", (event) => {
        let message = JSON.parse(event.data);
        if (message.action === "typingend") {
          setTest((test) => delete test[message.chat.chatroom_id]);
        }
      });


    }
    else {
      localStorage.clear()
      if (config.environment !== "development") {
        logoutService().logoutRedirection()
      } else {
        history.push({
          pathname: "/login",
        });
      }


    }
  }
  useEffect(() => {
    auth()
  }, [])

  const onImageClick = () => {
    uploadImage.current.click();
  };
  const subscribe = (subscription) => {
    axios({
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: `user/subscribe`,
      data: {
        subscription: subscription
      },
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
    })
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const notification = (message) => {
    addNotification({
      title: message?.chatroom_type === "ChatRoom.GroupChatRoom" ? message?.sender_name + " @ " + message?.chatroom_name : "New message from " + message?.sender_name,
      message: message.message_body.replace(/(<([^>]+)>)/gi, ""),
      theme: "darkblue",
      native: true, // when using native, your OS will handle theming.
      closeButton: 'Go away',
    });
  };

  useEffect(async () => {
    if ("serviceWorker" in navigator) {
      let sw = await navigator.serviceWorker.ready;

      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BHBGsh8bWs6kOnxm0FdNe0Ibh6bEd6niewiFeyAQdfgvRkLq2FTosy9a1Io9BJrhLqNmkaALAhV45Paz9txzNpI",
      });
      subscribe(push)
    }


  }, [])


  const listener = (message) => {
    window.addEventListener("focus", notification(message));
    return window.removeEventListener("focus", notification(message));
  };


  return (


    <AppContext.Consumer>
      {(context) => (

        <div>
          {(!context.allChatroomsLoaded && context.isLogged) || context.isSwitching ?
            <LoadingScreen isLoggedIn={!context.tokenExpired} isSwitching={context.isSwitching} /> :
            <div className="__MainWrapper">

              {context.selectedChatID ? context.rightSideBarStatus ? <RightSideBar
                setRightSideBarStatus={context.setRightSideBarStatus}
                activeStatus={activeStatus}
                selectedChat={context?.selectedChatroom}
                addPhoto={addPhoto}
                setAddPhoto={setAddPhoto}
                imagePreviewModal={imagePreviewModal}
                setImagePreviewModal={setImagePreviewModal}
                newcroppedImage={newcroppedImage}
                setNewCroppedImage={setNewCroppedImage}
                uploadImage={uploadImage}
                onImageClick={onImageClick}
                proPicUploaded={proPicUploaded}
                setImagePreview={setImagePreview}
                setImage={setImage}
                setCurrentImage={setCurrentImage}
                setNewGroupModal1={setNewGroupModal1}
                newGroupModal1={newGroupModal1}
                setAddMembersFrom={setAddMembersFrom}
                setModalHeader={setModalHeader}
                setModalDescription={setModalDescription}
                setModalVisibility={context.setCenterModalVisibility}
                setModalWarnig={setModalWarnig}
                chatObject={context?.isChatObject}
                setModalFunction={setModalFunction}
                setMembersList={setMembersList}
                onlineList={onlineGroupUsers}
              /> : <MessageBox
                setRightSideBarStatus={context.setRightSideBarStatus}
                setSelectedChatID={context.setSelectedChatID}
                rightSideBarStatus={context.rightSideBarStatus}
                typingChats={test}
                setTypingChats={setTest}
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
                setImagePreview={setImagePreview}
                setImage={setImage}
                setCurrentImage={setCurrentImage}
                setSelectedChat={setSelectedChat}
                chatObject={context?.isChatObject}
                isSaveToCloud={isSaveToCloud}
                setIsSaveToCloud={setIsSaveToCloud}
                isUploadFromCloud={isUploadFromCloud}
                setIsUploadFromCloud={setIsUploadFromCloud}
                textFocus={textFocus}
                setTextFocus={setTextFocus}
                footerFixedBottom={footerFixedBottom}
                setFooterFixedBottom={setFooterFixedBottom}
              /> : context.sendModal ? <SendComponent sendComponentFrom={sendComponentFrom} /> : <Sidebar
                setRightSideBarStatus={context.setRightSideBarStatus}
                sendMessageModal={context.sendMessageModal}
                setSendMessageModal={context.setSendMessageModal}
                setNewGroupModal1={setNewGroupModal1}
                newGroupModal1={newGroupModal1}
                typingChats={test}
                setAddMembersFrom={setAddMembersFrom}
                setSelectedChatID={context.setSelectedChatID}
                popup={popup}
                textFocus={textFocus}
                setTextFocus={setTextFocus}
                footerFixedBottom={footerFixedBottom}
                setFooterFixedBottom={setFooterFixedBottom}
                setSendComponentFrom={setSendComponentFrom}
              />}


              {context.rightSideBarStatus ? (
                <RightSideBar
                  setRightSideBarStatus={context.setRightSideBarStatus}
                  activeStatus={activeStatus}
                  selectedChat={context?.selectedChatroom}
                  addPhoto={addPhoto}
                  setAddPhoto={setAddPhoto}
                  imagePreviewModal={imagePreviewModal}
                  setImagePreviewModal={setImagePreviewModal}
                  newcroppedImage={newcroppedImage}
                  setNewCroppedImage={setNewCroppedImage}
                  uploadImage={uploadImage}
                  onImageClick={onImageClick}
                  proPicUploaded={proPicUploaded}
                  setImagePreview={setImagePreview}
                  setImage={setImage}
                  setCurrentImage={setCurrentImage}
                  setNewGroupModal1={setNewGroupModal1}
                  newGroupModal1={newGroupModal1}
                  setAddMembersFrom={setAddMembersFrom}
                  setModalHeader={setModalHeader}
                  setModalDescription={setModalDescription}
                  setModalVisibility={context.setCenterModalVisibility}
                  setModalWarnig={setModalWarnig}
                  chatObject={context?.isChatObject}
                  setModalFunction={setModalFunction}
                  setMembersList={setMembersList}
                  onlineList={onlineGroupUsers}
                  sendMessageModal={context.sendMessageModal}
                  setSendMessageModal={context.setSendMessageModal}
                  rec={rec}
                  setRec={setRec}
                />
              ) : (
                ""
              )}


              {context.settingsModal ?
                <SettingsWrapper
                  setSettingsModal={context.setSettingsModal}
                  settingsModal={context.settingsModal}
                  profileUpdating={context.profileUpdating}
                  updateProfile={context.updateProfile}
                  setModalType={setModalType}
                  setIsPrimary={setIsPrimary}
                /> : ""}


              {context.sendMessageModal ? (
                <SendNewMessage
                  sendingMultipleMsg={context.sendingMultipleMsg}
                  setSendMessageModal={context.setSendMessageModal}
                  sendMessageModal={context.sendMessageModal}
                  setNewGroupPopup={setNewGroupModal2}
                  setNewGroupFrom={setNewGroupFrom}
                  isNewMessage={context.sendMessageModal}
                  isOnline={context.isOnline}
                  rec={rec}
                  setRec={setRec}
                />
              ) : (
                ""
              )}



              {newGroupModal1 ? (
                <NewGroupModal1
                  setNewGroupModal1={setNewGroupModal1}
                  newGroupModal1={newGroupModal1}
                  setNewGroupModal2={setNewGroupModal2}
                  newGroupModal2={newGroupModal2}
                  setNewGroupFrom={setNewGroupFrom}
                  addMembersFrom={addMembersFrom}
                />
              ) : (
                ""
              )}
              {newGroupModal2 ? (
                <NewGroupModal2
                  setNewGroupModal1={setNewGroupModal1}
                  newGroupModal1={newGroupModal1}
                  setNewGroupModal2={setNewGroupModal2}
                  newGroupModal2={newGroupModal2}
                  newGroupFrom={newGroupFrom}
                  setSendMessageModal={context.setSendMessageModal}
                />
              ) : (
                ""
              )}

              {imagePreview ? (
                <ImagePreview
                  setImagePreview={setImagePreview}
                  image={image}
                  currentImage={currentImage}
                />
              ) : (
                ""
              )}
              {addPhoto ? (
                <UploadDpModal
                  addPhoto={addPhoto}
                  setAddPhoto={setAddPhoto}
                  imagePreviewModal={imagePreviewModal}
                  setImagePreviewModal={setImagePreviewModal}
                  uploadImage={uploadImage}
                  onImageClick={onImageClick}
                  setProPicUploaded={setProPicUploaded}
                  proPicUploaded={proPicUploaded}
                  relatedComponent={"groupImage"}
                  newcroppedImage={newcroppedImage}
                  setNewCroppedImage={setNewCroppedImage}
                />
              ) : (
                ""
              )}
              {context.centerModalVisibility ? (
                <CenterModal
                  modalHeader={modalHeader}
                  modalDescription={modalDescription}
                  modalWarnig={modalWarnig}
                  setModalVisibility={context.setCenterModalVisibility}
                  modalFunction={modalFunction}
                  membersList={membersList}
                  setMembersList={setMembersList}


                />
              ) : (
                ""
              )}
              {context?.adminModalVisibility ? (
                <AdminModal
                  membersList={membersList}
                  setMembersList={setMembersList}
                />
              ) : ""}
              {/* {context.contactModal ?
            <SaveContactRight
              setRightSideBarStatus={context.setRightSideBarStatus}
            /> : ''} */}
              {newUiModalVisibility ?
                <NewUiModal setNewUiModalVisibility={setNewUiModalVisibility}
                  setStep={setStep} /> : ""}
              {localStorage.getItem("firstLogin") === "true" ?
                <Tooltip step={step} setStep={setStep} mobile={true} setSignupStatus={setSignupStatus} setPopup={setPopup} setNewUiModalVisibility={setNewUiModalVisibility} />
                : ""}
              {isSaveToCloud ? (
                <SaveToCloud
                  isSaveToCloud={isSaveToCloud}
                  setIsSaveToCloud={setIsSaveToCloud}
                />) : ""}

              {isUploadFromCloud ?
                <ImageUpload
                  setIsUploadFromCloud={setIsUploadFromCloud}
                />
                : ""}
              {context?.forwardModal ? (
                <ForwardModal />
              ) : ""
              }

              {context?.reportModal ? (
                <ReportModal />
              ) : ""}

              {context?.reportSent ? (
                <ReportSentModal />
              ) : ""}
              {context.isModalVisible ?
                <Modal
                  modalType={modalType}
                  isPrimary={isPrimary}
                /> : ""}


              {context.blockConfirm ?
                <BlockConfirm />
                : ''}


            </div>
          }

        </div>
      )}
    </AppContext.Consumer>
  )





}

