const Feature = ({ name, activeComponent, inactivecomponent }) => {
    const features = JSON.parse(localStorage.getItem('flags'));
    const feature = features?.find(feature => feature.name === name);
    if (feature && feature.active) {
        return activeComponent;
    } else {
        return inactivecomponent
    }


};

export default Feature;