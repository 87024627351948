import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { ws } from "../../../App";
import AppContext from "../../../ContextAPI/AppContext";
import WelcomePage from "../WelcomePage/WelcomePage";
import "../MessageBox/MessageBoxStyle.scss";
import pdf from "../../../Assets/Icons/pdf.png";
import doc from "../../../Assets/Icons/doc.jpg";
import csv from "../../../Assets/Icons/csv.jpg";
import excel from "../../../Assets/Icons/excel.jpg";
import ppt from "../../../Assets/Icons/ppt.png";
import txt from "../../../Assets/Icons/txt.png";
import Loader from "react-js-loader";
import ReplyComponent from "./ReplyComponent";
import MessageBoxNavbar from "./MessageBoxNavbar";
import plus from ".././../../Assets/Icons/plus-2.png";
import InlineMessageComponent from "./InlineMessageComponent";
import { useContext } from "react";
import TextEditor from "../../../Components/BixTextEditor/TextEditor";
import DragDrop from "./DragDrop/DragDrop";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}


const MessageBox = (props) => {

  const [subjectInput, setSubjectInput] = useState(true);
  const [subject, setSubject] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const scroller = useRef();
  const divFooter = useRef(null);
  const messageBox = useRef(null);
  const [maxHeight, setMaxHeight] = useState(window.screen.width < 600 ? "99%" : "85%");
  const [moreIcon, setMoreIcon] = useState("none");
  const [selectedMore, setSelectedMore] = useState("");
  const [isReply, setIsReply] = useState("none");
  const [selectedTab, setSelectedTabs] = useState("reply");
  const [isAttachmentView, setIsAttachmentView] = useState(false);
  const [selectedReactionTab, setSelectedReactionTab] = useState("All");
  const [isReactionsView, setIsReactionsView] = useState(true);
  const [footerWidthSize, setFooterWidthSize] = useState("")
  const conText = useContext(AppContext)
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);

  let count = 0

  const uploadImage = useRef(null);
  const uploadFile = useRef(null);
  // windowDimensions.width 

  if (windowDimensions.width <= 600) {

    window.addEventListener('popstate', (event) => {
      props.setSelectedChatID("")
    });

  }




  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (conText.files.length > 0) {
      props.setTextFocus(true)
    }
  }, [conText.files])
  const changeMargin = () => {
    return new Promise((resolve) => {
      let objDiv = document.getElementById("email-wrapper");
      if (conText.expandSelected) {
        setMaxHeight(window.screen.width < 600 ? "51%" : "28%")
        resolve(objDiv)
      } else {
        if (props.textFocus) {
          if (conText.files.length > 0) {
            setMaxHeight(window.screen.width < 600 ? "72%" : "57%")
            resolve(objDiv)
          } else {
            setMaxHeight(window.screen.width < 600 ? "83%" : "66%")
            resolve(objDiv)
          }



        } else {
          setMaxHeight(window.screen.width < 600 ? "99%" : "85%")
          resolve(objDiv)

        }
      }
    })
  };
  useEffect(() => {
    changeMargin().then((objDiv) => {
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    })

  }, [conText?.selectedChatroomDatas])
  const scrollToBottom = () => {
    //don't remove this function
  };

  const handleScrollPrimary = (e) => {

    const currentScrollY = e.target.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);

    }
    prevScrollY.current = currentScrollY;
    count = count + 1
    if (count === 10) {

      if (conText?.selectedChatroomDatas?.chatroomData?._cls === 'ChatRoom.GroupChatRoom') {
        // mixpanel.track('Scrolling in Group chat')
      }
      else {
        // mixpanel.track('Scrolling in private chat')
      }

    }


  }

  useEffect(() => {
    if (conText?.selectedChatroomDatas?.chatroomData?.group_title) {

    }
    else {
      if (conText.isContact === false) {
        // conText.contactPopup()
      }

    }
  })

  const footerWidth = () => {
    if (windowDimensions.width <= 600) {
      return '100%'
    }
    else {
      return footerWidthSize + 15
    }

  }
  useEffect(() => {
    let divWidth = document.getElementById("__MessageBoxWrapper")?.clientWidth
    if (divWidth) {
      setFooterWidthSize(divWidth - 42)
    }
  }, [props.rightSideBarStatus, conText.contactModal])

  const getDocument = (attachment) => {
    if (attachment.includes(".pdf")) {
      return pdf;
    } else if (attachment.includes(".doc") || attachment.includes(".docx")) {
      return doc;
    } else if (attachment.includes(".ppt")) {
      return ppt;
    } else if (attachment.includes(".csv")) {
      return csv;
    } else if (attachment.includes(".xlsx")) {
      return excel;
    } else if (attachment.includes(".txt")) {
      return txt;
    } else {
      return attachment;
    }
  };
  useEffect(() => {
    if (isReply === "block") {
      setIsReply("none")
      conText?.setReplySubject(false);
    }
  }, [localStorage.getItem("bixChat_selectedChatId")])

  const onImageClick = () => {
    uploadImage.current.click();
  };
  const onFileClick = () => {
    uploadFile.current.click();
  };
  const threads = conText?.selectedChatroomDatas?.threads !== undefined ? conText?.selectedChatroomDatas?.threads : {}

  return (
    <AppContext.Consumer>
      {(context) =>
        context.isChatSelected ? (
          <div
            ref={messageBox}
            className="__MessageBoxWrapper"
            id="__MessageBoxWrapper"
            onClick={() => {
              if (moreIcon === "block") {
                setMoreIcon("none");
                context.setShowReactionTab(false)
              }

              context.leftNavPopupHandler("closeOnly")
              context.RightNavPopupHandler("closeOnly")



            }}
            style={{ maxWidth: !props.rightSideBarStatus ? "76.5%" : "", width: !props.rightSideBarStatus ? "76.5%" : "" }}
          >
            {context?.selectedChatroomDatas?.chatroomData?._cls ===
              "ChatRoom.PrivateChatRoom"
              ? context?.selectedChatroomDatas?.chatroomData?.members_list?.forEach(
                (member) => {
                  if (
                    member.public_id !==
                    localStorage.getItem("bixChat_loggedUserPublicId")
                  ) {
                    localStorage.setItem(
                      "selectedChat_receiverId",
                      member.public_id
                    );
                    if (ws.readyState === WebSocket.OPEN) {
                      ws.send(
                        JSON.stringify({
                          action: "stateCheck",
                          publicID: localStorage.getItem(
                            "bixChat_loggedUserPublicId"
                          ),
                          receiverID: member.public_id,
                        })
                      );
                    }


                  }
                }
              )
              : ""}
            <DragDrop trigger={context?.DragPopup} setTrigger={context?.setDragPopup} />
            {/* Navbar */}

            <MessageBoxNavbar
              setRightSideBarStatus={props.setRightSideBarStatus}
              setSelectedChat={props.setSelectedChat}
              activeStatus={props.activeStatus}
              rightSideBarStatus={props.rightSideBarStatus}
            />

            <div

              className="email-convertations-wrapper"
              onClick={() => {
                context.UploadModalHandler("closeOnly")
                props.setTextFocus(false)


              }}
              onDragEnter={() => context?.setDragPopup(true)}
              id="email-wrapper"
              onLoad={scrollToBottom}
              style={{
                paddingTop:
                  context?.selectedChatroomDatas?.threads?.length === 1 &&
                    moreIcon === "block"
                    ? "20vh"
                    : "",
                maxHeight: maxHeight,
              }}
              onScroll={(e) => {
                if (scroller) {
                  if (e.target.scrollTop === 0) {
                    context?.setLoadMoreMessages(true);
                  }
                } else {
                  context?.setLoadMoreMessages(false);
                }

                handleScrollPrimary(e)
              }}
              ref={scroller}
            >
              {context?.loadMoreMessages === true ? (
                <Loader
                  type="spinner-default"
                  bgColor={"#aec8e0"}
                  color={"#aec8e0"}
                  size={25}
                />
              ) : (
                ""
              )}
              {threads && Object.entries(threads)?.map(([key, value]) => {
                return (
                  <div>
                    {/* {value[0][0]?.indexdb_status === "delivered" ? <div className="message-time">{getDate(key)}</div> : ""} */}

                    {value?.map((singleTread, i) => {
                      if (singleTread[0]) {
                        return (
                          <>
                            {
                              context?.unreadSelectedChat !== undefined ? (
                                context?.unreadSelectedChat.length > 0 && context?.unreadSelectedChat[0]?.thread ? (
                                  singleTread[0]?.thread_id ===
                                    context?.unreadSelectedChat[0]?.thread["$oid"] ? (
                                    <div className="new-message-label" >
                                      <span className="new-message">New Message</span>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            }

                            <InlineMessageComponent
                              singleThread={singleTread[0]}
                              thread={singleTread}
                              i={i}
                              setSelectedMore={setSelectedMore}
                              setMoreIcon={setMoreIcon}
                              setIsReply={setIsReply}
                              setIsAttachmentView={setIsAttachmentView}
                              setSelectedTabs={setSelectedTabs}
                              setImage={props.setImage}
                              setCurrentImage={props.setCurrentImage}
                              setImagePreview={props.setImagePreview}
                              setIsReactionsView={setIsReactionsView}
                              setSelectedReactionTab={setSelectedReactionTab}
                              selectedMore={selectedMore}
                              moreIcon={moreIcon}
                              isSaveToCloud={props.isSaveToCloud}
                              setIsSaveToCloud={props.setIsSaveToCloud}
                              setTextFocus={props.setTextFocus}
                            />
                          </>
                        );
                      } else {
                        return (
                          <div key={i} className={"income-message-wrapper"}>
                            {singleTread[0]?.message_subject ? (
                              <div className="subject">
                                {singleTread[0]?.message_subject}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="body">
                              {singleTread[0]?.attachments?.length > 0 ? (
                                <div className="attachement-container">
                                  <img
                                    alt=""
                                    src={getDocument(
                                      singleTread[0]?.attachments_with_links[0]
                                        .attachment_link
                                    )}
                                    className="attachment-image"
                                  />

                                </div>
                              ) : (
                                ""
                              )}
                              <div dangerouslySetInnerHTML={{ __html: singleTread[0]?.message_body }} />
                            </div>
                            <div className="message-box-footer">
                              <div className="time">
                                {moment().format("L") ===
                                  moment(singleTread[0]?.timestamp["$date"]).format(
                                    "L"
                                  )
                                  ? ` Today ${moment(
                                    singleTread[0]?.timestamp["$date"]
                                  ).format("LT")}`
                                  : moment(
                                    singleTread[0]?.timestamp["$date"]
                                  ).format("lll")}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                    )}
                  </div>
                )

              })}
            </div>


            {/* ------------ Reply Thread --------- */}
            <ReplyComponent
              isReply={isReply}
              setIsReply={setIsReply}
              setSelectedTabs={setSelectedTabs}
              setIsAttachmentView={setIsAttachmentView}
              setIsReactionsView={setIsReactionsView}
              setSelectedReactionTab={setSelectedReactionTab}
              setImage={props.setImage}
              setCurrentImage={props.setCurrentImage}
              setImagePreview={props.setImagePreview}
              width={footerWidth()}
              isAttachmentView={isAttachmentView}
              selectedTab={selectedTab}
              isReactionsView={isReactionsView}
              selectedReactionTab={selectedReactionTab}
              windowDimensions={windowDimensions} />
            {/* ------------ Footer --------- */}
            {props.textFocus || isReply === "block" ?
              <div
                ref={divFooter}
                id="footerDiv"
                className="main-message-box-footer"
                style={{
                  visibility:
                    (isReply === "block" && selectedTab === "attachment") ||
                      (isReply === "block" && selectedTab === "reactions")
                      ? "hidden"
                      : "visible",
                  bottom: props.footerFixedBottom,
                  animation: props.textFocus ? "mymove 0.3s" : ""
                }}
              >
                {props.typingChats ? (
                  props.typingChats[
                    context?.selectedChatroomDatas?.chatroomData?._id["$oid"]
                  ] ? (
                    context?.selectedChatroomDatas?.chatroomData?._cls ===
                      "ChatRoom.PrivateChatRoom" ? (
                      <div className="typing-container">
                        <div className="typing-message">
                          {
                            context?.selectedChatroomDatas?.chatroomData?.profile_name.split(
                              " "
                            )[0]
                          }{" "}
                          is Typing...
                        </div>
                      </div>
                    ) : context?.selectedChatroomDatas?.chatroomData?._cls ===
                      "ChatRoom.GroupChatRoom" ? (
                      context?.selectedChatroomDatas?.chatroomData?.members_list?.map(
                        (member, i) => {
                          if (
                            props.typingChats[
                              context?.selectedChatroomDatas?.chatroomData?._id[
                              "$oid"
                              ]
                            ].publicID === member.public_id
                          ) {
                            return (
                              <div className="typing-container" key={i} >
                                <div className="typing-message">
                                  {member.full_name.split(" ")[0]} is Typing...
                                </div>
                              </div>
                            );
                          }
                          return null;
                        }
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {context?.selectedChatroomDatas?.is_deleted ? <div className="deleted-div" style={{ width: '108%' }}>This group was deleted,You can't message</div> : context?.selectedChatroomDatas?.is_removed ? <div className="deleted-div" style={{ width: '108%' }}>You are no longer participant in this group</div> :
                  context?.isBlocked ?
                    <div style={{ width: '108%' }} className="deleted-div" > {context?.isBlocker ? 'You Blocked this Contact' : 'You cannot reply to this Conversation'}</div>
                    :
                    ""
                }
                <TextEditor
                  setSubjectInput={setSubjectInput}
                  subjectInput={subjectInput}
                  sendMessage={context.sendMessage}
                  subject={subject}
                  setSubject={setSubject}
                  typingChats={props.typingChats}
                  setTypingChats={props.setTypingChats}
                  isNewMessage={false}
                  changeMargin={changeMargin}
                  selectedChat={context?.selectedChat}
                  selectedReply={context.selectedReply}
                  isOnline={context.isOnline}
                  setSendMessageModal={context.setSendMessageModal}
                  sendMessageModal={context.sendMessageModal}
                  windowDimensions={context.windowDimensions}
                  isReply={isReply}
                  isUploadFromCloud={props.isUploadFromCloud}
                  setIsUploadFromCloud={props.setIsUploadFromCloud}
                  textFocus={props.textFocus}
                  uploadImage={uploadImage}
                  onImageClick={onImageClick}
                  onFileClick={onFileClick}
                  uploadFile={uploadFile}
                />


              </div> :

              <div className="bottom-editor" >
                <div className="plus" >

                  <img alt="" src={plus} onClick={onFileClick
                  }
                    className="plus-button"
                  />
                  <input
                    onChange={context.handleUploadFile}
                    style={{ display: "none" }}
                    ref={uploadFile}
                    type="file"
                    className="hidden"
                    multiple
                  />

                </div>


                <input placeholder={`Send message to ${context?.selectedChatroomDatas?.chatroomData?.group_title
                  ? context?.selectedChatroomDatas?.chatroomData
                    ?.group_title
                  :
                  context?.selectedChatroomDatas?.chatroomData?.contact?.email !== undefined
                    ? context?.selectedChatroomDatas?.chatroomData?.contact?.name
                    : context?.selectedChatroomDatas?.chatroomData?.profile_name
                      ? context?.selectedChatroomDatas?.chatroomData
                        ?.profile_name
                      : context?.selectedChatroomDatas?.chatroomData
                        ?.email_receiver
                  }`} onClick={() => {
                    props.setTextFocus(true);
                    props.setFooterFixedBottom("0%")
                  }} className="input-place" /></div>}


          </div>
        ) : (
          <div className="__MessageBoxWrapper" onClick={() => {
            if (moreIcon === "block") {
              setMoreIcon("none");
              context.setShowReactionTab(false)

            }

            context.leftNavPopupHandler("closeOnly")
            context.RightNavPopupHandler("closeOnly")

          }} style={{ width: "76.5%", maxWidth: "76.5%" }}>
            <div className="welcome-page">
              <WelcomePage />
            </div>
          </div>
        )
      }
    </AppContext.Consumer>
  );
};

export default MessageBox;
