import React, { useContext } from "react";
import Editor from "./Editor";
import "./TextEditorStyles.scss"
import AppContext from "../../ContextAPI/AppContext";
import { useEffect } from "react";
import { useState } from "react";

const TextEditor = (props) => {
    const context = useContext(AppContext)
    const [placeholder, setPlaceholder] = useState(null)
    const getPlaceHolder = () => {
        let subject = context.selectedReply[0]?.message_subject !== "" ?
            context.selectedReply[0]?.message_subject : JSON.parse(localStorage.getItem("bixChat_selectedChatMessage"))?.chatroom_name ? JSON.parse(localStorage.getItem("bixChat_selectedChatMessage"))?.chatroom_name : JSON.parse(localStorage.getItem("bixChat_selectedTempChatroom"))?.chatroom_name
        if (props.isReply === "none") {
            return `Sending to ${props.selectedChat?.chatroom_name ? props.selectedChat?.chatroom_name : props.selectedChat?.chatroom_name}`
        } else {
            return `Replying to ${subject}`
        }
    }
    useEffect(() => {
        if (props.selectedChat) {
            setPlaceholder(getPlaceHolder())
        }
    }, [props.selectedChat])
    return (
        <div className='custom-toolbar-example'>
            {!props.isNewMessage ? <Editor placeholder={placeholder}
                setSubjectInput={props.setSubjectInput}
                subjectInput={props.subjectInput}
                sendMessage={props.sendMessage}
                subject={props.subject}
                setSubject={props.setSubject}
                typingChats={props.typingChats}
                setTypingChats={props.setTypingChats}
                isNewMessage={props.isNewMessage}
                changeMargin={props.changeMargin}
                selectedChat={props?.selectedChat}
                selectedReply={props.selectedReply}
                isOnline={props.isOnline}
                setSendMessageModal={props.setSendMessageModal}
                sendMessageModal={props.sendMessageModal}
                windowDimensions={props.windowDimensions}
                isReply={props.isReply}
                isUploadFromCloud={props.isUploadFromCloud}
                setIsUploadFromCloud={props.setIsUploadFromCloud}
                textFocus={props.textFocus}
                uploadImage={props.uploadImage}
                onImageClick={props.onImageClick}
                onFileClick={props.onFileClick}
                uploadFile={props.uploadFile}
            /> :
                <Editor
                    placeholder={'Type your Text Here'}
                    setSubjectInput={props.setSubjectInput}
                    subjectInput={props.subjectInput}
                    outSideEmail={true}
                    ccReceivers={props.ccReceivers}
                    receivers={props.receivers}
                    sendingMultipleMsg={props.sendingMultipleMsg}
                    subject={props.subject}
                    setSubject={props.setSubject}
                    sendMessageModal={props.sendMessageModal}
                    setSendMessageModal={props.setSendMessageModal}
                    setNewGroupPopup={props.setNewGroupPopup}
                    setNewGroupFrom={props.setNewGroupFrom}
                    isNewMessage={props.isNewMessage}
                    isOnline={props.isOnline}
                    windowDimensions={props.windowDimensions}
                    fromCompose={props.fromCompose}
                    textFocus={props.textFocus}
                    uploadImage={props.uploadImage}
                    onImageClick={props.onImageClick}
                    onFileClick={props.onFileClick}
                    uploadFile={props.uploadFile}
                    rec={props.rec}
                    setRec={props.setRec}
                />
            }

        </div>
    )
}
export default TextEditor