import React, { useState, useRef } from "react";
import playBtn from "../../Assets/Icons/play.svg"
import pauseBtn from "../../Assets/Icons/pause.svg"
import "./AudioComponentStyle.scss"
import { useEffect } from "react";


const AudioComponent = (props) => {
    const [play, setPlay] = useState(false)
    const [pause, setPause] = useState(true)
    const [audio, setAudio] = useState(new Audio(props.audio))
    const [cTime, setCTime] = useState("00:00")
    const [start, setStart] = useState(true)
    const player = useRef(null)

    const handlePlay = () => {
        setPlay(true)
        setPause(false)
        setStart(false)
        audio?.play()
    }
    const handlePause = () => {
        setPause(true)
        setPlay(false)
        audio?.pause()
    }
  

    const getTime = (time) => {
        if (!isNaN(time)) {
            let mod = '0' + Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
            setCTime(mod)
        }
    }
   

    useEffect(() => {
        audio.addEventListener("timeupdate", e => {
            getTime(e.target.currentTime)
            player.current.value=e.target.currentTime
            if(e.target.currentTime===audio.duration){
                setPause(true)
                setPlay(false)
            }
        })
        audio.removeEventListener("timeupdate", () => { })
    }, [])



    return (
        <div className="_AudioComponent">
            {!play ?
               !isNaN(audio.duration)? <img src={playBtn} onClick={handlePlay} alt="" className="btnStyle2" />:<div className="loader"/>
                : <img src={pauseBtn} onClick={handlePause} alt="" className="btnStyle" />}
            <input aria-controls ref={player} type="range" min="1" max={audio.duration} value="0" className="e-range" id="seekslider"  step="0.01"  />
            <div className="time">{!isNaN(audio.duration) ? start && audio ? '0' + Math.floor(audio.duration / 60) + ":" + ('0' + Math.floor(audio.duration % 60)).slice(-2) : cTime : "00:00"}</div>
        </div>
    )
}
export default AudioComponent