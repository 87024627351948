import React from 'react'
import AppContext from "../../../ContextAPI/AppContext";

const ReactionsComponent = (props) => {
  return (
    <AppContext.Consumer>
      {(context) =>
        <div className="reactions-container">
          <div className="tab-view">
            <div
              className="tabs"
              onClick={() => {
                props.setSelectedReactionTab("All");
              }}
              style={{
                borderBottom:
                  props.selectedReactionTab === "All"
                    ? "2px solid #0d2a4c"
                    : "",
              }}
            >
              All
              <div className='all-reaction'>
                {
                  context?.selectedReply[0]?.reactions?.length
                }
              </div>

            </div>




            {context?.selectedReply[0]?.reactions?.filter(
              (el) => el.emoji === "👌"
            ).length > 0 ? (
              <div
                className="tabs"
                onClick={() => {
                  props.setSelectedReactionTab("good");
                }}
                style={{
                  borderBottom:
                    props.selectedReactionTab === "good"
                      ? "2px solid #0d2a4c"
                      : "",
                }}
              >
                👌
                <span className="react">
                  {
                    context?.selectedReply[0]?.reactions?.filter(
                      (el) => el.emoji === "👌"
                    ).length
                  }
                </span>
              </div>
            ) : (
              ""
            )}
            {context?.selectedReply[0]?.reactions?.filter(function (
              el
            ) {
              return el.emoji === "👍";
            }).length > 0 ? (
              <div
                className="tabs"
                onClick={() => {
                  props.setSelectedReactionTab("shape");
                }}
                style={{
                  borderBottom:
                    props.selectedReactionTab === "shape"
                      ? "2px solid #0d2a4c"
                      : "",
                }}
              >
                👍
                <span className="react">
                  {
                    context?.selectedReply[0]?.reactions?.filter(
                      function (el) {
                        return el.emoji === "👍";
                      }
                    ).length
                  }
                </span>
              </div>
            ) : (
              ""
            )}

            {context?.selectedReply[0]?.reactions?.filter(function (
              el
            ) {
              return el.emoji === "✅";
            }).length > 0 ? (
              <div
                className="tabs"
                onClick={() => {
                  props.setSelectedReactionTab("done");
                }}
                style={{
                  borderBottom:
                    props.selectedReactionTab === "done"
                      ? "2px solid #0d2a4c"
                      : "",
                }}
              >
                ✅
                <span className="react">
                  {
                    context?.selectedReply[0]?.reactions?.filter(
                      function (el) {
                        return el.emoji === "✅";
                      }
                    ).length
                  }
                </span>
              </div>
            ) : (
              ""
            )}
            {context?.selectedReply[0]?.reactions?.filter(function (
              el
            ) {
              return el.emoji === "❌";
            }).length > 0 ? (
              <div
                className="tabs"
                onClick={() => {
                  props.setSelectedReactionTab("bad");
                }}
                style={{
                  borderBottom:
                    props.selectedReactionTab === "bad"
                      ? "2px solid #0d2a4c"
                      : "",
                }}
              >
                ❌
                <span className="react">
                  {
                    context?.selectedReply[0]?.reactions?.filter(
                      function (el) {
                        return el.emoji === "❌";
                      }
                    ).length
                  }
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="reactions">
            {props.selectedReactionTab === "All"
              ? context?.selectedReply[0]?.reactions?.map(
                (reaction, i) => {
                  return (
                    <div className="reaction-list" key={i}>
                      <div className="reaction-ic">
                        <img
                          alt=""
                          className="profile-pic"
                          src={
                            reaction?.user?.profile_image === null
                              ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                              : reaction?.user?.profile_image
                          }
                        />

                      </div>
                      <div className="name">
                        {reaction.user.full_name}

                        <span className="emoji-preview">
                          {reaction.emoji}
                        </span>
                      </div>


                    </div>

                  );
                }
              )
              : props.selectedReactionTab === "good"
                ? context?.selectedReply[0]?.reactions
                  ?.filter((el) => el.emoji === "👌")
                  .map((reaction, i) => {
                    return (
                      <div className="reaction-list" key={i}>
                        <div className="reaction-ic">
                          <img
                            alt=""
                            className="profile-pic"
                            src={
                              reaction?.user?.profile_image === null
                                ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                : reaction?.user?.profile_image
                            }
                          />
                          <span className="emoji-preview">
                            {reaction.emoji}
                          </span>
                        </div>
                        <div className="name">
                          {reaction.user.full_name}
                        </div>
                      </div>
                    );
                  })
                : props.selectedReactionTab === "shape"
                  ? context?.selectedReply[0]?.reactions
                    ?.filter((el) => el.emoji === "👍")
                    .map((reaction, i) => {
                      return (
                        <div className="reaction-list" key={i}>
                          <div className="reaction-ic">
                            <img
                              alt=""
                              className="profile-pic"
                              src={
                                reaction?.user?.profile_image === null
                                  ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                  : reaction?.user?.profile_image
                              }
                            />

                          </div>
                          <div className="name">
                            {reaction.user.full_name}

                            <span className="emoji-preview">
                              {reaction.emoji}
                            </span>
                          </div>
                        </div>
                      );
                    })
                  : props.selectedReactionTab === "done"
                    ? context?.selectedReply[0]?.reactions
                      ?.filter((el) => el.emoji === "✅")
                      .map((reaction, i) => {
                        return (
                          <div className="reaction-list" key={i} >
                            <div className="reaction-ic">
                              <img
                                alt=""
                                className="profile-pic"
                                src={
                                  reaction?.user?.profile_image === null
                                    ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                    : reaction?.user?.profile_image
                                }
                              />

                            </div>
                            <div className="name">
                              {reaction.user.full_name}

                              <span className="emoji-preview">
                                {reaction.emoji}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : props.selectedReactionTab === "bad"
                      ? context?.selectedReply[0]?.reactions
                        ?.filter((el) => el.emoji === "❌")
                        .map((reaction, i) => {
                          return (
                            <div className="reaction-list" key={i}>
                              <div className="reaction-ic">
                                <img
                                  alt=""
                                  className="profile-pic"
                                  src={
                                    reaction?.user?.profile_image === null
                                      ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                                      : reaction?.user?.profile_image
                                  }
                                />

                              </div>
                              <div className="name">
                                {reaction.user.full_name}

                                <span className="emoji-preview">
                                  {reaction.emoji}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      : ""}
          </div>
        </div>
      }

    </AppContext.Consumer>
  )
}
export default ReactionsComponent;