import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import SignupCreateUsername from "../SignupCreateUsername/SignupCreateUsername";
import SignupCompleteYourProfile from "../SignupCompleteYourProfile/SignupCompleteYourProfile";
import SignupSetupMoreDetails from "../SignupSetupMoreDetails/SignupSetupMoreDetails";

import config from "../../../config";
import SignupSuccess from "../SignupSuccess/SignupSuccess";
import AppContext from "../../../ContextAPI/AppContext";
import Carousel from "../../../Components/Carousel/Carousel";
import SignupSetup from "../SignupCompleteYourProfile/SignupSetup";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Signup = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [pageToShow, setPageToShow] = useState(1);

  const [signupUserName, setSignupUserName] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupFullname, setSignupFullname] = useState("");
  const [signupGender, setSignupGender] = useState("");
  const [signupDOB, setSignupDOB] = useState("");

  const [signupLoading, setSignupLoading] = useState(false);
  const [singupFailed, setSingupFailed] = useState(false);
  const [signupSkipLoading, setSignupSkipLoading] = useState(false);

  const [takenUsername, setTakenUsername] = useState([]);

  // const [temp, setTemp] = useState("");

  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");

  const history = useHistory();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getUsers();
  }, [history]);

  const getUsers = () => {
    axios({
      headers: { "Access-Control-Allow-Origin": "*" },
      method: "get",
      url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/users`,
    }).then((response) => {
      let takenUsernames = [];

      response.data.map((singleData) => {
        let username = singleData.email.split("@")[0];
        takenUsernames.push(username);

        return null;
      });
      setTakenUsername(takenUsernames);
    });
  };

  const sendSigupRequest = (e) => {
    if (!signupSkipLoading) {
      setSignupLoading(true);
    } else {
      setSignupSkipLoading(true);
    }

    let profilePicture = e;

    // console.log(profilePicture, "profilePicture")

    const usersAllData = new FormData();
    usersAllData.append("full_name", signupFullname);
    usersAllData.append("email", signupUserName.toLowerCase());
    usersAllData.append("password", signupPassword);
    usersAllData.append("date", signupDOB);

    // usersAllData.append("gender", signupGender);
    if (profilePicture !== null) {
      usersAllData.append(
        "profile_picture_encoded",
        profilePicture.split("base64,")[1]
      );
    }
    usersAllData.append("designation", "");
    usersAllData.append("address", address);
    usersAllData.append("headline", designation);

    axios({
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "users",
      data: usersAllData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data["public_id"]) {
          setSignupLoading(false);
          setSignupSkipLoading(false);
          setPageToShow(5);
        }
        localStorage.setItem("bixChat_mobileView", 1);
      })
      .catch((err) => {
        console.log(err);

        setSingupFailed(true);
        setSignupLoading(false);
        setSignupSkipLoading(false);
      });
  };

  const renderCurrentPage = () => {
    if (pageToShow === 1) {
      return (
        <SignupCreateUsername
          setPageToShow={setPageToShow}
          setSignupUserName={setSignupUserName}
          setSignupPassword={setSignupPassword}
          takenUsername={takenUsername}
        />
      );
    } else if (pageToShow === 2) {
      return (
        <SignupCompleteYourProfile
          setPageToShow={setPageToShow}
          setSignupFullname={setSignupFullname}
          setSignupGender={setSignupGender}
          setSignupDOB={setSignupDOB}
          sendSigupRequest={sendSigupRequest}
          singupFailed={singupFailed}
          signupLoading={signupLoading}
          setSignupLoading={setSignupLoading}
        />
      );
    } else if (pageToShow === 4) {
      return (
        <SignupSetupMoreDetails
          setPageToShow={setPageToShow}
          setDesignation={setDesignation}
          setAddress={setAddress}
          sendSigupRequest={sendSigupRequest}
          signupLoading={signupLoading}
          singupFailed={singupFailed}
          setSignupLoading={setSignupLoading}
          signupSkipLoading={signupSkipLoading}
          setSignupSkipLoading={setSignupSkipLoading}
        />
      );
    } else if (pageToShow === 5) {
      return (
        <AppContext.Consumer>
          {(context) => (
            <SignupSuccess
              fullname={signupFullname}
              username={signupUserName}
              getStartedOnClick={() => {
                if (document.querySelector(".slideshow-container")) {
                  document.querySelector(".slideshow-container").style.display =
                    "block";
                }

                if (document.querySelector(".SignupInnercontainerRight")) {
                  document.querySelector(
                    ".SignupInnercontainerRight"
                  ).style.display = "block";
                }

                context?.loginClicked(
                  signupUserName.toLowerCase(),
                  signupPassword,
                  "personal"
                );
                localStorage.setItem("firstLogin", true)
                localStorage.setItem("tooltipFun", false)
              }}
              getStartedLoading={context?.loginIsLoading}
            />
          )}
        </AppContext.Consumer>
      );
    } else if (pageToShow === 6) {
      return (
        <SignupSetup
          setPageToShow={setPageToShow}
          setSignupFullname={setSignupFullname}
          setSignupGender={setSignupGender}
          setSignupDOB={setSignupDOB}
          sendSigupRequest={sendSigupRequest}
          singupFailed={singupFailed}
          signupLoading={signupLoading}
          setSignupLoading={setSignupLoading}
        />
      );
    }
  };

  if (windowDimensions.width > 768) {
    return (
      <div className="signupMainWrapper">
        <div className="renderWrapper">{renderCurrentPage()}</div>
        {pageToShow === 5 ? (
          ""
        ) : (
          <div className="SignupInnercontainerRight">
            <div className="wrapper2">
              <Carousel />
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="_MobileSignup">
        <Carousel />
        {renderCurrentPage()}
      </div>
    );
  }
};

export default Signup;
