import React, { useState, useRef } from "react"
import AppContext from "../../../ContextAPI/AppContext";
import "./SignatureModal.scss"
import edit from "../../../Assets/Icons/edit.svg";
import ReactQuill from 'react-quill';


const SignatureModal = () => {
    const [signatureValue, setSignatureValue] = useState("");
    const input = useRef(null);


    const handleChange = html => {
        setSignatureValue(html);
    };




    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="_SignatureModal" onClick={() => { context.leftNavPopupHandler("closeOnly") }}>
                    <div id="myModal" className="modal-1">
                        {console.log(context.signatureData)}
                        <div className="modal-content-top" >
                            <div className="modal-content-add-admin">
                                <div className="plus-container-left">
                                    <img alt="" src={edit} className="plus" />
                                </div>

                                <div className="add-header">Add Your New Signature</div>


                            </div>

                            <div className="inner-container">

                                <div className="report-main">
                                    <div className="report-user-random">
                                        New Signature
                                    </div>
                                    <div className="report-user-random-des">
                                        appended at the end of all outgoing messages
                                    </div>

                                    <div className="signature-editor">
                                        <ReactQuill
                                            type="text"
                                            id="inputEditorSignature"
                                            ref={input}
                                            className="signatureQuill"

                                            onChange={handleChange}
                                            theme="snow"
                                            defaultValue={context.signatureData}
                                        />

                                        {/* <input
                                            type="text"
                                            id="message"
                                            name="message"
                                            onChange={handleChange}
                                            value={signatureValue}
                                            className="report-input"
                                            placeholder="Describe the issue (Optional)" /> */}

                                    </div>


                                    <div className="report-bottom">
                                        <div className="report-bottom-div">
                                            <span className="report-cancel" onClick={() => {
                                                context?.setSignatureModal(false)
                                            }}>Cancel</span>
                                            <span className="report-btn"
                                                onClick={() => {
                                                    context.sendSignatureData(
                                                        signatureValue,


                                                    );
                                                    console.log("working")
                                                    context?.setSignatureModal(false)
                                                    // context?.setReportModal(false)
                                                    // context?.setReportSent(true)
                                                    // reportSentModalClose()

                                                }}
                                            >Save</span>

                                        </div>

                                    </div>




                                </div>




                            </div>



                        </div>

                    </div>

                </div>
            )}</AppContext.Consumer>
    )
}
export default SignatureModal 