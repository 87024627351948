export const featureToggleHelper = () => {
    const initFeatures = () => {
        localStorage.setItem('flags', JSON.stringify([
            { name: 'forward', description: 'message forward', active: true },
            { name: 'archived-chats', description: 'Archived chats in more', active: false },
            { name: 'new-label', description: 'new label', active: false },
            { name: 'join-others', description: 'Set as join others', active: false },
            { name: 'save-to-cloud', description: 'Save to bix cloud', active: false },
            { name: 'labelled-messages', description: 'labelled messages in details bar', active: false },
            { name: 'remind-messages', description: 'remind messages in details bar', active: false },
            { name: 'import-from-bix-cloud', description: 'bix cloud import', active: true },
            { name: 'account-settings', description: 'setting page account', active: false },
            { name: 'privacy-and-security-settings', description: 'setting page privacy and security', active: false },
            { name: 'notification-settings', description: 'setting page notification', active: false },
            { name: 'help-and-support-settings', description: 'setting page help and support', active: false },
        ]));
    };
    return { initFeatures }
};