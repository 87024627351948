import React from "react";
import "../LeftSidebarStyle.scss"
import AppContext from "../../../../ContextAPI/AppContext";





const ChatOptions = (props) => {



  return (
    <AppContext.Consumer>
      {(context) =>

        <div
          className="pop-up1 box-style1"
          style={{
            // display: context.uploadImgModal,
          }}
        >
          {props.value?.contact?.email !== undefined || props.value?._cls === "ChatRoom.GroupChatRoom" ? '' :

            <div
              className="item1"
              onClick={() => {
                context.setIsChatSelected(true)
                context.setRightSideBarStatus(true)
                context.setContactModal(true)
                // props.setShowOption(false)
                props.setShowDropDown(false)


              }}
            >
              Save Contact
            </div>
          }

          {props.isOther ? '' :
            <div
              className="item1"
              onClick={() => {
                // context.setIsChatSelected(true)
                context.moveToOther()
                props.setShowOption(false)

              }}
            >
              Move To Other
            </div>
          }

          {props.isOther ? '' :
            props?.value?.pinned === 'null' || props?.value?.pinned === null || props?.value?.pinned === '' || props?.value?.pinned === undefined ?
              <div
                className="item1"
                onClick={() => {
                  context.pinChat(props?.value?._id['$oid'], 'pin')
                }}
              >
                Pin Chat
              </div> :
              <div
                className="item1"
                onClick={() => {
                  context.pinChat(props?.value?._id['$oid'], 'unpin')
                }}
              >
                Unpin Chat
              </div>

          }



        </div>


      }
    </AppContext.Consumer>
  )


}
export default ChatOptions