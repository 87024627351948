import React, { useContext, useEffect, useState, useRef } from "react"
import AppContext from "../../ContextAPI/AppContext";
import mic from "../../Assets/Icons/audio-mic.svg";
import docx from "../../Assets/Icons/doc.jpg";
import csv from "../../Assets/Icons/csv.jpg";
import excel from "../../Assets/Icons/excel.jpg";
import ppt from "../../Assets/Icons/ppt.png";
import txt from "../../Assets/Icons/txt.png";
import pdf from "../../Assets/Icons/pdf.png"
import xd from "../../Assets/Icons/xd.png"
import html from "../../Assets/Icons/html.png"
import close from "../../Assets/Icons/redClose.svg";
import image from "../../Assets/Icons/Image.svg";
import arrow from "../../Assets/Icons/next-2.png"
import "./ScrollingMenuStyles.scss"

const ScrollingMenu = () => {
    const newContext = useContext(AppContext)
    const scrl = useRef(null)
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);
    const removeUploadedFile = (e) => {
        const name = e.target.getAttribute("name");
        const filteredItems = newContext.files.filter((item) => item.name !== name);
        newContext.setFiles(filteredItems);
    };
    const getDocument = (attachment, type) => {
        let g = /\.([0-9a-z]+)$/i;
        let doc = type.match(g)[1]
        if (doc === "pdf") {
            return pdf;
        }
        else if (doc === "doc" || doc === "docx") {
            return docx;
        } else if (doc === "ppt" || doc === "pptx") {
            return ppt;
        } else if (doc === "csv") {
            return csv;
        } else if (doc === "xlsx") {
            return excel;
        } else if (doc === "txt") {
            return txt;
        } else if (doc === "mp4") {
            return image;
        }
        else if (doc === "html") {
            return html
        } else if (doc === "xd") {
            return xd
        }
        else {
            return URL.createObjectURL(attachment);
        }
    };

    const getType = (attachment) => {
        let g = /\.([0-9a-z]+)$/i;
        if (attachment.includes(".pdf")) {
            return "PDF";
        } else if (attachment.includes(".doc") || attachment.includes(".docx")) {
            return "Word Document";
        } else if (attachment.includes(".ppt")) {
            return "Power Point";
        } else if (attachment.includes(".csv")) {
            return "CSV";
        } else if (attachment.includes(".xlsx")) {
            return "Excel Sheet";
        } else if (attachment.includes(".txt")) {
            return "Text File";
        } else {
            return attachment.match(g)[0].replace(".", "")
        }
    }
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    const LeftArrow = () => {
        return (
            <div className="left" onClick={() => slide(-200)}>
                <img src={arrow} alt="" />
            </div>
        );
    }

    const RightArrow = () => {
        return (
            <div className="right" onClick={() => slide(+200)}>
                <img src={arrow} alt="" />
            </div>
        );
    }
    useEffect(() => {
        if (
            scrl.current &&
            scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => { };
    }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    useEffect(() => {
        scrollCheck()
    }, [newContext.files])
    return (<AppContext.Consumer>
        {(context) => (
            <div className="attachment-scroller" >
                {scrollX !== 0 ? <LeftArrow /> : ""}
                <div className="scroll-attachments" ref={scrl} onScroll={scrollCheck}>
                    {context.files.map((file) => {
                        return (
                            <div className="attachement-container-n">
                                <div className="attachment-container-editor">  {file.type.split("/")[1] === "mp3" || file.type.split("/")[1] === "mpeg" ?
                                    <img
                                        alt=""
                                        src={mic}
                                        className="attachment-mic"

                                    /> : <img
                                        alt=""
                                        src={getDocument(
                                            file, file.name
                                        )}
                                        className="attachment-image-n"

                                    />}
                                    <div className="file-text"> {file.name}
                                        <div className="file-type-text">{getType(file.name)}
                                        </div></div>
                                </div>

                                <img
                                    alt=""
                                    src={close}
                                    className="close-icon-n"
                                    onClick={removeUploadedFile}
                                    name={file.name}
                                />
                            </div>
                        )
                    })}
                </div>
                {!scrolEnd && (<RightArrow />)}
            </div>
        )}</AppContext.Consumer>)

}
export default ScrollingMenu