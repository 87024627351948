import React from 'react';
import './avatar.scss'
import AppContext from '../../ContextAPI/AppContext'


const avatar = (props) => {
    return (
        <AppContext.Consumer>
            {(context) => (
                <div >
                    <img
                        className={props.size === 'xs' ?
                            props.withBorder === true ? 'avatar-img-xs-border' : 'avatar-img-xs' :
                            props.size === 's' ? "avatar-img-s" :

                                props.size === 'm' ? `avatar-img-m-${context.fontSizeType}` :
                                    props.size === 'ml' ? 'avatar-img-ml' :
                                        props.size === 'l' ? 'avatar-img-l' :

                                            props.size === 'xxl' ? 'avatar-img-xxl' :
                                                props.size === 'xl' ? 'avatar-img-xl' :
                                                    ''}
                        src={props.src}
                        alt=""
                        onClick={props.onClick}
                        style={{ position: 'relative' }}
                    />



                    {props.showOnlineStatus === true ? context?.selectedChatroomDatas?.chatroomData?._cls ===
                        "ChatRoom.PrivateChatRoom" ? (
                        props.isOnline !== "online" ? (
                            <div className="offline-status" style={{ position: 'absolute', marginTop: '-20px' }} />
                        ) : (
                            <div className="online-status" style={{ position: 'absolute', marginTop: '-20px' }} />

                        )
                    ) : (
                        ""
                    ) : ''}


                </div>
            )}
        </AppContext.Consumer>
    )
}



export default avatar;