import trash from "../../../Assets/Icons/trash.svg";
import Button from '../../../Components/Button/Button';


const AccountSettings = (props) => {
    return (
        <div className="account">
        <div className="delete-head"><img alt="" src={trash} /> Delete my account</div>
        <div className="delete-cont">
          <div className="cont-del">
            <div className="dot" />
            Delete your account from bixChat
          </div>
          <div className="cont-del">
            <div className="dot" />
            Erase your email history
          </div>
          <div className="cont-del">
            <div className="dot" />
            Delete you from all of your email groups
          </div>
          <div className="cont-del">
            <div className="dot" />
            Delete your all media files and attachments
          </div>
        </div>
        <Button buttonText="Delete Account" onClick={props.onDeleteClick} />
      </div>

    )
}
export default AccountSettings