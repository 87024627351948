import React from "react";
import ProgressBar from "./ProgressBar";
import './LoadingScreen.scss'

const LoadingScreen = (props) => {

	return (

		<div className="LoadingScreen">
			<div className="logoContainer">
				<div className="bix-logo">
					<b>ZapMail</b>
				</div>
				<div className="bix-subtext">
					{props.isSwitching ? "Switching Account" : ""}

				</div>
				<div className="progressbarContainer">
					<ProgressBar />
				</div>
			</div>

		</div>
	)
}

export default LoadingScreen