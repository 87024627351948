import React, { useState, useEffect, useRef, useContext } from 'react'
import "./../Settings/Settings.scss";

import settings from "../../../Assets/Icons/pro-settings.svg";
import user from "../../../Assets/Icons/user-settings.svg";
import lock from "../../../Assets/Icons/lock-setting.svg";
import shield from "../../../Assets/Icons/shield-settings.svg";
import close from "../../../Assets/Icons/cross-square-button.svg";
import ProImage from "../../../Assets/Icons/cam.svg";
import next from "../../../Assets/Icons/next.png";
import agree from "../../../Assets/Icons/agreement.svg";
import display from "../../../Assets/Icons/touch-screen.png";
import edit from "../../../Assets/Icons/edit.svg";
import manage from "../../../Assets/Icons/management.png";

import UploadDpModal from '../../../Components/Modal/UploadDPModal/UploadDPModal';
import Avatar from '../../../Components/Avatar/avatar'
import BlockList from '../BlockList/BlockList';
import Agreement from '../../Admin/Agreement/Agreement';
import AppContext from '../../../ContextAPI/AppContext';
import PrivacyContent from '../../../Content/PrivacyContent';
import ProfileSettings from './ProfileSettings';
import { getDPService } from '../../../services/DPService';
import DisplaySettings from './DisplaySettings';
import AccountSettings from './AccountSettings';
import SignatureSettings from './SignatureSettings';
import ManageUsers from './ManageUsers';


const SettingsWrapper = (props) => {

  const [generalChatToggler, setGeneralChatToggler] = useState("general")
  const [header, setHeader] = useState("Profile Settings")
  const [viewProfSettings, setViewProfSettings] = useState(true)
  const [bio, setBio] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [headline, setHeadline] = useState("")
  const [address, setAddress] = useState("")
  const [isChanged, setIsChanged] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [countChange, setCountChange] = useState(0)
  const [profilePicture, setProfilePicture] = useState("")
  const [proPicChanged, setProPicChanged] = useState(false)
  const [base64Image, setBase64Image] = useState("")
  const uploadImage = useRef(null);

  const [firstNameNotValid, setFirstNameNotValid] = useState(false)
  const [lastNameNotValid, setLastNameNotValid] = useState(false)

  const [uploadDPModal, setUploadDPModal] = useState(false)
  const [viewPrivacy, setViewPrivacy] = useState(false)
  const [viewAgreement, setViewAgreement] = useState(false)
  const [viewAccount, setViewAccount] = useState(false)
  const [viewDisplay, setViewDisplay] = useState(false)
  const [viewSignature, setViewSignature] = useState(false)
  const [viewUsers, setViewUsers] = useState(false)





  const conText = useContext(AppContext)


  const onImageClick = () => {
    uploadImage.current.click();
  };

  useEffect(() => {


    let usersData = JSON.parse(localStorage.getItem("bixChat_loggedUserData"))

    let profile_image = getDPService().getDP("ChatRoom.PrivateChatRoom", usersData.public_id)
    setBio(usersData?.about)
    setFirstName(usersData?.first_name)
    setLastName(usersData?.last_name)
    setDisplayName(usersData?.display_name)
    setPhoneNumber(usersData?.phone_number ? usersData?.phone_number : "")
    setHeadline(usersData?.headline)
    setAddress(usersData?.address)
    setCountChange(usersData?.change_count)
    setProfilePicture(profile_image ? profile_image : "")

    setIsDataLoaded(true)

    console.log(usersData?.first_name);
    if (usersData?.first_name.length === 0) {
      setFirstNameNotValid(true)
    } else {
      setFirstNameNotValid(false)
    }

    if (usersData?.last_name.length === 0) {
      setLastNameNotValid(true)
    } else {
      setLastNameNotValid(false)
    }


  }, []);
  const handleInputs = ((e) => {
    let usersData = JSON.parse(localStorage.getItem("bixChat_loggedUserData"))


    let textOnly = /^[a-zA-Z ]*$/;

    if (e.target.name === "firstName") {


      if (textOnly.test(e.target.value)) {
        if (usersData?.first_name !== e.target.value) {
          setIsChanged(true)
        } else {
          setIsChanged(false)
        }
        setFirstName(e.target.value)
      }

      if (e.target.value.length === 0) {
        setFirstNameNotValid(true)
      } else {
        setFirstNameNotValid(false)

      }

    } else if (e.target.name === "lastName") {


      if (textOnly.test(e.target.value)) {
        if (usersData?.last_name !== e.target.value) {
          setIsChanged(true)
        } else {
          setIsChanged(false)
        }
        setLastName(e.target.value)

      }


      if (e.target.value.length === 0) {
        setLastNameNotValid(true)
      } else {
        setLastNameNotValid(false)

      }


    } else if (e.target.name === "displayName") {
      if (usersData?.display_name !== e.target.value) {
        setIsChanged(true)
      } else {
        setIsChanged(false)
      }
      setDisplayName(e.target.value)
    } else if (e.target.name === "phoneNumber") {
      if (usersData?.phone_number !== e.target.value || usersData?.phone_number !== "") {
        setIsChanged(true)
      } else {
        setIsChanged(false)
      }
      setPhoneNumber(e.target.value)
    } else if (e.target.name === "headline") {
      if (usersData?.headline !== e.target.value) {
        setIsChanged(true)
      } else {
        setIsChanged(false)
      }
      setHeadline(e.target.value)
    } else if (e.target.name === "address") {
      if (usersData?.address !== e.target.value) {
        setIsChanged(true)
      } else {
        setIsChanged(false)
      }
      setAddress(e.target.value)
    } else if (e.target.name === "bio") {
      if (usersData?.about !== e.target.value) {
        setIsChanged(true)
      } else {
        setIsChanged(false)
      }
      setBio(e.target.value)
    }
  })



  const handleNewProPic = ((e) => {
    document.querySelector(".__SettingsWrapper").style = "display : none"

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      let base64Only = reader.result;


      setBase64Image(base64Only)
      setUploadDPModal(true)
      setProPicChanged(true)

    };

    reader.onerror = ((err) => {

    })

  })


  const renderUpdatedBtn = (() => {

    if (firstNameNotValid || lastNameNotValid || !isChanged) {
      console.log("iii");
      return (
        <button className={`add-photo-button-sttings-disable`} >Save Changes</button>
      )
    } else {
      console.log("asdkk");
      return (
        <button className={`add-photo-button-sttings button ${props.profileUpdating ? "is-loading" : ""} `} onClick={() => { props.updateProfile({ bio, firstName, lastName, displayName, phoneNumber, headline, address, countChange, base64Image }) }} >Save Changes</button>
      )
    }
  })


  const accountSettings = () => {
    return (
      <div className={viewAccount ? "setting-menus setting-menus-selected" : 'setting-menus'} onClick={() => {
        setViewAccount(true)
        setHeader("Account Settings")
        setViewProfSettings(false)
        conText.setViewBlockList(false)
        setViewAgreement(false)
        setViewPrivacy(false);
        setViewDisplay(false)
        setViewUsers(false)
        showRight()
      }}
      >
        <img alt="" src={user} className="icons-setting disable" /> Account Settings <img alt="" src={next} className="next-setting" /> </div>
    )
  }
  const displaySettings = () => {
    return (
      <div className={viewDisplay ? "setting-menus setting-menus-selected" : 'setting-menus'} onClick={() => {
        setViewDisplay(true)
        setHeader("Display Settings")
        setViewProfSettings(false)
        conText.setViewBlockList(false)
        setViewAgreement(false)
        setViewPrivacy(false);
        setViewAccount(false);
        setViewSignature(false)
        setViewUsers(false)
        showRight()
      }}
      >
        <img alt="" src={display} className="icons-setting disable" /> Display Settings <img alt="" src={next} className="next-setting" /> </div>
    )
  }
  const usersSettings = () => {
    return (
      <div className={viewUsers ? "setting-menus setting-menus-selected" : 'setting-menus'} onClick={() => {
        setViewDisplay(false)
        setHeader("Manage Users")
        setViewProfSettings(false)
        conText.setViewBlockList(false)
        setViewAgreement(false)
        setViewPrivacy(false);
        setViewAccount(false);
        setViewSignature(false)
        setViewUsers(true)
        showRight()
      }}
      >
        <img alt="" src={manage} className="icons-setting disable" /> Manage Users <img alt="" src={next} className="next-setting" /> </div>
    )
  }
  const privacyAndSecuritySettings = () => {
    return (

      <div className={`${viewPrivacy ? "setting-menus-selected setting-menus" : " setting-menus"}`} onClick={() => {
        setViewPrivacy(true);
        setHeader("Privacy Policy")
        setViewProfSettings(false)
        conText.setViewBlockList(false)
        setViewAgreement(false)
        setViewAccount(false)
        setViewDisplay(false)
        setViewSignature(false)
        showRight()
        setViewUsers(false)
      }}>
        <img alt="" src={shield} className="icons-setting" /> Privacy and Policy  <img alt="" src={next} className="next-setting" />  </div>

    )
  }

  // const notificationSettings = () => {
  //   return (
  //     <div className="setting-menus" style={{ cursor: "not-allowed" }}>
  //       <img alt="" src={notification} className="icons-setting" /> Notification Settings <img alt="" src={next} className="next-setting" />  </div>

  //   )
  // }

  const helpAndSupportSettings = () => {
    return (
      <div className={`${viewAgreement ? "setting-menus-selected setting-menus" : " setting-menus"}`} onClick={() => {
        setViewAgreement(true)
        setHeader("Beta Agreement")
        setViewProfSettings(false)
        conText.setViewBlockList(false)
        setViewPrivacy(false);
        setViewAccount(false)
        setViewDisplay(false)
        setViewSignature(false)
        showRight()
        setViewUsers(false)
      }}>
        <img alt="" src={agree} className="icons-setting" />Beta Agreement<img alt="" src={next} className="next-setting" /> </div>

    )
  }
  const showRight = () => {
    if (window.screen.width <= 600) {
      let right = document.getElementById('right-wrapper');
      let left = document.getElementById('left-wrapper');
      left.style.display = "none"
      right.style.display = 'block'
    }
  }
  const showLeft = () => {
    if (window.screen.width <= 600) {
      let right = document.getElementById('right-wrapper');
      let left = document.getElementById('left-wrapper');
      left.style.display = "block"
      right.style.display = 'none'
    }
  }



  const onDeleteClick = () => {
    conText.setSettingsModal(false)
    props.setModalType("deleteAccount")
    conText.setIsModalVisible(true)
    props.setIsPrimary(false)
  }

  const onSignatureClick = () => {
    conText?.setSignatureModal(true)
    conText.setSettingsModal(false)


  }


  return (

    <div>
      <div className="__SettingsWrapper">
        <div className={`modal ${props.settingsModal ? "is-active" : ""}`}>
          <div className="modal-background" onClick={() => {
            props.setSettingsModal(false)
            if (window.screen.width > 600 && conText.profileUpdated) { window.location.reload(false) }
          }}></div>
          <div className="modal-card" >
            <section className="modal-card-body" >
              <div className="left-wrapper" id="left-wrapper">
                <div className="left-wrapper-header">
                  <div className="logged-username">
                    Settings
                  </div>
                  <div>
                    <img alt="" src={close} className="icons-close" onClick={() => {
                      props.setSettingsModal(false)
                      conText.setShowPasswordLink(false)
                    }} />
                  </div>
                </div>
                <div className="generalChatToggleWrapper">
                  <div className={generalChatToggler === 'general' ? "generalChatToggle selectedGeneralChatToggle " : "generalChatToggle"} style={{ cursor: "pointer" }} onClick={() => {
                    setGeneralChatToggler("general")
                  }}>
                    General
                  </div>

                  <div className={generalChatToggler === 'chat' ? "generalChatToggle selectedGeneralChatToggle " : "generalChatToggle"} style={{ cursor: "pointer" }} onClick={() => {
                    setGeneralChatToggler("chat")
                  }}>
                    Chat
                  </div>
                </div>
                <div className="setting-option" id="general" >
                  {generalChatToggler === 'general' ?
                    <div>
                      <div className={`${viewProfSettings ? "setting-menus-selected setting-menus" : " setting-menus"}`}
                        onClick={() => {
                          setViewProfSettings(true)
                          setViewPrivacy(false)
                          conText.setViewBlockList(false)
                          setHeader("Profile Settings")
                          setViewAgreement(false)
                          setViewAccount(false)
                          setViewDisplay(false)
                          setViewSignature(false)
                          showRight()
                          setViewUsers(false)


                        }}
                      >
                        <img alt="" src={settings} className="icons-setting" /> Profile Settings  <img alt="" src={next} className="next-setting" /> </div>
                      {accountSettings()}
                      {displaySettings()}

                      {localStorage.getItem("bixChat_role") === "ADMIN" ? usersSettings() : ""}
                      {/* <Feature name='account-settings' activeComponent={accountSettings()} inactivecomponent={null} /> */}
                      {/* {notificationSettings()} */}
                      {/* <Feature name='notification-settings' activeComponent={notificationSettings()} inactivecomponent={null} /> */}
                      {privacyAndSecuritySettings()}
                      {/* <Feature name='privacy-and-security-settings' activeComponent={privacyAndSecuritySettings()} inactivecomponent={null} /> */}

                      {helpAndSupportSettings()}
                      {/* <Feature name='help-and-support-settings' activeComponent={helpAndSupportSettings()} inactivecomponent={null} /> */}
                    </div>
                    :
                    <div>
                      <div className={conText.viewBlockList ? "setting-menus setting-menus-selected" : 'setting-menus'} onClick={() => {
                        conText.setViewBlockList(true)
                        setViewSignature(false)
                        setViewProfSettings(false)
                        setViewPrivacy(false)
                        setHeader("Blocked contacts and emails")
                        setViewAgreement(false)
                        setViewAccount(false)
                        showRight()
                        setViewUsers(false)

                      }} >
                        <img alt="" src={lock} className="icons-setting" /> Block List  <img alt="" src={next} className="next-setting" />
                      </div>

                      <div>
                        <div className={viewSignature ? "setting-menus setting-menus-selected" : 'setting-menus'} onClick={() => {
                          conText.getEmailSignature()
                          setViewSignature(true)
                          conText.setViewBlockList(false)
                          setViewProfSettings(false)
                          setViewPrivacy(false)
                          setViewDisplay(false)
                          setHeader("Add Your Signature")
                          setViewAgreement(false)
                          setViewAccount(false)
                          showRight()
                          setViewUsers(false)
                        }} >
                          <img alt="" src={edit} className="icons-setting" /> Signature  <img alt="" src={next} className="next-setting" />
                        </div>
                      </div>
                    </div>


                  }
                </div>
              </div>

              <div className="right-wrapper" id="right-wrapper" >

                <div className="right-wrapper-header">
                  <img alt="" src={next} className="back-settings" onClick={showLeft} />
                  <div className="logged-username">
                    {header}
                  </div>
                  <div>
                    <img alt="" src={close} className="icons-close" onClick={() => {
                      props.setSettingsModal(false)
                      conText.setShowPasswordLink(false)
                      if (window.screen.width > 600 && conText.profileUpdated) { window.location.reload(false) }
                    }} />
                  </div>
                </div>


                {isDataLoaded ? <div className="input-wrapper-div" >

                  {viewProfSettings ?
                    <div>
                      < ProfileSettings

                        proPicChanged={proPicChanged} a
                        Avatar={Avatar}
                        base64Image={base64Image}
                        profilePicture={profilePicture}
                        ProImage={ProImage}
                        onImageClick={onImageClick}
                        uploadImage={uploadImage}
                        handleNewProPic={handleNewProPic}
                        countChange={countChange}
                        displayName={displayName}
                        address={address}
                        firstName={firstName}
                        lastName={lastName}
                        handleInputs={handleInputs}
                        phoneNumber={phoneNumber}
                        headline={headline}
                        renderUpdatedBtn={renderUpdatedBtn}
                      />
                    </div>
                    :
                    viewPrivacy ?
                      <div>
                        <PrivacyContent />
                      </div>
                      : viewAgreement ?
                        <div className="agr">
                          <Agreement />
                        </div>
                        :
                        viewAccount ?
                          <div>
                            <AccountSettings
                              onDeleteClick={onDeleteClick}
                            />
                          </div>
                          :
                          viewDisplay ?
                            <div>
                              <DisplaySettings />
                            </div>
                            :
                            viewUsers ?
                              <div>
                                <ManageUsers />
                              </div>
                              :
                              conText.viewBlockList ?
                                <div>
                                  <BlockList />
                                </div>
                                :
                                viewSignature ?
                                  <div>
                                    <SignatureSettings
                                      onSignatureClick={onSignatureClick}
                                    />
                                  </div>
                                  : ''
                  }

                </div> : ""}
              </div>
            </section>
          </div>
        </div>
      </div>

      {uploadDPModal ? (

        <UploadDpModal
          // addPhoto={addPhoto}
          setAddPhoto={setUploadDPModal}
          imagePreviewModal={base64Image}
          setImagePreviewModal={setBase64Image}
          // uploadImage={uploadImage}
          onImageClick={onImageClick}
          relatedComponent={"settings"}
          // setProPicUploaded={setProPicUploaded}
          // proPicUploaded={proPicUploaded}
          // newcroppedImage={newcroppedImage}
          setNewCroppedImage={setBase64Image}
        />
      ) : (
        ""
      )}
    </div>



  )
}



export default SettingsWrapper