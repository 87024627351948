import React, { useEffect } from "react";
import "./MultiEmails.scss";
import AppContext from "../../ContextAPI/AppContext"
import config from "../../config"


const MultiEmails = (props) => {

    let emails = [];


    useEffect(() => {
        localStorage.setItem("emails", JSON.stringify(emails));

    }, [])

    // const isEmailValidation = (email) => {
    //     let emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //     )
    //     return emailPattern.test(email);
    // }

    // const handleKeyPress = (e, func) => {
    //     if (func === 'contact') {
    //         emails = JSON.parse(localStorage.getItem('emails'))
    //         if (!emails.includes(e.profile_email)) {
    //             emails.push(e.profile_email)
    //             localStorage.setItem("emails", JSON.stringify(emails));
    //             document.getElementById('emailtxt').value = ''

    //             let arr = conText?.searchList?.filter(
    //                 (item) => item != e.profile_email.trim()
    //             )


    //             conText.setSearchList(arr)
    //         }
    //         else {
    //             document.getElementById('emailtxt').value = ''
    //             // e.preventDefault();



    //         }

    //     }
    //     else {

    //         let isEmail = isEmailValidation(e.target.value)

    //         if (!isEmail) {
    //             if (e.key === 'Enter' || e.code == 'Space') {
    //                 e.preventDefault();
    //                 emails = JSON.parse(localStorage.getItem('emails'))

    //             }
    //             else {
    //                 emails = JSON.parse(localStorage.getItem('emails'))
    //             }
    //         }
    //         else {
    //             emails = JSON.parse(localStorage.getItem('emails'))

    //             if (e.key === 'Enter' || e.code == 'Space' || e.type == 'blur') {
    //                 if (!emails.includes(e.target.value)) {
    //                     emails.push(e.target.value)
    //                     localStorage.setItem("emails", JSON.stringify(emails));
    //                     document.getElementById('emailtxt').value = ''
    //                 }
    //                 else {
    //                     document.getElementById('emailtxt').value = e.target.value
    //                     // e.preventDefault();


    //                 }

    //             }

    //         }
    //     }

    //     props.setReceivers(emails)

    // }

    // const removeEmail = (email) => {
    //     let arr = props?.receivers?.filter(
    //         (item) => item != email
    //     );

    //     props?.setReceivers(arr)
    //     localStorage.setItem("emails", JSON.stringify(arr));
    // }


    return (

        <AppContext.Consumer>
            {(context) => (
                <div >
                    {props?.receivers?.length > 0 ?
                        <div className="email-labels" id='main'>
                            {props?.receivers?.map((value, i) => {
                                return (
                                    <div className="single-label">
                                        {value}
                                        <div
                                            className="closex"
                                            onClick={() => {
                                                props.removeEmail(value, props.from)
                                            }}

                                        >x</div>
                                    </div>
                                )
                            })}

                        </div>
                        : props?.ccReceivers?.length > 0 ?
                            <div className="email-labels" id='cc'>
                                {props?.ccReceivers?.map((value, i) => {
                                    return (
                                        <div className="single-label">
                                            {value}
                                            <div
                                                className="closex"
                                                onClick={() => {
                                                    props.removeEmail(value, props.from)
                                                }}

                                            >x</div>
                                        </div>
                                    )
                                })}

                            </div> : ''}

                    {props?.receivers?.length > 0 ?
                        props.from === 'main' ? props.setTop((document?.getElementById('main')?.offsetHeight) + 45 + 'px') :
                            '' : ''}

                    {/* // props?.ccReceivers?.length > 0 ? props.from === 'cc' ? props.setTop((document?.getElementById('cc')?.offsetHeight) + 45 + 'px') : '' : '' : ''} */}

                    {props?.ccReceivers?.length > 0 ?
                        props.from === 'cc' ? props.setccTop((document?.getElementById('cc')?.offsetHeight) + 45 + 'px') :
                            '' : ''}

                    <input
                        id={props.from === 'main' ? 'mainn' : 'ccc'}
                        placeholder={props.from === 'main' && !props.receivers.length > 0 ? `john${config.emailProvider}` : props.from === 'cc' && !props.ccReceivers.length > 0 ? `john${config.emailProvider}` : ''}
                        className='multi-email'
                        onKeyPress={(e) => {
                            props.handleKeyPress(e)
                        }}

                        onClick={() => {
                            context.setShowSugestions(false)
                            if (props.from === 'main') {
                                props.setTop('50px')

                            }

                        }}

                        onFocus={() => {
                            props.setOnFocus(props.from)

                        }}

                        onBlur={(e) => {
                            props.handleKeyPress(e)
                            if (props.from === 'main') {
                                props.setTop('50px')

                            }

                        }
                        }

                        onChange={(e) => {
                            if (e.target.value !== '') {
                                if (props.from === 'main') {
                                    context.searchContact(e, props?.receivers)

                                }
                                else {
                                    context.searchContact(e, props?.ccReceivers)

                                }
                            }
                        }}

                    />

                </div>


            )
            }


        </AppContext.Consumer >


    );
};
export default MultiEmails;
