import React, { useContext } from "react";
import logout from "../../../Assets/Icons/vuesax-linear-logout.svg";
import settings from "../../../Assets/Icons/vuesax-linear-setting.svg";
import AppContext from "../../../ContextAPI/AppContext";
import "./LeftNavbarStyle.scss";
import Avatar from '../../../Components/Avatar/avatar'


const LeftNavbar = (props) => {
  const conText = useContext(AppContext)

  const closePopup = () => {
    conText.leftNavPopupHandler("closeOnly")
    conText.RightNavPopupHandler("closeOnly")
    conText.UploadModalHandler("closeOnly")

  }
  return (
    <AppContext.Consumer>

      {(context) => (
        <div className="__LeftNavbarWrapper">

          <div className={`header-${context.fontSizeType}`} onClick={closePopup}> <div>ZapMail</div></div>
          {/* <div className="centerText" onClick={closePopup}>
            <div className="unselected">Task</div>
            <div className="selected">Message</div>
            <div className="unselected" onClick={() => {
              window.location.href =
                'https://cloud.bixchat.xyz';
              mixpanel.track('Cloud button clicked')
            }}
            >Cloud</div>
            <div className="unselected">Calendar</div>
          </div> */}


          {/*  */}

          <div className="bottom-iconwrapper">
            <div style={{ flex: 1 }} onClick={closePopup}></div>
            <div className="icon2-wrapper" onClick={context.leftNavPopupHandler}>
              <img
                className="profile-img"
                src={localStorage?.getItem("bixChat_profileImage")}
                alt=""
                id="profile-img"
              />
              {/* <Avatar
                size='s'
                type='personalAvatar'
                src={localStorage?.getItem("bixChat_profileImage")}

              /> */}

              <div className="popOver" style={{ display: context.leftNavPopup }}>
                <div className="img-cont">
                  <Avatar
                    src={localStorage.getItem("bixChat_profileImage")}
                    withBorder={false}
                    size='xs'
                  />
                  <div className="data">
                    <span className="name">{localStorage.getItem("bixChat_fullname")}</span>
                    <div className="email">{localStorage.getItem("bixChat_email")}</div>
                  </div>
                </div>
                <div className="sections"
                  onClick={() => {

                    if (props.settingsModal) {
                      props.setSettingsModal(false);
                    } else {
                      props.setSettingsModal(true);
                    }
                  }}
                >
                  <img alt="" src={settings} className="img-sec" />
                  Settings
                </div>
                <div
                  className="sections"
                  style={{ marginBottom: "0px" }}
                  onClick={async () => {
                    context.RightNavPopupHandler("closeOnly")
                    context.logoutFunction()

                  }}
                >
                  <img alt="" src={logout} className="img-sec" />
                  Log out
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default LeftNavbar;
