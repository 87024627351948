import React, { useEffect, useContext } from "react"
import image1 from "../../../Assets/Images/zap1.png"
import image2 from "../../../Assets/Images/zap2.png"
import image3 from "../../../Assets/Images/zap3.png"
import AppContext from "../../../ContextAPI/AppContext"
import "./NewUiModalStyles.scss"
import CloseIcon from "../../../Components/Button/CloseIcon/CloseIcon"
const NewUiModal = (props) => {
    const context = useContext(AppContext)
    let slideIndex = 1;
    useEffect(() => {
        showSlides(slideIndex);
        setInterval(() => {
            plusSlides(1)
        }, 2000);
    }, []);

    const plusSlides = (n) => {
        showSlides(slideIndex += n);
    }

    const currentSlide = (n) => {
        showSlides(slideIndex = n);
    }


    const showSlides = (n) => {
        let i;
        let slides = document.getElementsByClassName("NewModalUi-top");
        let dots = document.getElementsByClassName("slide");
        if (slides.length > 0) {
            // console.log(slides);
            if (n > slides.length) { slideIndex = 1 }
            if (n < 1) { slideIndex = slides.length }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className?.replace(" active", "");
            }
            slides[slideIndex - 1].style.display = "block";
            dots[slideIndex - 1].className += " active";
        }



    }
    let data = [image1, image2, image3]
    return (
        <div className="NewModalUi">
            <div className="pop-modal">
                <div className="close-btn"><CloseIcon size="l" onClick={() => {
                    props.setNewUiModalVisibility(false)
                    context.getSupport().then((response) => {
                        if (response) {
                            props.setStep(0)
                        }
                    })

                }} /></div>
                <div className="seperator" />
                <div className="text-newui">Get ready for an upgraded and enhanced experience with Zapmail.</div>
                <div className="text-newui-COMING ">COMING SOON</div>
                <div className="slide-cont">
                    <div className="animation-zoom">
                        {data.map((img, i) => {
                            return (
                                <div className="NewModalUi-top" key={i}>
                                    <img alt="" src={img} />
                                </div>
                            )
                        })}
                    </div>
                </div>



                <div className='dot-bar'>
                    <div className="slide" onClick={() => currentSlide(1)}></div>
                    <div className="slide" onClick={() => currentSlide(2)}></div>
                    <div className="slide" onClick={() => currentSlide(3)}></div>
                </div>

            </div>
        </div>
    )

}
export default NewUiModal