import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { ws } from "../../App";
import "./HomePageStyle.scss";
import LeftNavbar from "./LeftNavbar/LeftNavbar";
import Sidebar from "./LeftSidebar/LeftSidebar";
import MessageBox from "./MessageBox/MessageBox";
import RightSideBar from "./RightSidebar/RightSideBar";
import { useHistory } from "react-router-dom";
import SendNewMessage from "./SendNewMessage/SendNewMessage";
import NewGroupModal1 from "./NewGroupModal1/NewGroupModal1";
import NewGroupModal2 from "./NewGroupModal1/NewGroupModal2";
import AdminModal from "../../Components/Modal/AdminModal/AdminModal";
import ImagePreview from "../../Components/Modal/ImagePreview/ImagePreview";
import AppContext from "../../ContextAPI/AppContext";
import addNotification from "react-push-notification";
import SettingsWrapper from "./Settings/SettingsWrapper";
import UploadDpModal from "../../Components/Modal/UploadDPModal/UploadDPModal";
import CenterModal from "../../Components/Modal/CenterModal/CenterModal";
import config from "../../config";
import MainWrapper from "../MobileComponent/MainWrapper";
import LoadingScreen from "./LoadingScreen/LoadingScreen";

import '../ChatUIComponents/ToolTips/ToolTip.scss'
import SaveToCloud from "../../Components/Modal/SaveToBixCloud/SaveToCloudModal";
import ImageUpload from "../../Components/Modal/ImageUploadModal/ImageUploadModal";
import Tooltip from "./ToolTips/ToolTip";
import ForwardModal from "../../Components/Modal/ForwardModal/ForwardModal";
import SendComponent from "./SendComponent/SendComponent";
import Alert from "../../Components/Alert/Alert";
import ReportModal from "../../Components/Modal/ReportModal/ReportModal";
import ReportSentModal from "../../Components/Modal/ReportModal/ReportSentModal";
import BlockConfirm from "./BlockList/BlockConfirm/BlockConfirm";
import Modal from "../../Components/Modal/Modal";
import { logoutService } from "../../services/LogoutService";
import SignatureModal from "./SignatureModal/SignatureModal";
import AppIntregrationModal from "../../Components/Modal/AppIntegrationModal/AppIntegrationModal";
import GoogleCalenderInputs from "../../Components/Modal/AppIntegrationModal/GoogleCalenderInputs";
import GoggleCalenderOptions from "../../Components/Modal/AppIntegrationModal/GoogleCalenderOptions";
import AddGuest from "../../Components/Modal/AppIntegrationModal/AddGuest";
import NewUiModal from "./NewUiModal/NewUiModal";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const ChatUIWrapper = () => {
  const [newGroupModal1, setNewGroupModal1] = useState(false);
  const [newGroupModal2, setNewGroupModal2] = useState(false);
  const [activeStatus, setActiveStatus] = useState("");
  const [newGroupFrom, setNewGroupFrom] = useState("newGroup");
  const [test, setTest] = useState({});
  const [imagePreview, setImagePreview] = useState(false);
  const [image, setImage] = useState("");
  const [currentImage, setCurrentImage] = useState(1);
  const history = useHistory();
  const [imagePreviewModal, setImagePreviewModal] = useState(null);
  const [addPhoto, setAddPhoto] = useState(false);
  const [newcroppedImage, setNewCroppedImage] = useState(null);
  const [proPicUploaded, setProPicUploaded] = useState(false);
  const [addMembersFrom, setAddMembersFrom] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalWarnig, setModalWarnig] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [modalFunction, setModalFunction] = useState("");
  const [membersList, setMembersList] = useState([]);
  const [isSaveToCloud, setIsSaveToCloud] = useState(null);
  const [isUploadFromCloud, setIsUploadFromCloud] = useState(false);
  const uploadImage = useRef(null);

  const [signupStatus, setSignupStatus] = useState(localStorage.getItem("firstLogin"));
  const loginStatus = useState(localStorage.getItem('bixChat_isLogged'));
  const conText = useContext(AppContext)
  const initialStep = 0;
  const [step, setStep] = useState(0)
  const [onlineGroupUsers, setOnlineGroupUsers] = useState([])
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const newPopup = useRef(null)
  const [popup, setPopup] = useState(false)

  //modal states
  const [newUiModalVisibility, setNewUiModalVisibility] = useState(false)
  const [isPrimary, setIsPrimary] = useState(true)
  const [modalType, setModalType] = useState("")
  const [textFocus, setTextFocus] = useState(false)
  const [footerFixedBottom, setFooterFixedBottom] = useState("")
  const [sendComponentFrom, setSendComponentFrom] = useState("")
  const [rec, setRec] = useState("")




  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    if (config.environment !== "development") {
      if (window.screen.width > 600) {
        conText.loginFunction()
      }
    }
  }, [])
  const auth = async () => {
    let accessToken = localStorage.getItem('bixChat_accessToken');
    if (accessToken) {
      let email = localStorage.getItem("bixChat_email");
      window.clarity("set", "username", email)

      if (ws.readyState === WebSocket.CONNECTING) {
        ws.addEventListener("open", function (event) {
          ws.send(
            JSON.stringify({
              action: "login",
              publicID: localStorage.getItem("bixChat_loggedUserPublicId"),
              userEmail: localStorage.getItem("bixChat_email"),
            })
          );
        });
      } else if (ws.readyState === WebSocket.OPEN) {
        ws.send(
          JSON.stringify({
            action: "login",
            publicID: localStorage.getItem("bixChat_loggedUserPublicId"),
            userEmail: localStorage.getItem("bixChat_email"),
          })
        );
      }
      ws.onmessage = (message) => {
        let data = JSON.parse(message.data);
        switch (data.action) {
          // active status start ...................................................
          case "activeStatus":
            setActiveStatus(data.status);
            break;
          case "online":
            if (
              data.onlineUserPublicID ===
              localStorage.getItem("selectedChat_receiverId")
            ) {
              setActiveStatus("online");
            }
            break;
          case "offline":
            if (
              data.offlineUserPublicID ===
              localStorage.getItem("selectedChat_receiverId")
            ) {
              setActiveStatus("offline");
            }
            break;
          case "message":
            if (
              data?.body?.sender_id !==
              localStorage.getItem("bixChat_loggedUserPublicId")
            ) {
              let message = data?.body;
              listener(message);
            }
            break;
          case "pong":
            break;
          case "groupActiveStatus":
            setOnlineGroupUsers(data?.result)
            break;
          // active status end .....................................................
          default:
            break;
        }
      };
      ws.addEventListener("message", (event) => {
        let message = JSON.parse(event.data);
        if (message.action === "typing") {
          setTest((test) => ({
            ...test,
            [message.chat.chatroom_id]: message.chat,
          }));
        }
      });

      ws.addEventListener("message", (event) => {
        let message = JSON.parse(event.data);
        if (message.action === "typingend") {
          setTest((test) => delete test[message.chat.chatroom_id]);
        }
      });


    }
    else {
      localStorage.clear()
      if (config.environment !== "development") {
        console.log("logs auth");
        logoutService().logoutRedirection()
      } else {
        history.push({
          pathname: "/login",
        });
      }


    }
  }
  useEffect(() => {
    auth()
  }, [])



  const onImageClick = () => {
    uploadImage.current.click();
  };
  const subscribe = (subscription) => {
    axios({
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: `user/subscribe`,
      data: {
        subscription: subscription
      },
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
    })
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const notification = (message) => {
    addNotification({
      title: message?.chatroom_type === "ChatRoom.GroupChatRoom" ? message?.sender_name + " @ " + message?.chatroom_name : "New message from " + message?.sender_name,
      message: message.message_body.replace(/(<([^>]+)>)/ig, ''),
      theme: "darkblue",
      native: true, // when using native, your OS will handle theming.
      closeButton: 'Go away',
    });
  };

  useEffect(async () => {


    if ("serviceWorker" in navigator) {
      let sw = await navigator.serviceWorker.ready;

      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BHBGsh8bWs6kOnxm0FdNe0Ibh6bEd6niewiFeyAQdfgvRkLq2FTosy9a1Io9BJrhLqNmkaALAhV45Paz9txzNpI",
      });
      subscribe(push)
    }


  }, [])

  // const onExit = () => {


  //   localStorage.setItem('bixChat_Signup', 'false');

  //   setSignupStatus(localStorage.getItem('bixChat_Signup'));

  //   if (signupStatus === 'false') {
  //     setStepsEnabled(false);
  //   }

  // };

  const listener = (message) => {
    window.addEventListener("focus", notification(message));
    return window.removeEventListener("focus", notification(message));
  };


  if (windowDimensions.width > 600) {
    // Desktop Screen
    return (
      <AppContext.Consumer>
        {(context) => (

          <div>
            {(!context.allChatroomsLoaded && context.isLogged) || context.isSwitching ?
              <LoadingScreen isLoggedIn={!context.tokenExpired} isSwitching={context.isSwitching} /> :

              < div className="parent" >

                {/* {signupStatus === 'true' ?
                  <div>
                    <Steps
                      enabled={stepsEnabled}
                      steps={steps}
                      initialStep={initialStep}
                      onExit={onExit}
                      options={{
                        showBullets: false, hidePrev: true,
                        doneLabel: 'Finish', exitOnEsc: false, exitOnOverlayClick: false,
                        disableInteraction: true
                      }}
                    />

                  </div>
                  : ""} */}


                <div className="__ChatUIWrapper">


                  <LeftNavbar
                    setSettingsModal={context.setSettingsModal}
                    settingsModal={context.settingsModal}
                  />
                  {context.sendModal ? <SendComponent sendComponentFrom={sendComponentFrom} /> : window.screen.width > 1200 ? < Sidebar
                    setRightSideBarStatus={context.setRightSideBarStatus}
                    sendMessageModal={context.sendMessageModal}
                    setSendMessageModal={context.setSendMessageModal}
                    setNewGroupModal1={setNewGroupModal1}
                    newGroupModal1={newGroupModal1}
                    typingChats={test}
                    setAddMembersFrom={setAddMembersFrom}
                    initialStep={initialStep}
                    newPopup={newPopup}
                    popup={popup}
                    textFocus={textFocus}
                    setTextFocus={setTextFocus}
                    footerFixedBottom={footerFixedBottom}
                    setFooterFixedBottom={setFooterFixedBottom}
                    setSendComponentFrom={setSendComponentFrom}
                  /> : !context.rightSideBarStatus ? < Sidebar
                    setRightSideBarStatus={context.setRightSideBarStatus}
                    sendMessageModal={context.sendMessageModal}
                    setSendMessageModal={context.setSendMessageModal}
                    setNewGroupModal1={setNewGroupModal1}
                    newGroupModal1={newGroupModal1}
                    typingChats={test}
                    setAddMembersFrom={setAddMembersFrom}
                    initialStep={initialStep}
                    newPopup={newPopup}
                    popup={popup}
                    textFocus={textFocus}
                    setTextFocus={setTextFocus}
                    footerFixedBottom={footerFixedBottom}
                    setFooterFixedBottom={setFooterFixedBottom}
                    setSendComponentFrom={setSendComponentFrom}
                  /> : ""}





                  <MessageBox
                    setRightSideBarStatus={context.setRightSideBarStatus}
                    rightSideBarStatus={context.rightSideBarStatus}
                    typingChats={test}
                    setTypingChats={setTest}
                    activeStatus={activeStatus}
                    setActiveStatus={setActiveStatus}
                    setImagePreview={setImagePreview}
                    setImage={setImage}
                    setCurrentImage={setCurrentImage}
                    setSelectedChat={context?.setSelectedChatroom}
                    chatObject={context?.isChatObject}
                    isSaveToCloud={isSaveToCloud}
                    setIsSaveToCloud={setIsSaveToCloud}
                    isUploadFromCloud={isUploadFromCloud}
                    setIsUploadFromCloud={setIsUploadFromCloud}
                    textFocus={textFocus}
                    setTextFocus={setTextFocus}
                    footerFixedBottom={footerFixedBottom}
                    setFooterFixedBottom={setFooterFixedBottom}

                  />
                  {context.rightSideBarStatus ? (
                    <RightSideBar
                      setRightSideBarStatus={context.setRightSideBarStatus}
                      activeStatus={activeStatus}
                      selectedChat={context?.selectedChatroom}
                      addPhoto={addPhoto}
                      setAddPhoto={setAddPhoto}
                      imagePreviewModal={imagePreviewModal}
                      setImagePreviewModal={setImagePreviewModal}
                      newcroppedImage={newcroppedImage}
                      setNewCroppedImage={setNewCroppedImage}
                      uploadImage={uploadImage}
                      onImageClick={onImageClick}
                      proPicUploaded={proPicUploaded}
                      setImagePreview={setImagePreview}
                      setImage={setImage}
                      setCurrentImage={setCurrentImage}
                      setNewGroupModal1={setNewGroupModal1}
                      newGroupModal1={newGroupModal1}
                      setAddMembersFrom={setAddMembersFrom}
                      setModalHeader={setModalHeader}
                      setModalDescription={setModalDescription}
                      setModalVisibility={context.setCenterModalVisibility}
                      setModalWarnig={setModalWarnig}
                      chatObject={context?.isChatObject}
                      setModalFunction={setModalFunction}
                      setMembersList={setMembersList}
                      onlineList={onlineGroupUsers}
                      rec={rec}
                      setRec={setRec}
                      sendMessageModal={context.sendMessageModal}
                      setSendMessageModal={context.setSendMessageModal}
                    />
                  ) : (
                    ""
                  )}

                  {/* {context.contactModal ?
                    <SaveContactRight
                      setRightSideBarStatus={context.setRightSideBarStatus}
                    /> : ''} */}



                  {context.settingsModal ?
                    <SettingsWrapper
                      setSettingsModal={context.setSettingsModal}
                      settingsModal={context.settingsModal}
                      profileUpdating={context.profileUpdating}
                      updateProfile={context.updateProfile}
                      setModalType={setModalType}
                      setIsPrimary={setIsPrimary}
                    />
                    : ""}

                  {context.sendMessageModal ? (
                    <SendNewMessage
                      sendingMultipleMsg={context.sendingMultipleMsg}
                      setSendMessageModal={context.setSendMessageModal}
                      sendMessageModal={context.sendMessageModal}
                      setNewGroupPopup={setNewGroupModal2}
                      setNewGroupFrom={setNewGroupFrom}
                      isNewMessage={context.sendMessageModal}
                      isOnline={context.isOnline}
                      rec={rec}
                      setRec={setRec}
                    />
                  ) : (
                    ""
                  )}

                  {newGroupModal1 ? (
                    <NewGroupModal1
                      setNewGroupModal1={setNewGroupModal1}
                      newGroupModal1={newGroupModal1}
                      setNewGroupModal2={setNewGroupModal2}
                      newGroupModal2={newGroupModal2}
                      setNewGroupFrom={setNewGroupFrom}
                      addMembersFrom={addMembersFrom}
                    />
                  ) : (
                    ""
                  )}
                  {newGroupModal2 ? (
                    <NewGroupModal2
                      setNewGroupModal1={setNewGroupModal1}
                      newGroupModal1={newGroupModal1}
                      setNewGroupModal2={setNewGroupModal2}
                      newGroupModal2={newGroupModal2}
                      newGroupFrom={newGroupFrom}
                      setSendMessageModal={context.setSendMessageModal}
                    />
                  ) : (
                    ""
                  )}

                  {imagePreview ? (
                    <ImagePreview
                      setImagePreview={setImagePreview}
                      image={image}
                      currentImage={currentImage}
                    />
                  ) : (
                    ""
                  )}
                  {addPhoto ? (
                    <UploadDpModal
                      addPhoto={addPhoto}
                      setAddPhoto={setAddPhoto}
                      imagePreviewModal={imagePreviewModal}
                      setImagePreviewModal={setImagePreviewModal}
                      uploadImage={uploadImage}
                      onImageClick={onImageClick}
                      setProPicUploaded={setProPicUploaded}
                      proPicUploaded={proPicUploaded}
                      relatedComponent={"groupImage"}
                      newcroppedImage={newcroppedImage}
                      setNewCroppedImage={setNewCroppedImage}
                    />
                  ) : (
                    ""
                  )}
                  {context.centerModalVisibility ? (
                    <CenterModal
                      modalHeader={modalHeader}
                      modalDescription={modalDescription}
                      modalWarnig={modalWarnig}
                      setModalVisibility={context.setCenterModalVisibility}
                      modalFunction={modalFunction}
                      membersList={membersList}
                      setMembersList={setMembersList}


                    />
                  ) : (
                    ""
                  )}
                  {context?.adminModalVisibility ? (
                    <AdminModal
                      membersList={membersList}
                      setMembersList={setMembersList}
                    />
                  ) : ""}
                  {localStorage.getItem("firstLogin") === "true" ?
                    <Tooltip
                      step={step}
                      setStep={setStep}
                      setPopup={setPopup}
                      setSignupStatus={setSignupStatus}
                      setNewUiModalVisibility={setNewUiModalVisibility}

                    />
                    : ""}
                  {console.log(newUiModalVisibility, "newUiModalVisibility")}
                  {newUiModalVisibility ?
                    <NewUiModal
                      setNewUiModalVisibility={setNewUiModalVisibility}
                      setStep={setStep}
                    /> : ""}
                  {context.alert ? <Alert alert="Contact Saved" /> : ""}
                  {context.pinAlert ? <Alert alert="3 chats already pinned" /> : ""}
                  {context.isModalVisible ?
                    <Modal
                      modalType={modalType}
                      isPrimary={isPrimary}
                    /> : ""}
                </div>


                {isSaveToCloud ? (
                  <SaveToCloud
                    isSaveToCloud={isSaveToCloud}
                    setIsSaveToCloud={setIsSaveToCloud}
                  />) : ""}


                {context.blockConfirm ?
                  <BlockConfirm />
                  : ''}


                {isUploadFromCloud ?
                  <ImageUpload
                    setIsUploadFromCloud={setIsUploadFromCloud}
                  />
                  : ""}
                {context?.forwardModal ? (
                  <ForwardModal />
                ) : ""
                }






                {context?.appIntregrationModal ? (
                  <AppIntregrationModal />
                ) : ""
                }

                {context?.googleCalenderOptionModal ? (
                  <GoggleCalenderOptions />
                ) : ""
                }

                {context?.googleCalenderInputModal ? (
                  <GoogleCalenderInputs />
                ) : ""
                }

                {context?.addGuestModal ? (
                  <AddGuest />
                ) : ""}

                {context?.reportModal ? (
                  <ReportModal />
                ) : ""}

                {context?.reportSent ? (
                  <ReportSentModal />
                ) : ""}


                {context?.signatureModal ? (
                  <SignatureModal />
                ) : ""}



                {/* {context?.contactModal ? (
                  <SaveToContact />
                ) : ''} */}


              </div>
            }
          </div>


        )
        }
      </AppContext.Consumer >
    );
  } else {
    return (
      <MainWrapper />)
  }


};

export default ChatUIWrapper;
