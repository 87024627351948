import React, { useState } from "react";
import user from "../../../Assets/Icons/vuesax-linear-user.svg";
import edit from "../../../Assets/Icons/vuesax-linear-message-edit.svg";
import uplaod from "../../../Assets/Icons/export.svg";



import "./AdminInputStyles.scss"
const AdminInput = (props) => {
    const [isFocus, setIsFocus] = useState(false)
    return (
        <div className="AdminInputContainer" onClick={props.onClick}>
            {isFocus || props.value !== "" ?
                <label className="admin-input-label" style={{ color: props.readonly ? "#9d9d9d" : !isFocus ? "#9d9d9d" : "" }}>{props.name}</label>
                : ""}
            <br />
            <div className="input-cont" style={{ borderColor: isFocus && !props.readonly ? "#0d2a4c" : props.isError || props.required && props.value === "" ? "#ff0000" : "" }}>
                {props.value === "" && !isFocus ?
                    props.readOnly ? <img alt="" src={uplaod} className="icon" /> : props.name === "Name" ? <img alt="" src={user} className="icon" /> : <img alt="" src={edit} className="icon" /> : ""}
                <input
                    name={props.name}
                    placeholder={!isFocus && props.value === "" ? props.name : ""}
                    onFocus={() => setIsFocus(true)}
                    onChange={props.onChange}
                    onBlur={() => setIsFocus(false)}
                    readOnly={props.readonly}
                    value={props.value}
                    style={{ color: props.color ? props.color : "" }}
                />
            </div>
            {props.required && props.value === "" && !isFocus ?
                <div className="required">* Required</div>
                : ""}
        </div>)
}
export default AdminInput