import React from "react";
import "./PrivacyModalStyles.scss";
import CloseIcon from "../../Button/CloseIcon/CloseIcon";
import Agreement from "../../../pages/Admin/Agreement/Agreement";
import PrivacyContent from "../../../Content/PrivacyContent";




const PrivacyModal = (props) => {
    const getContent = () => {
        if (props.type === "privacy") {
            return <PrivacyContent />
        }
        else {
            return <Agreement />
        }

    }

    return (

        <div className="_PrivacyModal">
            <div className="privacy-modal-black-background">
                <div className="privacy-modal-content-inner">
                    <div className="privacy-modal-content-center">
                        <div className="header">{props.type === "privacy"?"Privacy Policy":"Terms and Conditions"}</div>
                        <div style={{ marginTop: '10px' }}>
                            <CloseIcon
                                size='s'
                                onClick={props.onClick}
                            />
                        </div>
                    </div><div className="privacy-inner-container">{getContent()}
                    </div>
                </div>
            </div>
        </div>


    );
};
export default PrivacyModal;
