import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import lottie from "lottie-web";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
import "../Login/LoginStyles.scss";
import UsernameInput from "../../../Components/InputComponents/UsernameInput/UsernameInput";
import "../SignupWrapper/Signup.scss";
import calender from "../../../Assets/Icons/vuesax-linear-calendar.svg";
import ProImg from "../../../Assets/Icons/addPic.PNG";
import getCroppedImg from "../utils/cropImage";
import Resizer from "react-image-file-resizer";
import upload from "../../../Assets/Icons/vuesax-linear-user-cirlce-add.svg";
import UploadDpModal from "../UploadDPModal/UploadDPModal";
import Button from "../../../Components/Button/Button";
import PrivacyModal from "../../../Components/Modal/PrivacyModal/PrivacyModal";

const SignupSetup = (props) => {
  const container = useRef(null);
  const nextButton = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("../../../Assets/Animations/error.json"),
    });
  }, []);

  const [generatedYears, setGeneratedYears] = useState([]);
  const [generatedMonths, setGeneratedMonths] = useState([]);
  const [generatedDays, setGeneratedDays] = useState([]);

  const [firstnameInputStatus, setFirstnameInputStatus] = useState(0); //Zero No Touch, 1 Valid, 2 Not Valid
  const [lastnameInputStatus, setLastnameInputStatus] = useState(0); //Zero No Touch, 1 Valid, 2 Not Valid

  const [isGenderError, setIsGenderError] = useState(false);
  const [isDOBError, setIsDOBError] = useState(false);

  const [FirstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [gender, setGender] = useState("");
  // const [dob, setDob] = useState("")

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");

  const [ageError, setAgeError] = useState(false);
  const [isFnameFocus, setIsFnameFocus] = useState(false);
  const [isLnameFocus, setIsLnameFocus] = useState(false);
  const [isGenderFocus, setIsGenderFocus] = useState(false);
  const [isMonthFocus, setIsMonthFocus] = useState(false);
  const [isDayFocus, setIsDayFocus] = useState(false);
  const [isYearFocus, setIsYearFocus] = useState(false);
  const inputRef = useRef();
  const [addPhoto, setAddPhoto] = useState(false);
  const [proPicUploaded, setProPicUploaded] = useState(false);
  const [newcroppedImage, setNewCroppedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const triggerFileSelectPopup = () => inputRef.current.click();
  const [checked, setChecked] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const [privacyType, setPrivacyType] = useState("privacy")


  useEffect(() => {
    generateYears();
  }, []);

  const handleFirstname = (e) => {
    let firstNameValue = e.target.value;

    if (firstNameValue === " ") {
      setFirstName("");
    } else {
      setFirstName(firstNameValue);

      if (firstNameValue.length === 0) {
        setFirstnameInputStatus(0);
      } else {
        if (firstNameValue.length !== 0) {
          let mediumRegex = new RegExp("^[a-zA-Z ]*$");

          if (mediumRegex.test(firstNameValue)) {
            setFirstnameInputStatus(1);
          } else {
            setFirstnameInputStatus(2);
          }
        }
      }
    }
  };
  const removeFocus = () => {
    setIsFnameFocus(false);
    setIsLnameFocus(false);
    setIsGenderFocus(false);
    setIsMonthFocus(false);
    setIsYearFocus(false);
    setIsDayFocus(false);
  };
  // Input Handlers
  const handleLastname = (e) => {
    let lastNameValue = e.target.value;

    if (lastNameValue === " ") {
      setFirstName("");
    } else {
      setlastName(lastNameValue);

      if (lastNameValue.length === 0) {
        setLastnameInputStatus(0);
      } else {
        if (lastNameValue.length !== 0) {
          let mediumRegex = new RegExp("^[a-zA-Z ]*$");
          // let rjx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
          if (mediumRegex.test(lastNameValue)) {
            setLastnameInputStatus(1);
          } else {
            setLastnameInputStatus(2);
          }
        }
      }
    }
  };

  const firstnameInputValidation = () => {
    // Use 8 or more characters with a mix of letters, numbers & symbols
    if (firstnameInputStatus === 0) {
      return "";
    } else if (firstnameInputStatus === 1) {
      return "";
    } else {
      return (
        <div className="form-fail">
          <img src={danger} alt="" className="danger-complete-your-profile" />
          <p>Please enter valid Name</p>
        </div>
      );
    }
  };

  const lastnameInputValidation = () => {
    // Use 8 or more characters with a mix of letters, numbers & symbols
    if (lastnameInputStatus === 0) {
      return "";
    } else if (lastnameInputStatus === 1) {
      return "";
    } else {
      return (
        <div className="form-fail">
          <img src={danger} alt="" className="danger-complete-your-profile" />
          <p>Please enter valid Name</p>
        </div>
      );
    }
  };

  const generateYears = () => {
    let thisYear = moment().format("YYYY");
    let generatedYears = [];
    let x = 100;

    for (x; x >= 0; x--) {
      let y = parseInt(thisYear) - parseInt(x);

      generatedYears.push(y);
    }

    setGeneratedYears(generatedYears);
    generateMonths();
  };

  const generateMonths = () => {
    let getMonths = moment().month(1);
    let allMonths = getMonths._locale._months;

    setGeneratedMonths(allMonths);
    generateDays();
  };

  const generateDays = () => {
    let selectedMonthX =
      localStorage.getItem("selected_birthday_month") || "February";
    let selectedYearX =
      localStorage.getItem("selected_birthday_year") || "1990";
    let daysInMonth = [];
    let selectedMonthNumber =
      selectedMonthX && moment().month(selectedMonthX).format("M");
    let selectedMonthAndYear = `${selectedYearX +
      "-" +
      (selectedMonthNumber > 10
        ? selectedMonthNumber
        : "0" + selectedMonthNumber)
      }`;
    let monthDate = moment(selectedMonthAndYear).startOf("month");
    for (let i = 0; i < monthDate.daysInMonth(); i++) {
      let newDay = monthDate.clone().add(i, "days");
      daysInMonth.push(newDay.format("DD"));
    }
    setGeneratedDays(daysInMonth);
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        nextButton.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  const nextBtn = () => {
    // signupLoading

    if (checked) {
      if (props.signupLoading && !props.signupSkipLoading) {
        return (
          <Button
            ref={nextButton}
            loading={true}
            notAllowed={true}
            marginTop="80px"
            buttonText="Finish"
          />
        );
      } else if (props.signupLoading && props.signupSkipLoading) {
        return (
          <Button
            ref={nextButton}
            disabled={true}
            notAllowed={true}
            marginTop="80px"
            buttonText="Finish"
          />
        );
      } else {
        return (
          <Button
            ref={nextButton}
            marginTop="80px"
            buttonText="Finish"
            onClick={() => {
              nexBtnClick();
            }}
          />
        );
      }
    } else {
      return (
        <Button
          ref={nextButton}
          disabled={true}
          notAllowed={true}
          marginTop="80px"
          buttonText="Finish"
        />
      );
    }
  };

  const backBtnClick = () => {
    props.setPageToShow(1);
  };

  const nexBtnClick = () => {
    let year18plus = moment().subtract(18, "years").format("YYYY-DD-MMMM");
    let year = selectedYear;
    let month = selectedMonth;
    let day = selectedDay;

    let selectDate = day + "-" + month + "-" + year;

    if (FirstName === "") {
      setFirstnameInputStatus(2);
    }

    if (lastName === "") {
      setLastnameInputStatus(2);
    }

    if (selectedYear === "" || selectedMonth === "" || selectedDay === "") {
      setIsDOBError(true);
    }

    if (selectedYear === "" || selectedMonth === "" || selectedDay === "") {
      setIsDOBError(true);
      return;
    }

    if (moment(selectDate).format("YYYY-DD-MMMM") >= year18plus) {
      setAgeError(true);
      return;
    }

    if (
      firstnameInputStatus === 1 &&
      lastnameInputStatus === 1 &&
      isDOBError === false
    ) {
      props.sendSigupRequest(newcroppedImage);
      props.setSignupLoading(true);
    }
  };
  useEffect(() => {
    props.setSignupFullname(`${FirstName} ${lastName}`);
  }, [FirstName, lastName]);
  useEffect(() => {
    let month = moment().month(selectedMonth).format("MM");
    props.setSignupDOB(`${selectedYear}-${month}-${selectedDay}`);
  }, [selectedYear, selectedMonth, selectedYear]);
  const handleProPic = (event) => {
    const reader = new FileReader();

    reader.onload = function () {
      setImagePreview(reader.result);

      setAddPhoto(true);
    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const resizeFile = (newcroppedImage) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        newcroppedImage,

        300,

        300,

        "PNG",

        100,

        0,

        (uri) => {
          resolve(uri);
        },

        "base64"
      );
    });

  const onChange = async (event) => {
    await resizeFile(event.target.files[0]).then((res) => {
    });
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const resizeImage = (base64Str, maxWidth = 300, maxHeight = 300) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imagePreview, croppedArea);
      let newestImage = croppedImage.toDataURL();
      let img = await resizeImage(newestImage);
      setNewCroppedImage(img);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="_SignupContainer">
      <div className="SignupInnercontainerLeft">
        <div className="wrapper">
          <div className="bixHeader">ZapMail</div>
          <div className="form-wrapper">
            <div className="input-wrapper username-margin-top-reg">
              <div className="regLabel">Profile</div>
            </div>
            <div className="multi-container-div">
              <div className="input-wrapper firstName-conatiner">
                {isFnameFocus || FirstName !== "" ? (
                  <label
                    className="input-label"
                    style={{ color: isFnameFocus ? "#0094ff" : "" }}
                  >
                    First name
                  </label>
                ) : (
                  ""
                )}

                <br />
                <div
                  className="inputdiv"
                  style={{
                    borderColor:
                      (FirstName === "" && firstnameInputStatus === 2) ||
                        (FirstName !== "" && firstnameInputStatus === 2)
                        ? "#ff0000"
                        : isFnameFocus
                          ? "#0d2a4c"
                          : "#9d9d9d",
                    alignItems: "center",
                    display: "flex",
                    marginBottom: lastnameInputValidation() === "" ? "" : "3vh",
                  }}
                >
                  <UsernameInput
                    type="text"
                    name="fname"
                    placeholder={isFnameFocus ? "" : "First name"}
                    value={FirstName}
                    onChange={(e) => {
                      handleFirstname(e);
                    }}
                    onFocus={() => setIsFnameFocus(true)}
                    onBlur={() => setIsFnameFocus(false)}
                  />
                </div>
                {firstnameInputValidation()}
              </div>
              <div className="input-wrapper lastName-container">
                {isLnameFocus || lastName !== "" ? (
                  <label
                    className="input-label"
                    style={{ color: isLnameFocus ? "#0094ff" : "" }}
                  >
                    Last Name
                  </label>
                ) : (
                  ""
                )}

                <br />
                <div
                  className="inputdiv"
                  style={{
                    borderColor:
                      (lastName === "" && lastnameInputStatus === 2) ||
                        (lastName !== "" && lastnameInputStatus === 2)
                        ? "#ff0000"
                        : isLnameFocus
                          ? "#0d2a4c"
                          : "#9d9d9d",
                    alignItems: "center",
                    display: "flex",
                    marginBottom:
                      firstnameInputValidation() === "" ? "" : "3vh",
                  }}
                >
                  <UsernameInput
                    type="text"
                    name="lname"
                    value={lastName}
                    placeholder={isLnameFocus ? "" : "Last name"}
                    onChange={(e) => {
                      handleLastname(e);
                    }}
                    onFocus={() => setIsLnameFocus(true)}
                    onBlur={() => setIsLnameFocus(false)}
                  />
                </div>

                {lastnameInputValidation()}
              </div>
            </div>

            <br />

            <div className="multiple-input-wrapper">
              <div
                className="input-wrapper month-margin"
                style={{
                  borderBottom:
                    (selectedMonth === "" && isDOBError === true) ||
                      (selectedMonth === "Day" && isDOBError === true)
                      ? "#ff0000 1px solid"
                      : isMonthFocus
                        ? "#0d2a4c 1px solid"
                        : "#9d9d9d 1px solid",
                  width: "30%",
                  // marginTop:selectedMonth!==""?"30px":  isMonthFocus?"10px": ""
                }}
              >
                <br />
                {isMonthFocus || selectedMonth !== "" ? (
                  <label
                    className="input-label"
                    style={{ color: isMonthFocus ? "#0094ff" : "" }}
                  >
                    Month
                  </label>
                ) : (
                  ""
                )}
                {(isMonthFocus && selectedMonth !== "") ||
                  selectedMonth !== "" ? (
                  ""
                ) : (
                  <img
                    src={calender}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginBottom: "-43px",
                    }}
                  />
                )}

                <select
                  className={
                    selectedMonth === ""
                      ? "form-input date-input input-height minimal-gray-day date-select"
                      : "form-input date-input input-height minimal-black-day date-select"
                  }
                  style={{
                    // borderColor:
                    //   (selectedYear === "" && isDOBError === true) ||
                    //     (selectedYear === "Month" && isDOBError === true)
                    //     ? "#ff0000"
                    //     : isMonthFocus
                    //       ? "#0d2a4c"
                    //       : "#9d9d9d",
                    borderWidth:
                      (selectedMonth === "" && isDOBError === true) ||
                        (selectedMonth === "Month" && isDOBError === true)
                        ? "1px"
                        : isMonthFocus
                          ? "2px"
                          : "1px",
                    color: selectedMonth === "" ? "#9d9d9d" : "#414141",
                    WebkitAppearance: "-moz-initial",
                    MozAppearance: "none",
                    width: selectedMonth === "" ? "83%" : "99%",
                    marginLeft:
                      selectedMonth === "" || isMonthFocus === "true"
                        ? "30px"
                        : "1px",
                  }}
                  onFocus={() => setIsMonthFocus(true)}
                  onBlur={() => setIsMonthFocus(false)}
                  id="month"
                  onChange={(e) => {
                    localStorage.setItem(
                      "selected_birthday_month",
                      e.target.value
                    );
                    setSelectedMonth(e.target.value);
                    generateDays();
                    setIsDOBError(false);
                  }}
                >
                  <option className="select-option-style" value="">
                    Month
                  </option>

                  {generatedMonths.map((months, i) => {
                    return (
                      <option
                        className="select-option-style"
                        key={i}
                        value={months}
                      >
                        {months}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div
                className="input-wrapper wrap2 day-margin"
                style={{
                  borderBottom:
                    (selectedDay === "" && isDOBError === true) ||
                      (selectedDay !== "" && isDOBError === true)
                      ? "#ff0000"
                      : isDayFocus
                        ? "#0d2a4c solid 1px"
                        : "#9d9d9d solid 1px",
                  width: "30%",
                  // marginTop:selectedDay!==""?"30px":  isDayFocus?"10px": ""
                }}
              >
                <br />
                {isDayFocus || selectedDay !== "" ? (
                  <label
                    className="input-label"
                    style={{ color: isDayFocus ? "#0094ff" : "" }}
                  >
                    Day
                  </label>
                ) : (
                  ""
                )}
                {(isDayFocus && selectedDay !== "") || selectedDay !== "" ? (
                  ""
                ) : (
                  <img
                    src={calender}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginBottom: "-43px",
                    }}
                  />
                )}
                <select
                  dataflip="false"
                  className={
                    selectedDay === ""
                      ? "form-input date-input input-height minimal-gray-day date-select"
                      : "form-input date-input input-height minimal-black-day date-select"
                  }
                  style={{
                    // borderColor:
                    //   (selectedDay === "" && isDOBError === true) ||
                    //     (selectedDay === "Day" && isDOBError === true)
                    //     ? "#ff0000"
                    //     : isDayFocus
                    //       ? "#0d2a4c"
                    //       : "#9d9d9d",
                    borderWidth:
                      (selectedDay === "" && isDOBError === true) ||
                        (selectedDay === "Day" && isDOBError === true)
                        ? "1px"
                        : isDayFocus
                          ? "2px"
                          : "1px",
                    color:
                      selectedDay === "Day" || selectedDay === ""
                        ? "#9d9d9d"
                        : "#414141",
                    WebkitAppearance: "-moz-initial",
                    MozAppearance: "none",
                    width: selectedDay === "" ? "83%" : "99%",
                    marginLeft:
                      selectedDay === "" || isDayFocus === "true"
                        ? "30px"
                        : "1px",
                  }}
                  onChange={(e) => {
                    setSelectedDay(e.target.value);
                    setIsDOBError(false);
                  }}
                  onFocus={() => setIsDayFocus(true)}
                  onBlur={() => setIsDayFocus(false)}
                >
                  <option className="select-option-style">Day</option>
                  {generatedDays.map((days, i) => {
                    return (
                      <option
                        className="select-option-style"
                        key={i}
                        value={days}
                      >
                        {days}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div
                className="input-wrapper wrap2 year-margin"
                style={{
                  borderBottom:
                    (selectedYear === "" && isDOBError === true) ||
                      (selectedYear !== "" && isGenderError === true)
                      ? "#ff0000"
                      : isYearFocus
                        ? "#0d2a4c solid 1px"
                        : "#9d9d9d solid 1px",
                  width: "31%",
                  // marginTop:selectedYear!==""?"30px":  isYearFocus?"10px": ""
                }}
              >
                <br />
                {isYearFocus || selectedYear !== "" ? (
                  <label
                    className="input-label"
                    style={{ color: isYearFocus ? "#0094ff" : "" }}
                  >
                    Year
                  </label>
                ) : (
                  ""
                )}

                {(isYearFocus && selectedYear !== "") || selectedYear !== "" ? (
                  ""
                ) : (
                  <img
                    src={calender}
                    style={{
                      height: "16px",
                      width: "16px",
                      marginBottom: "-43px",
                    }}
                  />
                )}

                <select
                  className={
                    isYearFocus === ""
                      ? "form-input date-input input-height minimal-gray-day date-select"
                      : "form-input date-input input-height minimal-black-day date-select"
                  }
                  style={{
                    borderColor:
                      (selectedYear === "" && isDOBError === true) ||
                        (selectedYear === "Year" && isDOBError === true)
                        ? "#ff0000"
                        : isYearFocus
                          ? "#0d2a4c"
                          : "#9d9d9d",
                    borderWidth:
                      (selectedYear === "" && isDOBError === true) ||
                        (selectedYear === "Year" && isDOBError === true)
                        ? "1px"
                        : isYearFocus
                          ? "1px"
                          : "1px",
                    color: selectedYear === "" ? "#9d9d9d" : "#414141",
                    WebkitAppearance: "-moz-initial",
                    MozAppearance: "none",
                    width: selectedYear === "" ? "83%" : "99%",
                    marginLeft:
                      selectedYear === "" || isYearFocus === "true"
                        ? "30px"
                        : "1px",

                    // paddingLeft: "8px",
                  }}
                  onChange={(e) => {
                    localStorage.setItem(
                      "selected_birthday_year",
                      e.target.value
                    );
                    setSelectedYear(e.target.value);
                    setIsDOBError(false);
                    generateDays();
                  }}
                  onFocus={() => setIsYearFocus(true)}
                  onBlur={() => setIsYearFocus(false)}
                >
                  <option className="select-option-style" value="">
                    Year
                  </option>

                  {generatedYears
                    .slice()
                    .reverse()
                    .map((year, i) => {
                      return (
                        <option
                          className="select-option-style"
                          key={i}
                          value={year}
                        >
                          {year}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="input-wrapper username-margin-top-login">
              <label className="profile-pic-label">Profile Picture</label>

              {proPicUploaded ? (
                <div style={{ marginBottom: "80px" }}>
                  <div
                    className="add-photo-div"
                    onClick={triggerFileSelectPopup}
                  >
                    <img
                      className="popup-image-after"
                      src={newcroppedImage}
                      alt=""
                      style={{ marginBottom: "-70px" }}
                    />

                    <div className="add-photo-after-div">
                      <img
                        className="add-photo-after"
                        src={ProImg}
                        alt=""
                        onClick={triggerFileSelectPopup}
                      // style={{cursor : "not-allowed"}}
                      />

                      <input
                        onChange={(e) => {
                          handleProPic(e);

                          onChange(e);
                        }}
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        className="hidden"
                        accept="image/*"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ textAlign: "center", marginTop: "40px" }}>
                  <div>
                    <div
                      className="add-photo-div"
                      onClick={triggerFileSelectPopup}
                    // style={{cursor : "not-allowed"}}
                    >
                      <img className="upload-div" src={upload} alt={""} />
                    </div>
                    <div>
                      <label className="lable-style">
                        Drag and drop or browse to choose a file
                      </label>
                    </div>
                  </div>

                  <input
                    onChange={(e) => {
                      handleProPic(e);

                      onChange(e);
                    }}
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    className="hidden"
                    accept="image/*"
                  />
                </div>
              )}
            </div>
            {isDOBError && (
              <div className="form-fail">
                <img
                  src={danger}
                  alt=""
                  className="danger-complete-your-profile"
                />
                <p>Please fill in a complete birthday</p>
              </div>
            )}

            <div
              className="terms"
              style={{ paddingTop: proPicUploaded ? "20px" : "" }}
            >
              <input
                type="checkbox"
                className="check"
                id="termsCheck"
                checked={checked}
                onClick={() => {
                  setChecked(!checked);
                }}
              />
              When creating an account you agree to our{" "}
              <span
                className="link"
                onClick={() => {
                  setIsPrivacy(true)
                  setPrivacyType("privacy")
                }}
              >
                Privacy Policy
              </span>{" "}
              and{" "}
              <span
                className="link"
                onClick={() => {
                  setIsPrivacy(true)
                  setPrivacyType("terms")
                }}
              >
                Terms and Conditions.
              </span>
            </div>

            {nextBtn()}

            {props.singupFailed && (
              <div className="form-fail">
                <img
                  src={danger}
                  alt=""
                  className="danger-complete-your-profile"
                />
                <p> Something Went Wrong, try again later</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {addPhoto ? (
        <UploadDpModal
          addPhoto={addPhoto}
          setAddPhoto={setAddPhoto}
          imagePreviewModal={imagePreview}
          setImagePreviewModal={setImagePreview}
          uploadImage={inputRef}
          onImageClick={triggerFileSelectPopup}
          setProPicUploaded={setProPicUploaded}
          proPicUploaded={proPicUploaded}
          relatedComponent={"auth"}
          newcroppedImage={newcroppedImage}
          setNewCroppedImage={setNewCroppedImage}
        />
      ) : (
        ""
      )}

      <div className={`modal ${ageError ? "is-active" : ""} `}>
        <div className="modal-background"></div>
        <div className="modal-content" style={{ height: "max-content" }}>
          <section className="modal-card-body">
            <div className="error-warning" ref={container}></div>

            <h1 className="sorry">Hey, we are sorry</h1>
            <p className="ageError">
              We are still not able to sign-up anyone under 18. <br></br> We
              will notify you as soon as you're eligible.
            </p>
            <button
              className="ageErrorButton"
              onClick={() => {
                setAgeError(false);
              }}
            >
              OK
            </button>
          </section>
        </div>
      </div>
      {isPrivacy ?
        <PrivacyModal onClick={() => {
          setIsPrivacy(false)
        }}
          type={privacyType} /> : ""}
    </div>
  );
};

export default SignupSetup;
