import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppContext from "../../../ContextAPI/AppContext";
import "./LoginStyles.scss";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
import UsernameInput from "../../../Components/InputComponents/UsernameInput/UsernameInput";
import Carousel from "../../../Components/Carousel/Carousel";
import Button from "../../../Components/Button/Button";
import CloseIcon from "../../../Components/Button/CloseIcon/CloseIcon";
import config from "../../../config"

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const loginBtn = useRef(null);
  const [userNameError, setUserNameError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [isUsernameFocus, setIsUsernameFocus] = useState(false);
  const [isPwdFocus, setIsPwdFocus] = useState(false);
  const location = useLocation();
  const [usernameModal, setUsernameModal] = useState(false)

  const handleUsername = (e) => {
    let userNameValue = e.target.value.trim();
    if (userNameError) {
      if (userNameValue !== "") {
        setUserNameError(false);
      }
    }
    setUserName(userNameValue);
  };

  const handlePassword = (e) => {
    let passwordValue = e.target.value;
    if (pwdError) {
      if (passwordValue !== "") {
        setPwdError(false);
      }
    }
    setPassword(passwordValue);
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        if (userName === "" && password === "") {
          setPwdError(true);
          setUserNameError(true);
        } else if (userName === "" && password !== "") {
          setUserNameError(true);
        } else if (userName !== "" && password === "") {
          setPwdError(true);
        } else {
          setPwdError(false);
          setUserNameError(false);
          loginBtn.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [userName, password]);
  const removeFocus = () => {
    setIsUsernameFocus(false);
    setIsPwdFocus(false);
  };

  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="login">
          {/* <div className="mobileUI-login">
            <Carousel />
          </div> */}
          <div className="_loginContainer">
            <div className="_loginInnerContainerLeft">
              <div className="wrapper">
                <div className="bixHeader">ZapMail</div>
                <div className="header-bottom-text">A smarter way to connect.</div>
                <div className="form-wrapper">
                  <div className="input-wrapper username-margin-top-login">
                    <div className="loginLabel">Login</div>

                    <div>
                      {isUsernameFocus || userName !== "" ? (
                        <label
                          className="input-label"
                          style={{ color: isUsernameFocus ? "#0094ff" : "" }}
                        >
                          Email 
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="inputdiv"
                      style={{
                        borderColor:
                          (userName === "" && context?.loggedFailed) ||
                            (userName !== "" && context?.loggedFailed) ||
                            (userName === "" && userNameError)
                            ? "#ff0000"
                            : isUsernameFocus
                              ? "#0d2a4c"
                              : "#9d9d9d",
                      }}
                    >
                      <UsernameInput
                        type="text"
                        isUsernameFocus={isUsernameFocus}
                        placeholder={isUsernameFocus ? "" : "Email"}
                        value={userName}
                        onChange={(e) => {
                          context?.setLoggedFailed(false);
                          handleUsername(e);
                        }}
                        userNameError={userNameError}
                        onFocus={() => setIsUsernameFocus(true)}
                        onBlur={() => setIsUsernameFocus(false)}
                        name="username"
                        from={location?.state?.from}
                      />
                    </div>
                    {userNameError ? (
                      <div className="form-fail" style={{marginTop:"5px"}}>
                      * Required
                    </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-wrapper username-margin-top-login">
                    <div>
                      {isPwdFocus || password !== "" ? (
                        <label
                          className="input-label"
                          style={{ color: isPwdFocus ? "#0094ff" : "" }}
                        >
                          {" "}
                          Password
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="inputdiv"
                      style={{
                        borderColor:
                          (password === "" && context?.loggedFailed) ||
                            (password !== "" && context?.loggedFailed) ||
                            (password === "" && pwdError)
                            ? "#ff0000"
                            : isPwdFocus
                              ? "#0d2a4c"
                              : "#9d9d9d",
                      }}
                    >
                      <UsernameInput
                        type={showPassword ? "text" : "password"}
                        placeholder={isPwdFocus ? "" : "Password"}
                        onChange={(e) => {
                          context?.setLoggedFailed(false);
                          handlePassword(e);
                        }}
                        isPwdFocus={isPwdFocus}
                        pwdError={pwdError}
                        onFocus={() => setIsPwdFocus(true)}
                        onBlur={() => setIsPwdFocus(false)}
                        name="password"
                        value={password}
                        from={location?.state?.from}
                      />
                    </div>
                    {pwdError ? (
                       <div className="form-fail" style={{marginTop:"5px"}}>
                       * Required
                     </div>
                    ) : (
                      ""
                    )}
                    {context?.loggedFailed ? (
                      <div className="form-fail">
                        <img src={danger} alt="" className="danger-login" />
                        Incorrect Username or Password
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="forgetText">
                    <span
                      className="forgetTextLink"
                      onClick={() => {
                        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
                        if (userName === "" || !regex.test(userName)) {
                          setUsernameModal(true)
                        } else {
                          if (userName.includes(`${config.emailProvider}`)) {
                            history.push({
                              pathname: "/forget-password",
                            });
                          } else {
                            history.push({
                              pathname: "/reset-password-business",
                            });
                          }
                        }

                      }}
                    >
                      Forgot Password?
                    </span>
                  </div>

                  <Button
                    ref={loginBtn}
                    loading={context?.loginIsLoading}
                    onClick={() => {
                      removeFocus();
                      if (userName === "" && password === "") {
                        setPwdError(true);
                        setUserNameError(true);
                      } else if (userName === "" && password !== "") {
                        setUserNameError(true);
                      } else if (userName !== "" && password === "") {
                        setPwdError(true);
                      } else {
                        context?.loginClicked(
                          userName.toLowerCase(),
                          password,
                          location?.state?.from,
                          "login"
                        );
                      }
                      if (context.loggingSuccess) {
                        setUserName("");
                        setPassword("");
                      }
                    }}
                    buttonText="Login"
                  />

                  {/* <div className="footerText">
                    Create a Personal Email Account
                    <span
                      className="footerTextLink"
                      onClick={() => {
                        history.push({
                          pathname: "/signup",
                        });
                      }}
                    >
                      Here
                    </span>
                  </div> */}
                </div>
              </div>
              {usernameModal ? <div className="username_modal">
                <div className="username-modal-inner">
                  <div className="inner-mod">
                    <div className="text-mod">
                      Enter your <span className="blu">Email</span>
                    </div>
                    <CloseIcon size="s" onClick={() => setUsernameModal(false)} />
                  </div>
                  <div className="ok-btn" onClick={() => setUsernameModal(false)}>
                    Ok
                  </div>

                </div>
              </div>
                : ""}
            </div>
            <div className="_loginInnerContainerRight">
              <div className="wrapper2">
                <Carousel />
              </div>
            </div>

          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default Login;
