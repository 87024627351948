const developemnt = {
  apiEndpoints: {
    protocol: "https://",
    messageServer: "api.bixchat.xyz/message/api",
    authServer: "api.bixchat.xyz/auth",
    cloudServer: "api.bixchat.xyz/cloud/api",
  },
  emailProvider: "@bixchat.xyz",
  emailText: "bixchat.xyz",
  websocketURL: "wss://amuv8wkzwf.execute-api.ap-south-1.amazonaws.com/bix-staging",
  domain: {
    app: "app.",
    auth: "auth.",
    cloud: "cloud.",
  },
  environment: "development",
  cookieDomain: ".bixchat.xyz",
  s3Bucket: "bix-cloud-s3-stag",
  mixpanel: '7e5c39fe1fcf12ed44b31c723648ff4e',
  gleap: "xIQqca7mkYR73iFcUSnkorfrI8kqf7sD"

};

const staging = {
  apiEndpoints: {
    protocol: "https://",
    messageServer: "api.bixchat.xyz/message/api",
    authServer: "api.bixchat.xyz/auth",
    cloudServer: "api.bixchat.xyz/cloud/api",
  },
  emailProvider: "@bixchat.xyz",
  emailText: "bixchat.xyz",
  websocketURL: "wss://amuv8wkzwf.execute-api.ap-south-1.amazonaws.com/bix-staging",
  domain: {
    app: "app.",
    auth: "auth.",
    cloud: "cloud.",
  },
  environment: "staging",
  cookieDomain: ".bixchat.xyz",
  s3Bucket: "bix-cloud-s3-stag",
  mixpanel: '7e5c39fe1fcf12ed44b31c723648ff4e',
  gleap: "xIQqca7mkYR73iFcUSnkorfrI8kqf7sD"
};

const production = {
  apiEndpoints: {
    protocol: "https://",
    messageServer: "api.zapmail.app/message/api",
    authServer: "api.zapmail.app/auth",
    cloudServer: "api.zapmail.app/cloud/api",
  },
  emailProvider: "@zapmail.app",
  emailText: "zapmail.app",
  websocketURL: "wss://amuv8wkzwf.execute-api.ap-south-1.amazonaws.com/bix-production",
  domain: {
    app: "s.",
    auth: "auth.",
    cloud: "cloud.",
  },
  environment: "production",
  cookieDomain: ".zapmail.app",
  s3Bucket: "bix-cloud-s3",
  mixpanel: '7e5c39fe1fcf12ed44b31c723648ff4e',
  gleap: "ZjBMzvPenoaCn0jQBLE9OTAzeurOAKFv"

};
let basicExport = developemnt; // developemnt || staging || production

if (process.env.REACT_APP_ENV === "dev") {
  basicExport = developemnt;
} else if (process.env.REACT_APP_ENV === "stage") {
  basicExport = staging;
} else if (process.env.REACT_APP_ENV === "prod") {
  basicExport = production;
}

export default basicExport;