import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../../ContextAPI/AppContext";
import AdminInput from "../../../Components/InputComponents/AdminInput/AdminInput";
import "./AdminBetaStyles.scss";
import AdminSteps from "../AdminSteps/AdminSteps";
import MobileSteps from "../AdminSteps/MobileSteps/MobileSteps";
import { useHistory } from "react-router-dom";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
const AdminForm = (props) => {
    let countdown = localStorage.getItem("bix_business_count_down") !== null ? JSON.parse(localStorage.getItem("bix_business_count_down")) : 0
    let countingTimer = localStorage.getItem("bix_business_counting") !== null ? JSON.parse(localStorage.getItem("bix_business_counting")) : false
    const [isError, setIsError] = useState(false)
    const [isErrorLength, setIsErrorLength] = useState(false)
    const [countDown, setCountDown] = useState(countdown)
    const [counting, setCounting] = useState(countingTimer)
    let history = useHistory();
    const context = useContext(AppContext)
    useEffect(() => {
        let timerId;
        if (context.runTimer) {
            if (countDown === 0) {
                localStorage.setItem("bix_business_count_down", 60 * 2)
                setCountDown(60 * 2)
            }
            if (counting) {
                timerId = setInterval(() => {
                    let count = JSON.parse(localStorage.getItem("bix_business_count_down")) - 1
                    localStorage.setItem("bix_business_count_down", count)
                    setCountDown(count)
                }, 1000);
            }
        } else {
            clearInterval(timerId);
        }
        return () => clearInterval(timerId);
    }, [context.runTimer, counting])
    useEffect(() => {
        if (countDown < 0 && context.runTimer) {
            localStorage.setItem("bix_business_count_down", 0)
            setCountDown(0)
            setCounting(false)
            localStorage.setItem("bix_business_counting", false)
        }
    }, [countDown, context.runTimer]);



    const onBtnClick = () => {
        if (props.name !== "" &&
            props.designation !== "" &&
            props.phone !== "" &&
            props.nic !== "" && !isError && !isErrorLength) {
            localStorage.setItem("bix_business_page", 2)
            context.setPageToShow(2)
        }
    }
    const phonenumberFormatCheck = (value) => {
        const reg = new RegExp('^[0-9]+$')
        if (value !== "") {
            if (reg.test(value)) {
                setIsError(false)
            } else {
                setIsError(true)
            }

        } else {
            setIsError(false)
        }


    }

    const isValidEmail = (email) => {
        if (/\S+@\S+\.\S+/.test(email)) {
            setIsError(false)
        } else {
            setIsError(true)
        }

    }
    let disabled = props.name === "" || props.designation === "" || props.phone === "" || props.nic === "" || isError || isErrorLength
    return (
        <div>
            <div className="AdminBetaContainer">
                <div className="AdminBetaInnerContainerRight">
                    <div className="wrapper2">
                        <div className="onboard-header">
                            <div className="bixHeader">bixBusiness</div>
                            <div className="header-bottom-text">Recommended for companies or industries.</div>
                        </div>
                        <div className="step-header">
                            <div className="step-hdr">Step 1</div>
                            <div className="step-header-bottom-text">Enter your personal information
                                to very your identification</div>
                        </div>
                        <div className="stp">
                            <AdminSteps step={1} />
                        </div>
                        <div className="stp-mobile">
                            <MobileSteps step={1} />
                        </div>
                    </div>
                </div>
                <div className="AdminBetaInnerContainerLeft">
                    <div className="wrapper">
                        <div className="content-header">
                            <div className="content-Header">Personal Information</div>
                            <div className="content-header-bottom-text">Enter your personal information</div>
                        </div>
                        <div className="form-wrapper">
                            <div className="admin-form-cont">

                                {localStorage.getItem("bix_business_OTP_verified") === "true" ?
                                    <div>
                                        <div className="margin-top-style" />
                                        <div className="no-div">
                                            <div className="div-left">
                                                <AdminInput
                                                    name="Contact No."
                                                    value={props.phone}
                                                    onChange={e => {
                                                        props.setPhone(e.target.value)
                                                        localStorage.setItem("bix_business_personal_contact", e.target.value)
                                                        if (e.target.value.length < 10 && e.target.value.length !== 0) {
                                                            setIsErrorLength(true)
                                                        } else {
                                                            setIsErrorLength(false)
                                                        }
                                                        phonenumberFormatCheck(e.target.value)

                                                    }}
                                                    isError={isError || isErrorLength}
                                                />
                                                {isError && isErrorLength ? <div className="phone-error">
                                                    Phone number must be valid and contain 10 digits
                                                </div> : isErrorLength ? <div className="phone-error">
                                                    Phone number must be at least 10 digits
                                                </div> :
                                                    isError ? <div className="phone-error">
                                                        Invalid phone number
                                                    </div> : ""}

                                            </div>
                                            <div className="margin-top-style-div" />
                                            <div className="div-right">
                                                <AdminInput
                                                    name="NIC No."
                                                    value={props.nic}
                                                    onChange={e => {
                                                        props.setNic(e.target.value)
                                                        localStorage.setItem("bix_business_personal_nic", e.target.value)
                                                    }}

                                                />
                                            </div>
                                        </div>
                                        <div className="margin-top-style" />
                                        <AdminInput
                                            name="Designation"
                                            value={props.designation}
                                            onChange={e => {
                                                props.setDEsignation(e.target.value)
                                                localStorage.setItem("bix_business_personal_designation", e.target.value)
                                            }}

                                        /> <div className="margin-top-style" />
                                    </div>
                                    : <div>
                                        {!context.runTimer ?
                                            <AdminInput
                                                name="Name"
                                                value={props.name}
                                                onChange={e => {
                                                    props.setName(e.target.value)
                                                    localStorage.setItem("bix_business_personal_name", e.target.value)
                                                }}
                                            /> : ""}
                                        <div className="margin-top-style" />
                                        <AdminInput
                                            name="Email"
                                            value={props.email}
                                            onChange={e => {
                                                props.setEmail(e.target.value)
                                                localStorage.setItem("bix_business_personal_email", e.target.value)
                                                setIsError(false)
                                            }}
                                            isError={isError}
                                            readonly={localStorage.getItem("bix_business_OTP_verified") === "true"}
                                            color={localStorage.getItem("bix_business_OTP_verified") === "true" ? "#a1a1a1" : ""}
                                        />
                                        {isError ? <div className="alert-error"><img alt="" src={danger} className="danger-ic" />Invalid email address</div> : ""}
                                        {context.runTimer ? !counting ? <div className="resend-otp"
                                            onClick={() => {
                                                isValidEmail(props.email)
                                                if (props.email !== "" && /\S+@\S+\.\S+/.test(props.email)) {
                                                    setCounting(true)
                                                    localStorage.setItem("bix_business_counting", true)
                                                    context.sendOTP(props.email)
                                                }

                                            }}
                                        >Resend OTP</div> : <div className="resend-otp-time"> Resend OTP in {String(Math.floor(countDown / 60)).padStart(2, 0)}:{String(countDown % 60).padStart(2, 0)}</div> : ""}
                                        {/* {localStorage.getItem("bix_business_OTP_verified") !== "true" ?
                                                <div className="prim-btn" style={{ backgroundColor: props.email === "" ? "#707070" : "" }} onClick={() => {
                                                    isValidEmail(props.email)
                                                    if (countDown === 0 && !context.runTimer && props.email !== "" && /\S+@\S+\.\S+/.test(props.email)) {
                                                        context.sendOTP(props.email)
                                                    }
                                                }}>{!context.runTimer ? "Send OTP" : resendTimer ? "Resend OTP" : <span>{String(Math.floor(countDown / 60)).padStart(2, 0)}:{String(countDown % 60).padStart(2, 0)}</span>}</div> : ""} */}

                                        <div className="margin-top-style" />
                                        {context.runTimer ? <div>
                                            <AdminInput
                                                name="OTP"
                                                value={props.OTP}
                                                onChange={e => {
                                                    props.setOTP(e.target.value)
                                                    context.setOTPError(false)
                                                }}
                                                isError={context.OTPError}

                                            />
                                            {context.OTPError ? <div className="alert-error"><img alt="" src={danger} className="danger-ic" />Invalid OTP</div> : ""}
                                        </div> : ""}
                                    </div>}
                            </div>
                            {localStorage.getItem("bix_business_OTP_verified") !== "true" ? !context.runTimer ?
                                <div className="otp-btn-container">
                                    <div className="prim-btn" style={{ backgroundColor: props.email === "" || props.name === "" ? "#707070" : "" }} onClick={() => {
                                        isValidEmail(props.email)
                                        if (countDown === 0 && !context.runTimer && props.email !== "" && props.name !== "" && /\S+@\S+\.\S+/.test(props.email)) {
                                            setCounting(true)
                                            localStorage.setItem("bix_business_counting", true)
                                            context.sendOTP(props.email)
                                        }
                                    }}>{context.sendingOTP ? <div className="prim-load-l" /> : "Send OTP"}</div> </div> : <div className="otp-btn-container">
                                    <div className="prim-btn" style={{ backgroundColor: props.OTP.length < 6 ? "#707070" : "" }}
                                        onClick={() => {
                                            if (props.OTP.length >= 6) {
                                                context.setOTPVerifying(true)
                                                context.verifyOTP(props.email, props.OTP)
                                            }
                                        }}

                                    >
                                        {context.OTPVerifying ? <div className="prim-load-l" /> : context.OTPVerified ? "Verfied" : "Verify"}
                                    </div>
                                </div> : ""}


                            {localStorage.getItem("bix_business_OTP_verified") === "true" ?
                                <div className="right-btn-container">
                                    <div className="scnd-btn" onClick={() => { history.goBack() }
                                    }>Back</div>
                                    <div className="prim-btn" style={{ backgroundColor: disabled ? "#707070" : "" }} onClick={onBtnClick}>Next</div>
                                </div> : ""}
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};
export default AdminForm;
