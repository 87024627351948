import React, { useState, useRef } from "react";
import "./NewGroupModalStyle.scss";
import arrow from "../../../Assets/Icons/arrow-blue.svg";
import tick from "../../../Assets/Icons/tick.svg";
import AppContext from "../../../ContextAPI/AppContext";
import Cropper from "react-easy-crop";
import minus from "../../../Assets/Icons/minus-sign-2.png";
import plus from "../../../Assets/Icons/plus.png";
import camera from "../../../Assets/Icons/camera.png";
import reupload from "../../../Assets/Icons/reload.png";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "../../Auth/utils/cropImage";
import Resizer from "react-image-file-resizer";
import cam from "../../../Assets/Icons/cam.svg";
import CloseIcon from "../../../Components/Button/CloseIcon/CloseIcon";


const NewGroupModal2 = (props) => {
  const [groupName, setGroupName] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [addPhoto, setAddPhoto] = useState(false);
  const [proPicUploaded, setProPicUploaded] = useState(false);
  const [croppedArea, setCroppedArea] = useState(null);
  const [newcroppedImage, setNewCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const uploadImage = useRef(null);
  const onImageClick = () => {
    uploadImage.current.click();
  };
  const resizeFile = (newcroppedImage) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        newcroppedImage,

        300,

        300,

        "PNG",

        100,

        0,

        (uri) => {
          resolve(uri);
        },

        "base64"
      );

    });

  const onChange = async (event) => {
    await resizeFile(event.target.files[0]);

  };
  const resizeImage = (base64Str, maxWidth = 300, maxHeight = 300) => {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement('canvas')
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imagePreview, croppedArea);

      let newestImage = croppedImage.toDataURL();
      let img = await resizeImage(newestImage)
      setNewCroppedImage(img)
    } catch (e) {
      console.error(e);
    }
  };
  const handleProPic = (event) => {
    const reader = new FileReader();

    reader.onload = function () {
      setImagePreview(reader.result);

      setAddPhoto(true);

    };

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }

  };
  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="_NewGroupMessage">
          <div id="myModal" className="modal-1">
            <div
              className="modal-content-top"
              style={{ display: addPhoto ? "none" : "block" }}
            >
              <div className="modal-content-1">
                <img
                  alt=""
                  src={arrow}
                  className="arrow"
                  onClick={() => {
                    if (props.newGroupFrom === "newGroup") {
                      props.setNewGroupModal2(false);
                      props.setNewGroupModal1(true);
                    } else {
                      props.setNewGroupModal2(false);
                    }
                  }}
                />
                <div
                  className="header"
                >
                  Create New Channel
                </div>
              </div>

              <div className="inner-container-2">
                <div
                  style={{
                    padding: "10px",
                    marginTop: "20px",
                    justifyContent: "center",
                  }}
                >
                  <div className="search-bar-wrapper">
                    <input
                      type="text"
                      placeholder="Group Name"
                      value={groupName}
                      onChange={(e) => {
                        setGroupName(e.target.value);
                      }}
                    />
                  </div>
                  {proPicUploaded ? (
                    <div>
                      <div
                        className="add-photo-div"
                        onClick={onImageClick}
                      // style={{cursor : "not-allowed"}}
                      >
                        <img
                          className="popup-image-after"
                          src={newcroppedImage}
                          alt=""
                        />

                        <div className="add-photo-after-div">
                          <img
                            className="add-photo-after"
                            src={cam}
                            alt=""
                            onClick={onImageClick}
                          // style={{cursor : "not-allowed"}}
                          />

                          <input
                            onChange={(e) => {
                              handleProPic(e);
                              onChange(e);
                            }}
                            style={{ display: "none" }}
                            ref={uploadImage}
                            type="file"
                            className="hidden"
                            accept="image/*"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div
                          className="add-photo-div"
                          onClick={onImageClick}
                        // style={{cursor : "not-allowed"}}
                        >
                          <img className="add-photo" src={cam} alt={""} />
                        </div>
                      </div>

                      <input
                        onChange={(e) => {
                          handleProPic(e);
                          onChange(e);
                        }}
                        style={{ display: "none" }}
                        ref={uploadImage}
                        type="file"
                        className="hidden"
                        accept="image/*"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                className="circle"
                onClick={() => {
                  if (groupName !== "") {
                    context?.setGroupCreating(true)
                    context?.createNewGroup(groupName, newcroppedImage)
                    if (!context?.groupCreating) {
                      setTimeout(() => {
                        if (props.newGroupFrom === "newGroup") {
                          props.setNewGroupModal2(false);
                        } else {
                          props.setNewGroupModal2(false);
                          props.setSendMessageModal(false);
                        }
                      }, 2000);
                    }
                  }
                }}
              >
                {context?.groupCreating === true ? <div className="loader" /> :
                  <img alt="" src={tick} style={{ height: 30, width: 30 }} />
                }
              </div>
            </div>
            <div
              className="modal-content-new"
              style={{ display: addPhoto ? "block" : "none" }}
            >
              <div className="modal-content-2">
                <div className="left">
                  <div className="camera">
                    <img alt="" src={camera} className="img" />
                  </div>
                  <div className="popup-div1-h1">Change Profile Picture</div>
                </div>

                <div className="right">
                  <div className="reupload" onClick={onImageClick}>
                    <img alt="" src={reupload} className="img" />
                    {context.windowDimensions.width <= 600 ? "" : "Reupload"}
                  </div>

                  <div style={{ marginTop: '16px' }}>
                    <CloseIcon
                      size='m' />
                  </div>

                </div>
              </div>
              <div className="inner-container-3">
                {imagePreview ? (
                  <div className="cropper">
                    <Cropper
                      image={imagePreview}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      cropShape="round"
                      showGrid={false}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : null}

                <div
                  style={{
                    display: "flex",
                    marginLeft: "7%",
                  }}
                >
                  <div className="minus-container">
                    <img alt="" src={minus} className="img" />
                  </div>
                  <div>
                    <Slider
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                  <div className="plus-container">
                    <img alt="" src={plus} className="img" />
                  </div>
                </div>
              </div>
              <div
                className="circle"
                onClick={() => {
                  setAddPhoto(false);
                  setProPicUploaded(true);
                  showCroppedImage();
                }}
              >
                <img alt="" src={tick} style={{ height: 30, width: 30 }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default NewGroupModal2;
