import React from "react";
import AppContext from "../../../ContextAPI/AppContext";
import './LoadingScreen.scss'


const progressBar = () => {
  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="barContainer">
          <div className="barCompleted" />
        </div>
      )}
    </AppContext.Consumer>
  )
}

export default progressBar