import React from "react";
import user from "../../../../Assets/Icons/profile-white.svg"
import eye from "../../../../Assets/Icons/receipt-edit.svg"
import thanks from "../../../../Assets/Icons/vuesax-linear-like-tag.svg"
import domain from "../../../../Assets/Icons/vuesax-linear-global-edit.svg"
import breifcase from "../../../../Assets/Icons/vuesax-linear-briefcase-2.svg"


import "./MobileStepsStyles.scss"
const MobileSteps = (props) => {
    const filteColor = "invert(42%) sepia(0%) saturate(503%) hue-rotate(169deg) brightness(99%) contrast(85%)"
    const filterWhite = "brightness(0) invert(1)";
    return (
        <div className="MobileSteps">
            <div className="stepper-wrapper">
                <div className={`${props.step !== 1 ? "stepper-item completed" : "stepper-item"}`}>
                    <div className="step-counter" style={{ borderColor: "#ffffff" }}>
                        <img alt="" src={user} style={{ filter: filterWhite }} />

                    </div>
                </div>
                <div className={`${props.step === 3 || props.step === 4 || props.step === 5 ? "stepper-item completed" : "stepper-item"}`}>
                    <div className="step-counter" style={{ borderColor: props.step === 2 ? "#ffffff" : "" }}>
                        <img alt="" src={breifcase} style={{ filter: `${props.step !== 1 ? filterWhite : filteColor}` }} />
                    </div>
                </div>
                <div className={`${props.step === 4 || props.step === 5 ? "stepper-item completed" : "stepper-item"}`}>
                    <div className="step-counter" style={{ borderColor: props.step === 3 ? "#ffffff" : "" }}>
                        <img alt="" src={eye} style={{ filter: `${props.step === 3 || props.step === 4 || props.step === 5 ?filterWhite : filteColor}` }} />

                    </div>
                </div>
                <div className={`${props.step === 5 ? "stepper-item completed" : "stepper-item"}`}>
                    <div className="step-counter" style={{ borderColor: props.step === 4 ? "#ffffff" : "" }}>
                        <img alt="" src={domain} style={{ filter: `${props.step === 4 || props.step === 5 ? filterWhite : filteColor}` }} />

                    </div>
                </div>
                <div className={`${props.step === 5 ? "stepper-item completed" : "stepper-item"}`}>
                    <div className="step-counter" style={{ borderColor: props.step === 5 ? "#ffffff" : "" }}>
                        <img alt="" src={thanks} style={{ filter: `${props.step === 5 ? filterWhite : filteColor}` }} />

                    </div>
                </div>
            </div>
        </div>

    )
}
export default MobileSteps