import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import checked from "../../../Assets/Icons/awesome-check.svg";
import errorPic from "../../../Assets/Icons/metro-cross.svg";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";

import config from "../../../config";
import axios from "axios";
import check from "../../../Assets/Icons/checked.png";
import cross from "../../../Assets/Icons/cross.png";
import "../SignupWrapper/Signup.scss";

import UsernameInput from "../../../Components/InputComponents/UsernameInput/UsernameInput";
import Button from "../../../Components/Button/Button";

const CreateUserName = (props) => {
  const history = useHistory();

  const [usernameInputStatus, setUsernameInputStatus] = useState(0); //Zero No Touch, 1 Valid, 2 Not Valid
  const [passwordInputStatus, setPasswordInputStatus] = useState(0); //Zero No Touch, 1 Valid, 2 Not Valid
  const [confirmPassStatus, setConfirmPassStatus] = useState(0);

  const [userNameValidated, setuserNameValidated] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  // input Value

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [suggestedUsernames, setSuggestedUsernames] = useState([]);
  const [isUsernameFocus, setIsUsernameFocus] = useState(false);
  const [isPwdFocus, setIsPwdFocus] = useState(false);
  const [isConPwdFocus, setIsConPwdFocus] = useState(false);
  const [pwdLength, setPwdLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [numVal, setNumVal] = useState(false);
  const nextButton = useRef(null);
  const passwordFocus = useRef(null);

  useEffect(() => {
    if (
      password.length >= 8 &&
      numVal &&
      upperCase &&
      lowerCase &&
      specialChar
    ) {
      setPasswordInputStatus(1);
    }

    if (!userNameValidated) {
      checkUsernameValidation();
    }
  });

  // Input Handlers
  const handleUsername = (e) => {
    let userNameValue = e.target.value.trim();
    let mediumRegex = new RegExp(
      '^(?=.*[!@#$%^&*"\\[\\]\\{\\}<>/\\(\\)=\\\\\\-_´+`~\\:;,\\.€\\|])'
    );
    if (mediumRegex.test(userNameValue)) {
      // setUsernameInputStatus(2);
    } else {
      setuserNameValidated(false);
      setIsRestricted(false);
      setSuggestedUsernames([]);
      setUserName(userNameValue);
      setUsernameInputStatus(1);
      setSomethingWentWrong(false);
    }

    // if (userNameValue.length === 0) {
    //   setUsernameInputStatus(0);
    // }

    // setRegisteredUsernames(props.takenUsername);

    // if (registeredUsernames.includes(userNameValue)) {
    //   setUsernameInputStatus(2);
    //   generateUsername(userNameValue);
    // } else {
    //   if (userNameValue.length !== 0) {
    //     setUsernameInputStatus(1);
    //   }
    // }
  };
  useEffect(() => {
    let passwordValue = password;
    if (passwordValue.length === 0 || passwordValue === "") {
      setPasswordInputStatus(0);
      setPwdLength(false);
      setLowerCase(false);
      setUpperCase(false);
      setSpecialChar(false);
      setNumVal(false);
    } else {
      if (/[A-Z]/.test(passwordValue)) {
        setUpperCase(true);
      } else {
        setUpperCase(false);
      }
      if (/[a-z]/.test(passwordValue)) {
        setLowerCase(true);
      } else {
        setLowerCase(false);
      }
      if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue)) {
        setSpecialChar(true);
      } else {
        setSpecialChar(false);
      }

      if (/\d/.test(passwordValue)) {
        setNumVal(true);
      } else {
        setNumVal(false);
      }
      if (passwordValue.length < 8) {
        setPwdLength(false);
      } else {
        setPwdLength(true);
      }
      if (
        passwordValue.length >= 8 &&
        numVal &&
        upperCase &&
        lowerCase &&
        specialChar
      ) {
        setPasswordInputStatus(1);
      } else {
        setPasswordInputStatus(0);
      }
    }
  }, [password]);
  const handlePassword = (e) => {
    setSomethingWentWrong(false);
  };

  const handleConfirm = (e) => {
    setSomethingWentWrong(false);

    let confirmPassValue = e.target.value;

    setConfirmPass(confirmPassValue);

    if (confirmPassValue.length > 0) {
      if (confirmPassValue !== password) {
        setConfirmPassStatus(2);
      } else {
        setConfirmPassStatus(1);
      }
    } else {
      setConfirmPassStatus(0);
    }
  };

  // Other Funtions

  // const generateUsername = (e) => {
  //   let randomNo = Math.random().toString();
  //   let lastNumber = randomNo.slice(randomNo.length - 3);
  //   let generatedUsername = e + lastNumber;

  //   if (!registeredUsernames.includes(generatedUsername)) {
  //     setGeneratedUsername(generatedUsername);
  //   } else {
  //     generateUsername(e);
  //   }
  // };

  const checkUsernameValidation = () => {
    // userName

    if (userName.length > 0) {
      axios({
        method: "post",
        url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/users/is_user_available`,
        data: {
          email: userName,
        },
      }).then((response) => {
        setuserNameValidated(true);

        if (response.data.is_valid) {
          setUsernameInputStatus(1);
        } else {
          if (response.data.is_restricted) {
            setUsernameInputStatus(2);
            setIsRestricted(true);
          } else {
            setUsernameInputStatus(2);
            setSuggestedUsernames(response.data.suggested);
          }
        }
      });
    }
  };

  const userNameInputValidation = () => {
    if (userNameValidated) {
      if (usernameInputStatus === 0) {
        return "";
      } else if (usernameInputStatus === 1) {
        return (
          <div
            className="form-success"
            style={{ alignItems: "center", display: "flex" }}
          >
            <img
              src={checked}
              alt=""
              style={{ height: "12px", width: "12px", marginRight: "6px" }}
            />
            <p>Username Available</p>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="form-fail"
              style={{ alignItems: "center", display: "flex" }}
            >
              <img
                src={errorPic}
                alt=""
                style={{ height: "12px", width: "12px", marginRight: "6px" }}
              />
              {isRestricted ? (
                <p>This username is Restricted, Try another one</p>
              ) : (
                <p>Username Taken,Try Another</p>
              )}
            </div>

            {isRestricted === false ? (
              <div className="available">
                <span className="right-text"> Available : </span>
                <span
                  style={{
                    color: "#0d2a4c",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUserName(suggestedUsernames[0]);
                    setUsernameInputStatus(1);
                  }}
                >
                  {" "}
                  {suggestedUsernames[0]}{" "}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      }
    }
  };

  const passwordInputValidation = () => {
    // Use 8 or more characters with a mix of letters, numbers & symbols
    if (passwordInputStatus === 0) {
      return "";
    } else if (passwordInputStatus === 1) {
      return "";
    } else {
      return (
        <div className="form-fail">
          <img src={danger} alt="" className="danger-image" />
          <p>Please choose a stronger Password</p>
        </div>
      );
    }
  };

  const confirmPassValidation = () => {
    if (confirmPass.length > 0) {
      if (confirmPass !== password) {
        return (
          <div className="form-fail">
            <img src={danger} alt="" className="danger-image" />
            <p>Please make sure passwords match.</p>
          </div>
        );
      } else if (confirmPassStatus === 1) {
        return "";
      }
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        nextButton.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const removeFocus = () => {
    setIsUsernameFocus(false);
    setIsPwdFocus(false);
    setIsConPwdFocus(false);
  };

  //next button validation
  const nextBtn = () => {
    if (
      usernameInputStatus === 1 &&
      passwordInputStatus === 1 &&
      confirmPassStatus === 1 &&
      userName.length > 0
    ) {
      return (
        <Button
          ref={nextButton}
          onClick={() => {
            removeFocus();
            nexBtnClick();
          }}
          buttonText="Next"
        />
      );
    } else {
      return (
        <Button
          ref={nextButton}
          className="submit-button button next-btn"
          notAllowed={true}
          disabled={true}
          buttonText="Next"
        />
      );
    }
  };

  const nexBtnClick = () => {
    if (
      usernameInputStatus === 1 &&
      passwordInputStatus === 1 &&
      confirmPassStatus === 1
    ) {
      if (userNameValidated) {
        props.setPageToShow(6);
        props.setSignupUserName(userName);
        props.setSignupPassword(password);
      } else {
        setSomethingWentWrong(true);
        setUsernameInputStatus(0);
        setPasswordInputStatus(0);
        setConfirmPassStatus(0);
        setuserNameValidated(false);
        setIsRestricted(false);
        setUserName("");
        setPassword("");
        setConfirmPass("");
        setSuggestedUsernames([]);
      }
    } else {
      return null;
    }
  };

  return (
    <div className="_SignupContainer">
      <div className="SignupInnercontainerLeft">
        <div className="wrapper">
          <div className="bixHeader">ZapMail</div>

          <div className="form-wrapper">
            <div className="input-wrapper username-margin-top-reg">
              <div className="regLabel">Register</div>
            </div>
            <div className="input-wrapper username-margin-top-login">
              {isUsernameFocus || userName !== "" ? (
                <label
                  className="input-label"
                  style={{ color: isUsernameFocus ? "#0094ff" : "" }}
                >
                  Create Username
                </label>
              ) : (
                ""
              )}
              <div
                className="inputdiv"
                style={{
                  borderColor:
                    (userName === "" && usernameInputStatus === 1) ||
                    (userName !== "" && usernameInputStatus !== 1)
                      ? "#ff0000"
                      : userName !== "" && usernameInputStatus === 1
                      ? "#00be19"
                      : isUsernameFocus
                      ? "#0d2a4c"
                      : "#9d9d9d",
                }}
              >
                <UsernameInput
                  type="text"
                  name="username"
                  value={userName}
                  isUsernameFocus={isUsernameFocus}
                  from="register"
                  placeholder={isUsernameFocus ? "" : "Username"}
                  onChange={(e) => {
                    handleUsername(e);
                  }}
                  onFocus={() => setIsUsernameFocus(true)}
                  onBlur={() => {
                    setIsUsernameFocus(false);
                    checkUsernameValidation();
                  }}
                />
              </div>

              {userNameInputValidation()}
            </div>

            <div className="input-wrapper username-margin-top-login">
              {isPwdFocus || password !== "" ? (
                <label
                  className="input-label"
                  style={{ color: isPwdFocus ? "#0094ff" : "" }}
                >
                  Password
                </label>
              ) : (
                ""
              )}

              <div
                style={{
                  borderColor:
                    (password === "" && passwordInputStatus === 1) ||
                    (password !== "" && passwordInputStatus !== 1)
                      ? "#ff0000"
                      : isPwdFocus
                      ? "#0d2a4c"
                      : "#9d9d9d",
                }}
                className="inputdiv"
              >
                <UsernameInput
                  type={showPassword ? "text" : "password"}
                  placeholder={isPwdFocus ? "" : "Password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    handlePassword(e);
                  }}
                  onFocus={() => setIsPwdFocus(true)}
                  onBlur={() => setIsPwdFocus(false)}
                  ref={passwordFocus}
                  value={password}
                  name="password"
                />
              </div>
              {passwordInputValidation()}
            </div>
            {isPwdFocus &&
            password.length !== 0 &&
            passwordInputStatus !== 1 ? (
              <div className="condition-strength">
                <div className={upperCase ? "item" : "item2"}>
                  {" "}
                  {upperCase ? (
                    <img src={check} alt="" className="danger-image2" />
                  ) : (
                    <img src={cross} alt="" className="danger-image2" />
                  )}
                  Contains an uppercase letter
                </div>
                <div className={lowerCase ? "item" : "item2"}>
                  {lowerCase ? (
                    <img src={check} alt="" className="danger-image2" />
                  ) : (
                    <img src={cross} alt="" className="danger-image2" />
                  )}
                  Contains a lowercase letter
                </div>
                <div className={numVal ? "item" : "item2"}>
                  {numVal ? (
                    <img src={check} alt="" className="danger-image2" />
                  ) : (
                    <img src={cross} alt="" className="danger-image2" />
                  )}
                  Contains a number
                </div>
                <div className={specialChar ? "item" : "item2"}>
                  {specialChar ? (
                    <img src={check} alt="" className="danger-image2" />
                  ) : (
                    <img src={cross} alt="" className="danger-image2" />
                  )}
                  Contains a special character
                </div>
                <div className={pwdLength ? "item" : "item2"}>
                  {pwdLength ? (
                    <img src={check} alt="" className="danger-image2" />
                  ) : (
                    <img src={cross} alt="" className="danger-image2" />
                  )}
                  Password strength 8 characters
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="input-wrapper username-margin-top-login">
              {confirmPass !== "" || isConPwdFocus ? (
                <label
                  className="input-label"
                  style={{ color: isConPwdFocus ? "#0094ff" : "" }}
                >
                  Confirm
                </label>
              ) : (
                ""
              )}

              <div
                style={{
                  borderColor:
                    confirmPass !== "" && confirmPass !== password
                      ? "#ff0000"
                      : isConPwdFocus
                      ? "#0d2a4c"
                      : "#9d9d9d",
                }}
                className="inputdiv"
              >
                <UsernameInput
                  type={showPassword ? "text" : "password"}
                  onFocus={() => setIsConPwdFocus(true)}
                  placeholder={isConPwdFocus ? "" : "Confirm"}
                  onBlur={() => setIsConPwdFocus(false)}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(e) => {
                    handleConfirm(e);
                  }}
                  name="password"
                  value={confirmPass}
                />
              </div>
              {confirmPassValidation()}

              <div className="form-caption">
                Use 8 or more characters with a mix of letters, numbers &
                symbols with at least one uppercase and lowercase{" "}
              </div>
            </div>

            {somethingWentWrong ? (
              <div className="form-fail">
                <img src={danger} alt="" className="danger-image" />
                <p>Somthing Went wrong try again</p>
              </div>
            ) : (
              ""
            )}

            {nextBtn()}

            <div className="footerText">
              Already have an account?{" "}
              <span
                className="footerTextLink"
                onClick={() => {
                  history.push({
                    pathname: "/login",
                    state: { from: "personal" },
                  });
                }}
              >
                Login
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUserName;
