
// React
import React, { useState, useEffect } from "react";

// React Modules
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment"
import { nanoid } from "nanoid";
import { openDB } from "idb";
import AWS from 'aws-sdk'

// Js
import config from "../config";
import { ws } from "../App";
import AppContext from "./AppContext";
import mixpanel from "mixpanel-browser";

// Assets
import pdf from "../Assets/Icons/pdf.png";
import DefultVideo from "../Assets/Images/defaultVideo.jpg"
import { useCookies } from "react-cookie";

//Helper Classes
import { LoginHelper, setAccountType, setUsersToLocalStorage, setUsersToLocalStorageDev } from "../pages/Auth/AuthHelper";
import { restHelper } from "./Helper/RestHelper";
import { loginService } from "../pages/Auth/Login/LoginService/LoginService";
import { featureToggleHelper } from "./Helper/FeatureToggleHelper";
import CookieService from "../services/CookieService";
import { logoutService } from "../services/LogoutService";
import { chatroomServices } from "../services/ChatroomServices/ChatroomSerivices";
import { groupServices } from "../services/ChatroomServices/GroupServices/GroupServices";
import { fileServices } from "../services/FileServices/FileServices";
import { domainServices } from "../services/DomainServices/DomainServices";
import { messageServices } from "../services/MessageServices/MessageServices";
import { userServices } from "../services/UserServices/UserServices";
import { tokenService } from "../services/TokenService";



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const AppProvider = (props) => {
  const history = useHistory();

  let timer = localStorage.getItem("bix_business_timer") !== null ? JSON.parse(localStorage.getItem("bix_business_timer")) : false
  let localPage = JSON.parse(localStorage.getItem("bix_business_page"))
  let url = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/file/`;

  const api = restHelper()

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [loggedFailed, setLoggedFailed] = useState(false);
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const [messageSendingLoading, setMessageSendingLoading] = useState(false);
  const [sendingMsgToOldUser, setSendingMsgToOldUser] = useState(false);
  const [chatUILoaded, setChatUILoaded] = useState(false);
  const [chatRooms, setChatRooms] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [allFilteredUsers, setAllFilteredUsers] = useState([]);
  const [selectedChat, setSelectedChat] = useState(
    JSON.parse(localStorage.getItem("bixChat_selectedChat"))
  );
  const [selectedSender, setSelectedSender] = useState({});
  const [sendMessageType, setSendMessageType] = useState(""); // newUser || oldUser || groupChat || emailChat || multiUser
  const [messageRecivers, setMessageRecivers] = useState([]);
  const [sendingMessages, setSendingMessages] = useState([]);
  const [sentTempMessages, setSentTempMessages] = useState([]);
  const [isChatSelected, setIsChatSelected] = useState(false);
  const [filteredUserChats, setFilteredUserChats] = useState([]);
  const [searchInput, setsearchInput] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);
  const [fromEdit, setFromEdit] = useState(false)
  const [newGroupMembers, setNewGroupMembers] = useState([]);
  const [downloadLink, setDownloadLink] = useState("");
  const [forgetPwdFailed, setforgetPwdFailed] = useState(false);
  const [forgetPwdLoading, setForgetPwdLoading] = useState(false);
  const [loadMoreMessages, setLoadMoreMessages] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});
  const [newMessage, setNewMessage] = useState([]);
  const [unread, setUnread] = useState(0);
  const [unreadSelectedChat, setUnreadSelectedChat] = useState([]);
  const [chatroomsLoaded, setChatroomsLoaded] = useState(false);
  const [allChatrooms, setAllChatrooms] = useState([]);
  const [loadedAllChatrooms, setLoadedAllChatrooms] = useState([])
  const [selectedChatroomId, setSelectedChatroomId] = useState("");
  const [selectedChatroomDatas, setSelectedChatroomDatas] = useState({});
  const [messageQueue, setMessageQueue] = useState([]);
  const [failedMessages, setFailedMessages] = useState([]);
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const [messageSendingPopup, setMessageSendingPopup] = useState(true);
  const [tempIsLoaded, setTempIsLoaded] = useState(false);
  const [tempChatrooms, setTempChatrooms] = useState(true);
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [selectedReply, setSelectedReply] = useState([]);
  const [selectedchatReceivers, setSelectedchatReceivers] = useState([]);
  const [reactionDetails, setReactionDetails] = useState({});
  const [currentMessage, setCurrentMessage] = useState("");
  const [isOnline, setIsOnline] = useState(true);
  const [sendingToNewUser, setSendingToNewUser] = useState(false);
  const [loggedUserData, setLoggedUserData] = useState({})
  const [sendingMultipleMsg, setSendingMultipleMsg] = useState(false)
  const [profileUpdating, setProfileUpdating] = useState(false)
  const [isChatObject, setIsChatObject] = useState("")
  const [isGroupNameChanging, setIsGroupNameChanging] = useState(false)
  const [isGroupEmailChanging, setIsGroupEmailChanging] = useState(false)
  const [isGroupDescriptionChanging, setIsGroupDescriptionChanging] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [centerModalVisibility, setCenterModalVisibility] = useState(false)
  const [adminModalVisibility, setAdminModalVisibility] = useState(false)
  const [selectedUser, setSelectedUser] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [mediaList, setMediaList] = useState([])
  const [mediaListLoading, setMediaListLoading] = useState(false)
  const [subjectListLoading, setSubjectListLoading] = useState(false)
  const [attachmentList, setAttachmentList] = useState([])
  const [attachmentListLoading, setAttachmentListLoading] = useState(true)
  const [tempNewMsgID, setTempNewMsgID] = useState("")
  const [groupChange, setGroupChange] = useState({})
  const [selectedChatroom, setSelectedChatroom] = useState("")
  const [groupCreating, setGroupCreating] = useState(false)
  const [allChatroomsLoaded, setAllChatroomsLoaded] = useState(false);
  const [selectedChatID, setSelectedChatID] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentURL, setCurrentURL] = useState(window.location.href)
  const [leftNavPopup, setLeftNavPopup] = useState("none");
  const [imageUploadPopup, setImageUploadPopup] = useState("none");
  const [rightNavPopup, setRightNavPopup] = useState("none");
  const [uploadImgModal, setuploadImgModal] = useState("none");
  const [viewBlockList, setViewBlockList] = useState(false)
  const [tempChatroomStatus, setTempChatroomStatus] = useState(false)
  const [domainName, setDomainName] = useState("")
  const [isSwitching, setIsSwitching] = useState(false)
  const [newChat, setNewChat] = useState("")
  const [chatroomLength, setChatroomLength] = useState(0)
  const [forwardModal, setForwardModal] = useState(false)
  const [appIntregrationModal, setAppIntregrationModal] = useState(false)
  const [googleCalenderInputModal, setGoogleCalenderInputModal] = useState(false)
  const [googleCalenderOptionModal, setGoogleCalenderOptionModal] = useState(false)
  const [fowardFilteredUsers, setForwardFilteredUsers] = useState([])
  const [contactModal, setContactModal] = useState(false)
  const [isContact, setIsContact] = useState(false)
  const [dataFolders, setDataFolders] = useState([]);
  const [dataFiles, setDataFiles] = useState([]);
  const [dataSubFolders, setDataSubFolders] = useState([]);
  const [loadData, setLoadData] = useState(1);
  const [showMainFolders, setShowMainFolders] = useState(true)
  const [isLogged, setIsLogged] = useState(false)
  const [viewContacts, setViewContacts] = useState(false)
  const [contactsSorted, setContactsSorted] = useState(false)
  const [resetIsLoading, setResetIsLoading] = useState(false)
  const [resetSuccess, setResetSuccess] = useState(false)
  const [anim, setAnim] = useState(false)
  const [savedChatsSelected, setSavedChatsSelected] = useState(true)
  const [otherChatsSelected, setOtherChatsSelected] = useState()
  const [savedUnreadMsgs, setSavedUnreadMsgs] = useState(0)
  const [unsavedUnreadMsgs, setUnsavedUnreadMsgs] = useState(0)
  const [savedContactsList, setSavedContactsList] = useState([])
  const [unsavedchatList, setunSavedchatList] = useState([])
  const [showTabs, setShowTabs] = useState(true)
  const [changePasswordIsLoading, setChangePasswordIsLoading] = useState(false)
  const [expandSelected, setExpandSelected] = useState(false)
  const [DragPopup, setDragPopup] = useState(false);
  const [files, setFiles] = useState([])
  const [passwordLinkError, setPasswordLinkError] = useState(false)
  const [sendModal, setSendModal] = useState(false)
  const [sendLoding, setSendLoading] = useState(false)
  const [messageTag, setMessageTag] = useState(false)
  const [alert, setAlert] = useState(false)
  const [pinAlert, setPinAlert] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [rightSideBarStatus, setRightSideBarStatus] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [blockList, setBlockList] = useState();
  const [blockedContacts, setBlockedContacts] = useState();
  const [blockedNonContacts, setBlockedNonContacts] = useState();
  const [isBlocked, setIsBlocked] = useState(false);
  const [threadIdNum, setThreadIdNum] = useState("")
  const [reportEmail, setReportEmail] = useState("")
  const [reportModal, setReportModal] = useState(false)
  const [signatureModal, setSignatureModal] = useState(false)
  const [reportSent, setReportSent] = useState(false)
  const [blockConfirm, setBlockConfirm] = useState(false)
  const [contractLoading, setContractLoading] = useState(false)
  const [showBlockDiv, setShowBlockDiv] = useState(false)
  const [isBlocker, setIsBlocker] = useState()
  const [domainContacts, setDomainContacts] = useState([])
  const [clickOther, setClickOther] = useState(false)
  const [replySubject, setReplySubject] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [unreadChatList, setUnreadChatList] = useState([])
  const [primaryCount, setPrimaryCount] = useState(0);
  const [modalLoading, setModalLoading] = useState(false)
  const [deleteError, setDeleteError] = useState(false)
  const [labels, setLabels] = useState([])
  const [cookies, setCookie, removeCookie] = useCookies("user");
  const [receivers, setReceivers] = useState([])
  const [searchList, setSearchList] = useState([])
  const [showSugestions, setShowSugestions] = useState(false)
  const [runTimer, setRunTimer] = useState(timer);
  const [OTPVerifying, setOTPVerifying] = useState(false);
  const [OTPError, setOTPError] = useState(false)
  const [OTPVerified, setOTPVerified] = useState(false)
  const [pageToShow, setPageToShow] = useState(localPage === null ? 1 : localPage)
  const [sendingOTP, setSendingOTP] = useState(false)
  const [setttingsTab, setSettingsTab] = useState(false);
  const [contentToShow, setContentToShow] = useState("All")
  const [toList, setToList] = useState("")
  const [ccList, setCcList] = useState("")
  const [messageInfo, setMessageInfo] = useState("")
  const [ccMessage, setCcMessage] = useState("")
  const [percentage, setPercentage] = useState(0);
  const [forwardMessageID, setForwardMessageID] = useState("")
  const [forwardLoading, setForwadLoading] = useState(false)
  const [fileInfo, setFileInfo] = useState([])
  const [newMessageFromSearch, setNewMessageFromSearch] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [showloader, setShowLoader] = useState(false)
  const [showInlineloader, setShowInlineLoader] = useState(false)
  const [showReactionTab, setShowReactionTab] = useState(false)
  const [signatureData, setSignatureData] = useState("")
  const [messageSeenList, setMessageSeenList] = useState([])
  const [forwardedList, setForwardedList] = useState([])



  const [messageStatus, setMessageStatus] = useState("")
  const [MessageBody, setMessageBody] = useState("")
  const [replySending, setReplySending] = useState(false)
  let fontS = localStorage.getItem("bix_fontSize") !== null ? localStorage.getItem("bix_fontSize") : "small"
  const [fontSizeType, setFontSizeType] = useState(fontS)
  const [addGuestModal, setAddGuestModal] = useState(false);
  const [guestList, setGuestList] = useState({});
  const [guestListEmail, setGuestListEmail] = useState({});
  const [googleAccessKey, setGoogleAccessKey] = useState("");
  const [showPasswordLink, setShowPasswordLink] = useState(false)
  const [passwordLinkLoading, setPasswordLinkLoading] = useState(false)
  const [primaryContacts, setPrimaryContacts] = useState("")




  let unreadList = []
  let contactList = []
  let nonContactList = []
  let cookieList = []
  let contactCount = 0;
  let contactWithMore = 0;
  let percent = 0;

  const S3_BUCKET = config.s3Bucket;
  const REGION = 'ap-south-1';
  AWS.config.update({
    accessKeyId: 'AKIAZ4HG4UTRKJSBKTIU',
    secretAccessKey: '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'
  })
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })

  const setUpFolderId = () => {
    localStorage.setItem('bixCloud-selectedFolderId', null);
    getData()

  };


  // Infinte Call

  window.addEventListener("offline", () => {
    setIsOnline(false);
  });
  window.addEventListener("online", () => {
    setIsOnline(true);
  });

  useEffect(() => {
    getProfile().then((e) => {
      if (e.status === 200) {
        getProfile()
      }
      else {
        setTokenExpired(false);
      }
    })
  }, [])

  useEffect(() => {
    if (showInlineloader) {
      setShowInlineLoader(false)

    } else {
      setShowInlineLoader(true)
    }
  }, [localStorage.getItem("bixChat_selectedChatId")])


  useEffect(() => {
    setCurrentURL(window.location.href)

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    if (localStorage.getItem("bixChat_selectedChatId")) {
      setSelectedChatID(localStorage.getItem("bixChat_selectedChatId"))

    } else {
      setSelectedChatID("")
    }

  }, [])

  useEffect(() => {
    window.onload = () => {

      if (localStorage?.getItem("bixChat_accessToken")) {
        localStorage.setItem("bixChat_selectedChatId", "");
        setIsLoggedIn(true);

      }
      else {
        setIsLoggedIn(false);

      }

    };
  }, []);

  useEffect(async () => {
    setupIDB();

    if (localStorage?.getItem("bixChat_accessToken")) {

      if (localStorage.getItem('bixChat_Signup')) {
        setAllChatroomsLoaded(true);

      }
      await getIDBChatRooms()
      await getAllChatroom()

      getProfile()
      ws.addEventListener("message", async (event) => {
        let message = JSON.parse(event.data);
        if (message?.action === "chatroom") {
          await getIDBChatRooms();
          await getAllChatroom();
        } else if (message?.action === "reaction") {
          let localChatID = localStorage.getItem("bixChat_selectedChatId");
          let localUserPublicID = localStorage.getItem(
            "bixChat_loggedUserPublicId"
          );
          if (
            message?.body?.chatroom_id === localChatID &&
            message?.body?.sender_id !== localUserPublicID
          ) {
            //re render existing chat
            let body = message?.body;
            setReactionDetails({ body });

          }
        }
      });
      ws.addEventListener("message", async (event) => {
        let data = JSON.parse(event.data);
        let localChatID = localStorage.getItem("bixChat_selectedChatId");
        let localUserID = localStorage.getItem("bixChat_loggedUserId");
        let message = data.body
        if (data?.action === "thread reply") {
          if (
            message?.chatroom_id === localChatID &&
            message?.sender_id !== localUserID
          ) {
            //re render existing chat
            setMessageDetails({ message });
            await getAllChatroom();
          }
          if (
            JSON.parse(localStorage.getItem("bixChat_selectedReply")).length > 0
          ) {
            getThread(message?.thread_id);

          }

        }
      });

      ws.addEventListener("message", async (event) => {
        let message = JSON.parse(event.data);
        let localChatID = localStorage.getItem("bixChat_selectedChatId");
        let localUserPublicID = localStorage.getItem(
          "bixChat_loggedUserPublicId"
        );
        if (message?.action === "group_name_change"
          || message?.action === "group_description_change"
          || message?.action === "group_add_members"
          || message?.action === "group_delete_member"
          || message?.action === "group_add_moderator"
          || message?.action === "group_remove_moderator") {
          await getAllChatroom();
          await getIDBChatRooms()
          if (
            message?.chatroom_id === localChatID &&
            message?.sender_id !== localUserPublicID
          ) {
            setGroupChange({ message })

          }
        }
      });

      ws.addEventListener("message", async (event) => {

        let data = JSON.parse(event.data);
        if (data.action === "message") {
          console.log("data", data.body);
          let message = data.body;
          let localChatID = localStorage.getItem("bixChat_selectedChatId");
          let localUserID = localStorage.getItem("bixChat_loggedUserId");
          setNewChat(message?.chatroom_id)


          if (
            message?.chatroom_id === localChatID &&
            message?.sender_id !== localUserID
          ) {
            //re render existing chat
            setMessageDetails({ message });

            await getAllChatroom();

          } else if (
            message?.chatroom_id !== localChatID &&
            message?.sender_id !== localUserID
          ) {
            //message notify  
            let found = false;
            for (let i = 0; i < chatRooms.length; i++) {
              if (chatRooms[i]?.chatroom_id === message?.chatroom_id) {
                found = true;
                break;
              }
            }
            if (!found) {
              getIDBChatRooms();
              getAllChatroom();

            }
            let array = newMessage;
            let isNewMessage = false;
            for (let i = 0; i < array.length; i++) {
              if (array[i]._id["$oid"] === message?.chatroom_id) {

                isNewMessage = true;
                break;
              }
            }
            if (isNewMessage) {

              array.forEach((newMessage) => {
                if (newMessage._id["$oid"] === message?.chatroom_id["$oid"]) {
                  newMessage.count = newMessage.count + 1;
                }
              });
            }
            if (!isNewMessage) {
              let msg = {
                _id: message.chatroom_id,
                count: 1,
              };
              array.push(msg);
              setNewMessage(array);


              let count = await getFirstChatroomLength(message.chatroom_id)
              setChatroomLength(count)
            }


          } else {
            //nothing
          }
        }
      });
    }



  }, []);

  const getMes = async (chatroomID) => {
    let length = await getFirstChatroomLength(chatroomID)
    setChatroomLength(length)
  }

  useEffect(() => {
    let chat = null;
    if (messageDetails !== undefined) {
      allChatrooms.forEach((value) => {
        if (value._id["$oid"] === messageDetails?.message?.chatroom_id) {
          chat = value;

          if (chat !== null) {
            chatSelected(chat);
          }
        }
      });
    }
  }, [messageDetails]);

  useEffect(() => {
    let chat = null
    if (groupChange !== undefined) {
      allChatrooms.forEach((chatroom) => {
        if (chatroom._id["$oid"] === groupChange?.message?.chatroom_id) {
          chat = chatroom
          if (chat !== null) {
            chatSelected(chat);
          }
          if (groupChange?.message?.action === "group_name_change" || groupChange?.message?.action === "group_description_change") {

            if (chat !== null) {
              setSelectedChatroom(chat)

            }
          }

        }
      })
    }
  }, [groupChange])

  useEffect(() => {
    if (loadMoreMessages) {
      let message = JSON?.parse(
        localStorage?.getItem("bixChat_selectedChatMessage")
      );
      let count = 0
      if (selectedChatroomDatas?.threads) {
        selectedChatroomDatas?.threads && Object.entries(selectedChatroomDatas?.threads)?.map(([key, value]) => {
          value?.map((val) => {
            if (val[0]) {
              return count++
            }
          })

        })
      }
      if (Math?.floor(count / 10) > 0) {
        loadMoreSelectedchat(message);
      } else {
        setLoadMoreMessages(false);
      }
    }
  }, [loadMoreMessages]);

  useEffect(() => {
    if (localStorage.getItem("bixChat_isPrivate") === "false") {
      getDomain()
    }
  }, [localStorage.getItem("bixChat_isPrivate")])
  useEffect(() => {
    setSavedUnreadMsgs(savedContactsList.length)
  }, [savedContactsList])

  const setupIDB = () => {
    openDB("bixChat-AllChatRooms", 1, {
      upgrade(db) {
        db.createObjectStore("chatrooms");
      },
    });
    openDB("bixChat-SelectedChatrooms", 1, {
      upgrade(db) {
        db.createObjectStore("loadedThreads");
      },
    });
  };


  // ----------------------------- Auth Functions -----------------------------


  const loginFunction = () => {
    loginService().getLoginData().then(async (success) => {
      if (success) {
        await getDomainContacts()
        setIsLogged(true)
        await getAllChatroom()
        await getProfile()
      }
    })
  }
  const getRefreshToken = () => {
    return new Promise((resolve) => {
      let users = CookieService.get("users")
      const options = { path: "/", domain: config.cookieDomain }
      if (users) {
        let userList = users.filter(user => {
          return user.isLogged === true
        })
        resolve(userList[0].refresh_token)

      }
    })

  }


  // Login Function
  const loginClicked = async (userName, password, type) => {
    setLoginIsLoading(true);

    const db1 = await openDB("bixChat-AllChatRooms", 1);
    db1
      .put("chatrooms", [], "chatrooms")
      .then((e) => {
        getIDBChatRooms();
      })
      .catch((err) => {
      });
    db1.close();

    let credential = {
      username: type === 'personal' ? `${userName}${config.emailProvider}` : `${userName}`,
      password: password,
    };
    let options = {
      body: credential
    }
    let url = "login"
    api
      .postCreate(url, options)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data
          featureToggleHelper().initFeatures()
          loginService().loginResponsHandler(data, setIsLogged, setLoginIsLoading, getProfile, getAllChatroom)
          getDomainContacts()
          if (data.is_first_login) {
            history.push({
              pathname: "/reset-password",
            });
          } else {
            history.push({
              pathname: "/",
            });
          }

        } else {
          setLoginIsLoading(false);
          setLoggedFailed(true);
        }

        // mixpanel.track('Login')
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  loginClicked(userName, password, type)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                loginClicked(userName, password, type)
              }
            })
          }

        }
        setLoginIsLoading(false);
        setLoggedFailed(true);
      });
  };
  const resetPassword = (password, confirmPassword) => {
    setResetIsLoading(true)
    let credential = {
      password: password,
      confirm_password: confirmPassword,
    };
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken")
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "users/reset_password",
      data: credential,
    })
      .then((response) => {
        if (response.data.msg === "password reset is success") {
          setResetSuccess(true)
          setResetIsLoading(false)
          localStorage.setItem("firstLogin", true)
          localStorage.setItem("tooltipFun", false)
          history.push({
            pathname: "/",
          });
        }

      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  resetPassword(password, confirmPassword)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                resetPassword(password, confirmPassword)
              }
            })
          }

        }
      });
  }
  const changePassword = (email, password, confirmPassword) => {
    setChangePasswordIsLoading(true)
    let credential = {
      email: email,
      password: password,
      confirm_password: confirmPassword,
    };
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_resetToken")
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "users/password_change",
      data: credential,
    })
      .then((response) => {
        if (response.status === 200) {
          featureToggleHelper().initFeatures()
          LoginHelper(response.data)
          setIsLogged(true)
          setAccountType(response.data.email)
          let fullname = response.data.first_name + " " + response.data.last_name
          let image = response.data.profile_pic === null || response.data.profile_pic === 'null' ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : response.data.profile_pic
          setUsersToLocalStorage(response.data.public_id, response.data.x_access_token, fullname, response.data.email, image)
          if (config.environment === "development") {
            setUsersToLocalStorageDev(response.data.public_id, response.data.x_access_token, fullname, response.data.email, image, response.data.refresh_token)
          }
          setChangePasswordIsLoading(false);
          getProfile();
          getAllChatroom();
          localStorage.setItem("bixChat_resetToken", "")
          history.push({
            pathname: "/",
          });

        }
        else if (response.status === 400) {
          setPasswordLinkError(true)
        }


      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  changePassword(email, password, confirmPassword)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                changePassword(email, password, confirmPassword)
              }
            })
          }
        }
      });
  }
  const getSupport = () => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken")
      },
      method: "get",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "sendsupportmessage",
    })
      .then(async (response) => {

        chatroomServices().handleSupportResponse(response, setAnim, getAllChatroom, setSendModal)

      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  getSupport()
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                getSupport()
              }
            })
          }
        }
      });
  }
  // Forget Password
  const forgetPassword = (email, dob, password, confirmPassword) => {
    setForgetPwdLoading(true);
    let credential = {
      email: `${email}${config.emailProvider}`,
      date_of_birth: dob,
      password: password,
      confirm_password: confirmPassword,
    };
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "users/reset_password",
      data: credential,
    })
      .then((response) => {
        setForgetPwdLoading(false);
        history.push({
          pathname: "/login",
        });
      })
      .catch((error) => {
        setForgetPwdLoading(false);
        setforgetPwdFailed(true);
      });
  };
  const handleUploadFile = (event) => {
    console.log("lsdklaklk");
    console.log(event.target.files);
    for (let file of event.target.files) {
      if (!files.includes(file)) {
        setFiles([...files, ...event.target.files]);
      }
    }
  };

  const dropHandleFiles = (event) => {
    for (let file of event) {
      UploadModalHandler("closeOnly")
      if (!files.includes(file)) {
        setFiles([...files, ...event]);
      }
    }
    setDragPopup(false)
  }

  // --------------------------------------------------------------------------


  const saveContact = (image, title, firstName, lastName, email, code, phoneNum, org, dept, role, industry) => {

    let chatroomId = localStorage.getItem("bixChat_selectedChatId")


    let fields = {
      "chatroom_id": chatroomId,
      "contact": {
        "title": title,
        "avatar": image,
        "name": `${firstName} ${lastName}`,
        "email": email,
        "code": code,
        "phoneNum": phoneNum,
        "organization": org,
        "department": dept,
        "role": role,
        "industry": industry,
      }

    }
    let contact = {
      "title": title,
      "avatar": image,
      "name": `${firstName} ${lastName}`,
      "email": email,
      "code": code,
      "phoneNum": phoneNum,
      "organization": org,
      "department": dept,
      "role": role,
      "industry": industry,
    }
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken")
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      data: fields,
      url: 'contact'

    })
      .then((response) => {
        let chatroom = selectedChatroomDatas?.chatroomData
        localStorage.setItem(
          "bixChat_selectedChatMessage",
          JSON.stringify(chatroom)
        );
        chatroom.contact = contact
        setSelectedChatroomDatas(selectedChatroomDatas)
        setIsSaving(false)
        setAlert(true)
        setContactModal(false);
        setRightSideBarStatus(true)
        setIsContact(true)
        getAllChatroom()
        setTimeout(() => {
          setAlert(false)
        }, 3000);
        setIsEdit(false)
      })
      .catch((error) => {
        setContactModal(true);
      });

  }

  const deleteContact = () => {
    let chatroomId = localStorage.getItem("bixChat_selectedChatId")
    let fields = {
      "chatroom_id": chatroomId,
      "function": "contact"
    }

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken")
      },
      method: "delete",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      data: fields,
      url: 'contact'

    })
      .then((response) => {
        setIsChatSelected(false);
        setRightSideBarStatus(false)
        setContactModal(false);
        setIsContact(true)
        getAllChatroom()
      })
      .catch((error) => {
        console.log(error)
      });

  }

  const pinChat = (chatId, func) => {
    getProfile()
    let fields = {
      "chatroom_id": chatId,
      "function": func
    }

    if (loggedUserData.pinchat_count === 3 && func === 'pin') {
      setPinAlert(true)

      setTimeout(() => {
        setPinAlert(false)
      }, 3000);
    }
    else if (loggedUserData.pinchat_count < 3 && func === 'pin') {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken")
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
        data: fields,
        url: 'pinchat'

      })
        .then((response) => {
          getAllChatroom()
        })
        .catch((error) => {
          console.log(error)

        });
    }
    else if (func === 'unpin') {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken")
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
        data: fields,
        url: 'pinchat'

      })
        .then((response) => {
          getAllChatroom()
        })
        .catch((error) => {
          console.log(error)

        });
    }
    else if (func === 'unpin') {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken")
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
        data: fields,
        url: 'pinchat'

      })
        .then((response) => {
          getAllChatroom()
        })
        .catch((error) => {
          console.log(error)

        });
    }

  }

  const moveToOther = () => {
    let chatroomId = localStorage.getItem("bixChat_selectedChatId")
    let fields = {
      "chatroom_id": chatroomId,
      "function": "primary"
    }

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken")
      },
      method: "delete",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      data: fields,
      url: 'contact'

    })
      .then((response) => {
        setIsChatSelected(false);
        setRightSideBarStatus(false)
        setContactModal(false);
        setIsContact(true)
        getAllChatroom()

        // mixpanel.track("Move to other")
      })
      .catch((error) => {
        console.log(error)
      });

  }

  const getBlockList = (allChatroom) => {

    let chats = allChatroom


    let blocked = []
    let cont = []
    let nonCont = []

    for (let i = 0; i <= chats?.length - 1; i++) {

      const isBlockedd = JSON.stringify(chats[i].blocked) !== '{}'



      if (isBlockedd && chats[i]?._cls !== "ChatRoom.GroupChatRoom") {


        blocked.push(chats[i])


        if (chats[i]?.contact?.email === undefined || chats[i]?.contact?.email === null || chats[i]?.contact?.email === '' || chats[i]?.contact?.email === 'null') {
          nonCont.push(chats[i])
        }
        else if (chats[i]?.contact?.email !== null || chats[i]?.contact?.email !== undefined || chats[i]?.contact?.email !== '' || chats[i]?.contact?.email !== 'null') {
          cont.push(chats[i])
        }
      }
    }

    setBlockList(blocked)
    setBlockedContacts(cont)
    setBlockedNonContacts(nonCont)

    if (viewBlockList === true) {

    }
    else {
      // mixpanelS(blocked, cont, nonCont)


    }

    let value = 0;

    let allCookies = document.cookie.split(';')

    cookieList = []

    for (let i = 0; i <= allCookies?.length - 1; i++) {
      let element = allCookies[i]?.split('=')
      cookieList.push(element)

    }

    let cc = 0;
    //All blocked chats
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Blocked') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }



    }


    if (cc === 0) {

      setCookie('Blocked', blocked?.length)
      // mixpanel.track('Blocked',
      //   { 'Blocked': blocked?.length }
      // )
    }

    else {
      if (parseInt(value) === blocked?.length) {

      }
      else {

        setCookie('Blocked', blocked?.length)
        // mixpanel.track('Blocked',
        //   { 'Blocked': blocked?.length }
        // )

      }
    }


    //  All blocked contacts
    cc = 0
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Blocked contacts') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }


    }

    if (cc === 0) {
      setCookie('Blocked contacts', cont?.length)
      // mixpanel.track('Blocked',
      //   { 'Blocked Chats': cont?.length }
      // )

    }
    else {
      if (parseInt(value) === cont?.length) {
      }
      else {
        setCookie('Blocked contacts', cont?.length)
        // mixpanel.track('Blocked',
        //   { 'Blocked Chats': cont?.length }
        // )

      }
    }


    //All blocked emails
    cc = 0
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Blocked emails') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }


    }

    if (cc === 0) {
      setCookie('Blocked emails', nonCont?.length)
      // mixpanel.track('Blocked',
      //   { 'Blocked Non-contacts': nonCont?.length }
      // )

    }
    else {
      if (parseInt(value) === nonCont?.length) {
      }
      else {
        setCookie('Blocked emails', nonCont?.length)
        // mixpanel.track('Blocked',
        //   { 'Blocked Non-contacts': nonCont?.length }
        // )

      }
    }


  }
  const blockContact = (chatId, func, blockdiv) => {
    let chatroomId = chatId
    // localStorage.getItem("bixChat_selectedChatId")
    let fields = {
      "chatroom_id": chatroomId,
      "function": func
    }
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken")
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      data: fields,
      url: 'block'
    })
      .then((response) => {
        if (blockdiv === true) {
          if (func === 'block') {
            setIsBlocked(true)

          }
          else if (func === 'unblock') {
            setIsBlocked(false)

          }
        }
        else if (chatId === localStorage.getItem("bixChat_selectedChatId")) {
          if (func === 'block') {
            setIsBlocked(true)

          }
          else if (func === 'unblock') {
            setIsBlocked(false)

          }
        }
        getAllChatroom()

        if (func === 'block') {
          getAllChatroom()
          getBlockList(allChatrooms)
          setRightSideBarStatus(false)

        }

      })
      .catch((error) => {
      });




  }

  const unblockContact = () => {
    let chatroomId = localStorage.getItem("bixChat_selectedChatId")
    let fields = {
      "chatroom_id": chatroomId,
      "function": "unblock"
    }

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken")
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      data: fields,
      url: 'block'

    })
      .then((response) => {
        setRightSideBarStatus(false)
        setContactModal(false);
        getAllChatroom()
      })
      .catch((error) => {
        console.log(error)
      });


  }



  const contactPopup = () => {
    let threadLength = selectedChatroomDatas?.threads?.length;

    if (threadLength > 5) {
      setContactModal(true);
    }
  }

  const getContacts = () => {
    let contacts = allChatrooms
    contactList = []

    for (let i = 0; i <= contacts?.length - 1; i++) {

      if (contacts[i]?.contact?.email === undefined || contacts[i]?.contact?.email === null || contacts[i]?.contact?.email === '' || contacts[i]?.contact?.email === 'null') {
        continue
      }
      else if (contacts[i]?.contact?.email !== null || contacts[i]?.contact?.email !== undefined || contacts[i]?.contact?.email !== '' || contacts[i]?.contact?.email !== 'null') {
        contactList.push(contacts[i])



        contactCount = contactCount + 1;

        if (contacts[i]?.contact?.organization != '' || contacts[i]?.contact?.role != '' || contacts[i]?.contact?.department != '') {
          contactWithMore = contactWithMore + 1
        }



      }
    }

    let value = 0;

    let allCookies = document.cookie.split(';')

    cookieList = []

    for (let i = 0; i <= allCookies?.length - 1; i++) {
      let element = allCookies[i]?.split('=')
      cookieList.push(element)

    }

    let cc = 0;
    //Saved Contacts
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Saved Contacts') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }

    }


    if (cc === 0) {
      setCookie('Saved Contacts', contactCount)
      // mixpanel.track('No. of Contacts',
      //   { 'Saved Contacts': contactCount }
      // )
    }

    else {
      if (parseInt(value) === contactCount) {

      }
      else {

        setCookie('Saved Contacts', contactCount)
        // mixpanel.track
        //   ('No. of Contacts',
        //     { 'Saved Contacts': contactCount }
        //   )


      }


    }


    //Contacts with more information
    cc = 0
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Contacts with more information') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }

    }


    if (cc === 0) {
      setCookie('Contacts with more information', contactWithMore)
      // mixpanel.track('No. of Contacts',
      //   { 'Contacts with more information': contactWithMore }
      // )
    }

    else {
      if (parseInt(value) === contactWithMore) {

      }
      else {

        setCookie('Contacts with more information', contactWithMore)
        // mixpanel.track
        //   ('No. of Contacts',
        //     { 'Contacts with more information': contactWithMore }
        //   )


      }


    }

    contactCount = 0
    contactWithMore = 0
    return (contactList)

  }

  const getPrimary = (chatRoomData) => {
    let contacts = chatRoomData
    let contactList = []

    let ignoredCount = 0;
    let readOnlyCount = 0;
    let blockCount = 0;

    for (let i = 0; i <= contacts?.length - 1; i++) {
      if (contacts[i]?._cls === "ChatRoom.GroupChatRoom") {
        contactList.push(contacts[i])
        continue
      }
      if (contacts[i].profile_email === 'support@bixchat.xyz') {
        contactList.push(contacts[i])
        continue
      }
      if (contacts[i]?.contact?.email === undefined || contacts[i]?.contact?.email === null || contacts[i]?.contact?.email === '' || contacts[i]?.contact?.email === 'null') {
        if (loggedUserData.domain === 'bixchat.xyz') {
          continue
        }
        else {
          if (contacts[i]?.profile_email?.split('@')[1] === loggedUserData.domain) {
            contactList.push(contacts[i])

          }
        }

      }
      else if (contacts[i]?.contact?.email !== null || contacts[i]?.contact?.email !== undefined || contacts[i]?.contact?.email !== '' || contacts[i]?.contact?.email !== 'null') {
        if (contacts[i]?.primary === false) {
          continue
        }
        else {
          contactList.push(contacts[i])

        }
      }
    }


    for (let i = 0; i <= contactList?.length - 1; i++) {
      if (contactList[i]?.last_message?.sender_id !== loggedUserData?.public_id && contactList[i]?.unread_message_count === 0) {
        readOnlyCount = readOnlyCount + 1;


      }

      if (contactList[i]?.last_message?.sender_id !== loggedUserData?.public_id && contactList[i]?.unread_message_count > 0) {
        ignoredCount = ignoredCount + 1;


      }
    }

    for (let i = 0; i <= contactList?.length - 1; i++) {
      if (JSON.stringify(contactList[i]?.blocked) !== '{}' && contactList[i]?._cls !== "ChatRoom.GroupChatRoom") {
        blockCount = blockCount + 1;
      }
    }



    let value = 0;

    let allCookies = document.cookie.split(';')

    cookieList = []

    for (let i = 0; i <= allCookies?.length - 1; i++) {
      let element = allCookies[i]?.split('=')
      cookieList.push(element)

    }


    let cc = 0;
    //Viewed Only
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Viewed only') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }

    }


    if (cc === 0) {
      setCookie('Viewed only', readOnlyCount)
      // mixpanel.track('Primary chat status',
      //   { 'Viewed only': readOnlyCount }
      // )

    }

    else {
      if (parseInt(value) === readOnlyCount) {

      }
      else {

        setCookie('Viewed only', readOnlyCount)

        // mixpanel.track('Primary chat status',
        //   { 'Viewed only': readOnlyCount }
        // )


      }


    }


    cc = 0
    //ignored
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Ignored') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }

    }


    if (cc === 0) {
      setCookie('Ignored', ignoredCount)
      // mixpanel.track('Primary chat status',
      //   { 'Ignored': ignoredCount }
      // )

    }

    else {
      if (parseInt(value) === ignoredCount) {

      }
      else {

        setCookie('Ignored', ignoredCount)

        // mixpanel.track('Primary chat status',
        //   { 'Ignored': ignoredCount }
        // )


      }


    }


    cc = 0
    //blocked
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Blocked In primary') {
        value = cookieList[i][1]
        i = cookieList?.length - 1
        cc = cc + 1
      }

    }


    if (cc === 0) {
      setCookie('Blocked In primary', blockCount)
      // mixpanel.track('Blocked In primary', {
      //   'Count': blockCount
      // })

    }

    else {
      if (parseInt(value) === blockCount) {

      }
      else {

        setCookie('Blocked In primary', blockCount)

        // mixpanel.track('Blocked In primary', {
        //   'Count': blockCount
        // })


      }


    }


    return (contactList)


  }


  const getPrimaryUnpinned = () => {
    let contacts = allChatrooms
    let contactList = []

    let ignoredCount = 0;
    let readOnlyCount = 0;
    let blockCount = 0;

    for (let i = 0; i <= contacts?.length - 1; i++) {
      if (contacts[i]?.pinned === 'null' || contacts[i]?.pinned === null || contacts[i]?.pinned === '' || contacts[i]?.pinned === undefined) {
      }
      else {
        continue
      }
      if (contacts[i]?._cls === "ChatRoom.GroupChatRoom") {
        contactList.push(contacts[i])
        continue
      }
      if (contacts[i]?.profile_email === 'support@bixchat.xyz') {
        contactList.push(contacts[i])
        continue
      }

      if (contacts[i]?.contact?.email === undefined || contacts[i]?.contact?.email === null || contacts[i]?.contact?.email === '' || contacts[i]?.contact?.email === 'null') {
        if (loggedUserData?.domain === 'bixchat.xyz') {
          continue
        }
        else {
          if (contacts[i]?.profile_email?.split('@')[1] === loggedUserData?.domain) {
            contactList.push(contacts[i])

          }
        }

      }
      else if (contacts[i]?.contact?.email !== null || contacts[i]?.contact?.email !== undefined || contacts[i]?.contact?.email !== '' || contacts[i]?.contact?.email !== 'null') {
        if (contacts[i]?.primary === false) {
          continue
        }
        else {
          contactList.push(contacts[i])

        }
      }
    }


    for (let i = 0; i <= contactList?.length - 1; i++) {
      if (contactList[i]?.last_message?.sender_id !== loggedUserData?.public_id && contactList[i]?.unread_message_count === 0) {
        readOnlyCount = readOnlyCount + 1;


      }

      if (contactList[i]?.last_message?.sender_id !== loggedUserData?.public_id && contactList[i]?.unread_message_count > 0) {
        ignoredCount = ignoredCount + 1;


      }
    }

    for (let i = 0; i <= contactList.length - 1; i++) {
      if (JSON.stringify(contactList[i].blocked) !== '{}') {
        blockCount = blockCount + 1;
      }
    }


    // mixpanel.track("Primary chat status",
    //   {
    //     'Viewed only': readOnlyCount,
    //     'Ignored': ignoredCount
    //   })

    // mixpanel.track('Blocked In primary', {
    //   'Count':blockCount
    // })



    return (contactList)


  }
  const unreadChats = (list) => {

    unreadList = []

    for (let i = 0; i <= list?.length - 1; i++) {
      if (list[i]?.unread_message_count > 0) {
        unreadList.push(list[i])

      }
    }


    return (unreadList)

  }

  const getPinnedChats = () => {
    let contacts = allChatrooms
    let pinned = []
    if (contacts) {
      for (let i = 0; i <= contacts.length - 1; i++) {
        if (contacts[i]?.pinned === 'null' || contacts[i]?.pinned === null || contacts[i]?.pinned === '' || contacts[i]?.pinned === undefined) {
          continue
        }
        else {
          pinned.push(contacts[i])
        }
      }


      pinned.sort(function (x, y) {
        let x_time = x.pinned
        let y_time = y.pinned
        var x = new Date(0);
        var y = new Date(0);
        x.setUTCSeconds(x_time);
        y.setUTCSeconds(y_time)

        return x_time - y_time
      }).reverse()
    }

    return pinned
  }

  const getDomainContacts = () => {
    return new Promise(async (resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        method: "get",
        url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer
          }/domain_contact`,
      }).then(async (response) => {

        if (response.status === 200) {
          let contact = response.data.contacts;
          localStorage.setItem("bix_domainContacts", JSON.stringify(contact))
          setDomainContacts(contact)
          resolve(contact)
        }

      }).catch((err) => {
        if (err.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  getDomainContacts()
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                getDomainContacts()
              }
            })
          }

        }

      })
    })
  }
  const filterContacts = (value) => {
    getDomainContacts()
    if (value === "") {
      return domainContacts.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      })
    } else {
      let array = domainContacts.filter(function (item) {
        if (item.name.toLowerCase().includes(value))
          return item
      }).sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      })
      return array
    }

  }

  const getNonContacts = (chatRoomData) => {

    let contacts = chatRoomData

    nonContactList = []
    let ignoredCount = 0;
    let readOnlyCount = 0;
    let blockCount = 0;


    for (let i = 0; i <= contacts?.length - 1; i++) {
      if (contacts[i]?._cls === "ChatRoom.GroupChatRoom") {
        continue
      }
      if (contacts[i].profile_email === 'support@bixchat.xyz') {
        continue
      }
      if (contacts[i]?.contact?.email === undefined || contacts[i]?.contact?.email === null || contacts[i]?.contact?.email === '' || contacts[i]?.contact?.email === 'null') {
        if (loggedUserData.domain === 'bixchat.xyz' || contacts[i]?.profile_email?.split('@')[1] !== loggedUserData.domain) {
          nonContactList?.push(contacts[i])
          continue
        }


      }
      else if (contacts[i]?.contact?.email != null || contacts[i]?.contact?.email != undefined || contacts[i]?.contact?.email != '' || contacts[i]?.contact?.email != 'null') {
        if (contacts[i]?.primary === false) {
          nonContactList?.push(contacts[i])

        }
        else {
          continue
        }
      }
    }


    for (let i = 0; i <= nonContactList?.length - 1; i++) {
      if (nonContactList[i]?.last_message?.sender_id !== loggedUserData.public_id && nonContactList[i]?.unread_message_count === 0) {
        readOnlyCount = readOnlyCount + 1;



      }

      if (nonContactList[i]?.last_message?.sender_id !== loggedUserData.public_id && nonContactList[i]?.unread_message_count > 0) {
        ignoredCount = ignoredCount + 1;


      }
    }

    for (let i = 0; i <= nonContactList?.length - 1; i++) {
      if (JSON.stringify(nonContactList[i]?.blocked) !== '{}') {
        blockCount = blockCount + 1;
      }
    }


    let value = 0;

    let allCookies = document.cookie.split(';')

    cookieList = []

    for (let i = 0; i <= allCookies.length - 1; i++) {
      let element = allCookies[i].split('=')
      cookieList.push(element)

    }

    let cc = 0;

    //Viewed Only
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Viewed only Other') {
        value = cookieList[i][1]
        i = cookieList.length - 1
        cc = cc + 1
      }

    }

    if (cc === 0) {
      setCookie('Viewed only Other', readOnlyCount)
      // mixpanel.track('Other chat status',
      //   { 'Viewed only': readOnlyCount }
      // )

    }

    else {
      if (parseInt(value) === readOnlyCount) {

      }
      else {

        setCookie('Viewed only Other', readOnlyCount)

        // mixpanel.track('Other chat status',
        //   { 'Viewed only': readOnlyCount }
        // )


      }


    }

    cc = 0
    //ignored
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Ignored Other') {
        value = cookieList[i][1]
        i = cookieList.length - 1
        cc = cc + 1
      }

    }


    if (cc === 0) {
      setCookie('Ignored Other', ignoredCount)
      // mixpanel.track('Other chat status',
      //   { 'Ignored': ignoredCount }
      // )

    }

    else {

      if (parseInt(value) === ignoredCount) {

      }
      else {
        setCookie('Ignored Other', ignoredCount)

        // mixpanel.track('Other chat status',
        //   { 'Ignored': ignoredCount }
        // )


      }


    }

    cc = 0
    //blocked
    for (let i = 0; i <= cookieList?.length - 1; i++) {
      if (cookieList[i][0] === ' Blocked In Other') {
        value = cookieList[i][1]
        i = cookieList.length - 1
        cc = cc + 1
      }

    }


    if (cc === 0) {
      setCookie('Blocked In Other', blockCount)
      // mixpanel.track('Blocked In Other', {
      //   'Count': blockCount
      // })

    }

    else {
      if (parseInt(value) === blockCount) {

      }
      else {

        setCookie('Blocked In Other', blockCount)

        // mixpanel.track('Blocked In Other', {
        //   'Count': blockCount
        // })


      }


    }



    return (nonContactList)

  }

  // Get all Chatroom From Server
  const getAllChatroom = (type) => {
    let loggeduser = localStorage.getItem("bixChat_email").split('@')[1]
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        method: "get",
        url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/chatroom`,
        onload: progressEvent => {
          const { loaded, total } = progressEvent;
          percent = Math.floor((loaded * 100) / total);
          setPercentage(percent);

          if (loaded === total) {
            setPercentage(0);
          }
        }
      }).then(async (response) => {
        if (response.status === 200) {

          setTokenExpired(false);
          let chatRoomData = response?.data?.chatrooms;
          getBlockList(chatRoomData)
          getPrimary(chatRoomData)
          getNonContacts(chatRoomData)


          let array = [];
          contactList = []
          for (let i = 0; i <= chatRoomData?.length - 1; i++) {
            if (chatRoomData[i]?._cls === "ChatRoom.GroupChatRoom") {
              contactList.push(chatRoomData[i])
              continue
            }
            if (chatRoomData[i]?.profile_email === 'support@bixchat.xyz') {
              contactList.push(chatRoomData[i])
              continue
            }
            if (chatRoomData[i]?.contact?.email === undefined || chatRoomData[i]?.contact?.email === null || chatRoomData[i]?.contact?.email === '' || chatRoomData[i]?.contact?.email === 'null') {
              if (loggeduser === 'bixchat.xyz') {
                continue
              }
              else {
                if (chatRoomData[i]?.profile_email?.split('@')[1] === loggeduser) {
                  contactList.push(chatRoomData[i])

                }
              }

            }
            else if (chatRoomData[i]?.contact?.email !== null || chatRoomData[i]?.contact?.email !== undefined || chatRoomData[i]?.contact?.email !== '' || chatRoomData[i]?.contact?.email !== 'null') {
              if (chatRoomData[i]?.primary === false) {
                continue
              }
              else {
                contactList.push(chatRoomData[i])

              }
            }
          }
          setPrimaryContacts(contactList)
          nonContactList = []

          for (let i = 0; i <= chatRoomData?.length - 1; i++) {
            if (chatRoomData[i]?._cls === "ChatRoom.GroupChatRoom") {
              continue
            }
            if (chatRoomData[i].profile_email === 'support@bixchat.xyz') {
              continue
            }
            if (chatRoomData[i]?.contact?.email === undefined || chatRoomData[i]?.contact?.email === null || chatRoomData[i]?.contact?.email === '' || chatRoomData[i]?.contact?.email === 'null') {
              if (loggeduser === 'bixchat.xyz' || chatRoomData[i]?.profile_email?.split('@')[1] !== loggeduser) {
                nonContactList?.push(chatRoomData[i])
                continue
              }


            }
            else if (chatRoomData[i]?.contact?.email != null || chatRoomData[i]?.contact?.email != undefined || chatRoomData[i]?.contact?.email != '' || chatRoomData[i]?.contact?.email != 'null') {
              if (chatRoomData[i]?.primary === false) {
                nonContactList?.push(chatRoomData[i])

              }
              else {
                continue
              }
            }
          }

          let value = 0;

          let allCookies = document.cookie.split(';')

          cookieList = []

          for (let i = 0; i <= allCookies.length - 1; i++) {
            let element = allCookies[i].split('=')
            cookieList.push(element)

          }

          let cc = 0;

          //No. of Primary Chats
          for (let i = 0; i <= cookieList?.length - 1; i++) {
            if (cookieList[i][0] === ' No. of Primary Chats') {
              value = cookieList[i][1]
              i = cookieList.length - 1
              cc = cc + 1
            }

          }


          if (cc === 0) {
            setCookie('No. of Primary Chats', contactList.length)
            // mixpanel.track('No. of Chats',
            //   {
            //     'No. of Primary Chats': contactList.length,
            //   }
            // )

          }
          else {
            if (parseInt(value) === contactList.length) {
            }
            else {
              setCookie('No. of Primary Chats', contactList.length)
              // mixpanel.track('No. of Chats',
              //   {
              //     'No. of Primary Chats': contactList.length,
              //   }
              // )

            }
          }
          cc = 0

          //No. of Other Chats
          for (let i = 0; i <= cookieList?.length - 1; i++) {
            if (cookieList[i][0] === ' No. of Other Chats') {
              value = cookieList[i][1]
              i = cookieList.length - 1
              cc = cc + 1
            }

          }


          if (cc === 0) {
            setCookie('No. of Other Chats', nonContactList.length)
            // mixpanel.track('No. of Chats',
            //   {
            //     'No. of Other Chats': nonContactList.length
            //   }
            // )

          }
          else {
            if (parseInt(value) === nonContactList.length) {
            }
            else {
              setCookie('No. of Other Chats', nonContactList.length)
              // mixpanel.track('No. of Chats',
              //   {
              //     'No. of Other Chats': nonContactList.length,
              //   }
              // )

            }
          }


          // mixpanel.track("No. of Chats",
          //   {
          //     'No. of Primary Chats': contactList.length,
          //     'No. of Other Chats': nonContactList.length
          //   })



          response?.data?.chatrooms.map((chat) => {
            if (chat.unread_message_count !== undefined) {
              if (chat.unread_message_count !== 0) {
                let message = {
                  _id: chat._id,
                  count: chat.unread_message_count,
                };
                array.push(message);
                setNewMessage(array);
              }
            }
            return null;
          });
          let chatArry = []
          chatRoomData.map((chat) => {
            if (chat.unread_message_count !== undefined) {
              if (chat.unread_message_count !== 0) {
                chatArry.push(chat);
                setUnreadChatList(chatArry);
              }
            }
            return null;
          });

          const db1 = await openDB("bixChat-AllChatRooms", 1);
          db1
            .put("chatrooms", chatRoomData, "chatrooms")
            .then(async (e) => {
              await getIDBChatRooms(type);
              if (localStorage.getItem("bixChat_onceLoaded")) {

              } else {
                setChatroomDetailsToIDB()
              }
              resolve(response)

            })
            .catch((err) => {
            });
          db1.close();
          let cArray = []
          contactList.map(
            (chat) => {
              if (chat.unread_message_count !== undefined) {
                if (chat.unread_message_count !== 0) {
                  let message = {
                    _id: chat._id,
                    count: chat.unread_message_count,
                  };
                  cArray.push(message);
                  setSavedContactsList(cArray);
                  setSavedUnreadMsgs(savedContactsList.length)
                }
              }
              return null;
            }
          )

          let ncArray = []
          nonContactList.map(
            (chat) => {
              if (chat.unread_message_count !== undefined) {
                if (chat.unread_message_count !== 0) {
                  let message = {
                    _id: chat._id,
                    count: chat.unread_message_count,
                  };
                  ncArray.push(message);
                  setunSavedchatList(ncArray);
                  setUnsavedUnreadMsgs(unsavedchatList.length)
                }
              }
              return null;
            }
          )
          setAllChatroomsLoaded(true);

        }
        // console.log("before resolve");
        setTimeout(() => {
          setAnim(false)
        }, 1000);

        setShowLoader(true)

      }).catch((err) => {
        if (err.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  getAllChatroom()
                }
              })

            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                getAllChatroom()
              }
            })

          }

        }
        // mixpanel.track("get all chatrooms error")
      })
    })

  };
  const setChatroomDetailsToIDB = async () => {

    const db1 = await openDB("bixChat-AllChatRooms", 1);

    if (db1) {
      db1.getAll("chatrooms").then((e) => {

        e[0].map(async (singleChatroom) => {

          let chatroomWithData = {
            chatroomData: singleChatroom,
            threads: [],
          };

          const db2 = await openDB("bixChat-SelectedChatrooms", 1);

          await db2.put("loadedThreads", chatroomWithData, singleChatroom._id['$oid'])
          localStorage.setItem("bixChat_onceLoaded", true)
          db2.close();
          // return null

        })
        return null
      });
    }

    // get chatrooms

  }

  // Get Loaded Chatrooms from IDB
  const getIDBChatRooms = (type) => {

    return new Promise(async (resolve, reject) => {
      const db1 = await openDB("bixChat-AllChatRooms", 1);
      if (db1) {
        db1.getAll("chatrooms").then((e) => {

          setChatroomsLoaded(true);
          setAllChatrooms(e[0]);
          setLoadedAllChatrooms(e[0])

          // console.log(type, "type")

          // console.log(e[0][0], "e[0][0]")

          if (type === "setSelectedId") {

            localStorage.setItem("bixChat_selectedChatId", e[0][0]._id["$oid"]);




            chatSelected(e[0][0])

          }
          resolve(e[0])

        }).catch((err) => {

          reject(err)
          // mixpanel.track("get IDB ChatRooms error")

        })
      }
    })


  };

  // Get LoggedUser Profile Data
  const getProfile = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        method: "get",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/user`,
      })
        .then(async (response) => {
          if (response.status === 200) {
            let singleData = response.data
            setLoggedUserData(singleData)

            localStorage.setItem("bixChat_loggedUserData", JSON.stringify(singleData))
            localStorage.setItem("bixChat_loggedUserId", singleData.user_id);
            localStorage.setItem(
              "bixChat_loggedUserPublicId",
              singleData.public_id
            );
            let profile_pic = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/user/profilepic/${singleData.public_id}`
            localStorage.setItem(
              "bixChat_profileImage", profile_pic);
            await getAllChatroom()
            setIsSwitching(false)
            resolve(response)
          }

        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    getProfile()
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  getProfile()
                }
              })
            }

          }
          // mixpanel.track("get logged user profile data error")
        });
    })


  };

  const getMessageInfo = (messageId) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        method: "get",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/messageinfo/${messageId}`,
      })
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            resolve(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    getMessageInfo(messageId)
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  getMessageInfo(messageId)
                }
              })
            }

          }
        });
    })


  };


  const getLabels = () => {

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
      method: "get",
      url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/chatroom`,

    })
      .then((response) => {



        let l = response?.data?.labels
        let c = getNonContacts(allChatrooms)


        let neww = []

        for (let i = 0; i <= l?.length - 1; i++) {
          let count = 0;
          for (let j = 0; j <= c?.length - 1; j++) {

            if (c[j]?.labels?.includes(l[i]?.name)) {
              count = count + 1;
            }

          }

          if (count != 0) {
            neww.push(l[i])
          }

        }

        setLabels(neww)



      })
      .catch((error) => {
        console.log(error)
      });


  }


  const filterLabel = (label) => {

    let chatList = getNonContacts(allChatrooms)

    let chats = []

    for (let i = 0; i <= chatList?.length - 1; i++) {
      if (chatList[i]?.labels?.includes(label)) {
        chats.push(chatList[i])
      }
    }

    return chats
  }


  // Update Logged User Profile
  const updateProfile = (e) => {

    setProfileUpdating(true)

    let userData = {}


    if (e?.change_count >= 3) {
      userData = {
        first_name: "",
        last_name: "",
        display_name: e.displayName,
        headline: e.headline,
        address: e.address,
        about: e.bio,
        private_attr: [],
      }
    } else {
      userData = {
        first_name: e.firstName,
        last_name: e.lastName,
        display_name: e.displayName,
        headline: e.headline,
        address: e.address,
        about: e.bio,
        private_attr: [],
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
        url: "set-user-settings",
        data: userData,
      })
        .then((response) => {

          getProfile()

          setSettingsModal(false)
          setProfileUpdating(false)

          resolve(response)


        })
        .catch((error) => {

          // console.log(error)

          getProfile()

          setSettingsModal(false)
          setProfileUpdating(false)

          reject(error)
          // mixpanel.track("update logged user error")


        });

    })

  }
  const updateUserDP = (e) => {
    let profilePicture = e;
    const formData = new FormData();
    formData.append("profile_picture_encoded", profilePicture.split("base64,")[1]);
    axios({
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}`,
      url: "user/profilePicture/",
      data: formData,
    }).then((response) => {
      setProfileUpdated(true)
      getProfile()

    })
      .catch((error) => {
        console.log(error);
      });
  };

  // Scroll Chat UI to Top
  const scollChatUI = () => {
    let objDiv = document.getElementById("email-wrapper");

    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  // Filer Chatrooms
  const filterChatrooms = async (e) => {


    if (e === "rest") {
      getIDBChatRooms();

    } else {
      if (e?.target?.value?.trim().length > 0) {
        const db1 = await openDB("bixChat-AllChatRooms", 1);

        if (db1) {
          db1.getAll("chatrooms").then((chatrooms) => {
            let filteredChatData = [];
            chatrooms[0]?.map((singleChat, i) => {
              if (
                singleChat?.chatroom_name
                  ?.toLowerCase()
                  ?.includes(e?.target?.value?.toLowerCase())
              ) {
                filteredChatData.push(singleChat);
              }
              return null;
            });

            setAllChatrooms(filteredChatData);
          });
        }
      } else {
        getIDBChatRooms();
      }
    }


  };

  // Search Contacts 
  const searchContact = (e, emails) => {

    let contacts = allChatrooms


    let allUsers = []
    let users = []
    let emailList = []


    if (e?.target?.value?.length > 0) {



      contacts?.map((contact, i) => {
        if (!emails?.includes(contact?.profile_email)) {
          if (contact?.profile_email?.includes(e?.target?.value?.trim())) {
            users.push(contact)
            emailList.push(contact.profile_email)
          }
          else if (contact?.contact?.name?.toLowerCase().includes(e?.target?.value?.toLowerCase().trim())) {
            users.push(contact)
            emailList.push(contact.profile_email)

          }
        }

      })

      setSearchList(users)


      if (loggedUserData.domain !== 'bix.chat') {
        axios({
          method: "get",
          baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/users`,
        }).then((res) => {

          res?.data?.map((value, i) => {

            if (!emailList.includes(value.email)) {

              if (value.domain === loggedUserData.domain) {
                if (!emails?.includes(value?.email)) {
                  if (value?.email?.includes(e?.target?.value?.trim())) {
                    users.push(value)
                  }
                }
              }
            }


          })

          setSearchList(users)

        });

      }



    }


    if (users.length > 0) {
      setShowSugestions(true)
    }


  }


  // Search Users For New Chat
  const searchUsers = async (e) => {
    return new Promise((resolve, reject) => {
      if (e === "reset") {
        setFilteredUsers([]);
        setForwardFilteredUsers([])

      } else {
        let x = e?.target?.value?.trim();
        if (x.length > 0) {

          axios({
            method: "post",
            baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/users/search`,
            data: { name: x },
          }).then((res) => {

            if (res.data.msg !== "user not found") {
              if (
                localStorage.getItem("bixChat_loggedUserPublicId") !==
                res.data[0].public_id
              ) {
                setTimeout(() => {
                  if (!forwardModal) {
                    setFilteredUsers([res.data[0]])
                  }
                  setForwardFilteredUsers([res.data[0]])
                }, 500)
              }
            } else {
              setFilteredUsers([]);
              setForwardFilteredUsers([])
            }

            resolve(res.data)

          }).catch((err) => {
            if (err.res.status === 401) {
              if (config.environment !== "development") {
                getRefreshToken().then((rToken) => {
                  tokenService().checkToken(rToken).then((res) => {
                    if (res === "success") {
                      searchUsers(e)
                    }
                  })
                })
              } else {
                let refreshToken = localStorage.getItem("bixChat_refreshToken")
                tokenService().checkToken(refreshToken).then((res) => {
                  if (res === "success") {
                    searchUsers(e)
                  }
                })
              }

            }
            // mixpanel.track("search users for new chat error")

          })


        } else {
          setFilteredUsers([]);
          setForwardFilteredUsers([])
        }
      }

    })
  };

  // Search Users for Create Group
  const searchAllUsers = (e) => {


    let x = e?.target?.value?.trim();

    if (x.length > 0) {
      axios({
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/users/search`,
        data: { name: x },
      }).then((res) => {
        if (res.data.msg !== "user not found") {
          if (
            localStorage.getItem("bixChat_loggedUserPublicId") !==
            res.data[0].public_id
          ) {
            setTimeout(() => {
              setAllFilteredUsers([res.data[0]]);
            }, 500)
          }
        } else {
          setAllFilteredUsers([]);
        }
      });
    } else {
      setAllFilteredUsers([]);
    }
  };

  // Loading Selected Chatroom (Already Loaded ? "Load from IDB" : "Load from DB")
  const chatSelected = async (e, type) => {
    return new Promise(async (resolve, reject) => {
      localStorage.setItem("bixChat_selectedChatUser", e.group_title)
      setIsChatSelected(true);
      if (e) {
        setIsBlocker(false)

        // console.log(loggedUserData.block_list[0])
        let bl_list = loggedUserData?.block_list

        if (bl_list?.length != 0) {
          for (let i = 0; i <= bl_list?.length; i++) {
            if (bl_list[i]?.chatroom_id === e?._id["$oid"]) {
              setIsBlocker(true)
            }
          }
        }


        let contact = e?.contact?.email;
        let blocked = e?.blocked
        if (blocked == undefined) {
          setIsBlocked(false)

        } else {
          if (Object.keys(blocked).length > 0) {
            setIsBlocked(true)
          }
          else {
            setIsBlocked(false)

          }
        }
        if (contact === undefined) {
          setIsContact(false)
        }
        else if (contact !== null) {
          setIsContact(true)

        }

        if (e.indexdb_chatroom_status === "pending") {
          setTempChatroomStatus(true)
        } else {
          setTempChatroomStatus(false)
        }

        let selectedChatMessage = e;
        let chatRoomId = e?._id["$oid"];
        localStorage.setItem("bixChat_selectedChatId", chatRoomId);
        localStorage.setItem(
          "bixChat_selectedChatMessage",
          JSON.stringify(selectedChatMessage)
        );

        setSelectedChatroomId(chatRoomId);

        const db2 = await openDB("bixChat-SelectedChatrooms", 1);

        if (db2) {
          db2.get("loadedThreads", chatRoomId).then(async (data) => {
            if (data) {

              getSelectedChatFromIDB(chatRoomId);

              if (e._cls !== "ChatRoom.EmailChatRoom") {

                e.members_list?.map((member) => {

                  if (
                    member.public_id !==
                    localStorage.getItem("bixChat_loggedUserPublicId")
                  ) {
                  }
                  return null;
                });
                // setSelectedchatReceivers(users);
              }

              await loadSelectedchat(selectedChatMessage, type);

              resolve(data)

            } else {
              if (e._cls !== "ChatRoom.EmailChatRoom") {
                e.members_list?.map((member) => {
                  if (
                    member.public_id !==
                    localStorage.getItem("bixChat_loggedUserPublicId")
                  ) {
                    return null;
                  }
                  return null;
                });
              }

              await loadSelectedchat(selectedChatMessage, type);

              reject("data not fatched")

            }
          });
        }
      }

    })

  };

  // Load Selected ChatRoom from Server
  const loadSelectedchat = (selectedMessage, type) => {
    return new Promise(async (resolve, reject) => {
      let chatID = selectedMessage._id["$oid"]
      if (chatID !== null) {
        axios({
          headers: {
            "Access-Control-Allow-Origin": "*",
            "x-access-token": localStorage?.getItem("bixChat_accessToken"),
          },
          method: "get",
          url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer
            }/chatroom/${chatID}?page=1&limit=10`,
        }).then(async (response) => {
          if (response.status === 200) {
            setIsChatObject(response.data)
            let threads = response.data.threads;
            let is_deleted = response.data.is_deleted
            let is_removed = response.data.is_removed
            setUnreadSelectedChat(response.data.unread_threads);
            let chatroomWithData = {
              chatroomData: selectedMessage,
              threads: threads,
              is_deleted: is_deleted,
              is_removed: is_removed
            };
            const db1 = await openDB("bixChat-SelectedChatrooms", 1);
            db1
              .put("loadedThreads", chatroomWithData, chatID)
              .then((e) => {
                if (type === "chat_selected") {

                  scollChatUI();
                }
                scollChatUI();
                getSelectedChatFromIDB(chatID);
              })
              .catch((err) => {
              });
            db1.close();

            setUnreadSelectedChat(response.data.unread_threads);
            if (selectedMessage) {
              selectedMessage.messagesData = threads;
              selectedMessage.is_deleted = is_deleted
              setSelectedChat(selectedMessage);
            }
          }

          resolve(response)

          setShowInlineLoader(true)

        }).catch((err) => {

          if (err.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    loadSelectedchat(selectedMessage, type)
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  loadSelectedchat(selectedMessage, type)
                }
              })
            }

          }

        })
      }
    })

  };



  // Load More Selected Chat Treads
  const loadMoreSelectedchat = (selectedMessage) => {
    let count = 0
    Object.entries(selectedChatroomDatas.threads).map(([key, value]) => {
      value.map((val) => {
        if (val[0]) {
          return count++
        }
      })
    })
    if (count % 10 === 0) {
      let page = Math.floor(count / 10) + 1;
      if (localStorage?.getItem("bixChat_selectedChatId") !== null) {
        axios({
          headers: {
            "Access-Control-Allow-Origin": "*",
            "x-access-token": localStorage?.getItem("bixChat_accessToken"),
          },
          method: "get",
          url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer
            }/chatroom/${localStorage?.getItem(
              "bixChat_selectedChatId"
            )}?page=${page}&limit=10`,
        }).then(async (response) => {
          if (response.status === 200) {

            let chatRoomId = localStorage.getItem("bixChat_selectedChatId");
            let loadedChatroomThreads = selectedChatroomDatas.threads;

            let newLoadedMessages = response.data.threads;
            if (newLoadedMessages) {
              Object.keys(loadedChatroomThreads).map((newVal) => {
                console.log(newVal, Object.keys(newLoadedMessages)?.pop());
                if (newVal === Object.keys(newLoadedMessages)?.pop()) {
                  Object.values(newLoadedMessages)?.pop().reverse().map((messages) => {
                    loadedChatroomThreads[newVal].unshift(messages)
                  })
                } else {
                  loadedChatroomThreads = { ...newLoadedMessages, ...loadedChatroomThreads }
                }
                return null
              })
              let chatroomWithData = {
                chatroomData: selectedMessage,
                threads: loadedChatroomThreads,
              };
              const db2 = await openDB("bixChat-SelectedChatrooms", 1);

              db2
                .put("loadedThreads", chatroomWithData, chatRoomId)
                .then((e) => {
                  getSelectedChatFromIDB(chatRoomId, "loadmore");

                  setLoadMoreMessages(false);
                })
                .catch((err) => {
                });
              db2.close();
            } else {
              setLoadMoreMessages(false);
            }


          }
        }).catch((err) => {
          if (err.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    loadMoreSelectedchat(selectedMessage)
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  loadMoreSelectedchat(selectedMessage)
                }
              })
            }

          }
        })
      }
    } else {
      setLoadMoreMessages(false);
    }
  };
  // Load Selected Chatroom from IDB
  const getSelectedChatFromIDB = async (id, type) => {
    return new Promise(async (resolve, reject) => {

      const db1 = await openDB("bixChat-SelectedChatrooms", 1);

      if (db1) {
        db1.get("loadedThreads", id).then((e) => {
          setSelectedChatroomDatas(e);


          if (!type) {
            scollChatUI()
            scollReply();
          }

          resolve(e)

        }).catch((err) => {
          reject(err)
        })
      }
    })
  };
  // useEffect(() => {
  //   let online = localStorage.getItem("bixOffilne")
  //   let message =  JSON.parse(localStorage.getItem("bix_sendingMessage"))
  //   if (online && message) {
  //     sendMessageToServer(
  //       message.msg,
  //       message.sub,
  //       message.rec,
  //       message.files,
  //       message.tempId,
  //       message.threadId,
  //       message.fromCompose,
  //       message.isForwarded,
  //       message.sendFiles,
  //       message.chatroomId)
  //   }
  // }, [])
  const countWords = (str) => {
    const arr = str.split(' ');

    return arr.filter(word => word !== '').length;
  }
  const sendMessageToServer = async (
    msg,
    sub,
    rec,
    files,
    tempId,
    threadId,
    fromCompose,
    isForwarded,
    sendFiles,
    chatroomId,
  ) => {
    setMessageStatus("Sending ...")
    if (chatroomId === "") {
      chatroomId = selectedChatroomDatas?.chatroomData?._id["$oid"]
    }

    if (sub != "") {
      // mixpanel.track("Message with subject line")
      // mixpanel.track("Number of charachters in subject", { 'no of chars': sub.length })

    }

    // mixpanel.track("Number of charachters in a message", { 'no of chars': msg.length })

    setSendingMsgToOldUser(true);
    setSendingToNewUser(false);
    setViewContacts(false);


    let thread_id = threadId ? threadId : null;
    let chatroom_id = null;
    let chatroomX_id = chatroomId;
    let receiver = null;
    let message_type = null;
    let subject = sub;
    let message = msg;
    let receiverNew = null;
    let chatClass = selectedChatroomDatas?.chatroomData

    let attachmentsX = []
    let attachments_file_namesX = []
    let attachments_with_linksX = []
    if (threadId !== null) {
      setReplySending(true)
    }
    let filePromise = files?.map((file) => {
      attachmentsX.push(file.name)
      // mixpanel.track(file.type.split('/')[0], { 'name': file.name, 'format': file.type.split('/')[1] })
      // Setup Attachment to Handling Sending Message UI/UX
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {

          let base64Only = reader?.result;

          let x = {
            thumbnail_link: base64Only,
            name: file.name,
            attachment_link: base64Only,
          }

          if (file.name.includes(".mp4")) {
            x = {
              thumbnail_link: DefultVideo,
              name: file.name,
              attachment_link: DefultVideo,
            }
          } if (file.name.includes("pdf") || file.name.includes("doc") || file.name.includes("txt") || file.name.includes("docx") || file.name.includes("csv") || file.name.includes("xlxs")) {
            x = {
              thumbnail_link: pdf,
              name: file.name,
              attachment_link: pdf,
            }
          }
          else {
            x = {
              thumbnail_link: base64Only,
              name: file.name,
              attachment_link: base64Only,
            }
          }

          attachments_file_namesX.push(base64Only)
          attachments_with_linksX.push(x)

          scollReply()
          resolve(reader.result)
        };

        reader.onerror = ((err) => {
          reject(err)

        })
      })
    })


    await Promise.all(filePromise).then(async () => {

      console.log(rec, "rec");
      // Logics to IndexDB

      if (sendMessageType === "multiUser") {
        // If user exist

        let chatroomDetected = false

        allChatrooms.map(async (singleChatroom) => {

          if (rec[0] === singleChatroom.profile_email) {
            chatroomDetected = true
            let id = singleChatroom._id["$oid"]
            console.log(singleChatroom);
            localStorage.setItem("bixChat_selectedChatId", id)
            const db2 = await openDB("bixChat-SelectedChatrooms", 1);



            if (db2) {
              db2.get("loadedThreads", id).then(async (data) => {


                let allThreads = data?.threads;

                let tempMessage = {
                  attachments: attachments_with_linksX,
                  attachments_file_names: attachmentsX,
                  attachments_with_links: attachments_with_linksX,
                  deleted_users: [],
                  indexdb_status: "sending",
                  is_threaded: false,
                  message_body: message,
                  message_subject: subject,
                  reactions: [],
                  read_users: [],
                  sender_name: loggedUserData.full_name,
                  sender: [{ $oid: localStorage.getItem("bixChat_loggedUserId") }],
                  sender_public_id: localStorage.getItem(
                    "bixChat_loggedUserPublicId"
                  ),
                  temp_id: tempId,
                  timestamp: { $date: Date.now() },
                  forwardInfo: ""
                };

                if (data) {
                  // chatSelected(data)
                }

                let firstThread = [];
                firstThread.push(tempMessage);
                let lastKey = Object.keys(allThreads)[Object.keys(allThreads).length - 1]
                allThreads[lastKey]?.push(firstThread)
                let updatedChatroomData = {
                  chatroomData: data?.chatroomData,
                  threads: allThreads,
                };

                db2
                  .put("loadedThreads", updatedChatroomData, id)
                  .then((e) => {
                    getSelectedChatFromIDB(id);
                  })
                  .catch((err) => {
                  });
                db2.close();
              });

            }





          }

        })


        if (!chatroomDetected) {



          setTempChatroomStatus(true)


          let xId = nanoid()

          let tempChatroom = [{
            chatroom_name: rec[0],
            indexdb_chatroom_status: "sending",
            last_activity_at: moment().format(),
            last_message: {},
            last_message_at: moment().format(),
            members_list: [],
            profile_email: rec[0],
            profile_headline: "",
            profile_image: null,
            profile_name: rec[0],
            temp_id: "null",
            _cls: "ChatRoom.PrivateChatRoom",
            _id: { $oid: xId },
          }]

          let tempMessage = {
            attachments: attachments_with_linksX,
            attachments_file_names: attachmentsX,
            attachments_with_links: attachments_with_linksX,
            deleted_users: [],
            indexdb_status: "sending",
            is_threaded: false,
            message_body: message,
            message_subject: subject,
            reactions: [],
            read_users: [],
            sender_name: loggedUserData.full_name,
            sender: [{ $oid: localStorage.getItem("bixChat_loggedUserId") }],
            sender_public_id: localStorage.getItem(
              "bixChat_loggedUserPublicId"
            ),
            temp_id: tempId,
            timestamp: { $date: Date.now() },
            forwardInfo: ""
          };

          let tempThreadX = [tempMessage]

          let updatedThreadX = {}
          let dateP = moment().format("YYYY-MM-DD")
          updatedThreadX[dateP] = [tempThreadX]

          let tempChatroomDataX = {
            chatroomData: tempChatroom[0],
            threads: updatedThreadX
          }


          const db2 = await openDB("bixChat-SelectedChatrooms", 1);

          if (db2) {
            db2
              .put("loadedThreads", tempChatroomDataX, xId)
              .then((e) => {
                getSelectedChatFromIDB(xId);

              })
              .catch((err) => {
              });
            db2.close();
          }

          // get IDB
          const db1 = await openDB("bixChat-AllChatRooms", 1);

          if (db1) {
            db1.getAll("chatrooms").then((e) => {

              //// console.log(e, "eeee")

              let chatroomsFromIDB = e[0]

              let updatedChatroom = tempChatroom.concat(chatroomsFromIDB)

              db1
                .put("chatrooms", updatedChatroom, "chatrooms")
                .then((e) => {

                  setsearchInput("")
                  filterChatrooms("reset");
                  searchUsers("reset");


                  getIDBChatRooms();
                })
                .catch((err) => {
                });
              db1.close();
            });
          }

        }
      } else if (sendMessageType === "newUser" && newMessageFromSearch) {
        // Create Sending temp Chatroom in IndexDB

        let selectedChatData = JSON.parse(
          localStorage.getItem("bixChat_selectedTempChatroom")
        );

        let xId = nanoid()
        setTempNewMsgID(xId)

        let tempChatroom = [{
          chatroom_name: selectedChatData.chatroom_name,
          indexdb_chatroom_status: "sending",
          last_activity_at: moment().format(),
          last_message: {},
          last_message_at: moment().format(),
          members_list: [],
          profile_email: selectedChatData.profile_email,
          profile_headline: selectedChatData.profile_headline,
          profile_image: selectedChatData.profile_image || "",
          profile_name: selectedChatData.profile_name,
          temp_id: xId,
          _cls: "ChatRoom.PrivateChatRoom",
          _id: { "$oid": xId },
        }]


        let tempMessage = {
          attachments: attachments_with_linksX,
          attachments_file_names: attachmentsX,
          attachments_with_links: attachments_with_linksX,
          deleted_users: [],
          indexdb_status: "sending",
          is_threaded: true,
          message_body: message,
          message_subject: subject,
          reactions: [],
          read_users: [],
          sender_name: loggedUserData?.full_name,
          sender: [{ $oid: localStorage.getItem("bixChat_loggedUserId") }],
          sender_public_id: localStorage.getItem(
            "bixChat_loggedUserPublicId"
          ),
          temp_id: tempId,
          timestamp: { $date: Date.now() },
          forwardInfo: "",
          thread_id
        };


        let tempThreadX = [tempMessage]

        let updatedThreadX = {}

        let dateP = moment().format("YYYY-MM-DD")
        updatedThreadX[dateP] = [tempThreadX]


        let tempChatroomDataX = {
          chatroomData: tempChatroom[0],
          threads: updatedThreadX
        }

        const db2 = await openDB("bixChat-SelectedChatrooms", 1);

        if (db2) {
          db2
            .put("loadedThreads", tempChatroomDataX, xId)
            .then((e) => {
              getSelectedChatFromIDB(xId);

              setTempChatroomStatus(true)


            })
            .catch((err) => {
            });
          db2.close();
        }



        // get IDB
        const db1 = await openDB("bixChat-AllChatRooms", 1);

        if (db1) {
          db1.getAll("chatrooms").then((e) => {
            let chatroomsFromIDB = e[0]

            let updatedChatroom = tempChatroom.concat(chatroomsFromIDB)

            db1
              .put("chatrooms", updatedChatroom, "chatrooms")
              .then((e) => {

                setsearchInput("")
                filterChatrooms("reset");
                searchUsers("reset");


                getIDBChatRooms();
              })
              .catch((err) => {
              });
            db1.close();


          });
        }

      } else {
        // get Related IDB
        const db2 = await openDB("bixChat-SelectedChatrooms", 1);


        if (thread_id) {
          if (db2) {
            db2.get("loadedThreads", chatroomX_id).then(async (data) => {
              let allThreads = data.threads || {};
              console.log("data", data);
              let tempMessage = {
                attachments: attachments_with_linksX,
                attachments_file_names: attachmentsX,
                attachments_with_links: attachments_with_linksX,
                deleted_users: [],
                indexdb_status: "sending",
                is_threaded: true,
                message_body: message,
                message_subject: subject,
                reactions: [],
                read_users: [],
                sender_name: loggedUserData?.full_name,
                sender: [{ $oid: localStorage.getItem("bixChat_loggedUserId") }],
                sender_public_id: localStorage.getItem(
                  "bixChat_loggedUserPublicId"
                ),
                temp_id: tempId,
                timestamp: { $date: Date.now() },
                forwardInfo: "",
                thread_id
              };

              let oldSelectedReply = selectedReply

              oldSelectedReply.push(tempMessage)
              let lastKey = Object.keys(allThreads)[Object.keys(allThreads).length - 1]
              allThreads[lastKey].push(oldSelectedReply)

              let updatedChatroomData = {
                chatroomData: data.chatroomData,
                threads: allThreads,
              };

              db2
                .put("loadedThreads", updatedChatroomData, chatroomX_id)
                .then((e) => {
                  getSelectedChatFromIDB(chatroomX_id);

                })
                .catch((err) => {
                });
              db2.close();
            })
          }
        } else {
          if (attachments_with_linksX.length < 0) {
            db2 = await openDB("bixChat-SelectedChatrooms", 1);
            if (db2) {
              db2.get("loadedThreads", chatroomX_id).then((data) => {
                let allThreads = data?.threads || {};
                console.log(data, "data");
                let tempMessage = {
                  attachments: attachments_with_linksX,
                  attachments_file_names: attachmentsX,
                  attachments_with_links: attachments_with_linksX,
                  deleted_users: [],
                  indexdb_status: "sending",
                  is_threaded: false,
                  message_body: message,
                  message_subject: subject,
                  reactions: [],
                  read_users: [],
                  sender_name: loggedUserData?.full_name,
                  sender: [{ $oid: localStorage.getItem("bixChat_loggedUserId") }],
                  sender_public_id: localStorage.getItem(
                    "bixChat_loggedUserPublicId"
                  ),
                  temp_id: tempId,
                  timestamp: { $date: Date.now() },
                  forwardInfo: ""
                };
                let firstThread = [];
                firstThread?.push(tempMessage);
                let lastKey = Object.keys(allThreads)[Object.keys(allThreads).length - 1]
                allThreads[lastKey].push(firstThread)

                let updatedChatroomData = {
                  chatroomData: data?.chatroomData,
                  threads: allThreads,
                };

                db2
                  .put("loadedThreads", updatedChatroomData, chatroomX_id)
                  .then((e) => {
                    getSelectedChatFromIDB(chatroomX_id);

                  })
                  .catch((err) => {
                  });
                db2.close();
              });
            }
          } else {
            if (db2) {
              db2.get("loadedThreads", chatroomX_id).then((data) => {
                let allThreads = data?.threads || {};
                let tempMessage = {
                  attachments: attachments_with_linksX,
                  attachments_file_names: attachmentsX,
                  attachments_with_links: attachments_with_linksX,
                  deleted_users: [],
                  indexdb_status: "sending",
                  is_threaded: false,
                  message_body: message,
                  message_subject: subject,
                  reactions: [],
                  read_users: [],
                  sender_name: loggedUserData?.full_name,
                  sender: [{ $oid: localStorage.getItem("bixChat_loggedUserId") }],
                  sender_public_id: localStorage.getItem(
                    "bixChat_loggedUserPublicId"
                  ),
                  temp_id: tempId,
                  timestamp: { $date: Date.now() },
                  forwardInfo: ""
                };
                let firstThread = [];
                firstThread?.push(tempMessage);
                let lastKey = Object.keys(allThreads)[Object.keys(allThreads)?.length - 1]
                allThreads[lastKey]?.push(firstThread);

                let updatedChatroomData = {
                  chatroomData: data?.chatroomData,
                  threads: allThreads,
                };

                db2
                  .put("loadedThreads", updatedChatroomData, chatroomX_id)
                  .then((e) => {
                    getSelectedChatFromIDB(chatroomX_id);

                  })
                  .catch((err) => {
                  });
                db2.close();
              });
            }
          }

        }
      }

      if (!isOnline) {
        localStorage.setItem("bixOffilne", true)
        let message = {
          msg: msg,
          sub: sub,
          rec: rec,
          files: files,
          tempId: tempId,
          threadId: threadId,
          fromCompose: fromCompose,
          isForwarded: isForwarded,
          sendFiles: sendFiles,
          chatroomId: chatroomId
        }
        localStorage.setItem("bix_sendingMessage", JSON.stringify(message))

      }


      // Setup Logics to Server

      thread_id = threadId !== null ? threadId : null;
      chatroom_id = chatroomId;
      let attachments = [];

      if (files.length > 0) {
        attachments.push(files);
      }

      const formData = new FormData();
      formData.append("thread_id", thread_id);
      formData.append("chatroom_id", chatroom_id);
      formData.append("message_body", message);
      formData.append("message_subject", subject);
      formData.append("temp_id", "null");
      console.log(sendFiles, "sendFiles.length");
      console.log(localStorage?.getItem("bixChat_accessToken"), "access");

      if (sendFiles.length > 0) {
        for (let i = 0; i < sendFiles.length; i++) {
          formData.append("file_info", JSON.stringify(sendFiles[i]));
        }
      }
      if (isForwarded) {
        formData.append("forward", true);
      }

      if (sendMessageModal) {
        setSendMessageModal(false)
      }
      console.log("thread_id", thread_id);
      console.log("chatroom_id", chatroom_id);
      console.log("message_body", message);
      console.log("message_subject", subject);
      console.log("temp_id", "null");
      setIsChatSelected(true)

      axios({
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
        url: "sendmessage",
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async (response) => {
          if (response.status === 200) {
            setReplySending(false)
            localStorage.setItem("bixOffilne", false)
            localStorage.setItem("bix_sendingMessage", "")
            let foundContact = false
            for (let i = 0; i < primaryContacts.length; i++) {
              if (primaryContacts[i]._id["$oid"] === response.data.chatroom_id) {
                foundContact = true
                break
              } else {
                foundContact = false
              }
            }
            setSavedChatsSelected(foundContact)
            let selectedMessage = JSON.parse(
              localStorage.getItem("bixChat_selectedChatMessage")
            );
            localStorage.setItem("bixChat_selectedChatId", response.data.chatroom_id)
            setSelectedChatID(response.data.chatroom_id)
            if (windowDimensions.width > 600) {

              setRightSideBarStatus(true)
            }

            let type = "Business User"
            if (sendMessageType === "groupChat") {
              type = "Group"

            } else if (sendMessageType === "emailChat") {
              type = "External User"
            }
            if (response.status === 200) {
              mixpanel.track("Emails Sent", {
                "Receiver Type": type,
                "Attachments": sendFiles.length > 0 ? true : false,
                "Subject": subject !== "" ? true : false,
                "Email Word Count": countWords(message.replace(/(<([^>]+)>)/ig, ''))
              })
              if (sendFiles.length > 0) {
                mixpanel.track("File Transferred", {
                  "Sender Type": type,
                  "Number of Files": sendFiles.length,
                  "Subject": subject !== "" ? true : false,
                  "Text Word Count": countWords(message.replace(/(<([^>]+)>)/ig, '')),
                  "Text Avaliable": message !== "" ? true : false
                })
              }
            }
            if (sendMessageType === "newUser") {
              // Remove Temp Chtrooms
              const db2 = await openDB("bixChat-SelectedChatrooms", 1);
              if (db2) {
                if (tempNewMsgID) {
                  db2.delete("loadedThreads", tempNewMsgID)
                }
              }

              getAllChatroom().then(async (e) => {
                let recentChatroom = e.data.chatrooms[0]

                await loadSelectedchat(recentChatroom, "message_sent");

                let recentChatroomId = recentChatroom._id["$oid"]

                localStorage.setItem("bixChat_selectedChatId", recentChatroomId)
                setSendMessageType("oldUser");
                setTempChatroomStatus(false)
              })
            } else if (sendMessageType === "oldUser") {
              await loadSelectedchat(selectedMessage, "message_sent");
              getAllChatroom();
            } else if (sendMessageType === "groupChat") {
              await loadSelectedchat(selectedMessage, "message_sent");
              getAllChatroom();
              // mixpanel.track('message sent to group')
              // mixpanel.track('words per message in group', {
              //   count: msg.split(" ").length
              // })
            } else if (sendMessageType === "emailChat") {
              await loadSelectedchat(selectedMessage, "message_sent");
              getAllChatroom();
            } else if (sendMessageType === "multiUser") {
              setTempChatroomStatus(false)
              getAllChatroom().then(async (e) => {
                let recentChatroom = e.data.chatrooms[0]
                let allChats = await loadSelectedchat(recentChatroom, "message_sent");

                let x = {
                  chatroomData: recentChatroom,
                  threads: allChats.data.threads,
                };
                setSelectedChatroomDatas(x);
                setSendMessageType("oldUser")
              })
            }

            setSendingMultipleMsg(false)

            if (sendMessageModal) {
              setSendMessageModal(false)
            }

            if (thread_id !== null) {
              getThread(thread_id);
            }

            setMessageStatus("")
            setsearchInput("")
            setFilteredUsers([]);
            setForwardFilteredUsers([])
            setForwadLoading(false)
            setNewMessageFromSearch(false)
            sendFiles = []
            if (files.length > 0) {
              getAttachments()
            }
            if (fromCompose === true) {
              // mixpanel.track('Compose mail and send' + loggedUserData.domain)
            }





          }

        }
        )
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    sendMessageToServer(
                      msg,
                      sub,
                      rec,
                      files,
                      tempId,
                      threadId,
                      fromCompose,
                      isForwarded,
                      sendFiles,
                      chatroomId,
                    )
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  sendMessageToServer(
                    msg,
                    sub,
                    rec,
                    files,
                    tempId,
                    threadId,
                    fromCompose,
                    isForwarded,
                    sendFiles,
                    chatroomId,
                  )
                }
              })
            }

          }
          setSendingInProgress(false);
        });
    })
  };
  const sendHiMessage = (chatroomId) => {
    const formData = new FormData();
    formData.append("thread_id", null);
    formData.append("chatroom_id", chatroomId);
    formData.append("message_body", "Hi");
    formData.append("message_subject", "");
    formData.append("temp_id", "null");
    axios({
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "sendmessage",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        await getAllChatroom();
        setTimeout(() => {
          setSendModal(false)
          setSendLoading(false)
          setIsChatSelected(false)
          setSelectedChat()
        }, 100);
        setSendLoading(false)
      })
      .catch(async (error) => {
        setSendModal(false)

      });

  }
  // When Click Users Opening a Temp chat

  const openNewChat = async (e) => {
    const db1 = await openDB("bixChat-AllChatRooms", 1);
    setSelectedChatID(e._id["$oid"])

    if (db1) {
      db1.getAll("chatrooms").then((ex) => {

        let chtroomsX = ex[0]
        let publicIdAndChtroom = []
        let publicIds = []

        chtroomsX.map((singleChtoomX) => {

          if (singleChtoomX.profile_public_id) {

            publicIdAndChtroom.push({
              publicId: singleChtoomX.profile_public_id,
              chtroomId: singleChtoomX._id["$oid"],
              chtroomDetails: singleChtoomX
            })
            publicIds.push(singleChtoomX.profile_public_id)

          }
          return null

        })

        let relatedChatroom = {}
        publicIdAndChtroom.map((singlePub) => {
          if (singlePub.publicId.includes(e.profile_public_id)) {
            relatedChatroom = singlePub.chtroomDetails
          }
          return null
        })

        if (publicIds.includes(e.profile_public_id)) {
          setsearchInput("")
          filterChatrooms("reset");
          searchUsers("reset");
          chatSelected(relatedChatroom, "chat_selected");
        } else {
          setSendingMessages([]);
          setSentTempMessages([]);
          setIsChatSelected(true);
          setSelectedChat(e);

          localStorage.setItem("bixChat_selectedTempChatroom", JSON.stringify(e))

          let x = {
            chatroomData: e,
            threads: [],
          };

          setSelectedChatroomDatas(x);
        }

      });
    }
  };

  // Create New Group
  const createNewGroup = (name, image) => {
    return new Promise((resolve, reject) => {
      let url = "chatroom"
      let data = {
        group_title: name,
        group_image_encoded:
          image !== null ? image.split("base64,")[1] : "null",
        temp_id: "null",
      }
      let options = {
        headers: {
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        body: data
      }
      api.postCreate(url, options)
        .then((response) => {
          if (response.status === 200) {
            addMembersToTheGroup(response.data.id).then(() => {
              resolve("group create Process done")
              // mixpanel.track('new group created')
            })
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    createNewGroup(name, image)
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  createNewGroup(name, image)
                }
              })
            }

          }
        });
    })
  };

  // Add Members to the Group
  const addMembersToTheGroup = async (chatroomId) => {
    await groupServices().handleAddMembersToGroup(
      getAllChatroom,
      setGroupCreating,
      setCenterModalVisibility,
      newGroupMembers, chatroomId)
  };

  // Scroll Reply
  const scollReply = () => {
    let objDiv = document.getElementById("reply-message-container");

    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  // get All thread of Current thread
  const getThread = (thread_id) => {
    messageServices().handleGetThread(thread_id, setSelectedReply, setCurrentMessage, scollReply, setReplySending)
  };

  // React Message
  const reactMessage = (emoji, message_id, chatroom_id) => {
    messageServices().handleReactMessages(emoji, message_id, chatroom_id, loggedUserData, getSelectedChatFromIDB, chatSelected)
  };

  const updateGroupName = (chatroomId, name) => {
    groupServices().handleGroupNameUpdate(chatroomId, name, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setIsGroupNameChanging)

  };

  const updateGroupEmail = (chatroomId, groupEmail) => {
    groupServices().handleGroupEmailUpdate(chatroomId, groupEmail, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setIsGroupEmailChanging)

  };



  const updateGroupDP = (e) => {
    groupServices().handleGroupDPUpdate(e, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas)


  };
  const updateGroupDescription = (chatroomId, description) => {
    groupServices().handleGroupDescriptionUpdate(chatroomId, description, selectedChatroomDatas, setSelectedChatroomDatas, setIsGroupDescriptionChanging)

  };
  const deleteGroup = (chatroomId) => {
    let url = `chatroom/delete-group/${chatroomId}`
    let options = {
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      }
    }
    api.postCreate(url, options)
      .then((response) => {
        if (response.status === 200) {
          groupServices().handleDeleteGroup(response, setIsDeleting, getAllChatroom, chatSelected, selectedChat, setCenterModalVisibility)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  deleteGroup(chatroomId)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                deleteGroup(chatroomId)
              }
            })
          }

        }
      });
  };
  const addModerator = (chatroomId, public_id) => {
    let url = `chatroom/addmoderators/${chatroomId}`
    let options = {
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
      body: {
        members: [
          { public_id: public_id }
        ]
      }
    }
    api.postCreate(url, options)
      .then((response) => {
        if (response.status === 200) {
          groupServices()
            .handleModerators(response, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setSelectedUser, setIsLoading)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  addModerator(chatroomId, public_id)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                addModerator(chatroomId, public_id)
              }
            })
          }

        }
      });
  };
  const deleteGroupMember = (chatroomId, public_id) => {
    let url = `chatroom/delete-group-members/${chatroomId}`
    let options = {
      body: {
        members: [
          { public_id: public_id }
        ]
      },
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
    }
    api.postCreate(url, options)
      .then((response) => {
        if (response.status === 200) {
          groupServices().handleDeletGroupMember(response, setIsDeleting, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setCenterModalVisibility, setSelectedUser)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  deleteGroupMember(chatroomId, public_id)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                deleteGroupMember(chatroomId, public_id)
              }
            })
          }

        }
      });
  };
  const leaveGroup = (chatroomId, adminId) => {
    let url = `chatroom/leave-group/${chatroomId}`
    let options = {
      body: {
        public_id: adminId

      },
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
    }
    api.postCreate(url, options)
      .then((response) => {
        if (response.status === 200) {
          groupServices().handleLeaveGroup(response, setIsDeleting, getAllChatroom, selectedChatroomDatas, chatSelected, selectedChat, setSelectedChatroomDatas, setCenterModalVisibility, setAdminModalVisibility)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  leaveGroup(chatroomId, adminId)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                leaveGroup(chatroomId, adminId)
              }
            })
          }

        }
      });
  };

  const removeModerator = (chatroomId, public_id) => {
    let url = `chatroom/removemoderators/${chatroomId}`
    let options = {
      body: {
        members: [
          { public_id: public_id }
        ]
      },
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
    }
    api.postCreate(url, options)
      .then((response) => {
        if (response.status === 200) {
          groupServices().handleRemoveModerator(response, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setSelectedUser, setIsLoading)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  removeModerator(chatroomId, public_id)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                removeModerator(chatroomId, public_id)
              }
            })
          }

        }
      });
  };


  const findChatroom = (e) => {
    let chatRoom = "";
    allChatrooms.map((chatroom, i) => {
      if (chatroom._cls === "ChatRoom.PrivateChatRoom") {

        chatroom.members_list.map((item) => {
          if (item.public_id === e.public_id) {

            chatRoom = chatroom
            return null

          }
        })
      }

    })
    if (chatRoom !== "") {
      localStorage.setItem(
        "bixChat_selectedChatId",
        chatRoom._id["$oid"]
      );
      setSendMessageType("oldUser");
      chatSelected(chatRoom, "chat_selected");
    } else {

      let x = {
        profile_image: e.profile_image
          ? e.profile_image
          : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png",
        group_title:
          e.full_name || e.email.split("@")[0],
        members: [e._id],
        members_list: [e],
        messagesData: [],
        threads: [],
        _cls: "ChatRoom.PrivateChatRoom",
        _id: e._id,
        isNew: true,
      };

      setSendMessageType("newUser");

      openNewChat(x);
    }


  }
  const sendHiForSingleUser = (e) => {
    setMessageTag(true)
    let chatRoom = "";
    allChatrooms.map((chatroom, i) => {
      if (chatroom._cls === "ChatRoom.PrivateChatRoom") {

        chatroom.members_list.map((item) => {
          if (item.email === e) {

            chatRoom = chatroom
            return null

          }
        })
      }
    })
    if (chatRoom !== "") {
      localStorage.setItem(
        "bixChat_selectedChatId",
        chatRoom._id["$oid"]
      );
      setSendMessageType("oldUser");
      chatSelected(chatRoom, "chat_selected");
    } else {
      let x = {
        profile_image: e.profile_image
          ? e.profile_image
          : "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png",
        group_title:
          e.full_name || e.email.split("@")[0],
        members: [e._id],
        members_list: [e],
        messagesData: [],
        threads: [],
        _cls: "ChatRoom.PrivateChatRoom",
        _id: e._id,
        isNew: true,
      };
      setSendMessageType("newUser");
      openNewChat(x);
    }


  }
  const getMedia = () => {
    let chatroomId = localStorage.getItem("bixChat_selectedChatId");
    let url = `multimedia/${chatroomId}`
    let options = {
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      }
    }
    api
      .get(url, options)
      .then((response) => {
        if (response.status === 200) {
          fileServices().handleMedia(response, setMediaList, setMediaListLoading)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  getMedia()
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                getMedia()
              }
            })
          }

        }
      });
  };
  const getAttachments = () => {
    let chatroomId = localStorage.getItem("bixChat_selectedChatId");
    const options = {
      url: `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/chat/attachments/${chatroomId}/`,
      method: 'GET',
      headers: {
        'x-access-token': localStorage?.getItem("bixChat_accessToken"),
      },
    };
    axios(options)
      .then((response) => {
        console.log(response.data.message);
        if (response.status === 200) {
          fileServices()
            .handleAttachments(response, setAttachmentList, setAttachmentListLoading)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  getAttachments()
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                getAttachments()
              }
            })
          }

        }
      });
  };


  const sendPeriodicallyActions = ((action) => {



    let data = {
      user_id: localStorage.getItem("bixChat_loggedUserPublicId") || "",
      action: action,
      email: localStorage.getItem("bixChat_email"),
    }

    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        baseURL: `https://api.bixchat.xyz/analytics/record`,
        data: data

      })
        .then((response) => {

          resolve(response)
        })
        .catch((error) => {
          console.log(error);

          reject(error)
        });
    })
  })


  const leftNavPopupHandler = ((e) => {
    if (e === "closeOnly") {
      setLeftNavPopup("none");
    } else {
      if (leftNavPopup === "none") {
        setLeftNavPopup("block");
      } else {
        setLeftNavPopup("none");
      }
    }
  })


  const imageUploadPopupHandler = ((e) => {
    if (e === "closeOnly") {
      setImageUploadPopup("none");
    } else {
      if (imageUploadPopup === "none") {
        setImageUploadPopup("block");
      } else {
        setImageUploadPopup("none");
      }
    }
  })



  const RightNavPopupHandler = ((e) => {
    if (e === "closeOnly") {
      setRightNavPopup("none");
    } else {
      if (rightNavPopup === "none") {
        setRightNavPopup("block");
      } else {
        setRightNavPopup("none");
      }
    }
  })

  const UploadModalHandler = ((e) => {
    if (e === "closeOnly") {
      setuploadImgModal("none");
    } else {
      if (uploadImgModal === "none") {
        setuploadImgModal("block");
      } else {
        setuploadImgModal("none");
      }
    }
  })

  const contactSideBarHandler = ((e) => {
    if (e === "closeOnly") {
      setContactModal(false);
    } else {
      if (contactModal === "none") {
        setContactModal("block");
      } else {
        setContactModal("none");
      }
    }
  })


  const unsubscribe = () => {
    return new Promise((resolve, reject) => {
      let url = `user/unsubscribe`
      let options = {
        headers: {
          "x-access-token": localStorage?.getItem("bixChat_accessToken")
        }
      }
      api.get(url, options)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    })
  };

  const mobileUnsubscribe = () => {
    return new Promise((resolve, reject) => {
      let url = `user/mobile_unsubscribe`
      let options = {
        headers: {
          "x-access-token": localStorage?.getItem("bixChat_accessToken")
        },
      }
      api.get(url, options)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        });
    })
  };
  const unsubscription = async () => {

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(function (reg) {
        reg.pushManager.getSubscription().then(function (subscription) {
          subscription.unsubscribe().then(function (successful) {
          }).catch(function (e) {
            // Unsubscription failed
            console.log('operation error');
          })
        })
      });
    }
  }

  // Logout User
  const logoutUser = async () => {
    let users = JSON.parse(localStorage.getItem("bixChat_users") || "[]");
    if (users.length === 1) {
      setRightSideBarStatus(false)
      setSelectedSender();
      setSelectedChat();
      setFilteredUsers([]);
      setForwardFilteredUsers([])
      setIsChatSelected(false);
      setsearchInput("")
      setSendMessageModal(false)
      setAllChatroomsLoaded(false);
      setLeftNavPopup("none");
      if (window.screen.width < 1200) {
        mobileUnsubscribe().then((e) => {
        })
      }
      const db1 = await openDB("bixChat-AllChatRooms", 1);
      const db2 = await openDB("bixChat-SelectedChatrooms", 1);

      db1.clear("chatrooms");
      db2.clear("loadedThreads");


      if (ws.readyState === WebSocket.OPEN) {

        ws.send(
          JSON.stringify({
            action: "logout",
            publicID: localStorage.getItem("bixChat_loggedUserPublicId"),
          })
        );
      }
      localStorage.clear()
    }

  };

  const setToken = (u_id) => {
    return new Promise((resolve, reject) => {
      let data = {
        u_id: u_id
      }
      axios({
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
        url: `user_get_token`,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: data

      })
        .then((response) => {
          localStorage.setItem(
            "bixChat_loggedUserPublicId",
            response.data.public_id
          );
          localStorage.setItem(
            "bixChat_accessToken",
            response.data.x_access_token
          );
          localStorage.setItem("bixChat_isLogged", true);
          localStorage.setItem("bixChat_mobileView", 1);
          localStorage.setItem("bixChat_email", response.data.email);
          setAccountType(response.data.email)

          let fullname = response.data.first_name + " " + response.data.last_name
          localStorage.setItem("bixChat_fullname", fullname);
          let image = response.data.profile_image === null || response.data.profile_image === 'null' ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : response.data.profile_image
          localStorage.setItem("bixChat_profileImage", image)
          getAllChatroom();
          setUsersToLocalStorage(response.data.public_id, response.data.x_access_token, fullname, response.data.email, image)
          if (config.environment === "development") {
            setUsersToLocalStorageDev(response.data.public_id, response.data.x_access_token, fullname, response.data.email, image, response.data.refresh_token)
          }
          history.push({
            pathname: "/",
          });


          resolve(response);
        })
        .catch((error) => {
          localStorage.clear()
          if (config.environment === "production") {
            let url = `${config.apiEndpoints.protocol}${config.domain.auth}${config.emailText}`;
            window.location.href = url;
          } else {
            history.push({
              pathname: "/login",
            });
          }
          reject(error);
        })

    });
  };


  const sendGoogleId = (id) => {

    let data = {
      key: id
    }
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
      method: "put",
      url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}/set-google-key`,
      data: data


    })
      .then((response) => {


      })
      .catch((error) => {
        console.log(error)
      });

  };





  // switch account
  const switchAccount = async (publicId, accessToken, email, fullname, image, role, refresh_token) => {
    if (sendMessageModal) {
      setSendMessageModal(false)
    }
    setsearchInput("")
    setFilteredUsers([])
    localStorage.setItem(
      "bixChat_loggedUserPublicId", publicId
    );
    localStorage.setItem(
      "bixChat_accessToken", accessToken
    );
    if (config.environment === "development") {
      localStorage.setItem("bixChat_refreshToken", refresh_token)
    }
    setRightSideBarStatus(false)
    setIsChatSelected(false);
    localStorage.setItem("bixChat_isLogged", true);
    localStorage.setItem("bixChat_mobileView", 1);
    localStorage.setItem("bixChat_email", email);
    localStorage.setItem("bixChat_fullname", fullname);
    localStorage.setItem("bixChat_selectedChatId", "")
    localStorage.setItem("bixChat_selectedChatMessage", "")
    localStorage.setItem("bixChat_profileImage", image)
    localStorage.setItem("bixChat_role", role)
    setAccountType(email)
    getDomain()
    getDomainContacts()
    await getProfile()
    await getAllChatroom();
    setIsSwitching(false)
    ws.send(
      JSON.stringify({
        action: "login",
        publicID: publicId,
        userEmail: email,
      })
    );

  }
  //switch account when logout
  const switchAccountLogout = async (publicId) => {
    let users = JSON.parse(localStorage.getItem("bixChat_users"));
    if (users.length > 1) {
      if (config.environment !== "development") {
        let cookieUsers = CookieService.get("users")
        let newLoggedUsers = cookieUsers.filter(el => { return el.public_id !== publicId })
        newLoggedUsers[0].isLogged = true
        const options = { path: "/", domain: config.cookieDomain }
        CookieService.set("users", newLoggedUsers, options)
      }
      let newUsers = users.filter(user => { return user.publicId !== publicId })
      localStorage.setItem("bixChat_users", JSON.stringify(newUsers));
      users = newUsers
      setIsChatSelected(false);
      setSelectedChat()
      setSelectedChatroom("")
      setSelectedChatroomId("")
      setSelectedReply([])
      const db = await openDB("bixChat-SelectedChatrooms", 1);
      db.clear("loadedThreads");
      localStorage.setItem("bixChat_selectedChatId", "")
      localStorage.setItem("bixChat_selectedChatMessage", "")
      switchAccount(users[0].public_id, users[0].accessToken, users[0].email, users[0].fullName, users[0].profileImage, users[0].role, users[0].refresh_token)
      setIsChatSelected(false);
    } else {
      let cookieUsersL = CookieService.get("users")
      console.log(cookieUsersL, "cookieUsersL");
      logoutUser()
      if (config.environment !== "development") {
        logoutService().logoutRedirection()
      } else {
        history.push({
          pathname: "/login",
        });
      }

    }

  }
  //get Domain
  const getDomain = () => {
    let url = `domain`
    let options = {
      headers: {
        "x-access-token": localStorage.getItem('bixChat_accessToken')
      }
    }
    api.get(url, options)
      .then(response => {
        if (response.status === 200) {
          domainServices().handleDomainName(response, setDomainName)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  getDomain()
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                getDomain()
              }
            })
          }

        }
      });
  };
  const getFirstChatroomLength = (chatroomID) => {
    return new Promise(async (resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        method: "get",
        url: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer
          }/thread-count/${chatroomID}`,
      }).then(async (response) => {

        if (response.status === 200) {
          let count = response.data.thread_count;
          resolve(count)
        }

      }).catch((err) => {

        if (err.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  getFirstChatroomLength(chatroomID)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                getFirstChatroomLength(chatroomID)
              }
            })
          }

        }

      })
    })
  }


  const getData = () => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixChat_accessToken');
      const options = {
        url: `${url}?root=True`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          // "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          if (response.status === 200) {
            let file = response.data || [];
            let folder = [];
            let files = [];

            file?.map((value, index) => {
              if (value.extention === 'null') {
                folder.push(value);
              } else {
                files.push(value);

              }
              return null;
            });

            setDataFolders(folder);
            setDataFiles(files)

          }

          resolve(response);
        })
        .catch(error => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    getData()
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  getData()
                }
              })
            }

          }
        });
    });
  };

  const getSubFolderData = e => {
    let folderId = e ? e : localStorage.getItem('bixCloud-selectedFolderId');

    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixChat_accessToken');

      const options = {
        url: `${url}?folder=${folderId}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          // "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          if (response.status === 200) {
            let file = response.data;
            let folder = [];
            let files = [];

            file.map((value, index) => {
              if (value.extention === 'null') {
                folder.push(value);
              } else {
                files.push(value);
              }
              return null;
            });

            setDataFiles(files);
            setDataSubFolders(folder);
          }

          resolve(response);
        })
        .catch(error => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    getSubFolderData(e)
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  getSubFolderData(e)
                }
              })
            }

          }
        });
    });

    setLoadData(0);
  };

  const showContactsNav = (state) => {
    let w = window.innerWidth;
    // document.getElementById('contacts').style.transition="all 0.5s ease-in"
    if (state === 'open' && document.getElementById('contacts')) {

      if (w < 1200) {
        document.getElementById('contacts').style.width = "30%"

      }
      if (w < 600) {
        document.getElementById('contacts').style.width = "100%"
      }
      else if (w > 1440) {
        if (w > 1921) {
          document.getElementById('contacts').style.width = "18%"
          document.getElementById('contacts').style.minWidth = "300px"
        }
        else if (w > 1695) {
          document.getElementById('contacts').style.width = "19.5%"
          document.getElementById('contacts').style.minWidth = "300px"
        } else {
          document.getElementById('contacts').style.width = "22.5%"
          document.getElementById('contacts').style.minWidth = "300px"
        }
      }


      document.getElementById('contacts').style.visibility = "visible"


    }
    else if (state === 'close' && document.getElementById('contacts')) {
      document.getElementById('contacts').style.visibility = "hidden"
      document.getElementById('contacts').style.width = "0"
      document.getElementById('contacts').style.minWidth = "0"

    }

  }

  const sendCcMessageToServer = (
    message_body, message_subject, receiver, ccReceiver, sendFiles) => {
    const formData = new FormData();
    formData.append("thread_id", "null");
    formData.append("chatroom_id", "null");
    formData.append("message_body", message_body);
    formData.append("message_subject", message_subject);
    formData.append("temp_id", "null");

    if (receiver) {
      for (let i = 0; i < receiver?.length; i++) {
        formData.append("receivers", [receiver[i].toLowerCase()]);
      }
    }
    if (ccReceiver) {
      for (let i = 0; i < ccReceiver?.length; i++) {
        formData.append("cc", [ccReceiver[i].toLowerCase()]);
      }
    }
    if (sendFiles.length > 0) {
      for (let i = 0; i < sendFiles.length; i++) {
        formData.append("file_info", JSON.stringify(sendFiles[i]));
      }
    }
    if (sendMessageModal) {
      setSendMessageModal(false)
    }

    setIsChatSelected(true)

    axios({
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "cc",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*"
        ,
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        "Content-Type": "multipart/form-data",
      },

    })
      .then(async (response) => {

        console.log(response, "hellooooo its working")
        sendFiles = []
        getAllChatroom()


        // let selectedMessage = JSON.parse(
        //   localStorage.getItem("bixChat_selectedChatMessage")
        // );

        // localStorage.setItem("bixChat_selectedChatId", response.data.chatroom_id)

        // if (sendMessageType === "newUser") {

        //   // Remove Temp Chtrooms
        //   const db2 = await openDB("bixChat-SelectedChatrooms", 1);


        //   if (db2) {
        //     if (tempNewMsgID) {
        //       db2.delete("loadedThreads", tempNewMsgID)
        //     }
        //   }

        //   getAllChatroom().then((e) => {
        //     let recentChatroom = e.data[0]
        //     loadSelectedchat(recentChatroom, "message_sent");

        //     let recentChatroomId = recentChatroom._id["$oid"]

        //     localStorage.setItem("bixChat_selectedChatId", recentChatroomId)
        //     setSendMessageType("oldUser");
        //     setTempChatroomStatus(false)
        //   })




        // } else if (sendMessageType === "oldUser") {
        //   loadSelectedchat(selectedMessage, "message_sent");
        //   getAllChatroom();
        // } else if (sendMessageType === "groupChat") {
        //   loadSelectedchat(selectedMessage, "message_sent");
        //   getAllChatroom();
        // } else if (sendMessageType === "emailChat") {
        //   loadSelectedchat(selectedMessage, "message_sent");
        //   getAllChatroom();
        // } else if (sendMessageType === "multiUser") {
        //   setTempChatroomStatus(false)
        //   getAllChatroom().then(async (e) => {
        //     let recentChatroom = e.data[0]

        //     // console.log(recentChatroom, "recentChatroom")
        //     // selectedChatroomDatas(recentChatroom)

        //     let allChats = await loadSelectedchat(recentChatroom, "message_sent");

        //     let x = {
        //       chatroomData: recentChatroom,
        //       threads: allChats.data.threads,
        //     };

        //     setSelectedChatroomDatas(x);


        //     setSendMessageType("oldUser")
        //   })

        // }

        // setSendingMultipleMsg(false)

        // if (sendMessageModal) {
        //   setSendMessageModal(false)
        // }

        // if (thread_id !== null) {
        //   getThread(thread_id);
        // }

        // setsearchInput("")
        // setFilteredUsers([]);
        // setForwardFilteredUsers([])

      })
      .catch(async (error) => {
        setSendingInProgress(false);
      });

  }
  const sendReportMessage = (reportDescription) => {
    let data = {
      "reporter_email": reportEmail,
      "message_id": threadIdNum,
      "description": reportDescription,
    }
    let url = "report"
    let options = {
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
      },
      body: data
    }
    api.postCreate(url, options)
      .then(async (response) => {
        if (response.status === 200) {
          messageServices().handleReportMessage(response, getAllChatroom)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  sendReportMessage(reportDescription)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                sendReportMessage(reportDescription)
              }
            })
          }

        }
      });

  }

  const sendSignatureData = (signatureValue) => {
    console.log(signatureValue, "ffff")
    let data = {
      "signature": "ssx",

    }

    const formData = new FormData();
    formData.append("signature", signatureValue);

    axios({
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
      url: "email-signature",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*"
        ,
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        "Content-Type": "multipart/form-data",
      },

    })

      .then(async (response) => {
        console.log(response)
      })
      .catch(async (error) => {
        console.log(error)
      });

  }




  const sendContract = (
    country,
    name,
    phone,
    address,
    organization,
    regNo,
    designation,
    nic,
    need_new_domain,
    need_dns_help,
    domain,
    email

  ) => {
    let data = {
      "agreement_version": "v1.2",
      "jurisdiction": country,
      "name": name,
      "email": email,
      "contact_number": phone,
      "address": address,
      "nic_no": nic,
      "organization_name": organization,
      "registration_number": regNo,
      "designation": designation,
      "need_new_domain": need_new_domain === true ? "true" : "false",
      "need_dns_help": need_dns_help === true ? "true" : "false",
      "domain": domain === "" ? "null" : domain,
      "company_picture_encoded": localStorage.getItem("bix_business_logo") === null ? "null" : localStorage.getItem("bix_business_logo"),
    }
    return new Promise(async (resolve, reject) => {
      let url = "contract"
      let options = {
        headers: {
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        body: data
      }
      api.postCreate(url, options)
        .then(async (response) => {
          if (response.status === 200) {
            domainServices().handleContract(response, setContractLoading, setPageToShow)
            resolve(response)
          }
        })
        .catch((error) => {
          setContractLoading(false)
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    sendContract(
                      country,
                      name,
                      phone,
                      address,
                      organization,
                      regNo,
                      designation,
                      nic,
                      need_new_domain,
                      need_dns_help,
                      domain,
                      email

                    )
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  sendContract(
                    country,
                    name,
                    phone,
                    address,
                    organization,
                    regNo,
                    designation,
                    nic,
                    need_new_domain,
                    need_dns_help,
                    domain,
                    email

                  )
                }
              })
            }

          }
        });
    })

  }
  const logoutFunction = async () => {
    let users = JSON.parse(localStorage.getItem("bixChat_users"));
    if (users.length === 1) {
      logoutUser();
      await unsubscribe().then(() => {
        console.log("Desktop Unscribe");
        if (config.environment !== "development") {
          logoutService().logoutRedirection()
        } else {
          history.push({
            pathname: "/login",
          });
        }
      });
    } else if (users.length > 1) {
      let publicId = localStorage.getItem(
        "bixChat_loggedUserPublicId"
      )
      setIsSwitching(true)
      await switchAccountLogout(publicId)
    } else {
      if (config.environment !== "development") {
        localStorage.clear()
        logoutService().logoutRedirection()
      } else {
        history.push({
          pathname: "/login",
        });
      }


    }
  }
  const deleteUser = (password) => {
    setDeleteError(false)
    let data = {
      "username": localStorage.getItem("bixChat_email"),
      "password": password
    }
    let url = "user"
    let options = {
      headers: {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        'Content-Type': 'application/json'
      },
      body: data
    }
    api.deletedata(url, options)
      .then(async (response) => {
        if (response.status === 200) {
          userServices().handleDeleteUser(response, setIsModalVisible, setModalLoading, logoutFunction)
        }
      })
      .catch(async (error) => {
        setModalLoading(false)
        setDeleteError(true)
        if (error.response.status === 401) {
          if (config.environment !== "development") {
            getRefreshToken().then((rToken) => {
              tokenService().checkToken(rToken).then((res) => {
                if (res === "success") {
                  deleteUser(password)
                }
              })
            })
          } else {
            let refreshToken = localStorage.getItem("bixChat_refreshToken")
            tokenService().checkToken(refreshToken).then((res) => {
              if (res === "success") {
                deleteUser(password)
              }
            })
          }

        }
      });


  }
  const sendOTP = (email) => {
    setSendingOTP(true)
    localStorage.setItem("bix_business_OTP_verified", false)
    const data = {
      "email": email
    }
    let url = "email-verify"
    let options = {
      body: data
    }
    api.postCreate(url, options)
      .then((response) => {

        domainServices().handleSendOTP(setSendingOTP, setRunTimer)
      })
      .catch((error) => {
        setSendingOTP(false)
        setRunTimer(true)
        localStorage.setItem("bix_business_timer", true)
        console.log(error)
      });
  }
  const verifyOTP = (email, otp) => {
    const data = {
      "email_to_verify": email,
      "otp_from_client": otp
    }
    let url = "otp-verify"
    let options = {
      body: data
    }
    api.postCreate(url, options)
      .then((response) => {
        domainServices().handleVerifyOTP(response, setRunTimer, setOTPVerifying, setOTPVerified, setOTPError)
      })
      .catch((error) => {
        setOTPVerifying(false)
        setOTPError(true)
        console.log(error)
      });
  }
  const createNewChatroom = (recievers) => {
    return new Promise(async (resolve, reject) => {
      const data = {
        "receivers": recievers,
      }
      let url = "private-chatroom"
      let options = {
        headers: {
          "x-access-token": localStorage?.getItem("bixChat_accessToken")
        },
        body: data
      }
      api.postCreate(url, options)
        .then((response) => {
          console.log(response, "chaaat");
          if (response.status === 200) {
            resolve(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    createNewChatroom(recievers)
                  }
                })
              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  createNewChatroom(recievers)
                }
              })
            }

          }
        });
    }
    )
  }

  const CalenderEventMessage = async (msg, sub, recievers, ccrecievers, fileList, tempId, threadId, fromCompose,
    isForwarded, fromSendHi) => {
    let chatrooms = allChatrooms
    let chatRooms = []
    let nonExisting = []
    let nonExistingCc = []
    let receiverNew = null
    let userChats = []
    let chatroomIds = []
    let k = 0

    let chatRoomId = localStorage.getItem("bixChat_selectedChatId");
    for (k = 0; k <= chatrooms?.length - 1; k++) {
      chatRooms.push(chatrooms[k]?.profile_email)
      let obj = {
        "id": chatrooms[k]?._id["$oid"],
        "email": chatrooms[k]?.profile_email
      }
      userChats.push(obj)
    }
    if (sendMessageType === "newUser" && newMessageFromSearch) {
      if (
        selectedChatroomDatas?.chatroomData?._cls === "ChatRoom.PrivateChatRoom"
      ) {
        selectedChatroomDatas?.chatroomData?.members_list?.forEach((member) => {
          if (member.email.toLowerCase() !== localStorage.getItem("bixChat_email")) {
            receiverNew = member.email.toLowerCase();
          }
        });
      }
      recievers = [receiverNew.toLowerCase()]
    }
    chatroomIds.push(chatRoomId)

    recievers?.map((receiver, j) => {
      if (chatRooms.includes(receiver)) {
        if (fromSendHi) {
          let id = userChats.filter((chat) => {
            if (chat.email == receiver) {
              return chat.id
            }
          })[0].id
          chatRoomId = id
        } else if (ccrecievers?.length > 0) {
          let id = userChats.filter((chat) => {
            if (chat.email == receiver) {
              return chat.id
            }
          })[0].id
          chatRoomId = id
          if (!chatroomIds.includes(chatRoomId)) {
            chatroomIds.push(chatRoomId)
          }
        }
      }
      else {
        if (!nonExisting.includes(receiver)) {
          nonExisting?.push(receiver)

        }

      }
    })
    if (ccrecievers?.length > 0) {
      if (nonExistingCc?.length > 0) {
        createNewChatroom(nonExistingCc).then((response) => {
          if (response.status === 200) {
            if (files.length > 0) {
              chatroomIds.push(response.data.chatroom_id)
              let filtered = chatroomIds.filter(function (el) {
                return el != "";
              });
              uploadData(filtered).then((array) => {
                if (array.length > 0) {
                  sendCcMessageToServer(msg, sub, recievers, ccrecievers, array)
                }
              })
            } else {
              chatRoomId = response.data.chatroom_id
              sendCcMessageToServer(msg, sub, recievers, ccrecievers, [])
            }

          }
        })
      }
      else {
        if (files.length > 0) {
          let filtered = chatroomIds.filter(function (el) {
            return el != "";
          });
          uploadData(filtered).then((array) => {
            if (array.length > 0) {
              sendCcMessageToServer(msg, sub, recievers, ccrecievers, array)
            }

          })
        } else {
          sendCcMessageToServer(msg, sub, recievers, ccrecievers, [])
        }

      }
    }
    else {
      if (nonExisting?.length > 0) {

        createNewChatroom(nonExisting).then((response) => {
          if (response.status === 200) {
            if (files.length > 0) {
              chatroomIds.push(response.data.chatroom_id)
              let filtered = chatroomIds.filter(function (el) {
                return el != "";
              });
              console.log(filtered);
              chatRoomId = response.data.chatroom_id
              uploadData(filtered).then((array) => {
                if (array.length > 0) {
                  sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
                    isForwarded, array, response.data.chatroom_id)
                }

              })
            } else {
              chatRoomId = response.data.chatroom_id
              if (fromSendHi) {
                sendHiMessage(chatRoomId)
              } else {
                sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
                  isForwarded, [], chatRoomId)
              }
            }

          }

        })
      }
      else {
        if (files.length > 0) {
          let filtered = chatroomIds.filter(function (el) {
            return el != "";
          });
          uploadData(filtered).then((array) => {
            if (array.length > 0) {
              sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
                isForwarded, array, chatRoomId, fromSendHi)
            }

          })
        } else {
          if (fromSendHi) {
            sendHiMessage(chatRoomId)
          } else {
            sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
              isForwarded, [], chatRoomId, fromSendHi)
          }
        }
      }
    }
  }


  const chatroomExists = async (msg, sub, recievers, ccrecievers, fileList, tempId, threadId, fromCompose,
    isForwarded, fromSendHi) => {
    let chatrooms = allChatrooms
    let chatRooms = []
    let nonExisting = []
    let nonExistingCc = []
    let receiverNew = null
    let userChats = []
    let chatroomIds = []
    let k = 0

    let chatRoomId = localStorage.getItem("bixChat_selectedChatId");
    for (k = 0; k <= chatrooms?.length - 1; k++) {
      chatRooms.push(chatrooms[k]?.profile_email)
      let obj = {
        "id": chatrooms[k]?._id["$oid"],
        "email": chatrooms[k]?.profile_email
      }
      userChats.push(obj)
    }
    if (sendMessageType === "newUser" && newMessageFromSearch) {
      if (
        selectedChatroomDatas?.chatroomData?._cls === "ChatRoom.PrivateChatRoom"
      ) {
        selectedChatroomDatas?.chatroomData?.members_list?.forEach((member) => {
          if (member.email.toLowerCase() !== localStorage.getItem("bixChat_email")) {
            receiverNew = member.email.toLowerCase();
          }
        });
      }
      recievers = [receiverNew.toLowerCase()]
    }
    chatroomIds.push(chatRoomId)
    if (ccrecievers?.length > 0) {
      ccrecievers?.map((ccreceiver, j) => {
        if (chatRooms.includes(ccreceiver)) {

          let id = userChats.filter((chat) => {
            if (chat.email == ccreceiver) {
              return chat.id
            }
          })[0].id
          chatRoomId = id
          if (!chatroomIds.includes(chatRoomId)) {
            chatroomIds.push(chatRoomId)
          }
        }
        else {
          if (!nonExisting.includes(ccreceiver)) {
            nonExistingCc?.push(ccreceiver)

          }

        }
      })
    }
    recievers?.map((receiver, j) => {
      if (chatRooms.includes(receiver)) {
        if (fromSendHi) {
          let id = userChats.filter((chat) => {
            if (chat.email == receiver) {
              return chat.id
            }
          })[0].id
          chatRoomId = id
        } else if (recievers?.length > 0) {
          let id = userChats.filter((chat) => {
            if (chat.email == receiver) {
              return chat.id
            }
          })[0].id
          chatRoomId = id
          if (!chatroomIds.includes(chatRoomId)) {
            chatroomIds.push(chatRoomId)
          }
        }
      }
      else {
        if (!nonExisting.includes(receiver)) {
          nonExisting?.push(receiver)

        }

      }
    })
    if (ccrecievers?.length > 0) {
      if (nonExistingCc?.length > 0) {
        createNewChatroom(nonExistingCc).then((response) => {
          if (response.status === 200) {
            if (files.length > 0) {
              chatroomIds.push(response.data.chatroom_id)
              let filtered = chatroomIds.filter(function (el) {
                return el != "";
              });
              uploadData(filtered).then((array) => {
                if (array.length > 0) {
                  sendCcMessageToServer(msg, sub, recievers, ccrecievers, array)
                }
              })
            } else {
              chatRoomId = response.data.chatroom_id
              sendCcMessageToServer(msg, sub, recievers, ccrecievers, [])
            }

          }
        })
      }
      else {
        if (files.length > 0) {
          let filtered = chatroomIds.filter(function (el) {
            return el != "";
          });
          uploadData(filtered).then((array) => {
            if (array.length > 0) {
              sendCcMessageToServer(msg, sub, recievers, ccrecievers, array)
            }

          })
        } else {
          sendCcMessageToServer(msg, sub, recievers, ccrecievers, [])
        }

      }
    }
    else {
      if (nonExisting?.length > 0) {

        createNewChatroom(nonExisting).then((response) => {
          if (response.status === 200) {
            if (files.length > 0) {
              chatroomIds.push(response.data.chatroom_id)
              let filtered = chatroomIds.filter(function (el) {
                return el != "";
              });
              console.log(filtered);
              chatRoomId = response.data.chatroom_id
              uploadData(filtered).then((array) => {
                if (array.length > 0) {
                  sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
                    isForwarded, array, response.data.chatroom_id)
                }

              })
            } else {
              chatRoomId = response.data.chatroom_id
              if (fromSendHi) {
                sendHiMessage(chatRoomId)
              } else {
                sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
                  isForwarded, [], chatRoomId)
              }
            }

          }

        })
      }
      else {
        console.log(chatRoomId, "chatRoomId");
        if (files.length > 0) {
          let filtered = chatroomIds.filter(function (el) {
            return el != "";
          });
          uploadData(filtered).then((array) => {
            if (array.length > 0) {
              sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
                isForwarded, array, chatRoomId)
            }

          })
        } else {
          if (fromSendHi) {
            sendHiMessage(chatRoomId)
          } else {
            sendMessageToServer(msg, sub, recievers, fileList, tempId, threadId, fromCompose,
              isForwarded, [], chatRoomId)
          }
        }
      }
    }
  }
  const uploadData = (chatRoomIds) => {
    setMessageStatus("Uploading ...")
    return new Promise((resolve, reject) => {
      let tempArr = fileInfo;
      files.map((file, i) => {
        let found = fileInfo.some(el => el.fileName === file.name);
        if (!found) {
          let ModifiedDate = moment(file.lastModifiedDate).format("YYYY-M-D")
          let imageType = file.type
          tempArr.push(
            {
              "fileName": file.name,
              "extention": imageType,
              "createdDate": "2021-05-15",
              "modifiedDate": ModifiedDate,
              "size": file.size,
              "thumb_availability": file.type.split("/")[0] === "image" ? "True" : "False"
            }
          )
        }
      })
      setFileInfo(tempArr);
      const formdata = new FormData();
      formdata.append('chatroom', JSON.stringify(chatRoomIds));
      formdata.append('file_info[]', JSON.stringify(fileInfo));
      const options = {
        url: `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/chat/file/`,
        method: 'POST',
        data: formdata,
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': localStorage?.getItem("bixChat_accessToken"),
          //"Access-Control-Allow-Origin": "Accept",
        },
      };
      axios(options)
        .then(response => {
          console.log(response);
          setFileInfo([])
          let dataFile = response.data.upload_url
          let array = []
          dataFile?.map((dFile) => {
            let fileObject = {
              "name": dFile.fileName,
              "size": dFile.size,
              "thumb_availability": dFile.type.split("/")[0] === "image" ? "True" : "False",
              "id": dFile.id
            }
            array.push(fileObject)
            let key = dFile?.file_upload_url?.fields?.key
            let thumbnailKey = dFile?.thumb_upload_url?.fields?.key
            let name = dFile.fileName
            files.map(async (newFile) => {
              if (newFile.name === name) {
                await uploadFile(newFile, key).then(async (success) => {
                  if (success) {
                    if (newFile.type.split("/")[0] === "image") {
                      await fileServices().resizeFile(newFile).then(async (thumbnail) => {
                        await uploadFile(thumbnail, thumbnailKey).then((success) => {
                          if (success) {
                            resolve(array);
                          }
                        })
                      })
                    } else {
                      resolve(array);
                    }
                  }
                })

              }
            })
          })

        })
    });
  };
  const uploadFile = (newFile, key) => {
    return new Promise((resolve, reject) => {
      const params = {
        ACL: 'public-read',
        Body: newFile,
        Bucket: S3_BUCKET,
        Key: key
      };
      myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
          percent = Math.floor((evt.loaded * 100) / evt.total)
          if (evt.loaded === evt.total) {
            console.log("sucess");
            setUploadPercentage(percent)
            setMessageStatus("")
            resolve(true)
          }
        })
        .send((err) => {
          if (err) console.log(err)
        })
    })

  }
  const forwardMessage = (recievers, emailRecievers) => {
    return new Promise((resolve) => {
      let chatroomIds = recievers
      emailRecievers.map((receiver) => {
        createNewChatroom([receiver]).then((response) => {
          if (response.status === 200) {
            chatroomIds.push(response.data.chatroom_id)
          }
        })
      })
      resolve(chatroomIds)
    })
  }
  const handleForwardMessage = async (recievers, emailRecievers) => {
    await forwardMessage(recievers, emailRecievers).then((
      chatroom_ids
    ) => {
      const formData = new FormData();
      formData.append("message_ref_id", forwardMessageID);
      if (chatroom_ids.length > 0) {
        for (let i = 0; i < chatroom_ids.length; i++) {
          formData.append("chatroom_ids", chatroom_ids[i]);
        }
      }
      axios({
        headers: {
          "x-access-token": localStorage?.getItem("bixChat_accessToken"),
        },
        method: "post",
        baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.messageServer}`,
        url: "forwardmessage",
        data: formData,
      }).then(async (response) => {
        console.log(response);

        if (response.status === 200) {
          console.log(response);
          setForwadLoading(false)
          setForwardModal(false)
          setForwardMessageID("")
          await getAllChatroom()
        }

      })
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    handleForwardMessage(recievers, emailRecievers)
                  }
                })

              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  handleForwardMessage(recievers, emailRecievers)
                }
              })

            }

          }
        })
    })
  }
  const passwordResetLink = (email) => {
    return new Promise((resolve) => {
      const data = {
        "email": email
      }
      let url = "users/reset_forget_password"
      let options = {
        body: data
      }
      api.postCreate(url, options)
        .then((response) => {
          if (response.status === 200) {
            setShowPasswordLink(true)
            resolve(response.data.x_access_token)
          }
          setPasswordLinkLoading(false)

        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    passwordResetLink(email)
                  }
                })

              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  passwordResetLink(email)
                }
              })

            }

          }
        });
    })

  }
  const getEmailSignature = () => {
    return new Promise((resolve) => {
      let url = `email-signature`
      let options = {
        headers: {
          "x-access-token": localStorage?.getItem("bixChat_accessToken")
        }
      }
      api.get(url, options)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.signature) {
              setSignatureData(response.data.signature)
            } else {
              let signature = JSON.parse(localStorage.getItem("bixChat_loggedUserData"))?.full_name
                + "<br>" +
                JSON.parse(localStorage.getItem("bixChat_loggedUserData"))?.designation
              setSignatureData(signature)

            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (config.environment !== "development") {
              getRefreshToken().then((rToken) => {
                tokenService().checkToken(rToken).then((res) => {
                  if (res === "success") {
                    getEmailSignature()
                  }
                })

              })
            } else {
              let refreshToken = localStorage.getItem("bixChat_refreshToken")
              tokenService().checkToken(refreshToken).then((res) => {
                if (res === "success") {
                  getEmailSignature()
                }
              })

            }

          }
        });
    })
  };

  return (
    <AppContext.Provider
      value={{
        // Auth Values and Funtions
        loggedFailed,
        loginIsLoading,
        loginClicked,
        setLoggedFailed,

        // Other Values and Funtions

        sendMessageToServer,
        chatUILoaded,
        chatRooms,
        setSelectedChat,
        selectedChat,
        chatroomsLoaded,
        allChatrooms,
        setSelectedSender,
        selectedSender,
        filterChatrooms,
        searchUsers,
        filteredUsers,
        chatSelected,
        setFilteredUsers,
        openNewChat,
        logoutUser,
        setSendMessageType,
        sendMessageType,
        messageSendingLoading,
        setMessageSendingLoading,
        selectedChatroomDatas,
        sendingMsgToOldUser,
        setMessageRecivers,
        messageRecivers,
        sendingMessages,
        setSendingMessages,
        createNewGroup,
        newGroupMembers,
        setNewGroupMembers,
        filteredUserChats,
        setFilteredUserChats,
        searchAllUsers,
        downloadLink,
        setDownloadLink,
        sentTempMessages,
        isChatSelected,
        scollChatUI,
        forgetPassword,
        forgetPwdFailed,
        forgetPwdLoading,
        setForgetPwdLoading,
        loadMoreMessages,
        setLoadMoreMessages,
        loadMoreSelectedchat,
        messageQueue,
        failedMessages,
        messageSendingPopup,
        setMessageSendingPopup,
        tempChatrooms,
        tempIsLoaded,
        allFilteredUsers,
        setAllFilteredUsers,
        sendingMultipleMsg,
        profileUpdating,
        setsearchInput,
        searchInput,
        ////Alpha////
        newMessage,
        setNewMessage,
        unread,
        setUnread,
        unreadSelectedChat,
        reactMessage,
        getThread,
        updateGroupName,
        updateGroupEmail,
        updateGroupDP,
        updateGroupDescription,
        sendMessageModal,
        setSendMessageModal,
        addMembersToTheGroup,
        updateProfile,

        settingsModal,
        setSettingsModal,
        isChatObject,
        setIsGroupNameChanging,
        isGroupEmailChanging,
        setIsGroupEmailChanging,
        isGroupNameChanging,
        setIsGroupDescriptionChanging,
        isGroupDescriptionChanging,
        deleteGroup,
        isDeleting,
        setIsDeleting,
        centerModalVisibility,
        setCenterModalVisibility,
        deleteGroupMember,
        adminModalVisibility,
        setAdminModalVisibility,
        unsubscribe,
        addModerator,
        removeModerator,
        isLoading,
        setIsLoading,
        setSelectedUser,
        selectedUser,
        findChatroom,
        mediaList,
        getMedia,
        setMediaList,
        mediaListLoading,
        setMediaListLoading,
        getAttachments,
        attachmentList,
        attachmentListLoading,
        setAttachmentListLoading,
        setAttachmentList,
        leaveGroup,
        selectedChatroom,
        setSelectedChatroom,
        groupCreating,
        setGroupCreating,

        // createMessageQueue,
        selectedReply,
        setSelectedReply,
        selectedchatReceivers,
        isOnline,

        windowDimensions,
        setWindowDimensions,
        selectedChatID,
        setSelectedChatID,


        leftNavPopupHandler,
        setLeftNavPopup,
        leftNavPopup,

        imageUploadPopupHandler,
        imageUploadPopup,
        setImageUploadPopup,

        // stepsEnabled,
        // steps,
        // initialStep,
        // onExit,
        // signupStatus,

        allChatroomsLoaded,
        percent,
        percentage,
        isLoggedIn,

        tempChatroomStatus,

        tokenExpired,
        setTokenExpired,
        setToken,
        rightNavPopup,
        RightNavPopupHandler,
        setRightNavPopup,
        switchAccount,
        switchAccountLogout,
        domainName,

        UploadModalHandler,
        uploadImgModal,
        setuploadImgModal,
        setIsSwitching,
        isSwitching,
        getFirstChatroomLength,
        newChat,
        setNewChat,
        chatroomLength,
        setChatroomLength,
        forwardModal,
        setForwardModal,
        fowardFilteredUsers,
        setForwardFilteredUsers,
        setIsChatSelected,
        dataFolders,
        setDataFolders,
        setDataFiles,
        dataFiles,
        setLoadData,
        setDataSubFolders,
        dataSubFolders,
        getData,
        getSubFolderData,
        setUpFolderId,
        showMainFolders,
        setShowMainFolders,
        saveContact,
        contactModal,
        setContactModal,
        isContact,
        setIsContact,
        isLogged,
        setIsLogged,

        viewContacts,
        setViewContacts,
        contactsSorted,
        setContactsSorted,
        getContacts,
        contactList,
        resetPassword,
        resetSuccess,
        setResetSuccess,
        resetIsLoading,
        getSupport,
        anim,

        savedChatsSelected,
        setSavedChatsSelected,
        otherChatsSelected,
        setOtherChatsSelected,
        getNonContacts,
        savedUnreadMsgs,
        setSavedUnreadMsgs,
        setUnsavedUnreadMsgs,
        unsavedUnreadMsgs,
        savedContactsList,
        unsavedchatList,
        showTabs,
        setShowTabs,
        changePassword,
        changePasswordIsLoading,
        setChangePasswordIsLoading,
        getAllChatroom,
        expandSelected,
        setExpandSelected,
        showContactsNav,
        setSavedContactsList,
        setunSavedchatList,
        contactSideBarHandler,
        contactPopup,
        fromEdit,
        setFromEdit,
        deleteContact,
        DragPopup,
        setDragPopup,
        handleUploadFile,
        dropHandleFiles,
        files,
        setFiles,
        passwordLinkError,
        setPasswordLinkError,
        filterContacts,
        sendModal,
        setSendLoading,
        sendLoding,
        setSendModal,
        setIsChatSelected,
        setSelectedChat,
        sendHiMessage,
        sendHiForSingleUser,
        messageTag,
        setMessageTag,
        alert,
        setAlert,
        isSaving,
        setIsSaving,
        rightSideBarStatus,
        setRightSideBarStatus,
        setIsEdit,
        isEdit,
        blockContact,
        blockedContacts,
        blockList,
        blockedNonContacts,
        getBlockList,
        setBlockedContacts,
        setBlockList,
        setBlockedNonContacts,
        isBlocked,
        setIsBlocked,
        unblockContact,

        threadIdNum,
        setThreadIdNum,
        reportEmail,
        setReportEmail,
        sendCcMessageToServer,
        sendReportMessage,
        setReportModal,
        reportModal,
        reportSent,
        setReportSent,
        blockConfirm,
        setBlockConfirm,
        setContractLoading,
        contractLoading,
        sendContract,
        showBlockDiv,
        setShowBlockDiv,
        isBlocker,
        setIsBlocker,
        getPrimary,
        moveToOther,
        setClickOther,
        replySubject,
        setReplySubject,
        isModalVisible,
        setIsModalVisible,
        unreadList,
        unreadChats,
        unreadChatList,
        setUnreadChatList,
        primaryCount,
        setPrimaryCount,
        modalLoading,
        setModalLoading,
        deleteError,
        setDeleteError,
        deleteUser,
        logoutFunction,
        pinChat,
        getPinnedChats,
        getPrimaryUnpinned,
        viewBlockList,
        setViewBlockList,
        pinAlert,
        setPinAlert,
        getLabels,
        labels,
        setLabels,
        filterLabel,
        receivers,
        setReceivers,
        searchContact,
        searchList,
        setSearchList,
        showSugestions,
        setShowSugestions,
        runTimer,
        setRunTimer,
        sendOTP,
        OTPVerifying,
        setOTPVerifying,
        OTPError,
        setOTPError,
        verifyOTP,
        OTPVerified,
        pageToShow,
        setPageToShow,
        setSendingOTP,
        sendingOTP,
        setttingsTab,
        setSettingsTab,
        contentToShow,
        setContentToShow,
        toList,
        setToList,
        ccList,
        setCcList,
        ccMessage,
        setCcMessage,
        forwardMessageID,
        setForwardMessageID,
        forwardLoading,
        setForwadLoading,
        loginFunction,
        chatroomExists,
        createNewChatroom,
        newMessageFromSearch,
        setNewMessageFromSearch,
        uploadPercentage,
        forwardMessage,
        updateUserDP,
        profileUpdated,
        showloader,
        setShowLoader,
        showInlineloader,
        setShowInlineLoader,
        domainContacts,
        loggedUserData,
        showReactionTab,
        setShowReactionTab,
        messageStatus,
        MessageBody,
        setMessageBody,
        subjectListLoading,
        setSubjectListLoading,
        handleForwardMessage,
        signatureModal,
        setSignatureModal,
        sendSignatureData,
        signatureData,
        setSignatureData,
        messageInfo,
        setMessageInfo,
        replySending,
        setReplySending,
        fontSizeType,
        setFontSizeType,
        setMessageSeenList,
        messageSeenList,
        getMessageInfo,
        isGroupEmailChanging,
        setIsGroupEmailChanging,
        appIntregrationModal,
        setAppIntregrationModal,
        setGoogleCalenderInputModal,
        googleCalenderInputModal,
        googleCalenderOptionModal,
        setGoogleCalenderOptionModal,
        setAddGuestModal,
        addGuestModal,
        guestList,
        setGuestList,
        guestListEmail,
        setGuestListEmail,
        sendGoogleId,
        setGoogleAccessKey,
        googleAccessKey,
        forwardedList,
        setForwardedList,
        showPasswordLink,
        setShowPasswordLink,
        passwordLinkLoading,
        setPasswordLinkLoading,
        passwordResetLink,
        getEmailSignature

      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
