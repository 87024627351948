import React, { useState, useEffect } from "react";
import AppContext from "../../../ContextAPI/AppContext";
import Avatar from '../../../Components/Avatar/avatar'
import back from "../../../Assets/Icons/next.png";
import EmailAvatar from "../../../Components/Avatar/EmailAvatar/EmailAvatar";
import { getDPService } from "../../../services/DPService";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
const MessageBoxNavbar = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <AppContext.Consumer>
      {(context) =>
        <header className={`navbar-wrapper-${context.fontSizeType}`}>
          <div
            id="navbar"
            className="navbar-mess"

          >

            <div className="arrow-back" onClick={() => {
              if (windowDimensions.width < 600) {
                context.setSelectedChatID("")
                localStorage.setItem(
                  "bixChat_selectedChatId",
                  ""
                );
              }
              if (props.rightSideBarStatus) {
                props.setRightSideBarStatus(false);
              }



            }} style={{ display: windowDimensions.width < 600 ? "block" : windowDimensions.width > 1200 ? "" : props.rightSideBarStatus ? "block" : "none" }}>
              <img alt="" src={back} />
            </div>
            <div className="profile"
              onClick={() => {
                props.setRightSideBarStatus(true);

              }}
            >
              <div className="profile-pic-wrapper">
                {context?.selectedChatroomDatas?.chatroomData?._cls ===
                  "ChatRoom.GroupChatRoom" ? (
                  context?.selectedChatroomDatas?.chatroomData
                    ?.group_image !== "null" ? (
                    <div >
                      <Avatar
                        src={getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"])}
                        size='m'


                      />
                    </div>
                  ) : (
                    <div>
                      <Avatar
                        src={getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?._id["$oid"])}
                        size='m'
                      />
                    </div>
                  )
                ) :
                  context?.selectedChatroomDatas?.chatroomData?._cls ===
                    "ChatRoom.EmailChatRoom" ? (
                    context?.selectedChatroomDatas?.chatroomData?.contact && Object.keys(context?.selectedChatroomDatas?.chatroomData?.contact).length > 0 ?

                      context?.selectedChatroomDatas?.chatroomData?.contact?.avatar === '' ?

                        <div className="email-profile-picture-dp">{context?.selectedChatroomDatas?.chatroomData
                          ?.contact?.name.charAt(0)}</div>
                        :
                        <Avatar
                          src={
                            context?.selectedChatroomDatas?.chatroomData
                              ?.contact?.avatar
                          }
                          size='m'

                        />
                      :
                      <EmailAvatar size="mn" initial={context?.selectedChatroomDatas?.chatroomData
                        ?.email_receiver.charAt(0)} />
                  ) :
                    context?.selectedChatroomDatas?.chatroomData?.contact && Object.keys(context?.selectedChatroomDatas?.chatroomData?.contact).length > 0 && context?.selectedChatroomDatas?.chatroomData?.contact?.email !== "support@bixchat.xyz" ?

                      <Avatar
                        src={context?.selectedChatroomDatas?.chatroomData
                          ?.contact?.avatar === null || context?.selectedChatroomDatas?.chatroomData
                            ?.contact?.avatar === "null" || context?.selectedChatroomDatas?.chatroomData
                              ?.contact?.avatar === "" ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : context?.selectedChatroomDatas?.chatroomData
                                ?.contact?.avatar}

                        size='m'

                      />
                      :

                      getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id) &&
                        getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id) !== "null" &&
                        getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id) !== null ? (

                        <div>
                          <Avatar
                            src={
                              getDPService().getDP(context?.selectedChatroomDatas?.chatroomData?._cls, context?.selectedChatroomDatas?.chatroomData?.profile_public_id)
                            }
                            size='m'

                          />
                        </div>

                      ) : (
                        <div>
                          <Avatar
                            src="https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png"
                            size='m'

                          />
                        </div>
                      )}
              </div>
              <div className="name-and-org">
                <div
                  className="org-name"
                >
                  {context?.selectedChatroomDatas?.chatroomData?.group_title
                    ? context?.selectedChatroomDatas?.chatroomData
                      ?.group_title
                    :
                    context?.selectedChatroomDatas?.chatroomData?.contact?.email !== undefined
                      ? context?.selectedChatroomDatas?.chatroomData?.contact?.name
                      : context?.selectedChatroomDatas?.chatroomData?.profile_name
                        ? context?.selectedChatroomDatas?.chatroomData
                          ?.profile_name
                        : context?.selectedChatroomDatas?.chatroomData
                          ?.email_receiver
                  }
                </div>{" "}
                {context?.selectedChatroomDatas?.chatroomData?._cls ===
                  "ChatRoom.GroupChatRoom" ? (
                  <div
                    className="org-members"
                    style={{ textTransform: "capitalize" }}
                  >
                    {context?.selectedChatroomDatas?.chatroomData?.members_list.length > 10 ?
                      (<div>{context?.selectedChatroomDatas?.chatroomData?.members_list?.slice(0, 10).map(
                        (data, i) => {
                          if (
                            data.public_id !==
                            localStorage.getItem("bixChat_loggedUserPublicId")
                          ) {
                            return (
                              <span key={i}>
                                <span>{data.full_name.split(" ")[0]}</span>
                                <span style={{ marginRight: "4px" }}>
                                  {","}
                                </span>
                              </span>
                            );
                          }
                          return null;
                        }
                      )}...
                      </div>) : <div>{context?.selectedChatroomDatas?.chatroomData?.members_list.map(
                        (data, i) => {
                          if (
                            data.public_id !==
                            localStorage.getItem("bixChat_loggedUserPublicId")
                          ) {
                            return (
                              <span key={i}>
                                <span>{data.full_name.split(" ")[0]}</span>
                                <span style={{ marginRight: "4px" }}>
                                  {","}
                                </span>
                              </span>
                            );
                          }
                          return null;
                        }
                      )}
                        {context?.selectedChatroomDatas?.chatroomData?.members_list.map(
                          (data) => {
                            if (
                              data.public_id ===
                              localStorage.getItem("bixChat_loggedUserPublicId")
                            ) {
                              return "You";
                            }
                            return null;
                          }
                        )}</div>}
                  </div>
                ) : context?.selectedChatroomDatas?.chatroomData?._cls ===
                  "ChatRoom.PrivateChatRoom" ? (
                  context?.selectedChatroomDatas?.chatroomData?.members_list.map(
                    (data, i) => {
                      if (
                        data.public_id !==
                        localStorage.getItem("bixChat_loggedUserPublicId")
                      ) {
                        return (
                          <div className="org" key={i}>
                            {data.headline !== ""
                              ? data.headline
                              : "ZapMail User"}
                          </div>
                        );
                      }
                      return null;
                    }
                  )
                ) : (
                  <div className="org" >
                    Non-ZapMail User
                  </div>

                )}
              </div>
            </div>
          </div>
        </header>
      }
    </AppContext.Consumer>
  )

}
export default MessageBoxNavbar;