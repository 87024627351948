import React, { useState, useContext, useEffect, useRef } from "react";
import "../SendNewMessage/SendNewMessageStyle.scss";
import "react-multi-email/style.css";
import close from "../../../Assets/Icons/closeCross.svg";
import AppContext from "../../../ContextAPI/AppContext"
import TextEditor from "../../../Components/BixTextEditor/TextEditor";
import "../../../Components/BixTextEditor/TextEditorStyles.scss"
import MultiEmails from "../../../Components/MultiEmails/MultiEmails";
import SingleContact from "../LeftSidebar/SingleContact/SingleContact";



const SendNewMessage = (props) => {
  const [subjectInput, setSubjectInput] = useState(true);
  const [subject, setSubject] = useState("");
  const [receivers, setReceivers] = useState([]);
  const [ccReceivers, setCcReceivers] = useState([]);
  const [onFocus, setOnFocus] = useState('')
  const [top, setTop] = useState(((document?.getElementById('main')?.offsetHeight) + 45 + 'px'))
  const [cctop, setccTop] = useState(((document?.getElementById('cc')?.offsetHeight) + 45 + 'px'))
  const uploadImage = useRef(null);
  const uploadFile = useRef(null);

  const [ccSelected, setCcSelected] = useState(false);
  let emails = [];
  const conText = useContext(AppContext)

  useEffect(() => {
    if (props.rec) {
      setReceivers([...receivers, props.rec])
    }
  }, [props.rec])
  useEffect(() => {
    localStorage.setItem("emails", JSON.stringify(emails));
    localStorage.setItem("cc", JSON.stringify(emails));

  }, [])

  const isEmailValidation = (email) => {
    let emailPattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    return emailPattern.test(email);
  }

  const handleKeyPress = (e, func, from) => {

    if (onFocus === 'main') {
      if (func === 'contact') {
        emails = JSON.parse(localStorage.getItem('emails'))
        if (e.profile_email !== undefined) {
          if (!emails.includes(e.profile_email)) {
            emails.push(e.profile_email)
            localStorage.setItem("emails", JSON.stringify(emails));
            document.getElementById('mainn').value = ''

          }
          else {
            document.getElementById('mainn').value = ''



          }
        }
        else {
          if (!emails.includes(e.email)) {
            emails.push(e.email)
            localStorage.setItem("emails", JSON.stringify(emails));
            document.getElementById('mainn').value = ''

          }
          else {
            document.getElementById('mainn').value = ''

          }
        }


      }
      else {

        let isEmail = isEmailValidation(e.target.value)

        if (!isEmail) {
          if (e.key === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            emails = JSON.parse(localStorage.getItem('emails'))

          }
          else {
            emails = JSON.parse(localStorage.getItem('emails'))
          }
        }
        else {
          emails = JSON.parse(localStorage.getItem('emails'))

          if (e.key === 'Enter' || e.code === 'Space' || e.type === 'blur') {
            if (!emails.includes(e.target.value)) {
              emails.push(e.target.value)
              localStorage.setItem("emails", JSON.stringify(emails));
              document.getElementById('mainn').value = ''
            }
            else {
              document.getElementById('mainn').value = e.target.value
              // e.preventDefault();


            }

          }

        }
      }

      setReceivers(emails)
    }

    else {
      if (func === 'contact') {
        emails = JSON.parse(localStorage.getItem('cc'))
        if (e.profile_email !== undefined) {
          if (!emails.includes(e.profile_email)) {
            emails.push(e.profile_email)
            localStorage.setItem("cc", JSON.stringify(emails));
            document.getElementById('ccc').value = ''

            // let arr = conText?.searchList?.filter(
            //   (item) => item != e.profile_email.trim()
            // )


            // conText.setSearchList(arr)
          }
          else {
            document.getElementById('ccc').value = ''
            // e.preventDefault();
          }


        }
        else {
          if (!emails.includes(e.email)) {
            emails.push(e.email)
            localStorage.setItem("cc", JSON.stringify(emails));
            document.getElementById('ccc').value = ''

            // let arr = conText?.searchList?.filter(
            //   (item) => item != e.profile_email.trim()
            // )


            // conText.setSearchList(arr)
          }
          else {
            document.getElementById('ccc').value = ''
            // e.preventDefault();
          }
        }

      }
      else {

        let isEmail = isEmailValidation(e.target.value)

        if (!isEmail) {
          if (e.key === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            emails = JSON.parse(localStorage.getItem('cc'))

          }
          else {
            emails = JSON.parse(localStorage.getItem('cc'))
          }
        }
        else {
          emails = JSON.parse(localStorage.getItem('cc'))

          if (e.key === 'Enter' || e.code === 'Space' || e.type === 'blur') {
            if (!emails.includes(e.target.value)) {
              emails.push(e.target.value)
              localStorage.setItem("cc", JSON.stringify(emails));
              document.getElementById('ccc').value = ''
            }
            else {
              document.getElementById('ccc').value = e.target.value
              // e.preventDefault();


            }

          }

        }
      }

      setCcReceivers(emails)
    }


  }

  const removeEmail = (email, focus) => {

    if (focus === 'main') {
      let arr = receivers?.filter(
        (item) => item !== email
      );

      setReceivers(arr)
      localStorage.setItem("emails", JSON.stringify(arr));
    }

    else {
      let arr = ccReceivers?.filter(
        (item) => item !== email
      );

      setCcReceivers(arr)
      localStorage.setItem("cc", JSON.stringify(arr));
    }

    conText.setShowSugestions(false)

  }
  const onImageClick = () => {
    uploadImage.current.click();
  };
  const onFileClick = () => {
    uploadFile.current.click();
  };
  useEffect(() => {
    conText.setFiles([])
  }, [])
  return (

    <AppContext.Consumer>
      {(context) => (
        <div className="__SendNewMessage" onClick={() => {
          context.leftNavPopupHandler("closeOnly")
          context.RightNavPopupHandler("closeOnly")
        }}>
          <div>
            <div className="modal-content-new" style={{ backgroundColor: "white" }} id='msg_box'>
              <div className="send-new-message-others-nav">
                <img
                  className="send-new-message-others-close"
                  onClick={() => {
                    context.setViewContacts(false);
                    props.setSendMessageModal(false);
                    context.setSendMessageModal(false);
                    context.setShowTabs(true);
                    context.showContactsNav('close')
                    let x = JSON.parse(localStorage.getItem("bixChat_selectedChatMessage"))
                    let oldSelectedChatType = ""
                    if (x !== "") {
                      if (x?._cls === "ChatRoom.EmailChatRoom") {
                        oldSelectedChatType = "emailChat"
                      } else if (x?._cls === "ChatRoom.PrivateChatRoom") {
                        oldSelectedChatType = "oldUser"
                      } else if (x?._cls === "ChatRoom.GroupChatRoom") {
                        oldSelectedChatType = "groupChat"
                      }
                      context.setSendMessageType(oldSelectedChatType);
                    }

                  }}
                  alt=""
                  src={close}
                />
                <div>New Message</div>
              </div>
              <section className="modal-card-body">
                <div className="send-new-message-others-to">

                  <div className="input-cont">
                    <div className="top-cont">


                      <div className="send-new-message-others-div1">

                        <div
                          className="lable"
                        >
                          To<span className="dash">-</span>
                        </div>


                        <MultiEmails
                          receivers={receivers}
                          setReceivers={setReceivers}
                          isEmailValidation={isEmailValidation}
                          handleKeyPress={handleKeyPress}
                          removeEmail={removeEmail}
                          from={'main'}
                          onFocus={onFocus}
                          setOnFocus={setOnFocus}
                          top={top}
                          setTop={setTop}
                        />

                        {context?.showSugestions && onFocus === 'main' ?
                          <div className="sug-background" style={{ marginTop: top }}>
                            <div className="sugestion-wrapper">
                              {context?.searchList?.map((value, i) => {
                                return (
                                  <SingleContact
                                    value={value}
                                    onClick={() => {
                                      handleKeyPress(value, 'contact')
                                      context.setShowSugestions(false)


                                    }}
                                    fromSugestion={true}
                                  />
                                )

                              })}



                            </div>

                          </div>
                          : ''}

                        {/* <ReactMultiEmail
                        style={{ borderColor: "transparent" }}
                        placeholder={`john${config.emailProvider}`}
                        emails={receivers}
                        onChange={(_emails) => {
                          setReceivers(_emails);
                        }}
                        validateEmail={(email) => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>


                            </div>

                          );
                        }}
                      /> */}


                      </div>
                      <div
                        className={ccSelected ? "cc-selected" : "cc-option"}
                        onClick={() => {
                          setCcSelected(true)
                        }}
                      >
                        cc
                      </div>

                    </div>

                    <div className="send-new-message-others-div2">

                      <div
                        className={ccSelected ? "cc-selected2" : "cc-option2"}

                        onClick={() => {

                        }}
                      >
                        <div
                          className="lable"
                        >
                          cc<span className="dash">-</span>
                        </div>
                      </div>


                      {/* <ReactMultiEmail
                        className={ccSelected ? "selected-tagging-people" : "tagging-people"}

                        style={{ borderColor: "transparent" }}
                        placeholder={`john${config.emailProvider}`}
                        emails={ccReceivers}
                        onChange={(_emails) => {
                          setCcReceivers(_emails);
                          console.log(ccReceivers)
                        }}
                        validateEmail={(email) => {
                          return isEmail(email); // return boolean
                        }}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>


                            </div>

                          );
                        }}
                      /> */}

                      {ccSelected ?
                        <MultiEmails
                          ccReceivers={ccReceivers}
                          setCcReceivers={setCcReceivers}
                          isEmailValidation={isEmailValidation}
                          handleKeyPress={handleKeyPress}
                          removeEmail={removeEmail}
                          from={'cc'}
                          onFocus={onFocus}
                          setOnFocus={setOnFocus}
                          cctop={cctop}
                          setccTop={setccTop} />
                        : ''}

                      {context?.showSugestions && onFocus === 'cc' ?
                        <div className="sug-background" style={{ marginTop: cctop }}>
                          <div className="sugestion-wrapper">

                            {context?.searchList?.map((value, i) => {
                              return (
                                <SingleContact
                                  value={value}
                                  onClick={() => {
                                    handleKeyPress(value, 'contact')
                                    context.setShowSugestions(false)


                                  }}
                                />
                              )

                            })}



                          </div>

                        </div>
                        : ''}

                    </div>
                  </div>



                </div>

                <div className="main-message-box-footer-new">
                  <TextEditor
                    setSubjectInput={setSubjectInput}
                    subjectInput={subjectInput}
                    outSideEmail={true}
                    receivers={receivers}
                    ccReceivers={ccReceivers}
                    sendingMultipleMsg={props.sendingMultipleMsg}
                    subject={subject}
                    setSubject={setSubject}
                    sendMessageModal={props.sendMessageModal}
                    setSendMessageModal={props.setSendMessageModal}
                    setNewGroupPopup={props.setNewGroupPopup}
                    setNewGroupFrom={props.setNewGroupFrom}
                    isNewMessage={props.isNewMessage}
                    isOnline={props.isOnline}
                    windowDimensions={context.windowDimensions}
                    fromCompose={true}
                    uploadImage={uploadImage}
                    onImageClick={onImageClick}
                    onFileClick={onFileClick}
                    uploadFile={uploadFile}
                    rec={props.rec}
                    setRec={props.setRec}
                  />
                </div>

              </section>
            </div>
          </div>
        </div>


      )
      }


    </AppContext.Consumer >


  );
};
export default SendNewMessage;
