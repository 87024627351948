import React from "react";
import user from "../../../Assets/Icons/profile-white.svg"
import eye from "../../../Assets/Icons/receipt-edit.svg"
import thanks from "../../../Assets/Icons/vuesax-linear-like-tag.svg"
import domain from "../../../Assets/Icons/vuesax-linear-global-edit.svg"
import breifcase from "../../../Assets/Icons/vuesax-linear-briefcase-2.svg"


import "./AdminStepsStyles.scss"
const AdminSteps = (props) => {
    const filteColor = "invert(42%) sepia(0%) saturate(503%) hue-rotate(169deg) brightness(99%) contrast(85%)"
    const filterWhite = "brightness(0) invert(1)";
    return (
        <div className="admin-steps">
            <div className="step step-active">
                <div>
                    <div className={`${props.step !== 1 ? "circle-1" : "circle"}`}>
                        <img alt="" src={user} style={{ filter: filterWhite }} />
                    </div>
                </div>
                <div>
                    <div className="caption">Personal Information</div>
                </div>
            </div>
            <div className={`${props.step !== 1 ? "step step-active" : "step"}`}>
                <div>
                    <div className={`${props.step === 3 || props.step === 4 || props.step === 5 ? "circle-1" : "circle"}`}>
                        <img alt="" src={breifcase} style={{ filter: `${props.step !== 1 ? filterWhite : filteColor}` }} />
                    </div>
                </div>
                <div>
                    <div className="caption">Company Information</div>
                </div>
            </div>
            <div className={`${props.step === 3 || props.step === 4 || props.step === 5 ? "step step-active" : "step"}`}>
                <div>
                    <div className={`${props.step === 4 || props.step === 5 ? "circle-1" : "circle"}`}>
                        <img alt="" src={eye} style={{ filter: `${props.step === 3 || props.step === 4 || props.step === 5 ? filterWhite : filteColor}` }} />
                    </div>
                </div>
                <div>
                    <div className="caption">Agreement</div>
                </div>
            </div>         <div className={`${props.step === 4 || props.step === 5 ? "step step-active" : "step"}`}>
                <div>
                    <div className={`${props.step === 5 ? "circle-1" : "circle"}`}>
                        <img alt="" src={domain} style={{ filter: `${props.step === 4 || props.step === 5 ? filterWhite : filteColor}` }} />
                    </div>
                </div>
                <div>
                    <div className="caption">Domain</div>
                </div>
            </div>
            <div className={`${props.step === 5 ? "step step-active" : "step"}`}>
                <div>
                    <div className={"circle-1"}>
                        <img alt="" src={thanks} style={{ filter: `${props.step === 5 ? filterWhite : filteColor}` }} />
                    </div>
                </div>
                <div>
                    <div className="caption">Thank You</div>
                </div>
            </div>
        </div>
    )
}
export default AdminSteps