import React from "react";
import info from "../../../Assets/Icons/info.svg";
import "./CenterModalStyles.scss";
import AppContext from "../../../ContextAPI/AppContext";
import CloseIcon from "../../Button/CloseIcon/CloseIcon";

const CenterModal = (props) => {
  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="_centerModal">
          <div className="center-modal-black-background">
            <div className="center-modal-content-inner">
              <div className="center-modal-content-center">
                <div className="plus-container-left">
                  <img alt="" src={info} className="info" />
                </div>

                <div className="header">{props.modalHeader}</div>
                {/* <img alt="" src={close} className="close" onClick={() => { props.setModalVisibility(false) }} /> */}
                <div style={{ marginTop: '10px' }}>
                  <CloseIcon
                    size='s'
                    onClick={() => { props.setModalVisibility(false) }}
                  />
                </div>
              </div>
              <div className="inner-container">
                <div className="description">{props.modalDescription}</div>
                {props.modalWarnig !== "" ? (
                  <div className="warning">warning:"{props.modalWarnig}"</div>
                ) : (
                  ""
                )}
                <div className="button-container">
                  <button className="no-button" onClick={() => { props.setModalVisibility(false) }}>No</button>
                  <button className="yes-button" onClick={() => {
                    if (props.modalFunction === "deleteGroup") {
                      let chatroom_id = localStorage.getItem("bixChat_selectedChatId")
                      context?.deleteGroup(chatroom_id)
                      context?.setIsDeleting(true)


                    }
                    else if (props.modalFunction === "removeMember") {
                      let chatroom_id = localStorage.getItem("bixChat_selectedChatId")
                      let public_id = localStorage.getItem("removeChatId")
                      context?.deleteGroupMember(chatroom_id, public_id)
                      context?.setIsDeleting(true)
                    }
                    else if (props.modalFunction === "leaveGroup") {
                      let chatroom_id = localStorage.getItem("bixChat_selectedChatId")

                      props.setModalVisibility(false)
                      let chatroom = JSON.parse(localStorage.getItem("bixChat_selectedChatMessage"))

                      if (chatroom.members_list.length > 1 && context?.isChatObject?.is_admin) {
                        context?.setAdminModalVisibility(true)

                      } else {
                        context?.setIsDeleting(true)
                        context?.leaveGroup(chatroom_id, "")
                      }
                    }
                  }}>{context?.isDeleting ? <div className="loader" /> : "Yes"}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>

  );
};
export default CenterModal;
