import React, { useState } from "react";
import userIcon from "../../../Assets/Icons/vuesax-linear-eye-slash.svg";
import pwdIcon2 from "../../../Assets/Icons/vuesax-linear-eye.svg";
import key from "../../../Assets/Icons/vuesax-linear-key.svg";

import "./PasswordInputStyles.scss"
const PasswordInput = (props) => {
    const [isFocus, setIsFocus] = useState(false)
    const [showPwd,setShowPwd]=useState(false)
    return (
        <div className="passwordInputContainer">
            <div className="input-cont" style={{ borderColor: isFocus ? "#0d2a4c" : props.isError || props.required && props.value === "" ? "#ff0000" : "" }}>
                {props.value === "" && !isFocus ?
                    <img alt="" src={key} className="icon" /> : ""}
                <input
                    name={props.name}
                    placeholder={!isFocus && props.value === "" ? props.name : ""}
                    onFocus={() => setIsFocus(true)}
                    onChange={props.onChange}
                    onBlur={() => setIsFocus(false)}
                    type={showPwd ? "text" : "password"}

                />
                 <span
                for="fname"
                className="icon-2"
                onClick={() => {
                  if (showPwd) {
                    setShowPwd(false);
                  } else {
                   setShowPwd(true);
                  }
                }}
              >
                {showPwd ? <img src={pwdIcon2} /> : <img src={userIcon} />}
              </span>
            </div>
            {props.required && props.value === "" && !isFocus ?
                <div className="required">* Required</div>
                : ""}
        </div>)
}
export default PasswordInput