import { ws } from "../../../App"
import axios from "axios";
import config from "../../../config";
import { restHelper } from "../../../ContextAPI/Helper/RestHelper";
export const groupServices = () => {
    const api = restHelper()
    const headers = {
        "x-access-token": localStorage?.getItem("bixChat_accessToken"),
    }

    const handleAddMembersToGroup = (
        getAllChatroom,
        setGroupCreating,
        setCenterModalVisibility,
        newGroupMembers, chatroomId) => {
        return new Promise((resolve, reject) => {
            let array = [];
            newGroupMembers.map((value) => {
                let x = {
                    public_id: value.public_id,
                };
                array.push(x);

                return null;
            });
            let url = `chatroom/addmembers/${chatroomId}`
            let data = { members: array, }
            let options = {
                headers: {
                    "x-access-token": localStorage?.getItem("bixChat_accessToken"),
                },
                body: data
            }
            api.postCreate(url, options)
                .then((response) => {
                    newGroupMembers.length = 0;
                    if (response.status === 200) {
                        getAllChatroom().then(() => {
                            setGroupCreating(false)
                            setCenterModalVisibility(false)
                            let ids = []
                            array.map(
                                (data) => {
                                    ids.push(data.public_id)
                                    return null
                                })
                            if (ws.readyState === WebSocket.OPEN) {
                                ws.send(
                                    JSON.stringify({
                                        action: "groupStateCheck",
                                        publicIDs: ids,
                                    })
                                );
                            }
                            // mixpanel.track("number of group members", { 'no of members': array.length })
                        })
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    const handleGroupNameUpdate = (chatroomId, name, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setIsGroupNameChanging) => {
        let url = `update-group-chatroom/${chatroomId}`
        let options = {
            headers: headers,
            body: {
                function: "name",
                group_name: name,
            }
        }
        api.postCreate(url, options)
            .then((response) => {
                if (response.status === 200) {
                    getAllChatroom();
                    if (selectedChatroomDatas?.chatroomData._cls === "ChatRoom.GroupChatRoom") {
                        let chatroom = selectedChatroomDatas?.chatroomData
                        chatroom.group_title = name
                        setSelectedChatroomDatas(selectedChatroomDatas)
                    }
                    setIsGroupNameChanging(false)
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const handleGroupEmailUpdate = (chatroomId, groupEmail, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setIsGroupEmailChanging) => {
        console.log(selectedChatroomDatas?.chatroomData.admin.domain, "datasOOO")
        console.log(chatroomId, "chtId")

        let grpMail = groupEmail + "@"  + selectedChatroomDatas?.chatroomData.admin.domain
        console.log(grpMail, "grouppppp mailll")
        console.log(grpMail)
        let url = `update-group-chatroom/${chatroomId}`
        let options = {
            headers: headers,
            body: {
                function: "email",
                group_email: grpMail,
            }
        }
        api.postCreate(url, options)
            .then((response) => {
                if (response.status === 200) {
                    getAllChatroom();
                    setIsGroupEmailChanging(false)
                }
                console.log(response, "kg")

            })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleGroupDPUpdate = (e, getAllChatroom,  selectedChatroomDatas, setSelectedChatroomDatas) => {
        let chatroomId = localStorage.getItem("bixChat_selectedChatId")
        let profilePicture = e;
        const formData = new FormData();
        formData.append("chatRoom_id", chatroomId);
        formData.append("profile_picture_encoded", profilePicture.split("base64,")[1]);
        axios({
            headers: headers,
            method: "put",
            baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}`,
            url: "chat/",
            data: formData,
        }).then((response) => {
            if (response.status === 201) {
                getAllChatroom();
                if (selectedChatroomDatas?.chatroomData._cls === "ChatRoom.GroupChatRoom") {
                    let chatroom = selectedChatroomDatas?.chatroomData
                    console.log(chatroom, "chatroom");
                    chatroom.group_image = `${config.apiEndpoints.protocol}${config.apiEndpoints.cloudServer}/chat/groupdp/${chatroomId}/`
                    setSelectedChatroomDatas(selectedChatroomDatas)
                }
            }
        })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleGroupDescriptionUpdate = (chatroomId, description, selectedChatroomDatas, setSelectedChatroomDatas, setIsGroupDescriptionChanging) => {
        console.log(chatroomId, "chatId")
        console.log(description, "description")

        let url = `update-group-chatroom/${chatroomId}`
        let options = {
            body: {
                function: "description",
                group_description: description,
            },
            headers: headers
        }
        api.postCreate(url, options)
            .then((response) => {
                console.log(response, "res")
                if (response.status === 200) {
                    if (selectedChatroomDatas?.chatroomData._cls === "ChatRoom.GroupChatRoom") {
                        let chatroom = selectedChatroomDatas?.chatroomData
                        chatroom.group_description = description
                        setSelectedChatroomDatas(selectedChatroomDatas)
                        setIsGroupDescriptionChanging(false)
                        console.log(chatroom.group_description, "jjjj")

                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleDeleteGroup = (response, setIsDeleting, getAllChatroom, chatSelected, selectedChat, setCenterModalVisibility) => {
        if (response.status === 200) {
            setIsDeleting(false)
            getAllChatroom()
            chatSelected(selectedChat, "deleteGroup")
            setCenterModalVisibility(false)
        }
    }
    const handleModerators = (response, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setSelectedUser, setIsLoading) => {
        if (response.status === 200) {
            let members = response.data.members
            getAllChatroom()
            if (selectedChatroomDatas?.chatroomData._cls === "ChatRoom.GroupChatRoom") {
                let chatroom = selectedChatroomDatas?.chatroomData
                chatroom.members_list = members
                setSelectedChatroomDatas(selectedChatroomDatas)
            }
            setSelectedUser("")
            setIsLoading(false)
        }
    }
    const handleDeletGroupMember = (response, setIsDeleting, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setCenterModalVisibility, setSelectedUser) => {
        if (response.status === 200) {
            let members = response.data.members
            setIsDeleting(false)
            getAllChatroom()
            if (selectedChatroomDatas?.chatroomData._cls === "ChatRoom.GroupChatRoom") {
                let chatroom = selectedChatroomDatas?.chatroomData
                chatroom.members_list = members
                setSelectedChatroomDatas(selectedChatroomDatas)
                setCenterModalVisibility(false)
            }
            setSelectedUser("")
        }
    }
    const handleLeaveGroup = (response, setIsDeleting, getAllChatroom, selectedChatroomDatas, chatSelected, selectedChat, setSelectedChatroomDatas, setCenterModalVisibility, setAdminModalVisibility) => {
        if (response.status === 200) {
            let members = response.data.members
            setIsDeleting(false)
            getAllChatroom()
            if (selectedChatroomDatas?.chatroomData._cls === "ChatRoom.GroupChatRoom") {
                let chatroom = selectedChatroomDatas?.chatroomData
                chatroom.members_list = members
                chatSelected(selectedChat, "leaveGroup")
                setSelectedChatroomDatas(selectedChatroomDatas)
                setCenterModalVisibility(false)
            }
            setAdminModalVisibility(false)
            // mixpanel.track('leave group')
        }
    }


    const handleRemoveModerator = (response, getAllChatroom, selectedChatroomDatas, setSelectedChatroomDatas, setSelectedUser, setIsLoading) => {
        if (response.status === 200) {
            let members = response.data.members
            getAllChatroom()
            if (selectedChatroomDatas?.chatroomData._cls === "ChatRoom.GroupChatRoom") {
                let chatroom = selectedChatroomDatas?.chatroomData
                chatroom.members_list = members
                setSelectedChatroomDatas(selectedChatroomDatas)
            }
            setSelectedUser("")
            setIsLoading(false)
        }


    }
    return {
        handleAddMembersToGroup,
        handleGroupNameUpdate,
        handleGroupEmailUpdate,
        handleGroupDPUpdate,
        handleGroupDescriptionUpdate,
        handleDeleteGroup,
        handleModerators,
        handleDeletGroupMember,
        handleLeaveGroup,
        handleRemoveModerator
    }
}