import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import "../WelcomePage/WelcomePageStyle.scss";
import AppContext from "../../../ContextAPI/AppContext";
import image from "../../../Assets/Images/hi.png"
import image1 from "../../../Assets/Images/image1.png"
import image2 from "../../../Assets/Images/image2.png"
import image3 from "../../../Assets/Images/image3.png"


export default function WelcomePage() {
  const container = useRef(null);
  const redirectBtn = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../../Assets/Animations/lets-chat"),
    });
  }, []);

  useEffect(() => {
    redirectBtn.current.click()
  }, [])
  const dateFun = () => {
    let date = new Date();
    let dayOfWeek = date.getDay();
    let item;
    switch (dayOfWeek) {
      case 1:
        item = {
          image: image1,
          des1: "Build your connections easily.",
          des2: "Fast and secure messages with user friendly features. "
        }
        return item;
      case 2:
        item = {
          image: image2,
          des1: "Send Emails smarter than before.",
          des2: "Everything in one place, at your fingertips. "
        }
        return item;
      case 3:
        item = {
          image: image3,
          des1: "Experience faster connections around you. ",
          des2: "A better way to send emails. "
        }
        return item;
      case 4:
        item = {
          image: image1,
          des1: "Build your connections easily.",
          des2: "Fast and secure messages with user friendly features. "
        }
        return item;
      case 5:
        item = {
          image: image2,
          des1: "Send Emails smarter than before.",
          des2: "Everything in one place, at your fingertips. "
        }
        return item;
      case 6:
        item = {
          image: image3,
          des1: "Experience faster connections around you. ",
          des2: "A better way to send emails. "
        }
        return item;
      default:
        item = {
          image: image2,
          des1: "Send Emails smarter than before.",
          des2: "Everything in one place, at your fingertips. "
        }
        return item;
    }
  }
  return (


    <AppContext.Consumer>

      {(context) => (
        <div className="__WelcomeWrapper" onClick={() => {
          context.leftNavPopupHandler("closeOnly")
          context.RightNavPopupHandler("closeOnly")
        }
        }>
          {context.sendModal ? <div>
            <div className="welcome-container anim">
              <img src={image} alt="" />

            </div>
            <div className="text-content">
              <div className="hi-text">Say "hi" to one of your work mates</div>

            </div></div> :
            <div>
              <div className="welcome-container">
                <img src={dateFun().image} alt="" />

              </div>
              <div className="text-content">
                <div> <h1>{dateFun().des1}</h1>
                  <h2>{dateFun().des2}</h2></div>

              </div>
            </div>}



          {context.windowDimensions.width > 600 ? <button style={{ display: "none" }} ref={redirectBtn}>PC</button> : <button ref={redirectBtn} style={{ display: "none" }} onClick={() => {
            context.setSelectedChatID("")
          }}>Mobile</button>}



        </div>
      )}


    </AppContext.Consumer>


  );
}